import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {useLocation, useParams} from 'react-router-dom'

import {Document, Packer, Paragraph, TextRun, HeadingLevel} from 'docx'
import {saveAs} from 'file-saver'
import GenerateDr from './GenerateDr'
import GenerateOa from './GenerateOa'
import GenerateWr from './GenerateWr'
import GenerateQMSax from './generateAnnexureReport/GenerateQMSax'
import GenerateEMSax from './generateAnnexureReport/GenerateEMSax'
import GenerateOHSMSax from './generateAnnexureReport/GenerateOHSMSax'
import GenerateFSMSax from './generateAnnexureReport/GenerateFSMSax'
import GenerateMDQMSax from './generateAnnexureReport/GenerateMDQMSax'
import GenerateFinalReport from './GenerateFinalReport'
import GenerateApprovalReport from './GenerateApprovalReport'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
import UAFLoader from '../../../loader/UafLoader'
import {toast} from 'react-toastify'
interface DocumentReview {
  id: string
  review_report_qa: string
  review_report_qb: string
  cab: string
  review: string[]
}
type Assessor = {
  id: string
  first_name: string
  last_name: string
}

type AssessorData = {
  update_at?: string
  scheme_name?: string
  assessment_number?: string
  assessor_data?: Assessor[]
  assessor_planner_updated_at?: string
}

type DocumentReviewData = {
  assessor_data?: AssessorData[]
  review_report_qa?: string
  review_report_qb?: string
}
interface LocationState {
  targetvalue?: any
}
const GenerateDARCreport = () => {
  const location = useLocation()
  const state = location.state as LocationState
  const [assessorDetail, setAssessorDetail] = useState<any>()
  console.log(assessorDetail, 'assessorDetail')
  const {targetvalue} = state || {}
  const authUser = localStorage.getItem('current_user_id')
  const [pdfInvoice, setPdfInvoice] = useState<File | null>(null)
  console.log(targetvalue, 'this is the target value')
  const documentReviewItem = targetvalue.find((item) => item.type === 'Document Review')
  const officeItem = targetvalue.find((item) => item.type === 'Office Assessment')
  const witnessItem = targetvalue.find((item) => item.type === 'Witness Assessment')
  const [loader, setLoader] = useState<boolean>(false)
  // Get the assessment_number of the found item
  const documentNumber = documentReviewItem?.assessment_number
  const officeNumber = officeItem?.assessment_number
  const witnessNumber = witnessItem?.assessment_number
  console.log("witnessNumberNumber:",witnessNumber);
  

  const {cab_id, arc_member_id, arc_no, stage} = useParams()
  const userId = cab_id
  const ARC_member = arc_member_id
  const ARC_no = arc_no
  const stage_id = stage
  const API_URL = process.env.REACT_APP_API_URL
  const [cabData, setCabData] = useState<any>()
  const [CABSchemeData, setCABSchemeData] = useState<any>()
  console.log(userId, ARC_member, 'USER')

  useEffect(() => {
    if (userId) {
      const getUserDetails = async () => {
        try {
          const response = await axios.get(`${API_URL}/account/patch/user/${userId}/`)
          setCabData(response.data)
          setCABSchemeData(response.data?.user_schemes)
        } catch (error) {
          console.error('API Error:', error)
          // throw error;
        }
      }
      getUserDetails()
    }
  }, [userId])
  useEffect(() => {
    if (userId) {
      const getAssessorDetails = async () => {
        try {
          const response = await axios.get(`${API_URL}/team/user/data/${ARC_member}/`)
          setAssessorDetail(response.data?.details)
        } catch (error) {
          // Handle errors
          console.error('API Error:', error)
          // throw error;
        }
      }
      getAssessorDetails()
    }
  }, [ARC_member])
  const QmsSchemeID = '745048c9-17e9-40bc-a8d7-6ef2a91e9aee'
  const OHSMSID = 'df28ddf3-b330-47d8-abec-a44333e4aabb'
  const FSMSID = '40f65889-bbe5-4784-9e10-38863a220be9'
  const EMSID = '07f858f5-7e61-4780-baf0-b1ff97210de1'
  const MDQMSID = '3d350ed1-ae27-44fe-8b2a-3c6a6029b774'

  const hasQmsScheme = CABSchemeData?.includes(QmsSchemeID)
  const hasOhsmsScheme = CABSchemeData?.includes(OHSMSID)
  const hasFsmsScheme = CABSchemeData?.includes(FSMSID)
  const hasEmsScheme = CABSchemeData?.includes(EMSID)
  const hasMdqmsScheme = CABSchemeData?.includes(MDQMSID)
  const noSchemeSelected = !(
    hasQmsScheme ||
    hasOhsmsScheme ||
    hasFsmsScheme ||
    hasEmsScheme ||
    hasMdqmsScheme
  )
  const CreatePDF = async (): Promise<File> => {
    return new Promise((resolve, reject) => {
      const input = document.getElementById('pdfContent')
      if (input) {
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'mm', 'a4')
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = pdf.internal.pageSize.getHeight()
            const imgProps = pdf.getImageProperties(imgData)
            const imgHeight = (imgProps.height * pdfWidth) / imgProps.width
            let heightLeft = imgHeight
            let position = 0
            pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight)
            heightLeft -= pdfHeight
            while (heightLeft >= 0) {
              position = heightLeft - imgHeight
              pdf.addPage()
              pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight)
              heightLeft -= pdfHeight
            }
            const pdfBlob = pdf.output('blob')
            const file = new File(
              [pdfBlob],
              `FinalARCReport_${assessorDetail?.first_name || ''} ${
                assessorDetail?.last_name || ''
              }.pdf`,
              {type: 'application/pdf'}
            )
            resolve(file) // Return the file
          })
          .catch((error) => {
            console.error('Error creating PDF:', error)
            reject(error)
          })
      } else {
        console.error("Element with id 'pdfContent' not found.")
        reject(new Error("Element with id 'pdfContent' not found."))
      }
    })
  }
  const generatePDF = () => {
    setLoader(true);
    const input = document.getElementById('pdfContent')
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'mm', 'a4')
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()
        const imgProps = pdf.getImageProperties(imgData)
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width

        let heightLeft = imgHeight
        let position = 0

        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight)
        heightLeft -= pdfHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          pdf.addPage()
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight)
          heightLeft -= pdfHeight
        }

        pdf.save(
          `FinalARCReport_${assessorDetail?.first_name || ''} ${
            assessorDetail?.last_name || ''
          }.pdf`
        )
        setLoader(false);
      })
    } else {
      console.error("Element with id 'pdfContent' not found.")
      setLoader(false);
    }
  }
  const handleSaveAndSubmit = async () => {
    try {
      // Await the PDF generation to get the resolved File object
      const pdfFile = await CreatePDF()

      setPdfInvoice(pdfFile)
    } catch (error) {
      console.error('Error during Save & Submit:', error)
    }
  }
  useEffect(() => {
    if (pdfInvoice) {
      handleSubmit()
    }
  }, [pdfInvoice])
  const handleSubmit = async () => {
    if (!pdfInvoice) {
      toast.error('Try again,your report have not been generated successfully.')
      return
    }

    try {
      setLoader(true)
      const formData = new FormData()
      if (ARC_member) {
        formData.append('assessor', ARC_member)
      } else {
        console.error('ARC_member is undefined')
      }
      if (stage_id) {
        formData.append('assessment_stage', stage_id)
      } else {
        console.error('stage_id is undefined')
      }
      if (userId) {
        formData.append('cab', userId)
      } else {
        console.error('userId is undefined')
      }
      if (pdfInvoice instanceof File) {
        formData.append('invoice_pdf', pdfInvoice)
      } else {
        console.error('pdfInvoice is not a valid File object')
      }

      // Make the POST request
      const response = await axios.post(`${API_URL}/arc/final/report/submission/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
        },
      })

      if (response.status === 200 || response.status === 201) {
        setLoader(false)
        toast.success('Your report have been submitted successfully.')
      } else {
        setLoader(false)
        toast.error('Fail to submit,Please try again.')
      }
    } catch (error) {
      setLoader(false)
      console.log(error)
      toast.error('Fail to submit.')
    }
  }
  return (
    <>
      {' '}
      {loader ? <UAFLoader /> : null}
      <div className='container'>
        <div id='pdfContent'>
          <div className='container'>
            <div className='row'>
              <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                <img
                  alt='Logo'
                  src={'/media/logos/uaf_logo.png'}
                  className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
                />
                <div className='uaf_title py-5 text-center'>
                  <h2 className=''>ACCREDITATION REVIEW COMMITTEE REPORT</h2>
                  <span className='fs-18'>ACCREDITATION PROCESS </span>
                </div>
              </div>
            </div>

            {/* Cab info */}
            <div
              className='mb-5 mb-xl-10'
              id='kt_profile_details_view'
              style={{width: '100%', background: '#fff', padding: '25px', borderRadius: '5px'}}
            >
              <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>
                    CAB INFORMATION<span style={{color: 'red'}}>*</span>
                  </h3>
                </div>
              </div>
              <div className='card-body p-9'>
              <div className="row mb-7">
  <div className="col-lg-3">CAB Name:</div>
  <div className="col-lg-9">{cabData?.company}</div>
</div>

<div className="row mb-7">
  <div className="col-lg-3">Key Location:</div>
  <div className="col-lg-9">
    <ul className="">
      {(() => {
        const keyLocation = cabData?.cab_country_active?.find(
          (location) => location.location_type === 'Key Location'
        );
        if (keyLocation) {
          return (
            <li>
              {keyLocation.address || ''}
              {keyLocation.city__name ? `, ${keyLocation.city__name}` : ''}
              {keyLocation.state__name ? `, ${keyLocation.state__name}` : ''}
              {keyLocation.country__name ? `, ${keyLocation.country__name}` : ''}
            </li>
          );
        }
        return null
      })()}
    </ul>
  </div>
</div>

<div className="row mb-7">
  <div className="col-lg-3">Other Locations:</div>
  <div className="col-lg-9">
    <ul className="">
      {(() => {
        const otherLocations = cabData?.cab_country_active?.filter(
          (location) => location.location_type === 'Others'
        );
        if (otherLocations && otherLocations.length > 0) {
          return otherLocations?.map((location, index) => (
            <li key={index}>
              {location.address || ''}
              {location.city__name ? `, ${location.city__name}` : ''}
              {location.state__name ? `, ${location.state__name}` : ''}
              {location.country__name ? `, ${location.country__name}` : ''}
            </li>
          ));
        }
        return null
      })()}
    </ul>
  </div>
</div>

                <div className='row mb-7'>
                  <div className='col-lg-3'>Scheme(s) of accreditation for ARC:</div>
                  <div className='col-lg-9'>
                    <ul>
                      {cabData?.scheme_names?.map((item) => (
                        <li key={item?.id}>{item?.name}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*Document review*/}
            <GenerateDr
              userId={userId}
              ARC_member={ARC_member}
              ARC_no={ARC_no}
              documentNumber={documentNumber}
            />

            {/*office review*/}
            <GenerateOa
              userId={userId}
              ARC_member={ARC_member}
              ARC_no={ARC_no}
              officeNumber={officeNumber}
            />

            {/* witness review */}
            {/* <GenerateWr
              userId={userId}
              ARC_member={ARC_member}
              ARC_no={ARC_no}
              witnessNumber={witnessNumber}
            /> */}
            {witnessNumber?.map((number, index) => (
        <GenerateWr
          key={index} // Unique key for each component in the list
          userId={userId}
          ARC_member={ARC_member}
          ARC_no={ARC_no}
          witnessNumber={number}
        />
      ))}
            {!noSchemeSelected && <h2 className='text-center'>Scopes of Accreditation</h2>}

            {hasQmsScheme && (
              <GenerateQMSax userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />
            )}
            {/* QMS Annexure */}
            {hasEmsScheme && (
              <GenerateEMSax userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />
            )}
            {hasOhsmsScheme && (
              <GenerateOHSMSax userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />
            )}
            {hasFsmsScheme && (
              <GenerateFSMSax userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />
            )}
            {hasMdqmsScheme && (
              <GenerateMDQMSax userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />
            )}
            {/* final report */}
            <GenerateFinalReport userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />

            <GenerateApprovalReport userId={userId} ARC_member={ARC_member} ARC_no={ARC_no} />
          </div>
        </div>
        <div className='text-end m-5'>
          {authUser === ARC_member && (
            <button className='btn btn-primary' onClick={handleSaveAndSubmit}>
              Save Final Report
            </button>
          )}
          <button className='ms-2 btn btn-primary' onClick={generatePDF}>
            Download
          </button>
        </div>
      </div>
    </>
  )
}

export default GenerateDARCreport
