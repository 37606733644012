/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Card4} from '../../../../../_metronic/partials/content/cards/Card4'
import {UserDataType} from '../../AssessorInterface'
// import { getCertificateList, getUserDetails } from '../acc';
import {getCertificateList, getUserDetails} from '../../assessorLead/components/AssessorFunction'
import FormRenderer from '../../../certificateForms/FormRender'
import {Link} from 'react-router-dom'
import {usePermissions} from '../../../../permission_component/PermissionContext'
import axios from 'axios'
import FolderHierarchy from '../../../../hierarchy/FolderHierarchy'
import AssessorHierarchy from '../../../../hierarchy/AssessorHierarchy'
import VerifiedAssessoruafDoc from './VerifiedAssessoruafDoc'
import VerifiedAssessorAllDoc from './VerifiedAssessorAllDoc'
interface AssessorIDProps {
  userId: string
}
interface CertificateType {
  id: string
  name: string
}
const VerifiedAssessorDoc: React.FC<AssessorIDProps> = ({userId}) => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const {assessorPermission, cabPermission, superUserPermission, updatePermissions} =
    usePermissions()
  const userIdObject = JSON.parse(userId)
  const uuid = userIdObject['*']
  const uuidRegex =
    /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  const uuids = uuid.match(uuidRegex)
  const urlId = uuids[0]

  const [searchQuery, setSearchQuery] = useState('')
  const [filteredValue, setFilteredValue] = useState<UserDataType[]>([])
  const [activeTab, setActiveTab] = useState('Document')

  const [userData, setUserData] = useState<UserDataType>()
  const [certificateList, setCertificateList] = useState<CertificateType>()
  const [formQuery, setFormQuery] = useState<any>()
  console.log(formQuery, 'formQuery......printed')
  console.log(userData, 'userData......printed')
  console.log(certificateList, 'certificateList......printed')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserDetails(urlId)
        setUserData(result.details)
      } catch (error) {
        // Handle errors
        console.error('Error:', error)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchCertificateList = async () => {
      try {
        const result = await getCertificateList()
        setCertificateList(result)
      } catch (error) {
        // Handle errors
        console.error('Error:', error)
      }
    }
    fetchCertificateList()
  }, [])

  useEffect(() => {
    if (userData?.scheme_names) {
      console.log(userData?.scheme_names)

      setFormQuery(userData?.scheme_names)
    }
  }, [userData])

  // Function to handle search query change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
    // Filter the value array based on the search query
    const filteredData = formQuery.filter((user) => {
      const fullName = `${user?.name}`.toLowerCase()
      const searchValue = e.target.value.toLowerCase()
      return fullName.includes(searchValue) || user?.name.toLowerCase().includes(searchValue)
    })
    setFilteredValue(filteredData)
  }

  return (
    <div className='row gy-10 gx-xl-10'>
      <div className='col-xl-12'>
        <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
          <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 p-4'>
            <li className='nav-item'>
              <div
                className={`nav-link ${activeTab === 'Document' ? 'active' : ''}`}
                onClick={() => setActiveTab('Document')}
              >
                Documents
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={`nav-link ${activeTab === 'Competence' ? 'active' : ''}`}
                onClick={() => setActiveTab('Competence')}
              >
                Competence Documents
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={`nav-link ${activeTab === 'UAF' ? 'active' : ''}`}
                onClick={() => setActiveTab('UAF')}
              >
                UAF 
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={`nav-link ${activeTab === 'Assessor' ? 'active' : ''}`}
                onClick={() => setActiveTab('Assessor')}
              >
                Assessor
              </div>
            </li>
          </ul>
        </div>
      </div>
      {activeTab === 'Competence' && (
      <div id='doc_1'>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-bolder my-2'>Documents</h3>

          <div className='d-flex my-2'>
            <div className='d-flex align-items-center position-relative me-4'>
              <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
              <input
                type='text'
                id='kt_filter_search'
                className='form-control form-control-white form-control-sm w-250px ps-9'
                placeholder='Enter Scheme'
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>

        <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
          {(searchQuery.length > 0 ? filteredValue : formQuery)?.map((item, index) => (
            <div key={item?.id} className='col-12 col-sm-12 col-xl'>
              <div className='card h-100'>
                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                  <Link
                    id={`doc_file_${index}`}
                    to={`details/${item?.id}`}
                    state={{userData: userData}}
                    className='text-gray-800 text-hover-primary d-flex flex-column'
                  >
                    <div className='symbol symbol-75px mb-6'>
                      <img src='/media/svg/files/pdf.svg' alt='' />
                    </div>
                    <div className='fs-5 fw-bolder mb-2'>{item?.name}</div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>)}
      {activeTab === 'Document' && (
        <AssessorHierarchy urlId={urlId} />
      )}
      {activeTab === 'Assessor' && (
        <VerifiedAssessoruafDoc urlId={urlId} />
      )}
       {activeTab === 'UAF' && (
        <VerifiedAssessorAllDoc urlId={urlId} />
      )}
    </div>
  )
}
export {VerifiedAssessorDoc}
