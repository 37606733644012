import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios'


import { KTIcon } from '../../../_metronic/helpers';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const TrainingList = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const [trainingList, setTrainingList] = useState<any[]>([])
    const [trainingId, setTrainingId] = useState<any>()
    const [pagenumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(1);
    const [totalcount, settotalcount] = useState(0);
    const [showDeleteTraining, setshowDeleteTraining] = useState<boolean>(false)
  
    const totalPagesArray = Array.from({ length: totalPages }, (_, index) => index + 1);
    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setPageNumber(page);
        }
    };

    const handleDeleteTraining = async () => {
        try {
            const response = await axios.delete(`${API_URL}/blog/training-article/destroy/${trainingId}/`)
            if (response) {
                toast.success('Blog Deleted Successfully');
                setshowDeleteTraining(false);
                fetchTrainingDAta();
                setTrainingId("");
            }
        } catch (error) {
            console.log(error)
        }
    }
    const fetchTrainingDAta = async () => {
        try {
            // setLoader(true);
            const response = await axios.get(
                `${API_URL}/blog/training-article/get/?page=${pagenumber}`
            );
            const pages = Math.ceil(response?.data?.count / 10);
            settotalcount(response?.data?.count);
            setTotalPages(pages);
            if (response.status === 200) {
                setTrainingList(response.data?.results);
            }
        } catch (error) {
            console.log(error);
        } finally {
            // setLoader(false);
        }
    };

    useEffect(() => {
        fetchTrainingDAta();
    }, [pagenumber]);

    const ShowDeleteModal = (id: any) => {
        setTrainingId(id)
        setshowDeleteTraining(true)
    }

    const formatMyDate = (date: Date | string | null) => {
        if (!date) return null;
      
        // If the date is a string, parse it to a Date object
        const parsedDate = typeof date === "string" ? new Date(date) : date;
      
        // Ensure the parsed date is valid
        if (isNaN(parsedDate.getTime())) return null;
      
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
        const day = String(parsedDate.getDate()).padStart(2, "0");
      
        return `${month}-${day}-${year}`;
      };

    return (
        <div id="v_blog_01">

            <div className='row jusify-content-space-between'>
                <div className='col-md-6 d-flex align-items-center justify-content-start'>

                    <h2 className='mb-3 p-0 '>Training List
                        <span
                            className='card-label fw-bold fs-1 m-2'
                            style={{ backgroundColor: '#136BA1', color: 'white', padding: '0.3em 0.5em' }}
                        >
                            {totalcount}
                        </span>
                    </h2>
                </div>
            </div>



            <div className='card '>
                <div className='card-header pt-5 ' style={{ borderBottom: "1px solid #333 !important" }}>
                    <div className='card-title'>
                        {/* <div className='d-flex align-items-center position-relative my-1'>
                            <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                                <svg
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <rect
                                        opacity='0.5'
                                        x='17.0365'
                                        y='15.1223'
                                        width='8.15546'
                                        height='2'
                                        rx='1'
                                        transform='rotate(45 17.0365 15.1223)'
                                        fill='currentColor'
                                    />
                                    <path
                                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                        fill='currentColor'
                                    />
                                </svg>
                            </span>
                            <input
                                id="le_lu_ri"
                                type="text"
                                data-kt-user-table-filter="search"
                                className="form-control form-control-solid w-250px ps-14"
                                onChange={handleSearchChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearchChange(e);
                                    }
                                }}
                                placeholder="Search"
                            />
                        </div> */}
                    </div>
                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end' style={{ gap: "10px " }} data-kt-user-table-toolbar='base'>
                            <Link
                                id="le_lu_mai_1"
                                to="/crafted/addtraining"
                                className='btn btn-primary'
                            >
                                <span className='svg-icon svg-icon-2'>
                                    <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <rect
                                            opacity='0.5'
                                            x='11.364'
                                            y='20.364'
                                            width='16'
                                            height='2'
                                            rx='1'
                                            transform='rotate(-90 11.364 20.364)'
                                            fill='currentColor'
                                        />
                                        <rect x='4.36396' y='11.364' width='16' height='2' rx='1' fill='currentColor' />
                                    </svg>
                                </span>
                                Add Training</Link>
                        </div>
                    </div>
                </div>
                <div className='card-body pt-5'>
                    <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                        <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>


                                <th className='min-w-125px'>Title</th>
                                <th className='min-w-125px'>Trainer Name</th>
                                <th className='min-w-125px'>Training Date(s)</th>
                                <th className='min-w-125px'>Action</th>

                                {/* <th className='text-center min-w-100px'>Actions</th> */}
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-semibold'>

                            {/* {(filteredValue?.length > 0 ? filteredValue : value)?.map((item, index) => ( */}
                            {trainingList?.map((item, index) => (
                                <tr key={index}>

                                    <td>
                                        <div className='text-gray-800 text-hover-primary mb-1'>
                                            {item?.training_name}
                                        </div>
                                    </td>
                                    <td>
                                        <div className='text-gray-800 text-hover-primary mb-1'>
                                            {item?.trainer_name}
                                        </div>
                                    </td>
                                    <td>
                                        {item?.multi_date
                                            ? (() => {
                                                try {
                                                    // Replace 'None' with null
                                                    const fixedMultiDate = item.multi_date
                                                        .replace(/None/g, 'null')
                                                        .replace(/'/g, '"')
                                                    const dates = JSON.parse(fixedMultiDate)
                                                    return Array.isArray(dates)
                                                        ? dates.map((date, index) => (
                                                            <React.Fragment key={index}>
                                                                {date ? formatMyDate(new Date(date)) : '--'}
                                                                <br />
                                                            </React.Fragment>
                                                        ))
                                                        : '--'
                                                } catch (e) {
                                                    return '--'
                                                }
                                            })()
                                            : '--'}
                                    </td>

                                    <td>
                                        <div className='d-flex justify-content-start flex-shrink-0'>

                                            <Link
                                                to={`/crafted/training/${item?.id}`}
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                                <KTIcon iconName='pencil' className='fs-3' />
                                            </Link>
                                            <a
                                                onClick={() => ShowDeleteModal(item?.id)}
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            >
                                                <KTIcon iconName='trash' className='fs-3' />
                                            </a>
                                        </div>
                                    </td>



                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <ul className='pagination'>
                        <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
                            <a id="le_lu_mai_4" href='#' className='page-link' onClick={() => handlePageChange(pagenumber - 1)}>
                                <i className='previous'></i>
                            </a>
                        </li>
                        {totalPagesArray.map((page, index) => (
                            <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
                                <a id={`le_lu_page_${index}`} href='#' className='page-link' onClick={() => handlePageChange(page)}>
                                    {page}
                                </a>
                            </li>
                        ))}
                        <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
                            <a id="le_lu_mai_5" href='#' className='page-link' onClick={() => handlePageChange(pagenumber + 1)}>
                                <i className='next'></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div >
            <Modal
                show={showDeleteTraining}
                onHide={() => setshowDeleteTraining(false)}
                backdrop='static'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete Training</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>Do you want to delete this Training ?</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setshowDeleteTraining(false)}>
                        Close
                    </Button>
                    <Button variant='danger' id='delete_cab_lead' onClick={() => handleDeleteTraining()}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TrainingList