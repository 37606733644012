import React, {useCallback, useEffect, useState} from 'react'
import UAFLoader from '../../loader/UafLoader'
import {Button, Modal} from 'react-bootstrap'
import Select, {components} from 'react-select'
import axios from 'axios'

import {toast} from 'react-toastify'
import debounce from 'lodash/debounce'
import {MultiValue} from 'react-select'
import _ from 'lodash'
import {Link, useNavigate} from 'react-router-dom'

import AssessmentPlannerTableModal from '../organisation/AssessmentPlanner/component/modal/AssessmentPlannerModal'

const API_URL = process.env.REACT_APP_API_URL
const AllAssessmentPlan = () => {
  const [loader, setLoader] = useState<boolean>(false)
  const [dataWithScheme, setDataWithScheme] = useState<any>([])
  console.log(dataWithScheme,"dataWithScheme")
  const [showAssessmentTableModal, setShowAssessmentTableModal] = useState<boolean>(false)
  const [assessmentPlannerTableData, setAssessmentPlannerTableData] = useState<any>([])
  const [showAssessentClicked, setShowAssessmentClicked] = useState<any>()
  const [pagenumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const totalPagesArray = Array.from({length: totalPages}, (_, index) => index + 1)
  const handleSearchChange = useCallback(
    debounce(async (e) => {
      const searchValue = e?.target?.value
      if (searchValue) {
        try {
          const response = await axios.get(
            `${API_URL}/cab/get/assessor/planner/pagination/?search=${searchValue}`
          )

          if (response.status === 200) {
            setAssessmentPlannerTableData(response.data?.results)
          }
        } catch (error) {
          console.log(error)
        } finally {
        }
      } else {
        getAssessmentPlannerData()
      }
    }, 500),
    []
  )
  useEffect(() => {
    getAssessmentPlannerData()
  }, [pagenumber])

  const getAssessmentPlannerData = () => {
    setLoader(true)
    axios
      .get(`${API_URL}/cab/get/assessor/planner/pagination/?page=${pagenumber}`)
      .then((response) => {
        const result = response.data.results // Assuming API returns paginated data in 'results'
        // const total = response.data.total;

        // Calculate total pages
        const pages = Math.ceil(response?.data?.count / 10) // Each page has 10 items
        setTotalPages(pages)

        // Sort the result array by createdAt field in descending order
        result.sort((a, b) => {
          // Convert createdAt strings to Date objects for comparison
          const dateA = new Date(a.create_at).getTime()
          const dateB = new Date(b.create_at).getTime()

          // Compare the dates
          return dateB - dateA
        })

        setAssessmentPlannerTableData(result)
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        console.error('API Error:', error)
      })
  }

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setPageNumber(page)
    }
  }
  const fetchAssessmentSchemeData = async () => {
    const responses = await Promise.all(
      assessmentPlannerTableData?.map(async (assessment) => {
        try {
          setLoader(true)
          const response = await axios.get(
            `${API_URL}/cab/get/assessor/planner/scheme/${assessment.id}/data`
          )
          return {...assessment, schemeData: response.data}
        } catch (error) {
          console.error('API Error:', error)
          return {...assessment, schemeData: []}
        }
      })
    )
    const validResponses = responses?.filter((response) => response !== null)
    setDataWithScheme(responses)
    setLoader(false)
  }
  useEffect(() => {
    fetchAssessmentSchemeData()
  }, [assessmentPlannerTableData])
  const handlePaid = async (item) => {
    const data = {
      is_paid: true,
    }
    try {
      setLoader(true)
      const response = await axios.put(
        `${API_URL}/cab/assessor/planner/paid/${item?.id}/update/`,
        data  
      )
      if (response?.status === 200 || response?.status === 201) {
        setLoader(false)
        toast.success('Paid Status Updated')
        getAssessmentPlannerData()
      } else {
        setLoader(false)
        toast.error('Paid Status Updation Fail')
        getAssessmentPlannerData()
      }
    } catch (error) {
      setLoader(false)
      console.log(error)
      toast.error('Error to update status')
    }
  }

  const formatMyDate = (date: Date | string | null) => {
    if (!date) return null;
  
    // If the date is a string, parse it to a Date object
    const parsedDate = typeof date === "string" ? new Date(date) : date;
  
    // Ensure the parsed date is valid
    if (isNaN(parsedDate.getTime())) return null;
  
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
  
    return `${month}-${day}-${year}`;
  };
  return (
    <>
      {loader ? <UAFLoader /> : null}
      {/* <div className='card card-flush'> */}
      <div className='card-header pt-5'>
        <div className='card-title w-100 d-flex justify-content-between'>
          <div className='d-flex align-items-center position-relative my-1'>
            <span className='svg-icon svg-icon-1 position-absolute ms-6'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  opacity='0.5'
                  x='17.0365'
                  y='15.1223'
                  width='8.15546'
                  height='2'
                  rx='1'
                  transform='rotate(45 17.0365 15.1223)'
                  fill='currentColor'
                />
                <path
                  d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search...'
              onChange={handleSearchChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearchChange(e)
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className='card-body pt-5 assesment_planner_table'>
        <table
          className='table align-middle table-row-dashed fs-6 gy-5 assessmentPlanner_table'
          id='kt_table_users'
        >
          <thead>
            <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
              <th className='min-w-125px'>Assessment Number</th>
              <th className='min-w-125px'>CAB Organisation</th>
              <th className='min-w-125px'>Assessment Stage</th>
              <th className='min-w-125px'>Assessment Type</th>
              <th className='min-w-125px'>Assessment Dates</th>
              {/* <th className='min-w-125px'>Schemes Type/ Remark</th> */}
              <th className='min-w-125px'>Schemes Type/ Mandays</th>
              <th className='min-w-125px'>Assessment Invoice</th>
              <th className='min-w-125px'>Team Lead/ Team Member</th>
              <th className='min-w-125px'>Status</th>
              <th className='text-end min-w-100px'>Actions</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-semibold'>
            {dataWithScheme?.map((item, index) => (
              <tr>
                <td>
                  <a className='text-gray-800 text-hover-primary mb-1'>
                    {item?.assessment_number || '--'}
                  </a>
                </td>
                <td>
                  {' '}
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    {item?.cab_company || '--'}
                  </div>
                </td>
                <td>{item?.assessment_stage_name}</td>
                <td>{item?.assessment_name}</td>
                <td>
                  {item?.multi_date
                    ? (() => {
                        try {
                          // Replace 'None' with null
                          const fixedMultiDate = item.multi_date
                            .replace(/None/g, 'null')
                            .replace(/'/g, '"')
                          const dates = JSON.parse(fixedMultiDate)
                          return Array.isArray(dates)
                            ? dates.map((date, index) => (
                                <React.Fragment key={index}>
                                  {date ? formatMyDate(new Date(date)) : '--'}
                                  <br />
                                </React.Fragment>
                              ))
                            : '--'
                        } catch (e) {
                          return '--'
                        }
                      })()
                    : '--'}
                </td>

                {/* <td style={{paddingLeft: '0.75rem'}}>
                  {item?.schemeData?.map((i) => (
                    <React.Fragment key={index}>
                      {i?.scheme_short_name} - {i?.remark}
                      <br />
                    </React.Fragment>
                  ))}
                </td> */}
                <td style={{paddingLeft: '0.75rem'}}>
                  {item?.schemeData?.map((i) => (
                    <React.Fragment key={index}>
                      {i?.scheme_short_name} - {i?.assessment_duration}
                      <br />
                    </React.Fragment>
                  ))}
                </td>

                {item?.is_approved === true ? (
                  <td>
                    <div className='badge badge-light fw-bolder'>
                      <div className='badge badge-light-success fw-bold'>{item?.invoice}</div>
                    </div>
                  </td>
                ) : (
                  <td>
                    <div className='badge badge-light fw-bolder'>
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    </div>
                  </td>
                )}
                <td style={{paddingLeft: '0.75rem'}}>
                  {_.uniqBy(item?.get_assessment_location, 'assessorId')?.map((locationItem) => (
                    <React.Fragment key={index}>
                      {locationItem?.assessor_name}
                      <br />
                    </React.Fragment>
                  ))}
                </td>
                {item?.is_paid === true ? (
                  <td>
                    <div className='badge badge-light fw-bolder'>
                      <div className='badge badge-light-success fw-bold'>Paid</div>
                    </div>
                  </td>
                ) : (
                  <td>
                    <div className='badge badge-light fw-bolder'>
                      <div className='badge badge-light-danger fw-bold'>Unpaid...</div>
                    </div>
                  </td>
                )}
                <td className='text-end'>
                  <div className='dropdown'>
                    <button
                      className='btn btn-light btn-active-light-primary btn-sm dropdown-toggle'
                      type='button'
                      id={`dropdownMenuButton${index}`}
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      Actions
                    </button>

                    <ul className='dropdown-menu' aria-labelledby={`dropdownMenuButton${index}`}>
                      <li>
                        <a
                          className='dropdown-item'
                          href='#'
                          onClick={() => {
                            setShowAssessmentClicked(item)
                            setShowAssessmentTableModal(true)
                          }}
                        >
                          Generate Assessment Invoice
                        </a>
                      </li>
                      <li>
                        <a
                          className='dropdown-item'
                          href='#'
                          onClick={() => {
                            handlePaid(item)
                            // setShowAssessmentTableModal(true)
                          }}
                        >
                          Paid
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ul className='pagination'>
          <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
            <a
              id='link_id_1'
              href='#'
              className='page-link'
              onClick={() => handlePageChange(pagenumber - 1)}
            >
              <i className='previous'></i>
            </a>
          </li>
          {totalPagesArray.map((page, index) => (
            <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
              <a
                id={`page_number_${index}`}
                href='#'
                className='page-link'
                onClick={() => handlePageChange(page)}
              >
                {page}
              </a>
            </li>
          ))}
          <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
            <a
              href='#'
              id='link_id_2'
              className='page-link'
              onClick={() => handlePageChange(pagenumber + 1)}
            >
              <i className='next'></i>
            </a>
          </li>
        </ul>
      </div>
      {assessmentPlannerTableData?.length && (
        <AssessmentPlannerTableModal
          approval={() => getAssessmentPlannerData()}
          show={showAssessmentTableModal}
          handleClose={() => setShowAssessmentTableModal(false)}
          showAssessentClicked={showAssessentClicked}
        />
      )}
      {/* </div> */}
    </>
  )
}

export default AllAssessmentPlan
