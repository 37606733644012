import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import axios from 'axios'
import Select, {SingleValue} from 'react-select'
import AddToGroup from '../team-mangement/components/settings/cards/AddToGroup'
import {toast} from 'react-toastify'
import {Button, Dropdown, DropdownButton, Modal} from 'react-bootstrap'
import {debounce} from '@mui/material'
import UAFLoader from '../../loader/UafLoader'

const BASE_URL = process.env.REACT_APP_API_URL

const GlobalCluster = () => {
  const [value, setValue] = useState<any[]>([])
  const [filteredValue, setFilteredValue] = useState<any[]>([])
  const [programme, setProgramme] = useState<any[]>([])
  const [addNewScheme, setAddNewScheme] = useState({name: '', standard: ''})
  const [showEditRole, setShowEditRole] = useState<any>()
  const [showAddScheme, setShowAddScheme] = useState(false)
  const [loader, setLoader] = useState<boolean>(false)
  const fetchData = async () => {
    try {
      setLoader(true)
      const response = await axios.get(`${BASE_URL}/global/cluster/all/filter/`)
      setValue(response.data)
      setLoader(false)
    } catch (error) {
      console.log(error)
      setLoader(false)
    }
  }

  const fetchPrograme = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/global/standard/data/list/`)
      setProgramme(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchPrograme()
    fetchData()
  }, [])

  const Validation = () => {
    if (!addNewScheme?.name) {
      toast.error('Please Enter Cluster First')
      return false
    } else if (!addNewScheme?.standard) {
      toast.error('Please Enter Standard First')
      return false
    }

    return true
  }
  const AddScheme = async () => {
    if (!Validation()) return
    try {
      setLoader(true)
      const response = await axios.post(`${BASE_URL}/global/cluster/post/`, addNewScheme)
      if (response.status === 201 || response.status === 200) {
        setLoader(false)
        fetchData()
        setAddNewScheme({name: '', standard: ''})
        toast.success('Cluster created Successfully')
      }
    } catch (error) {
      console.log(error)
      setLoader(false)
    }
  }

  const handleEditScheme = async (id, name) => {
    try {
      const response = await axios.put(`${BASE_URL}/global/cluster/update/${id}/`, {name})
      if (response.status === 200) {
        fetchData()
        toast.success('Standard Updated Successfully')
      }
    } catch (error) {
      console.log(error)
      toast.error('Error Updating Role')
    }
  }

  const handleReject = async (id:string) => {
    try {
      const response = await axios.put(`${BASE_URL}/global/cluster/update/${id}/`, {is_status:false})
      if (response.status === 200) {
        fetchData()
        toast.success('Status Updated Successfully')
      }
    } catch (error) {
      console.log(error)
      toast.error('Error Updating Role')
    }
  }
  const handleAccept = async (id:string) => {
    try {
      const response = await axios.put(`${BASE_URL}/global/cluster/update/${id}/`, {is_status:true})
      if (response.status === 200) {
        fetchData()
        toast.success('Status Updated Successfully')
      }
    } catch (error) {
      console.log(error)
      toast.error('Error Updating Role')
    }
  }

  const handleShowScheme = async () => {
    setShowAddScheme(true)
  }

  const handleSearchChange = useCallback(
    debounce(async (e) => {
      const searchValue = e?.target?.value
      console.log(searchValue, 'searchValue')
      if (searchValue) {
        try {
          const endpoint = `${BASE_URL}/global/cluster/data/list/?search=${searchValue}`

          // Make the API request
          const response = await axios.get(endpoint)

          // Extract and handle the data
          const result = response?.data || []
          setFilteredValue(result)
        } catch (error) {
          console.log(error)
        } finally {
        }
      } else {
        setFilteredValue([])
      }
    }, 500),
    []
  )
  return (
    <>
      {loader ? <UAFLoader /> : null}
      <div id='text_context'>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-12'>
            <h1 className='mb-5'>Clusters</h1>
            <div className='card card-xl-stretch mb-xl-8'>
              <div className='card-header border-0 pt-5'>
                <div className='card-title'>
                  <div className='d-flex align-items-center position-relative my-1'>
                    <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.5'
                          x='17.0365'
                          y='15.1223'
                          width='8.15546'
                          height='2'
                          rx='1'
                          transform='rotate(45 17.0365 15.1223)'
                          fill='currentColor'
                        />
                        <path
                          d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                    <input
                      type='text'
                      id='user_list_2'
                      data-kt-user-table-filter='search'
                      className='form-control form-control-solid w-250px ps-14'
                      onChange={handleSearchChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSearchChange(e)
                        }
                      }}
                      placeholder='Search '
                    />
                  </div>
                </div>

                <div className='me-0'>
                  <button
                    className='btn btn-sm btn-primary '
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={handleShowScheme}
                  >
                    + Add Cluster
                  </button>
                </div>
              </div>

              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>Name </th>
                        <th className='min-w-150px'>Scheme</th>
                        <th className='min-w-125px'>Status</th>
                        <th className='min-w-140px'>Date Created</th>
                        <th className='min-w-120px'>Last Update</th>
                        <th className='min-w-100px text-end'>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {(filteredValue?.length > 0 ? filteredValue : value)?.map((user, index) => (
                        <tr key={index}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                  {user?.name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                  {user?.scheme_name}
                                </div>
                              </div>
                            </div>
                          </td>

                          <td>
                            {user?.is_status === true ? (
                              <div className='badge badge-light-primary fw-bold'>Active</div>
                            ) : (
                              <div className='badge badge-light-danger fw-bold'>InActive</div>
                            )}
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary d-block fs-6'
                            >
                              {new Date(user?.create_at).toLocaleDateString()}
                            </a>
                          </td>
                          <td className='text-end'>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack mb-2'>
                                <span className='text-muted me-2 fs-7 fw-semibold'>
                                  {new Date(user?.update_at).toLocaleDateString()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              {/* <a
                                onClick={() => setShowEditRole(user)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTIcon iconName='pencil' className='fs-3' />
                              </a> */}
                              <DropdownButton
                                className=''
                                id='dropdown-basic-button'
                                title='Action'
                              >
                                {user?.is_status ? (
                                  
                                  <Dropdown.Item onClick={() => handleReject(user?.id)}>
                                  InActive
                                </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item onClick={() => handleAccept(user?.id)}>
                                    Active
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Item onClick={() => setShowEditRole(user)}>
                                  Edit
                                </Dropdown.Item>
                              </DropdownButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <Modal show={showEditRole} onHide={() => setShowEditRole('')} backdrop='static'>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Cluster</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    className='form-control form-control-solid'
                    type='text'
                    onChange={(e) => setShowEditRole({...showEditRole, name: e?.target?.value})}
                    value={showEditRole?.name}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={() => setShowEditRole('')}>
                    Close
                  </Button>
                  <Button
                    variant='success'
                    onClick={() => {
                      handleEditScheme(showEditRole?.id, showEditRole?.name)
                      setShowEditRole('')
                    }}
                  >
                    Update
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={showAddScheme} onHide={() => setShowAddScheme(false)} backdrop='static'>
                <Modal.Header closeButton>
                  <Modal.Title>Add Cluster</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='mb-5 mt-5' style={{color: '#136ba1'}}>
                    <Select
                      className='cab-control'
                      value={
                        addNewScheme?.standard
                          ? programme.find((p) => p.id === addNewScheme.standard)
                            ? {
                                value: addNewScheme.standard,
                                label:
                                  programme.find((p) => p.id === addNewScheme.standard)?.name || '',
                              }
                            : null
                          : null
                      }
                      onChange={(selectedOption) =>
                        setAddNewScheme((prev) => ({
                          ...prev,
                          standard: selectedOption?.value || '',
                        }))
                      }
                      options={programme.map((item) => ({
                        value: item.id, // Use id as the value
                        label: item.name, // Use name as the label
                      }))}
                      placeholder='Select Standard'
                      styles={{
                        control: (base) => ({
                          ...base,
                          borderColor: '#136ba1',
                          boxShadow: 'none',
                          '&:hover': {
                            borderColor: '#136ba1',
                          },
                        }),
                        option: (base, state) => ({
                          ...base,
                          backgroundColor: state.isSelected ? '#136ba1' : 'white',
                          color: state.isSelected ? 'white' : '#136ba1',
                          '&:hover': {
                            backgroundColor: '#136ba1',
                            color: 'white',
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className='mb-5'>
                    <label className='form-label fw-bold'>Cluster:</label>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Enter Cluster Name'
                      value={addNewScheme.name || ''}
                      onChange={(e) =>
                        setAddNewScheme((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={() => setShowAddScheme(false)}>
                    Close
                  </Button>
                  <Button
                    variant='success'
                    onClick={() => {
                      if (addNewScheme.standard && addNewScheme.name) {
                        AddScheme()
                        setShowAddScheme(false)
                      } else {
                        alert('Please select a scheme and enter a standard.')
                      }
                    }}
                  >
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GlobalCluster
