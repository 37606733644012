/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'

import React, { useCallback, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'


const API_URL = process.env.REACT_APP_API_URL

// type Props = {
//   className: string
// }
type Props = {
    handleClose: () => void
}
interface PendingAssessor {
    id: string
    first_name: string
    last_name: string
    email: string
    phone: string
    is_assessor: boolean
    is_verify_assessor: boolean
    assessor: string
    store_password: string
    scheme_names: { id: string; name: string }[]
    programme_names: { id: string; name: string }[]
    accessor_approve_date: string
    create_at: string
}

// const BusinessUnitList = ({ className }: Props) => (
const ExportAllAssessments: React.FC = () => {

    const [assessmentPlannerTableData, setAssessmentPlannerTableData] = useState<any>([])
    const [dataWithScheme, setDataWithScheme] = useState<any>([])

    const formatMyDate = (date: Date | string | null) => {
        if (!date) return null;
      
        // If the date is a string, parse it to a Date object
        const parsedDate = typeof date === "string" ? new Date(date) : date;
      
        // Ensure the parsed date is valid
        if (isNaN(parsedDate.getTime())) return null;
      
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
        const day = String(parsedDate.getDate()).padStart(2, "0");
      
        return `${month}-${day}-${year}`;
      };

    const getAssessmentPlannerData = () => {
        axios
            .get(`${API_URL}/cab/get/assessor/planner/`)
            .then((response) => {
                const result = response.data; // Assuming API returns paginated data in 'results'
                result.sort((a, b) => {
                    const dateA = new Date(a.create_at).getTime()
                    const dateB = new Date(b.create_at).getTime()
                    return dateB - dateA
                })

                setAssessmentPlannerTableData(result)
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };




    const fetchAssessmentSchemeData = async () => {
        // setLoader(true)
        const responses = await Promise.all(
            assessmentPlannerTableData?.map(async (assessment) => {
                try {
                    const response = await axios.get(
                        `${API_URL}/cab/get/assessor/planner/scheme/${assessment.id}/data`
                    )
                    return { ...assessment, schemeData: response.data }
                } catch (error) {
                    console.error('API Error:', error)
                    return { ...assessment, schemeData: [] } // Handle the error by returning empty array or some error indicator
                }
            })
        )
        const validResponses = responses?.filter((response) => response !== null)
        setDataWithScheme(responses)

        // setLoader(false)
    }
    useEffect(() => {
        getAssessmentPlannerData()
    }, [])
    useEffect(() => {
        fetchAssessmentSchemeData()
    }, [assessmentPlannerTableData])

    const downloadExcel = () => {
        const workbook = XLSX.utils.book_new()

        // Prepare data for Excel
        const sheetData = dataWithScheme.flatMap((item) =>
            item.schemeData.map((scheme, index) => ({
                'Assessment Number': index === 0 ? scheme.assessment_number || '--' : '',
                'CAB Organisation': index === 0 ? scheme.company_name || '--' : '',
                'Assessment Type': index === 0 ? scheme.assessment_type : '',
                'Assessment Dates':
                    index === 0
                        ? scheme.multi_date
                            ? (() => {
                                try {
                                    return JSON.parse(item.multi_date.replace(/'/g, '"'))
                                        .map((date) => formatMyDate(new Date(date)))
                                        .join(',')
                                } catch (error) {
                                    console.error('Invalid JSON format in multi_date:', error)
                                    return '--'
                                }
                            })()
                            : '--'
                        : '',

                'Schemes Type': scheme.scheme_short_name,
                Remark: scheme.remark,
                'Assessment Duration': scheme.assessment_duration,
                'Assessor Name/Assessment Location': scheme.assessor_data
                    ? scheme.assessor_data
                        .map(
                            (item) =>
                                `${item.assessor.first_name} ${item.assessor.last_name}/ ${item.location}`
                        )
                        .join(',')
                    : '--',
                'Major NC CAP Start Date': scheme.major_nc_cab_start_date
                    ? formatMyDate(new Date(scheme.major_nc_cab_start_date))
                    : '--',
                'Major NC Closure Date': scheme.major_nc_cab_closer_date
                    ? formatMyDate(new Date(scheme.major_nc_cab_closer_date))
                    : '--',
                'Minor NC CAP start Date': scheme.minor_nc_cab_start_date
                    ? formatMyDate(new Date(scheme.minor_nc_cab_start_date))
                    : '--',
                'Minor NC Closure Date': scheme.minor_nc_cab_end_date
                    ? formatMyDate(new Date(scheme.minor_nc_cab_end_date))
                    : '--',
                'COI CAB': scheme.coi_cab === true ? 'Approved' : 'Pending...',
                'COI Assessor': scheme.coi_assessor === true ? 'Approved' : 'Pending...',
                'Job Order': scheme.job_order_number || 'Pending...',
                'Assessment Invoice': scheme.is_approved ? scheme.invoice || 'Pending...' : 'Pending...',
            }))
        )

        // Create worksheet and append data
        const worksheet = XLSX.utils.json_to_sheet(sheetData)
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Assessment Data')

        // Save the workbook as an Excel file
        XLSX.writeFile(workbook, 'assessment_data.xlsx')
    }


    return (
        <>

            <button className='thm-btn' onClick={downloadExcel}>
                Download Report
            </button>

        </>
    )
}
export { ExportAllAssessments }
