import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import axios from 'axios'
import Select, {MultiValue, SingleValue, ActionMeta} from 'react-select'
import {toast} from 'react-toastify'
import _ from 'lodash'

interface OptionType {
  label: string
  value: string | number
}

interface RowType {
  id: number
  type: string | null
  stage: string | number | null
  assessment_number: (string | number)[]
}

interface ARCAssignModalProps {
  show: boolean
  handleClose: () => void
  fetchAssesorUser: () => void
}

const ARCAssignModal: React.FC<ARCAssignModalProps> = ({show, handleClose, fetchAssesorUser}) => {
  const BASE_URL = process.env.REACT_APP_API_URL || ''

  const [cabUserDetail, setCabUserDetail] = useState<OptionType[]>([])
  const [assessorDetail, setAssessorDetail] = useState<OptionType[]>([])
  const [arcMemberSelect, setARCMemberSelect] = useState<OptionType[]>([])
  const [assessmentStageList, setAssessmentStageList] = useState<OptionType[]>([])
  const [assementType, setAssessmentType] = useState<any[]>([])
  const [rows, setRows] = useState<RowType[]>([
    {id: 1, type: null, stage: null, assessment_number: []},
  ])
  console.log(rows, 'rows')
  const [roleList, setRoleList] = useState<OptionType[]>([])
  const [assessorPlannerDataFromAssessment, setAssessorPlannerDataFromAssessment] = useState<any[]>(
    []
  )

  const [cabId, setCabId] = useState<string | number | null>(null)
  const [typeName, setTypeName] = useState<string | null>(null)
  const [stageId, setStageId] = useState<string | number | null>(null)

  const uniqueAssessmentNumbers = _.uniqBy(
    assessorPlannerDataFromAssessment,
    'assessment_number'
  ).map((item) => ({
    label: item.assessment_number,
    value: item.assessment_number,
  }))

  useEffect(() => {
    const fetchCabUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/nc/management/completed/cab/`)
        setCabUserDetail(
          response.data?.data.map((item: any) => ({
            label: item.registrar__company,
            value: item.registrar,
          }))
        )
      } catch (error) {
        console.error(error)
        toast.error('Failed to load CAB users.')
      }
    }

    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/role/list/`)
        setRoleList(
          response.data?.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))
        )
      } catch (error) {
        console.error(error)
        toast.error('Failed to load roles.')
      }
    }

    fetchCabUsers()
    fetchRoles()
  }, [])

  useEffect(() => {
    const getTypeList = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/cab/assessment/list/`)
        setAssessmentType(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    getTypeList()
  }, [])

  useEffect(() => {
    if (cabId) {
      const fetchStages = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/cab/assessment/stage/list/?cab=${cabId}`)
          setAssessmentStageList(
            response.data?.map((item: any) => ({
              label: item.name,
              value: item.id,
            }))
          )
        } catch (error) {
          console.error(error)
          toast.error('Failed to load assessment stages.')
        }
      }

      const fetchAssessorDetails = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/nc/management/missing/assessor/${cabId}/`)
          setAssessorDetail(
            response.data.map((item: any) => ({
              label: `${item.first_name} ${item.last_name}`,
              value: item.id,
            }))
          )
        } catch (error) {
          console.error(error)
          toast.error('Failed to load assessor details.')
        }
      }

      fetchStages()
      fetchAssessorDetails()
    }
  }, [cabId])

  useEffect(() => {
    if (cabId && typeName) {
      const fetchPlannerData = async () => {
        try {
          const response = await axios.get(
            `${BASE_URL}/cab/filter/scheme/assessor/planner/?cab=${cabId}&assessment_type_name=${typeName}`
          )
          setAssessorPlannerDataFromAssessment(response.data)
        } catch (error) {
          console.error(error)
          toast.error('Failed to load planner data.')
        }
      }

      fetchPlannerData()
    }
  }, [cabId, typeName])

  const handleCabChange = (selectedOption: SingleValue<OptionType>) => {
    setCabId(selectedOption?.value || null)
    resetDependentFields()
  }

  const handleTypeChange = (id: number, selectedOption: SingleValue<OptionType>) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? {...row, type: selectedOption?.label || null} : row))
    )
    setTypeName(selectedOption?.label || null)
  }

  const handleStageChange = (id: number, selectedOption: SingleValue<OptionType>) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? {...row, stage: selectedOption?.value || null} : row))
    )
    setStageId(selectedOption?.value || null)
  }

  const handleAssessmentChange = (
    id: number,
    selectedOptions: SingleValue<OptionType> | MultiValue<OptionType> | null
  ) => {
    if (!selectedOptions) return // Guard against null

    // Handle multi-value and single-value logic
    const selectedValues = Array.isArray(selectedOptions)
      ? selectedOptions.map((option) => option.value) // Handle MultiValue (array of OptionType)
      : selectedOptions
      ? [(selectedOptions as OptionType).value]
      : [] // Handle SingleValue (single OptionType)

    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id
          ? {
              ...row,
              assessment_number:
                row.type === 'Witness Assessment'
                  ? _.uniq([...row.assessment_number, ...selectedValues]) // Merge and remove duplicates
                  : [selectedValues[0]], // Handle single value for non-multi select types
            }
          : row
      )
    )
  }

  const handleAddRow = () => {
    if (rows.length < 3) {
      setRows((prevRows) => [
        ...prevRows,
        {id: prevRows.length + 1, type: null, stage: null, assessment_number: []},
      ])
    }
  }

  const resetDependentFields = () => {
    setTypeName(null)
    setStageId(null)
    setRows([{id: 1, type: null, stage: null, assessment_number: []}])
    setARCMemberSelect([])
  }

  const assignARCMember = async () => {
    if (
      !cabId ||
      rows.some((row) => !row.type || !row.stage || row.assessment_number.length === 0)
    ) {
      toast.warning('Please fill all required fields.')
      return
    }

    if (arcMemberSelect.length !== 2) {
      toast.warning('Please select exactly two ARC Members.')
      return
    }

    try {
      const payload = {
        cabId,
        assessor_data: arcMemberSelect.map((member) => ({
          assessorId: member.value,
          assessorRole: roleList.find((role) => role.label === 'ARC')?.value || '',
          assessorLocation: 'Remote',
          assessorTargets: rows,
        })),
        assessment_stage: stageId,
      }

      const response = await axios.post(`${BASE_URL}/arc/assign/cab/assessor/`, payload)

      if (response.status === 200 || response.status === 201) {
        toast.success('ARC Member assigned successfully!')
        resetForm()
        fetchAssesorUser()
        handleClose()
      } else {
        toast.error('Failed to assign ARC Member. Please try again.')
      }
    } catch (error) {
      console.error(error)
      toast.error('An error occurred while assigning ARC Member.')
    }
  }

  const resetForm = () => {
    setCabId(null)
    resetDependentFields()
  }

  return (
    <Modal show={show} onHide={handleClose} dialogClassName='modal-dialog-centered mw-900px'>
      <div className='modal-header'>
        <h2>Assign ARC</h2>
        <Button variant='close' onClick={handleClose}>
          <KTIcon iconName='cross' />
        </Button>
      </div>
      <div className='modal-body'>
        <div className='mb-5'>
          <label className='required'>Cab</label>
          <Select options={cabUserDetail} onChange={handleCabChange} />
        </div>

        {rows.map((row) => {
          // Filter out the already selected types for other rows
          const filteredOptions = assementType?.filter(
            (item) => !rows.some((r) => r.id !== row.id && r.type === item?.name)
          )

          return (
            <div key={row.id} className='row mb-4'>
              <div className='col-md-3'>
                <label className='required'>Type</label>
                <Select
                  options={filteredOptions?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  onChange={(selectedOption) => handleTypeChange(row.id, selectedOption)}
                />
              </div>
              <div className='col-md-3'>
                <label className='required'>Stage</label>
                <Select
                  options={assessmentStageList}
                  onChange={(selectedOption) => handleStageChange(row.id, selectedOption)}
                />
              </div>
              <div className='col-md-6'>
                <label className='required'>Assessment Number</label>
                <Select
                  options={uniqueAssessmentNumbers}
                  onChange={(
                    newValue: SingleValue<OptionType> | MultiValue<OptionType>,
                    actionMeta: ActionMeta<OptionType>
                  ) => {
                    handleAssessmentChange(row.id, newValue) // Pass newValue, which can be SingleValue or MultiValue
                  }}
                  isMulti={row.type === 'Witness Assessment'}
                />
              </div>
            </div>
          )
        })}

        <div className='text-end mb-4'>
          <Button variant='light-primary' onClick={handleAddRow}>
            Add Row
          </Button>
        </div>

        <div>
          <label className='required'>ARC Members</label>
          <Select
            options={assessorDetail}
            isMulti
            onChange={(selected) => setARCMemberSelect(selected as OptionType[])}
          />
        </div>
      </div>
      <div className='modal-footer'>
        <Button disabled={rows?.length < 3} onClick={assignARCMember} variant='success'>
          Assign
        </Button>
      </div>
    </Modal>
  )
}

export default ARCAssignModal
