import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { UserDataType } from '../../../../assessor-mangement/AssessorInterface'
const BASE_URL = process.env.REACT_APP_API_URL
interface ProgrammeData {
  id: string
  name: string
  description: string
}
// gopal
interface SchemeData {
  id: string
  name: string
  programme: string
  description: string
}
interface StandardData {
  id: string
  name: string
  scheme: string
  description: string
}
interface ClusterData {
  id: string
  name: string
  standard: string
  description: string
}
interface IAFCriticalData {
  id: string
  name: string
  scheme: string
  iaf_sector_doc: File | null
}
interface UserData {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: number
  company: string
  company_web: string
  subject: string
  message: string
  last_login: string
  is_cab_client: boolean
  is_cab_visible: boolean
  cab_profile: string
  address: string
  country: string
  language: string
  currency: string
  img: string
  country_name: string
  is_active: boolean
  is_cab_active: boolean
  is_cab_suspended: boolean
  is_cab_withdraw: boolean
  is_cab_deleted: boolean
  is_cab_hold: boolean
  scheme_names: string[]
}
const ProgramAndSchemeSection = ({
  setSelelctedIAFCriticalValue,
  selelctedIAFCriticalValue,
  selectedPrograms,
  selectedSchemes,
  selectedStandardList,
  selectedSchemesList,
  selectedClusterList,
  selectedStandards,
  selectedClusters,
  userIAFData,
  setSelectedPrograms,
  setSelectedSchemes,
  setSelectedStandards,
  setSelectedClusters,
  setSelectedSchemesList,
  setSelectedStandardList,
  setSelectedClusterList,
  urlId,
  setUserIAFData,

}) => {
  const API_URL = process.env.REACT_APP_API_URL
  console.log(userIAFData, 'jsjsdjkjdkdshsvg')
  console.log(selectedPrograms, 'selectedPrograms')
  const getDeailsOfUser = () => {
    axios
      .get(`${API_URL}/account/patch/user/${urlId}/`)
      .then((response) => {
        const result = response.data
        setUserIAFData(result)
      })
      .catch((error) => {
        // Handle errors
        console.error('API Error:', error)
      })
  }
  useEffect(() => {
    getDeailsOfUser();
  }, [])

  const [programmes, setProgrammes] = useState<ProgrammeData[]>([])
  const [schemes, setSchemes] = useState<SchemeData[]>([])
  const [standards, setStandards] = useState<StandardData[]>([])
  const [clusters, setClusters] = useState<ClusterData[]>([])
  const [IAFCritical, setIAFCritical] = useState<IAFCriticalData[]>([])
  const [expandedProgramIds, setExpandedProgramIds] = useState<string[]>([])

  // useEffect(()=>{
  //     if(userDetails){
  //         setSelectedSchemes(userDetails?.scheme_names)
  //     }
  // },[userDetails])

  const handleFileUpload = (itemId: string, clusterId: string, file: File) => {
    setSelelctedIAFCriticalValue((prevState) => ({
      ...prevState,
      [clusterId]: {
        ...prevState[clusterId],
        [itemId]: {
          ...(prevState[clusterId]?.[itemId] || {}),
          checked: true,
          file: file,
        },
      },
    }))
  }
  useEffect(() => {
    const fetchProgrammes = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/global/programme/list/`)
        setProgrammes(response.data)
      } catch (error) {
        console.error('Error fetching programme data:', error)
      }
    }
    fetchProgrammes()
  }, [])
  useEffect(() => {
    const fetchSchemes = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/global/scheme/list/`)
        setSchemes(response.data)
      } catch (error) {
        console.error('Error fetching scheme data:', error)
      }
    }
    fetchSchemes()
  }, [])
  useEffect(() => {
    const fetchStandards = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/global/standard/list/`)
        setStandards(response.data)
      } catch (error) {
        console.error('Error fetching standard data:', error)
      }
    }
    fetchStandards()
  }, [])
  useEffect(() => {
    const fetchClusters = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/global/cluster/list/`)
        setClusters(response.data)
      } catch (error) {
        console.error('Error fetching cluster data:', error)
      }
    }
    fetchClusters()
  }, [])
  const fetchIAF = async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/global/critical/iaf-sector/filter/?scheme=${id}`);
      const newData = response.data.filter((item: IAFCriticalData) => !IAFCritical.some((i) => i.id === item.id));
      setIAFCritical((prev) => [...prev, ...newData]);
      // Return the response data
      console.log(response.data, "response.data");
      // setIAFCritical(response.data)
    } catch (error) {
      console.error('Error fetching cluster data:', error);
    }
  };
  const handleSchemeSelection = (programId, schemeId) => {
  setSelectedSchemes(prevState => {
    const programSelectedSchemes = prevState[programId] || [];
    const index = programSelectedSchemes.indexOf(schemeId);
    const updatedSchemes =
      index === -1
        ? [...programSelectedSchemes, schemeId]
        : programSelectedSchemes.filter(id => id !== schemeId);

    const updatedSelectedSchemesList = updatedSchemes.includes(schemeId)
      ? [...selectedSchemesList, schemeId]
      : selectedSchemesList.filter(id => id !== schemeId);

    setSelectedSchemesList(updatedSelectedSchemesList);

    // Update selectedStandards and selectedStandardList
    setSelectedStandards(prevSelectedStandards => {
      const updatedStandards = { ...prevSelectedStandards };
      const standardsToRemove = standards.filter(standard => standard.scheme === schemeId).map(s => s.id);

      standardsToRemove.forEach(standardId => {
        Object.keys(updatedStandards).forEach(programId => {
          Object.keys(updatedStandards[programId] || {}).forEach(schemeId => {
            updatedStandards[programId][schemeId] = (updatedStandards[programId][schemeId] || []).filter(id => id !== standardId);
            if (updatedStandards[programId][schemeId].length === 0) {
              delete updatedStandards[programId][schemeId];
            }
          });
          if (Object.keys(updatedStandards[programId] || {}).length === 0) {
            delete updatedStandards[programId];
          }
        });
      });

      return updatedStandards;
    });

    setSelectedStandardList(prevSelectedStandardList =>
      prevSelectedStandardList.filter(standardId =>
        !standards.some(standard => standard.id === standardId && standard.scheme === schemeId)
      )
    );

    // Update selectedClusters and selectedClusterList
    setSelectedClusters(prevSelectedClusters => {
      const updatedClusters = { ...prevSelectedClusters };
      const clustersToRemove = clusters.filter(cluster => {
        const standard = standards.find(standard => standard.id === cluster.standard);
        return standard && standard.scheme === schemeId;
      }).map(c => c.id);

      clustersToRemove.forEach(clusterId => {
        Object.keys(updatedClusters).forEach(programId => {
          Object.keys(updatedClusters[programId] || {}).forEach(schemeId => {
            Object.keys(updatedClusters[programId][schemeId] || {}).forEach(standardId => {
              updatedClusters[programId][schemeId][standardId] = (updatedClusters[programId][schemeId][standardId] || []).filter(id => id !== clusterId);
              if (updatedClusters[programId][schemeId][standardId].length === 0) {
                delete updatedClusters[programId][schemeId][standardId];
              }
            });
            if (Object.keys(updatedClusters[programId][schemeId] || {}).length === 0) {
              delete updatedClusters[programId][schemeId];
            }
          });
          if (Object.keys(updatedClusters[programId] || {}).length === 0) {
            delete updatedClusters[programId];
          }
        });
      });

      return updatedClusters;
    });

    setSelectedClusterList(prevSelectedClusterList =>
      prevSelectedClusterList.filter(clusterId => {
        const cluster = clusters.find(c => c.id === clusterId);
        if (cluster) {
          const standard = standards.find(s => s.id === cluster.standard);
          return !(standard && standard.scheme === schemeId);
        }
        return true;
      })
    );

    return {
      ...prevState,
      [programId]: updatedSchemes,
    };
  });
};
const handleStandardSelection = (programId, schemeId, standardId) => {
  setSelectedStandards(prevState => {
    const programSelectedStandards = { ...prevState[programId] } || {};
    const schemeSelectedStandards = programSelectedStandards[schemeId] || [];
    const index = schemeSelectedStandards.indexOf(standardId);
    const updatedSchemeStandards =
      index === -1
        ? [...schemeSelectedStandards, standardId]
        : schemeSelectedStandards.filter(id => id !== standardId);

    const updatedSelectedStandardList = updatedSchemeStandards.includes(standardId)
      ? [...selectedStandardList, standardId]
      : selectedStandardList.filter(id => id !== standardId);

    setSelectedStandardList(updatedSelectedStandardList);

    return {
      ...prevState,
      [programId]: {
        ...programSelectedStandards,
        [schemeId]: updatedSchemeStandards,
      },
    };
  });

  // Remove corresponding clusters if the standard is being deselected
  if (!selectedStandardList.includes(standardId)) {
    setSelectedClusters(prevSelectedClusters => {
      const updatedClusters = { ...prevSelectedClusters };
      if (updatedClusters[programId] && updatedClusters[programId][schemeId]) {
        delete updatedClusters[programId][schemeId][standardId];
        if (Object.keys(updatedClusters[programId][schemeId]).length === 0) {
          delete updatedClusters[programId][schemeId];
          if (Object.keys(updatedClusters[programId]).length === 0) {
            delete updatedClusters[programId];
          }
        }
      }
      return updatedClusters;
    });

    setSelectedClusterList(prevSelectedClusterList => {
      const clustersToRemove = clusters
        .filter(cluster => cluster.standard === standardId)
        .map(cluster => cluster.id);
      return prevSelectedClusterList.filter(id => !clustersToRemove.includes(id));
    });
  }
};
const handleClusterSelection = (programId, schemeId, standardId, clusterId) => {
  setSelectedClusters(prevState => {
    const programSelectedClusters = { ...prevState[programId] } || {};
    const schemeSelectedClusters = programSelectedClusters[schemeId] || {};
    const standardSelectedClusters = schemeSelectedClusters[standardId] || [];

    const updatedClusterIds = standardSelectedClusters.includes(clusterId)
      ? standardSelectedClusters.filter(id => id !== clusterId)
      : [...standardSelectedClusters, clusterId];

    const updatedSelectedClusterList = updatedClusterIds.includes(clusterId)
      ? [...selectedClusterList, clusterId]
      : selectedClusterList.filter(id => id !== clusterId);

    setSelectedClusterList(updatedSelectedClusterList);

    return {
      ...prevState,
      [programId]: {
        ...programSelectedClusters,
        [schemeId]: {
          ...schemeSelectedClusters,
          [standardId]: updatedClusterIds,
        },
      },
    };
  });

  setExpandedProgramIds(prevExpandedProgramIds => {
    if (prevExpandedProgramIds.includes(clusterId)) {
      return prevExpandedProgramIds.filter(id => id !== clusterId);
    } else {
      return [...prevExpandedProgramIds, clusterId];
    }
  });

  fetchIAF(schemeId);


};
  const handleIAFCheckboxChange = (itemId, clusterId, type) => (e) => {
    const { checked, files } = e.target
    // If the type is 'file' and a file is selected, handle the file upload
    if (type === 'file' && files && files.length > 0) {
      handleFileUpload(itemId, clusterId, files[0])
      return // Exit early after handling file upload
    }
    // Update the state based on checkbox change
    setSelelctedIAFCriticalValue((prevState) => {
      const updatedState = {
        ...prevState,
        [clusterId]: {
          ...prevState[clusterId],
          [itemId]: {
            ...(prevState[clusterId]?.[itemId] || {}),
            checked: checked,
          },
        },
      }
      // If the checkbox is unchecked, remove the item from the state
      if (!checked) {
        delete updatedState[clusterId][itemId]
      }
      return updatedState
    })
  }
  const toggleProgram = (programId) => {
    // Update selectedPrograms
    setSelectedPrograms(prevSelectedPrograms => {
      const isSelected = prevSelectedPrograms.includes(programId);
      if (isSelected) {
        return prevSelectedPrograms.filter(id => id !== programId);
      } else {
        return [...prevSelectedPrograms, programId];
      }
    });

    // Update expandedProgramIds
    setExpandedProgramIds(prevExpandedProgramIds => {
      if (prevExpandedProgramIds.includes(programId)) {
        return prevExpandedProgramIds.filter(id => id !== programId);
      } else {
        return [...prevExpandedProgramIds, programId];
      }
    });

    // Clear state for the specified programId
    setSelectedSchemes(prevSelectedSchemes => {
      const updatedSchemes = { ...prevSelectedSchemes };
      delete updatedSchemes[programId];
      return updatedSchemes;
    });

    setSelectedSchemesList(prevSelectedSchemesList =>
      prevSelectedSchemesList.filter(schemeId =>
        !schemes.some(scheme => scheme.id === schemeId && scheme.programme === programId)
      )
    );

    setSelectedStandards(prevSelectedStandards => {
      const updatedStandards = { ...prevSelectedStandards };
      delete updatedStandards[programId];
      return updatedStandards;
    });

    setSelectedStandardList(prevSelectedStandardList =>
      prevSelectedStandardList.filter(standardId => {
        const standard = standards.find(s => s.id === standardId);
        if (standard) {
          const scheme = schemes.find(s => s.id === standard.scheme);
          return !(scheme && scheme.programme === programId);
        }
        return true;
      })
    );

    setSelectedClusters(prevSelectedClusters => {
      const updatedClusters = { ...prevSelectedClusters };
      delete updatedClusters[programId];
      return updatedClusters;
    });

    setSelectedClusterList(prevSelectedClusterList =>
      prevSelectedClusterList.filter(clusterId => {
        const cluster = clusters.find(c => c.id === clusterId);
        if (cluster) {
          const standard = standards.find(s => s.id === cluster.standard);
          const scheme = standard && schemes.find(s => s.id === standard.scheme);
          return !(scheme && scheme.programme === programId);
        }
        return true;
      })
    );
  };
  const storeDataList = () => {
    setSelectedSchemesList(userIAFData?.user_schemes?.map((i) => i))
    setSelectedStandardList(userIAFData?.user_standards?.map((i) => i))
    setSelectedClusterList(userIAFData?.user_clusters?.map((i) => i))
  }


  useEffect(() => {
    if (userIAFData) {
      setSelectedPrograms(userIAFData?.user_programmes);

      const dataschemes = schemes.filter(item => userIAFData?.user_programmes?.some(i => i == item.programme)).filter(item => userIAFData?.user_schemes?.includes(item?.id));
      const groupedSchemes = dataschemes.reduce((acc, scheme) => {
        const { programme } = scheme;
        if (!acc[programme]) {
          acc[programme] = [];
        }
        acc[programme].push(scheme.id);
        return acc;
      }, {});
      setSelectedSchemes(groupedSchemes);

    }
  }, [userIAFData]);
  useEffect(() => {
    if (userIAFData) {
      storeDataList();
      setSelectedPrograms(userIAFData.user_programmes);
      const dataschemes = schemes
        .filter(item => userIAFData.user_programmes.some(i => i === item.programme))
        .filter(item => userIAFData.user_schemes.includes(item.id));

      const groupedSchemes = dataschemes.reduce((acc, scheme) => {
        const { programme } = scheme;
        if (!acc[programme]) {
          acc[programme] = [];
        }
        acc[programme].push(scheme.id);
        return acc;
      }, {});

      setSelectedSchemes(groupedSchemes);

      const datastandards = standards
        .filter(item => userIAFData.user_schemes.includes(item.scheme))
        .filter(item => userIAFData.user_standards.includes(item.id));

      const groupedStandards = datastandards.reduce((acc, standard) => {
        const { scheme } = standard;
        const programme = schemes.find(s => s.id === scheme)?.programme;
        if (programme) {
          if (!acc[programme]) {
            acc[programme] = {};
          }
          if (!acc[programme][scheme]) {
            acc[programme][scheme] = [];
          }
          acc[programme][scheme].push(standard.id);
        }
        return acc;
      }, {});

      setSelectedStandards(groupedStandards);

      const dataclusters = clusters
        .filter(item => userIAFData.user_standards.includes(item.standard))
        .filter(item => userIAFData.user_clusters.includes(item.id));

      const groupedClusters = dataclusters.reduce((acc, cluster) => {
        const { standard } = cluster;
        const scheme = standards.find(s => s.id === standard)?.scheme;
        const programme = schemes.find(s => s.id === scheme)?.programme;
        if (programme) {
          if (!acc[programme]) {
            acc[programme] = {};
          }
          if (!acc[programme][scheme]) {
            acc[programme][scheme] = {};
          }
          if (!acc[programme][scheme][standard]) {
            acc[programme][scheme][standard] = [];
          }
          acc[programme][scheme][standard].push(cluster.id);
        }
        return acc;
      }, {});

      setSelectedClusters(groupedClusters);
    }
  }, [userIAFData, schemes, standards, clusters]);
  console.log(schemes, 'jfhaafg');

  return (
    <div className='card mb-5'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
          Awarded Schemes and Technical Categories<span style={{ color: 'red' }}>*</span>
          </h3>
        </div>
      </div>
      <div className='card-body '>
        <div className='row py-3'>
          <div className='col-lg-12 col-md-12 col-sm-12' style={{ padding: '5px' }}>
            {programmes.map((programme) => (
              <div key={programme.id} className='mb-3' style={{ borderBottom: '1px dotted #b1b1b1' }}>
                <div onClick={() => toggleProgram(programme.id)} style={{ marginBottom: '10px' }}>
                  <input
                    className='form-check-input'
                    style={{ fontWeight: '500', fontSize: '16px' }}
                    type='checkbox'
                    checked={selectedPrograms.includes(programme.id)}
                    onChange={() => { }}
                  />
                  <label className='form-check-label' style={{ marginLeft: '10px' }}>
                    {programme.name}
                  </label>
                </div>
                {selectedPrograms.includes(programme.id) && (
                  <div className='ml-4' style={{ paddingLeft: '35px' }}>
                    {schemes
                      .filter((scheme) => scheme.programme === programme.id)
                      .map((scheme) => (
                        <div key={scheme.id} className='mb-3' style={{ marginBottom: '10px' }}>
                          <input
                            className='form-check-input'
                            style={{ fontWeight: '500', fontSize: '16px' }}
                            type='checkbox'
                            checked={selectedSchemes[programme.id]?.includes(scheme.id)}
                            onChange={() => handleSchemeSelection(programme.id, scheme.id)}
                          />
                          <label
                            className='form-check-label'
                            style={{ marginLeft: '10px', marginBottom: '10px' }}
                          >
                            {scheme.name}
                          </label>
                          {selectedSchemes[programme.id]?.includes(scheme.id) && (
                            <div className='ml-4' style={{ paddingLeft: '55px' }}>
                              {standards
                                .filter((standard) => standard.scheme === scheme.id)
                                .map((standard) => (
                                  <div key={standard.id} className='mb-3'>
                                    <input
                                      type='checkbox'
                                      className='form-check-input'
                                      style={{ fontWeight: '500', fontSize: '16px' }}
                                      checked={selectedStandards[programme.id]?.[
                                        scheme.id
                                      ]?.includes(standard.id)}
                                      onChange={() =>
                                        handleStandardSelection(
                                          programme.id,
                                          scheme.id,
                                          standard.id
                                        )
                                      }
                                    />
                                    <label
                                      className='form-check-label'
                                      style={{ marginLeft: '10px' }}
                                    >
                                      {standard.name}
                                    </label>
                                    {selectedStandards[programme.id]?.[scheme.id]?.includes(
                                      standard.id
                                    ) && (
                                        <div className='ml-4' style={{ paddingLeft: '65px' }}>
                                          {clusters
                                            .filter((cluster) => cluster.standard === standard.id)
                                            .map((cluster) => (
                                              <div key={cluster.id} className='mb-3'>
                                                <input
                                                  type='checkbox'
                                                  className='form-check-input'
                                                  style={{ fontWeight: '500', fontSize: '16px' }}
                                                  checked={selectedClusters[programme.id]?.[
                                                    scheme.id
                                                  ]?.[standard.id]?.includes(cluster.id)}
                                                  onChange={() =>
                                                    handleClusterSelection(
                                                      programme.id,
                                                      scheme.id,
                                                      standard.id,
                                                      cluster.id
                                                    )
                                                  }
                                                />
                                                <label
                                                  className='form-check-label'
                                                  style={{ marginLeft: '10px' }}
                                                >
                                                  {cluster.name}
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                      )}
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProgramAndSchemeSection