import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import UAFLoader from '../../loader/UafLoader'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Modal } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

interface FormState {
  title: string

  desc: string
  date: Date | null
}

const AddNews = () => {
  const navigate = useNavigate()
  const param = useParams()
  const trainingId = param?.id
  const API_URL = process.env.REACT_APP_API_URL || ''
  const [loader, setLoader] = useState<boolean>(false)
  const [trainingData, setTrainingData] = useState<any>(null)
  const [showPdfModal, setShowPdfModal] = useState<boolean>(false)
  const [pdfUrl, setPdfUrl] = useState<string>('')
  const [selectedDates, setSelectedDates] = useState<Date | null>(null)

  const [value, setValue] = useState<FormState>({
    title: '',

    desc: '',
    date: null,
  })



  useEffect(() => {
    if (trainingData) {
      setValue({
        title: trainingData?.title || '',
    
        desc: trainingData?.desc || '',
        date: trainingData?.anno_date ? new Date(trainingData.anno_date) : null,
      });
      setSelectedDates(trainingData?.anno_date ? new Date(trainingData.anno_date) : null);
    }
  }, [trainingData]);
  

  const fetchTrainingData = async () => {
    try {
      const response = await axios.get(`${API_URL}/blog/announcement/patch/${trainingId}`)
      setTrainingData(response.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    if (trainingId) {
      fetchTrainingData()
    } else {
      setValue({
        title: '',
    
        desc: '',
        date: null,
      })
    }
  }, [trainingId])

  const handleDateChange = (date: Date | null) => {
    setValue((prevState) => ({
      ...prevState,
      date: date,
    }))
    setSelectedDates(date)
  }









  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const { title, desc, date } = value
    if (!title || !desc) {
      toast.error('Please fill in all mandatory fields.')
      return
    }

    try {
      setLoader(true)
      const formData = new FormData()
      formData.append('title', title)
   
      formData.append('desc', desc)
      formData.append('anno_date', date ? moment(date).format('MM-DD-YYYY') : '');

      let response
      if (trainingId) {
        response = await axios.put(
          `${API_URL}/blog/announcement/update/${trainingId}/`,
          formData
        )
      } else {
        response = await axios.post(`${API_URL}/blog/announcement/create/`, formData)
      }

      if (response.status === 201 || response.status === 200) {
        toast.success('Submitted successfully')
        setLoader(false)
        navigate(`/crafted/newslist`)
        setValue({
          title: '',
 
          desc: '',
          date: null,
        })
        setSelectedDates(null)
      } else {
        setLoader(false)
        toast.error('Failed to submit. Please try again.')
      }
    } catch (error: any) {
      setLoader(false)
      console.error(error)
      toast.error('Something went wrong, please try again.')
    }
  }

  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData()
    setValue((prevValue) => ({
      ...prevValue,
      desc: data,
    }))
  }

  return (
    <div id="v_blog_01">
      {loader && <UAFLoader />}

      <div className="row justify-content-space-between">
        <div className="col-md-6 d-flex align-items-center justify-content-start">
          <h2 className="mb-3 p-0 ">{trainingId ? 'Edit News' : 'Add News'}</h2>
        </div>
      </div>

      <div className="card card-flush">
        <div className="card-body pt-5">
          <form onSubmit={handleSubmit}>
            <div className="row">
             
          
                <div className="col-lg-6">
                  <div className="mb-5">
                    <label className="required form-label">Title</label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder="Enter  Title"
                      name="title"
                      value={value.title}
                      onChange={(e) => setValue({ ...value, title: e.target.value })}
                    />
                  </div>
                  </div>
                  <div className="col-lg-6">
                  <div className="mb-5">
                    <label className="required form-label">Date</label>

                    <ReactDatePicker
                      placeholderText="Select Date"
                      className="uaf-datepicker"
                      selected={selectedDates}
                      onChange={handleDateChange}
                   
                      disabledKeyboardNavigation
                      showMonthDropdown
                      showYearDropdown
                    />
                  </div>
                  
                
            
            </div>
                  {/* <div className="col-lg-6">
                  <div className="mb-5">
                    <label className="required form-label">Type</label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder="Enter Type"
                      name="type"
                      value={value.type}
                      onChange={(e) => setValue({ ...value, type: e.target.value })}
                    />
                  </div>
                  </div> */}
                </div>
                
            <div className="col-lg-12">
              <div className="fv-row">
                <div className="mb-5">
                  <label className="required form-label">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={value.desc}
                    onChange={handleEditorChange}
                  />
                </div>
              </div>
            </div>
            <div className="mb-5">
              <button type="submit" className="btn btn-primary">
                {trainingId ? 'Update' : 'Submit'}
                <i className="fa fa-angle-double-right" aria-hidden="true" />
              </button>
            </div>
          </form>
        </div>
      </div>
      
    </div>
  )
}

export default AddNews
