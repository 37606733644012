/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import BusinessUnitFilter from '../models/businessUniterFilter'
import Select from 'react-select'

import {Button, Dropdown, DropdownButton, Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {toast} from 'react-toastify'
import {debounce, IconButton, Tooltip} from '@mui/material'

import CreateManualAssesor from '../models/CreateManualAssesor'
import UAFLoader from '../../../../loader/UafLoader'
// type Props = {
//   className: string
// }

// const BusinessUnitList = ({ className }: Props) => (

interface Scheme {
  id: string
  name: string
}

interface Program {
  id: string
  name: string
}
interface PendingAssessor {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  resume: string
  is_assessor: boolean
  is_verify_assessor: boolean
  is_potential_assessor: boolean
  assessor: string
  store_password: string
  certification_names: string[]
  programme_names: Program[]
  scheme_names: Scheme[]
  create_at: string
}
const API_URL = process.env.REACT_APP_API_URL
const AssessorList: React.FC = () => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const [value, setValue] = useState<any[]>([])
  console.log(value, 'valuevalue.....')

  const [searchQuery, setSearchQuery] = useState('')
  const [loader, setLoader] = useState<any>(false)
  const [filteredValue, setFilteredValue] = useState<any[]>([])
  const [rejectPop, setRejectPop] = useState<any>()
  const [rejectedAssessor, setRejectedAssessor] = useState<any>()
  const [rejectionReason, setRejectionReason] = useState<any>()
  const [pagenumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalcount, settotalcount] = useState(0)

  const formatMyDate = (date: Date | string | null) => {
    if (!date) return null;
  
    // If the date is a string, parse it to a Date object
    const parsedDate = typeof date === "string" ? new Date(date) : date;
  
    // Ensure the parsed date is valid
    if (isNaN(parsedDate.getTime())) return null;
  
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
  
    return `${month}-${day}-${year}`;
  };

  // Function to handle search query change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
    // Filter the value array based on the search query
    const filteredData = value.filter((user) => {
      const fullName = `${user?.first_name} ${user?.last_name}`.toLowerCase()
      const searchValue = e.target.value.toLowerCase()
      const programMatch = user?.programme_names.some((certification) =>
        certification?.name.toLowerCase().includes(searchValue)
      )
      const certificationMatch = user?.scheme_names.some((certification) =>
        certification?.name.toLowerCase().includes(searchValue)
      )
      return (
        fullName.includes(searchValue) ||
        user?.first_name.toLowerCase().includes(searchValue) ||
        user?.email.toLowerCase().includes(searchValue) ||
        certificationMatch ||
        programMatch
        // user?.certification_names.toLowerCase().includes(searchValue)
      )
    })
    setFilteredValue(filteredData)
  }
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/team/accessor/pending/list/pagination/?page=${pagenumber}`
      )
      const pages = Math.ceil(response?.data?.count / 10)
      // Each page has 10 items
      setTotalPages(pages)
      setValue(response.data?.results)
      settotalcount(response?.data?.count)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [pagenumber])
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showBusinessUnitFilterModal, setShowBusinessUnitFilterModal] = useState<boolean>(false)
  const option = [
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'},
  ]
  const [showPdfModal, setShowPdfModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')

  const totalPagesArray = Array.from({length: totalPages}, (_, index) => index + 1)
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setPageNumber(page)
    }
  }

  const handlePdfIconClick = (url) => {
    setPdfUrl(url)
    setShowPdfModal(true)
  }
  const handleClosePdfModal = () => {
    setShowPdfModal(false)
    setPdfUrl('') // Clear the PDF URL when the modal is closed
  }
  const handleRejectPop = (id) => {
    setRejectPop(true)
    setRejectedAssessor(id)
  }
  const handleClosePop = () => {
    setRejectPop(false)
    setRejectedAssessor('')
    setRejectionReason('')
  }
  const rejectValid = () => {
    if (!rejectionReason) {
      toast.error('Enter Reason') // Notify the user if no reason is provided
      return false // Return false to indicate invalid input
    }
    return true // Return true if validation passes
  }

  const handleReject = async () => {
    if (!rejectValid()) {
      return // Exit if validation fails
    }

    const rejectionPayload = {rejection_reason: rejectionReason}
    try {
      const response = await axios.post(
        `${API_URL}/team/reject/accessor/potential/${rejectedAssessor}/`,
        rejectionPayload
      )

      if (response.status === 200) {
        toast.success('Assessor is rejected') // Success notification
        fetchData() // Refresh the data
        handleClosePop() // Close the popup/modal
      }
    } catch (error) {
      console.error('Error rejecting assessor:', error) // Log error for debugging
      toast.error('Failed to reject assessor') // Failure notification
    }
  }

  const handleAccept = async (id) => {
    try {
      setLoader(true)
      const response = await axios.post(`${API_URL}/team/approved/accessor/potential/${id}/`)
      if (response.status === 200) {
        toast.success('Lead is a Potential Assessor')
        fetchData()
        setLoader(false)
      }
    } catch (error) {
      // Handle errors
      // console.error('API Error:', error)
      // throw error
      setLoader(false)
    }
  }

  return (
    <>{loader ? <UAFLoader /> : null}
    <div id='assessor_lead_1'>
      <h2 className='p-2 mb-5'>Assessor Leads:{totalcount}</h2>
      <div className='card card-flush'>
        <div className='card-header pt-5'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='currentColor'
                  />
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <input
                id='assessor_search_id'
                type='text'
                data-kt-user-table-filter='search'
                onChange={handleSearchChange}
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search user'
              />
            </div>
          </div>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              {/* <button type="button" className="btn btn-light-primary me-3" onClick={() => setShowBusinessUnitFilterModal(true)}>
              <span className="svg-icon svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
                </svg>
              </span>
              Filter</button> */}
              {/* <Link to="/create/assessor"> */}
              <Link to='/create/assessor/' id='add_new_assessor' className='btn btn-primary'>
                <span className='svg-icon svg-icon-2'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='11.364'
                      y='20.364'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-90 11.364 20.364)'
                      fill='currentColor'
                    />
                    <rect x='4.36396' y='11.364' width='16' height='2' rx='1' fill='currentColor' />
                  </svg>
                </span>
                Add Assessor
              </Link>
              {/* </Link> */}
              <CreateManualAssesor
                show={showCreateAppModal}
                handleClose={() => setShowCreateAppModal(false)}
                editUserId={''}
              />
              {/* <CreateAssessor
              show={showCreateAppModal}
              handleClose={() => setShowCreateAppModal(false)} /> */}
              <BusinessUnitFilter
                show={showBusinessUnitFilterModal}
                handleClose={() => setShowBusinessUnitFilterModal(false)}
              />
            </div>
            <div
              className='d-flex justify-content-end align-items-center d-none'
              data-kt-user-table-toolbar='selected'
            >
              <div className='fw-bold me-5'>
                <span className='me-2' data-kt-user-table-select='selected_count'></span>Selected
              </div>
              <button
                type='button'
                className='btn btn-danger'
                data-kt-user-table-select='delete_selected'
              >
                Delete Selected
              </button>
            </div>
            {/*  <div className="modal fade" id="kt_modal_export_users" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="fw-bold">Export Users</h2>
                  <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                    <span className="svg-icon svg-icon-1">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                  <form id="kt_modal_export_users_form" className="form" action="#">
                    <div className="fv-row mb-10">
                      <label className="fs-6 fw-semibold form-label mb-2">Select Roles:</label>
                      <select name="role" data-control="select2" data-placeholder="Select a role" data-hide-search="true" className="form-select form-select-solid fw-bold">
                        <option></option>
                        <option value="Administrator">Administrator</option>
                        <option value="Analyst">Analyst</option>
                        <option value="Developer">Developer</option>
                        <option value="Support">Support</option>
                        <option value="Trial">Trial</option>
                      </select>
                    </div>
                    <div className="fv-row mb-10">
                      <label className="required fs-6 fw-semibold form-label mb-2">Select Export Format:</label>
                      <select name="format" data-control="select2" data-placeholder="Select a format" data-hide-search="true" className="form-select form-select-solid fw-bold">
                        <option></option>
                        <option value="excel">Excel</option>
                        <option value="pdf">PDF</option>
                        <option value="cvs">CVS</option>
                        <option value="zip">ZIP</option>
                      </select>
                    </div>
                    <div className="text-center">
                      <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
                      <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit">
                        <span className="indicator-label">Submit</span>
                        <span className="indicator-progress">Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
        <div className='card-body pt-5'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 location_table_show' id='kt_table_users'>
            <thead>
              {/* <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'> */}
              <tr className='text-start  fw-bold  text-uppercase gs-0'>
                <th className='w-10px pe-2'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      data-kt-check='true'
                      data-kt-check-target='#kt_table_users .form-check-input'
                      value='1'
                    />
                  </div>
                </th>
                <th className='min-w-125px'>Assessor Name</th>
                <th className='min-w-125px'>Country</th>
                <th className='min-w-125px'>Resume</th>
                <th className='min-w-125px'>Programmes</th>
                <th className='min-w-125px'>Schemes</th>
                <th className='min-w-125px'>Remark</th>
                <th className='min-w-125px'>Status</th>
                <th className='min-w-125px'>Applied Date</th>
                <th className='text-center min-w-120px'>Actions</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {(searchQuery.length > 0 ? filteredValue : value).map((item, index) => (
                <tr key={item?.id}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input className='form-check-input' type='checkbox' value='1' />
                    </div>
                  </td>
                  <td>
                    <Link
                      to={`/assessor/${item?.id}/overview`}
                      className='text-gray-800 text-hover-primary mb-1'
                    >
                      {item?.first_name} {item?.last_name}
                    </Link>
                  </td>
                  <td>
                    <div className=' '>{item?.country_name}</div>
                  </td>
                  <td>
                    {/* <div className='text-gray-800 text-hover-primary mb-1'>{item?.email}</div> */}
                    <div className='mb-1'>
                      {item?.resume ? (
                        <a href={item?.resume} target='_blank' rel='noopener noreferrer'>
                          <img src='/media/doc.png' alt='' />
                        </a>
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <DropdownButton id='dropdown-basic-button' title='Applied For'>
                      {/* {value?.map((pendingAssessor) => */}
                      {item?.programme_names?.map((program) => (
                        <Dropdown.Item key={program?.id}>{program?.name}</Dropdown.Item>
                      ))}
                      {/* )} */}
                    </DropdownButton>
                  </td>
                  <td>
                    <DropdownButton id='dropdown-basic-button' title='Applied For'>
                      {/* {value?.map((item) => */}
                      {item?.scheme_names?.map((scheme) => (
                        <Dropdown.Item key={scheme?.id}>{scheme?.name}</Dropdown.Item>
                      ))}
                      {/* )} */}
                    </DropdownButton>
                  </td>
                  <td>
                    {item?.message ? (
                      <Tooltip title={<span style={{fontSize: '1.2rem'}}>{item?.message}</span>}>
                        <IconButton>
                          <i className='fas fa-info-circle'></i>
                        </IconButton>
                      </Tooltip>
                    ) : (
                      'No Remarks'
                    )}
                  </td>
                  <td>
                    {item?.is_potential_assessor === true ? (
                      <div className='badge badge-light-primary fw-bold'>Potential Assessor</div>
                    ) : item?.is_potential_assessor_reject === true ? (
                      <>
                        <div className='badge badge-light-warning fw-bold'>Rejected </div>
                        <Tooltip
                          title={
                            <span style={{fontSize: '1.2rem'}}>
                              {item?.potential_assessor_reject_reason}
                            </span>
                          }
                        >
                          <IconButton>
                            <i className='fas fa-info-circle'></i>
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending</div>
                    )}
                  </td>
                  <td>{item && item.create_at ? formatMyDate(item.create_at) : ''}</td>

                  <td className='text-center'>
                    {/* <a
                    href='#'
                    className='btn btn-light btn-active-light-primary btn-sm'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                  >
                    Actions
                    <span className='svg-icon svg-icon-5 m-0'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </a> */}
                    <DropdownButton className='' id='dropdown-basic-button' title='Action'>
                      <Dropdown.Item onClick={() => handleAccept(item?.id)}>Approve</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleRejectPop(item?.id)}>
                        Reject
                      </Dropdown.Item>
                      {/* <Dropdown.Item href='#/action-3'>InProcess</Dropdown.Item> */}
                    </DropdownButton>
                    <div
                      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                      data-kt-menu='true'
                    >
                      <div className='menu-item px-3'>
                        <Link to={`/assessor/${item?.id}/settings`} className='menu-link px-3'>
                          Edit
                        </Link>
                      </div>
                      <div className='menu-item px-3'>
                        <a
                          href='#'
                          className='menu-link px-3'
                          data-kt-users-table-filter='delete_row'
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ul className='pagination'>
            <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
              <a className='page-link' onClick={() => handlePageChange(pagenumber - 1)}>
                <i className='previous'></i>
              </a>
            </li>
            {totalPagesArray.map((page) => (
              <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
                <a className='page-link' onClick={() => handlePageChange(page)}>
                  {page}
                </a>
              </li>
            ))}
            <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
              <a className='page-link' onClick={() => handlePageChange(pagenumber + 1)}>
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Modal show={showPdfModal} onHide={handleClosePdfModal} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display the PDF in the modal */}
          {/* <iframe src={pdfUrl} title='PDF Viewer' width='100%' height='500px'></iframe> */}
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
            title='DOCX Viewer'
            width='100%'
            height='500px'
          ></iframe>
          <a href={pdfUrl} download style={{display: 'block', marginTop: '10px'}}>
            <button className='btn btn-primary btn-sm ' type='button'>
              Download
            </button>
          </a>
        </Modal.Body>
      </Modal>

      {/* for rejection reason  modal*/}

      <Modal show={rejectPop} onHide={handleClosePop} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reason For Rejection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows={10}
            className='form-control form-control-solid'
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
          ></textarea>

          <button className='btn btn-primary btn-sm  mt-3' type='button' onClick={handleReject}>
            Submit
          </button>
        </Modal.Body>
      </Modal>
    </div>
    </>
  )
}
export {AssessorList}
