import React, {useEffect, useState} from 'react'
import {Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font} from '@react-pdf/renderer'
import {useLocation} from 'react-router-dom'

import parse from 'html-react-parser'
import axios from 'axios'
import Html from 'react-pdf-html';

Font.register({
  family: 'Open Sans',
  fonts: [
    {src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf'},
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-400.ttf',
      fontWeight: 400,
    },
  ],
})
const CabSchedulePDF = () => {
  // console.log(item,"SCHEDULE DATA");
  const location = useLocation()
  const state = location.state as {item?: any; userId?: any}

  const item = state?.item
  const urlId = state?.userId
  console.log(state, 'item SCHEDULE')
  const [standardData, setStandardData] = useState<any[]>([])
  const [cabData, setCabData] = useState<any>()
  const [schemeClusters, setSchemeClusters] = useState<any>([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/arc/management/schedule/data/get/?cab=${urlId}`
        )
        setCabData(response?.data?.cab_data)
      } catch (error) {
        console.error('Error fetching assessment data:', error)
      }
    }
    fetchData()
  }, [urlId])
  useEffect(() => {
    const fetchStandardData = async () => {
      try {
        const responses = await Promise.all(
          cabData?.scheme_names.map((scheme) =>
            axios.get(
              `${process.env.REACT_APP_API_URL}/global/standard/filter/?scheme=${scheme?.id}`
            )
          )
        )
        const data = responses.map((response) => response.data)
        setStandardData(data)
      } catch (error) {
        console.error('Error fetching assessment data:', error)
      }
    }

    fetchStandardData()
  }, [cabData])
  const styleObj = StyleSheet.create({
    page_1: {
      borderWidth: 8,
      borderColor: '#07408B',
      borderStyle: 'solid',
      margin: 10, // Margin only affects spacing outside the page
      padding: 20, // Adjust padding for internal spacing
    },
    view_1: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    text_1: {
      textAlign: 'center',
      fontSize: 28,
      color: '#07408B',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      marginBottom: 3,
    },
    text_2: {
      textAlign: 'center',
      fontSize: 20,
      color: '#07408B',
      fontFamily: 'Open Sans',
      fontWeight: 700,
    },
    text_3: {
      textAlign: 'center',
      fontSize: 12,
      color: '#07408B',
      fontFamily: 'Open Sans',
      fontWeight: 600,
    },
    font12: {
      textAlign: 'center',
      fontSize: 12,
      color: '#333',
    },
    fontAnchor12: {
      textAlign: 'center',
      fontSize: 12,
      color: '#07408B',
    },
    text_4: {
      textAlign: 'center',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      marginTop: 8,
      marginBottom: 8,
    },
    cabname: {
      textAlign: 'center',
      fontSize: 20,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 700,
    },
    cabaddress: {
      textAlign: 'center',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 600,
    },
    text_5: {
      textAlign: 'center',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      paddingLeft: 30,
      paddingRight: 30,
      marginTop: 20,
      marginBottom: 10,
      textTransform: 'uppercase',
    },
    text_6: {
      textAlign: 'left',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      paddingLeft: 25,
      paddingRight: 25,
      marginTop: 10,
      marginBottom: 10,
    },
    textValid: {
      textAlign: 'left',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      paddingLeft: 25,
      paddingRight: 25,
      marginTop: 5,
      marginBottom: 5,
    },
    fontbold13: {
      textAlign: 'center',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 600,
    },
    font13: {
      textAlign: 'center',
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 400,
    },
    margin10: {
      marginTop: 10,
      marginBottom: 10,
    },
  })
  const stylePage_2 = StyleSheet.create({
  
    content: {
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 70,  // Increase the margin to create more space for the footer
      position: "relative"
    },
    view_header: {
      display: 'flex', // Ensure flexbox is used
      flexDirection: 'row', // Layout children in a row
      justifyContent: 'space-between', // Space out children to the edges
      alignItems: 'center', // Align items vertically center
      marginHorizontal: 0, // Optional: add horizontal margin
      marginBottom: 10, // Optional: space from the top
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 0, // Keep it at the bottom
      right: 0,
      textAlign: 'right',
      color: 'grey',
      marginTop: 30,
      height: '50px',  // Add fixed height to the footer
    },
    
    text_2: {
      textAlign: 'center',
      fontSize: 20,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      marginTop: 15,
    },
    header_1: {
      fontSize: 17,
      fontFamily: 'Open Sans',
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: 10,
      marginTop: 10,
      color: '#07408B',
      textDecoration: 'underline',
    },
    table: {
      width: 'auto',
      margin: '0 auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#ccc',
      marginTop: 20,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableColHeader: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#ccc',
      backgroundColor: '#f0f0f0',
      padding: 5,
      fontWeight: 700,
      textAlign: 'center',
      fontSize: 13,
    },
    tableCol: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#ccc',
      padding: 5,
      textAlign: 'center',
      fontSize: 13,
    },
    tableCell: {
      fontSize: 8,
    },
    font13: {
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 400,
    },
    text12: {
      margin: 10,
      position: 'relative',
    },
  })
  const stylePage_3 = StyleSheet.create({
    table: {
      width: 'auto',
      margin: '0 auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#ccc',
      marginTop: 20,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableColHeader: {
      width: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#ccc',
      backgroundColor: '#f0f0f0',
      padding: 5,
      fontWeight: 700,
      textAlign: 'center',
      fontSize: 13,
    },
    tableCol: {
      width: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#ccc',
      padding: 5,
      // textAlign: 'center',
      fontSize: 13,
    },
    tableCell: {
      fontSize: 8,
    },
    font13: {
      fontSize: 13,
      color: '#333',
      fontFamily: 'Open Sans',
      fontWeight: 400,
    },
  })
 
  // const htmlToPlainText = (html) => {
  //   let tempDiv = document.createElement('div')
  //   tempDiv.innerHTML = html
  //   let plainText = tempDiv.textContent || tempDiv.innerText || ''
  //   plainText = plainText.replace(/(\.)(\s|$)/g, `$1\n`)
  //   return plainText
  // }
  useEffect(() => {
    const getSchemeClusterName = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/account/applied/data/certification/?cab=${urlId}`
        )
        setSchemeClusters(response.data?.scheme_with_cluster)
      } catch (error) {
        console.error('API Error:', error)
      }
    }
    getSchemeClusterName()
  }, [urlId])

  const formatMyDate = (date: Date | string | null) => {
    if (!date) return null;
  
    // If the date is a string, parse it to a Date object
    const parsedDate = typeof date === "string" ? new Date(date) : date;
  
    // Ensure the parsed date is valid
    if (isNaN(parsedDate.getTime())) return null;
  
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
  
    return `${month}-${day}-${year}`;
  };
  return (
    <PDFViewer style={{width: '100%', height: '100vh'}}>
      <Document>
        {/* Certificate Landscape Page */}
        <Page size='A4' orientation='landscape'>
          <View style={styleObj.page_1}>
            <Image
              src='/media/logos/uaf_logo.png'
              style={{
                position: 'absolute',
                top: '50%',
                left: '35%',
                transform: 'translate(-50%, -50%)',
                width: 350,
                opacity: 0.1,
                zIndex: -1,
              }}
            />
            <View style={styleObj.view_1}>
              <Image
                src='/media/logos/schedule-logo.png'
                style={{
                  width: '150px',
                  height: '75px',
                  // border: '2px solid #333',
                  marginTop: 10,
                }}
              />
              <View style={{alignItems: 'center', width: '465px', padding: 2}}>
                <Text style={styleObj.text_1}>CERTIFICATE OF ACCREDITATION</Text>
                <Text style={styleObj.text_2}>UNITED ACCREDITATION FOUNDATION INC</Text>
                <Text style={styleObj.text_3}>
                  1060 Laskin Rd, Suite: 12B/13B, Virginia Beach VA 23451, United States of America
                </Text>
                <Text style={styleObj.text_4}>Has Accredited</Text>
                <Text style={styleObj.cabname}>{item?.cab_data?.cab_company}</Text>
                <Text style={styleObj.cabaddress}>
                  {
                    item?.cab_data?.cab_address?.address}{' '}
                </Text>
              </View>
              <Image
                src='/media/iafmla.jpg'
                style={{width: '150px', height: '85px', marginTop: 5}}
              />
            </View>
            <Text style={styleObj.text_5}>
              IN THE FIELD OF {item?.programme_data?.programme_name} CERTIFICATIONS DETAILS OF SCOPE
              OF ACCREDITATION IS AS PER THE ACCREDITATION SCHEDULE ISSUED WITH THIS CERTIFICATE
            </Text>
            <View style={styleObj.view_1}>
              <Text style={styleObj.text_6}>
                
               {item?.issue_date ? formatMyDate(new Date(item?.issue_date)) : '--'}
              </Text>
              {/* <Image
                // src="https://uafaccreditation.s3.amazonaws.com/arc/schedule/dummyimg_QPIMwC1.png"
                source={{
                  uri: 'https://uafaccreditation.s3.amazonaws.com/arc/schedule/dummyimg_QPIMwC1.png',
                }}
                style={{
                  width: '120px',
                }}
              /> */}
             
              <Image  
          src={item?.member_signature_text}
          style={{
            width: '120px',
          }} 
        />
            </View>
            <View style={styleObj.view_1}>
              <Image
                src='/media/images/stamp.jpg'
                style={{
                  width: '108px',
                  height: '108px',
                  marginTop: 10,
                }}
              />
             
              <View style={{alignItems: 'center', width: '465px', padding: 2}}>
                <Text style={styleObj.textValid}> ACCREDITATION VALID FROM</Text>
                <Text style={styleObj.fontbold13}>
                  {item?.scheme_data &&
                    Object.entries(item.scheme_data)
                      .filter(([key, value]) => (value as {enabled?: boolean}).enabled)
                      .reduce((acc, [key, value]) => {
                        const {valid_date} = value as {
                          valid_date?: string
                          expiry_date?: string
                          issue_number?: string
                          enabled?: boolean
                          scheme_id?: string
                        }

                        if (valid_date) {
                          if (!acc[valid_date]) {
                            acc[valid_date] = []
                          }
                          acc[valid_date].push(key)
                        }

                        return acc
                      }, {} as Record<string, string[]>) &&
                    Object.entries(
                      Object.entries(item.scheme_data).reduce((acc, [key, value]) => {
                        const {valid_date} = value as {
                          valid_date?: string
                          enabled?: boolean
                        }

                        if (valid_date) {
                          if (!acc[valid_date]) acc[valid_date] = []
                          acc[valid_date].push(key)
                        }

                        return acc
                      }, {})
                    )
                      .map(([date, keys]: [string, unknown]) => {
                        const castedKeys = keys as string[]
                        return `${date} for ${castedKeys.join(', ')}`
                      })
                      .join('\n')}
                </Text>
                <Text style={styleObj.textValid}>ACCREDITATION EXPIRY </Text>
                <Text style={styleObj.fontbold13}>
                  {item?.scheme_data &&
                    Object.entries(item.scheme_data)
                      .filter(([key, value]) => (value as {enabled?: boolean}).enabled)
                      .reduce((acc, [key, value]) => {
                        const {expiry_date} = value as {
                          valid_date?: string
                          expiry_date?: string
                          issue_number?: string
                          enabled?: boolean
                          scheme_id?: string
                        }

                        if (expiry_date) {
                          if (!acc[expiry_date]) {
                            acc[expiry_date] = []
                          }
                          acc[expiry_date].push(key)
                        }

                        return acc
                      }, {} as Record<string, string[]>) &&
                    Object.entries(
                      Object.entries(item.scheme_data).reduce((acc, [key, value]) => {
                        const {expiry_date} = value as {
                          expiry_date?: string
                          enabled?: boolean
                        }

                        if (expiry_date) {
                          if (!acc[expiry_date]) acc[expiry_date] = []
                          acc[expiry_date].push(key)
                        }

                        return acc
                      }, {})
                    )
                      .map(([date, keys]: [string, unknown]) => {
                        const castedKeys = keys as string[]
                        return `${date} for ${castedKeys.join(', ')}`
                      })
                      .join('\n')}
                </Text>
              </View>
              <Text style={styleObj.text_6}> (DIRECTOR)</Text>
            </View>

            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: '#333',
                fontFamily: 'Open Sans',
                fontWeight: 600,
                marginBottom: 10,
                marginTop: 25,
              }}
            >
              UNITED ACCREDITATION FOUNDATION INC (UAF)
              <Text style={styleObj.font13}>
                {' '}
                is a signatory of the Multilateral Recognition arrangement with International
                Accreditation Forum (IAF) and a signatory of the Mutual Recognition Arrangement with
                Asia Pacific Accreditation Cooperation (APAC).
              </Text>
            </Text>
            <Text style={styleObj.font12}>
              Visit <Text style={styleObj.fontAnchor12}>www.uafaccreditation.org</Text> for current
              accreditation information.
            </Text>
          </View>
        </Page>

        {/* A4 Portrait Page */}
        <Page size="A4" style={{ padding: '25px', paddingBottom: '100px' }}>
  {/* Header Section */}
  <View style={stylePage_2.view_header} fixed>
    <Image src="/media/logos/schedule-logo.png" style={{ width: '100px', height: '48px', opacity: 0.5 }} />
    <Image src="/media/iafmla.jpg" style={{ width: '95px', height: '55px', opacity: 0.5 }} />
  </View>

  {/* Main Content */}
  <View style={{ paddingBottom: '150px' }}> {/* Increased bottom padding to avoid footer overlap */}
    <Text style={stylePage_2.text_2}>Schedule of Accreditation</Text>
    <Text style={stylePage_2.header_1}>
      Issue No:{item?.issue_number}, Issue Date:{' '}
      {formatMyDate(new Date(item?.issue_date))}
    </Text>
    <Text
      style={{
        fontSize: 13,
        color: '#333',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        marginBottom: 5,
      }}
    >
      Name of CAB:
      <Text style={stylePage_2.font13}>{item?.cab_data?.cab_company}</Text>
    </Text>

    <Text
      style={{
        fontSize: 13,
        color: '#333',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        marginBottom: 5,
      }}
    >
      Key Location(s):
      <Text style={stylePage_2.font13}>
        {[item?.key_location?.address, item?.key_location?.city, item?.key_location?.state, item?.key_location?.country, item?.key_location?.zip_code]
          .filter(Boolean)
          .join(', ')}
      </Text>
    </Text>

    {/* Accreditation Table */}
    <View style={stylePage_2.table}>
      <View style={stylePage_2.tableRow}>
        <Text style={stylePage_2.tableColHeader}>Accredited to ISO/IEC 17021-1</Text>
        <Text style={stylePage_2.tableColHeader}>Accreditation Number</Text>
        <Text style={stylePage_2.tableColHeader}>Accreditation Valid Date</Text>
        <Text style={stylePage_2.tableColHeader}>Accreditation Valid Up to*</Text>
      </View>
      {item?.scheme_data &&
        Object.entries(item.scheme_data)
          .filter(([key, value]) => (value as { enabled: boolean }).enabled)
          .map(([key, value]) => {
            const scheme = value as {
              valid_date: string;
              expiry_date: string;
              issue_number: string;
              enabled: boolean;
              scheme_id: string;
            };
            return (
              <View style={stylePage_2.tableRow} key={key}>
                <Text style={stylePage_2.tableCol}>{scheme.issue_number}</Text>
                <Text style={stylePage_2.tableCol}>{scheme.issue_number}</Text>
                <Text style={stylePage_2.tableCol}>{scheme.valid_date}</Text>
                <Text style={stylePage_2.tableCol}>{scheme.expiry_date}</Text>
              </View>
            );
          })}
    </View>

    {/* Accreditation Standards */}
    {item?.accreditation_standards ? (
      <View>
        <Text style={stylePage_2.text_2}>ACCREDITATION STANDARDS</Text>
        <Text style={stylePage_2.font13}>
          <Html style={stylePage_2.font13}>{item?.accreditation_standards}</Html>
        </Text>
      </View>
    ) : null}

    {/* Certification Standards */}
    {item?.program_standards ? (
      <View>
        <Text
          style={{
            textAlign: 'center',
            fontSize: 20,
            color: '#333',
            fontFamily: 'Open Sans',
            fontWeight: 700,
            textTransform: 'uppercase',
            marginTop: 15,
          }}
        >
          {item?.programme_data?.programme_name} CERTIFICATION STANDARDS
        </Text>
        <Html style={stylePage_2.font13}>{item?.program_standards}</Html>
      </View>
    ) : null}

    {/* Scope of Accreditation */}
    <View>
      <Text style={stylePage_2.text_2}>SCOPE OF ACCREDITATION</Text>
      {item?.scheme_data &&
        Object.entries(item.scheme_data)
          .filter(([key, value]) => (value as { enabled: boolean }).enabled)
          .map(([key, value]) => {
            const scheme = value as {
              valid_date: string;
              expiry_date: string;
              issue_number: string;
              enabled: boolean;
              scheme_id: string;
            };

            const matchedCluster = schemeClusters?.find(
              (cluster) => cluster.scheme_id === scheme.scheme_id
            );

            if (matchedCluster) {
              return (
                <View style={{ marginBottom: 10 }} key={scheme.scheme_id}>
                  <Text
                    style={{
                      marginBottom: 5,
                      fontSize: 13,
                      color: '#333',
                      fontFamily: 'Open Sans',
                      fontWeight: 600,
                    }}
                  >
                    {matchedCluster.scheme_name}
                  </Text>
                  <View style={stylePage_3.tableRow}>
                    <Text style={stylePage_3.tableColHeader}>IAF Scope</Text>
                    <Text style={stylePage_3.tableColHeader}>Description</Text>
                  </View>
                  {matchedCluster.clusters?.map((clusterItem) => {
                    const [iafScope, description] = clusterItem?.name.split(' - ', 2);
                    return (
                      <View style={stylePage_3.tableRow} key={clusterItem?.id}>
                        <Text style={stylePage_3.tableCol}>{iafScope}</Text>
                        <Text style={stylePage_3.tableCol}>{description}</Text>
                      </View>
                    );
                  })}
                </View>
              );
            }
            return null;
          })}
    </View>

    {/* Remarks */}
    {item?.remark ? (
      <View>
        <Text style={stylePage_2.text_2}>Remarks</Text>
        <Html style={stylePage_2.font13}>{item?.remark}</Html>
      </View>
    ) : null}

    {/* Signature Section */}
    <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
      <Image src={item?.member_signature_text} style={{ width: '120px' }} />
      <Text
        style={{
          fontSize: 13,
          color: '#333',
          fontFamily: 'Open Sans',
          paddingRight: 40,
        }}
      >
        DIRECTOR
      </Text>
    </View>
  </View>

  {/* Footer - Fixed Position */}
  <View
  style={{
    position: 'absolute',
    bottom: '25px', // Adjust the footer position
    left: 0,
    right: 0,
    textAlign: 'right', // Right-align the content
    marginRight: 20,
    fontSize: 12,
    color: 'grey',
  }}
  fixed
>
  <Text
    render={({ pageNumber }) => (
      <>
        <Text style={{ marginRight: 20 }}>{`Doc No: ${item.doc_number}`}</Text>{"\n"}
        <Text style={{ marginLeft: 20 }}>{`Rev No: ${item.rev_number}`}</Text>{"\n"}
        <Text>{`Effective Date: ${item?.issue_date ? new Date(item.issue_date).toISOString().split('T')[0] : '--'}`}</Text>{"\n"}
        <Text style={{ marginLeft: 20, marginRight: 20 }}>{`Page: ${pageNumber}`}</Text>
      </>
    )}
  />
</View>
</Page>

      </Document>
    </PDFViewer>
  )
}

export default CabSchedulePDF
