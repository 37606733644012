import React from 'react';

const NumberToWord = (num: number): string => {
  const ones = [
    "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
  ];
  const teens = [
    "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen",
    "Seventeen", "Eighteen", "Nineteen",
  ];
  const tens = [
    "", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety",
  ];
  const scales = ["", "Thousand", "Million", "Billion"];

  if (num === 0) return "Zero Only";

  let words = "";

  const chunkify = (n: number): number[] => {
    const chunks: number[] = [];
    while (n > 0) {
      chunks.push(n % 1000);
      n = Math.floor(n / 1000);
    }
    return chunks;
  };

  const chunkToWords = (chunk: number): string => {
    let chunkWords = "";

    if (chunk >= 100) {
      chunkWords += ones[Math.floor(chunk / 100)] + " Hundred ";
      chunk %= 100;
    }
    if (chunk >= 11 && chunk <= 19) {
      chunkWords += teens[chunk - 11] + " ";
    } else {
      if (chunk >= 10) {
        chunkWords += tens[Math.floor(chunk / 10)] + " ";
        chunk %= 10;
      }
      chunkWords += ones[chunk] + " ";
    }
    return chunkWords.trim();
  };

  const chunks = chunkify(num);

  chunks.forEach((chunk, i) => {
    if (chunk > 0) {
      words = `${chunkToWords(chunk)} ${scales[i]} ${words}`;
    }
  });

  return `${words.trim()} Only`;
};

export default NumberToWord;
