import axios from 'axios'
import React, {useState, ChangeEvent, useEffect} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {getUserDetails} from '../components/VerifiedAssessorFunction'

const API_URL = process.env.REACT_APP_API_URL

type AssessorPlannerData = {
  id: string
  cab_name: string
  assessor_name: {id: string; first_name: string; last_name: string}[]
  scheme_name: string
  assessor_data: {
    location: string
    assessor: string
    assessor__first_name: string
    assessor__last_name: string
  }[]
  assessment_multi_date: string
  assessment_number: string
  assessment_type: string
  assessment_duration: string
  remark: string
  is_approved: boolean
  is_rejected: boolean
  status: string | null
  report: string | null
  report_remark: string | null
  create_at: string
  update_at: string
  scheme: string
  assessor_planner: string
  cab: string
  assessor: string[]
  cluster_values: {id: string; name: string}[]
}

const AddReportModal = ({show, handleClose, refresh, selectedAssessment}) => {
  const [selectCluster, setSelectCluster] = useState<any[]>([])
  const [selectedCluster, setSelectedCluster] = useState<any[]>([])
  const [estimationDetails, setEstimationDetails] = useState<{
    documentFile: File | null
  }>({
    documentFile: null,
  })

  const documentFileUploader = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setEstimationDetails({...estimationDetails, documentFile: e.target.files[0]})
    } else {
      setEstimationDetails({...estimationDetails, documentFile: null})
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      const formData = new FormData()
      formData.append('status', 'Completed')

      if (estimationDetails.documentFile) {
        formData.append('report', estimationDetails.documentFile)
      }

      const response = await axios.put(
        `${API_URL}/cab/assessor/planner/scheme/${selectedAssessment.id}/new/update/`,
        formData
      )

      if (response.status === 200 || response.status === 201) {
        toast.success('Document Uploaded successfully.')
        handleClose()
        refresh()

        setSelectedCluster([])
      } else {
        toast.error('Failed to submit application. Please try again.')
        handleClose()
        refresh()
      }
    } catch (error) {
      toast.error('Something went wrong')
      handleClose()
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Recommended Scope / Technical Areas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>{selectedAssessment?.scheme_name}</h3>
        <div className='row p-5'>
          <div className='mb-5 col-12'>
            <label className='form-label'>Upload Report</label>
            <input
              type='file'
              className='form-control form-control-solid'
              placeholder='--'
              onChange={documentFileUploader}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button variant='success' onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddReportModal
