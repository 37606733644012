import axios from 'axios'
import React, {useEffect, useState} from 'react'
type Props = {
  userId: string | undefined
  ARC_member: string | undefined
  ARC_no: string | undefined
}
const GenerateQMSax = ({userId, ARC_member, ARC_no}: Props) => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const QmsSchemeID = '745048c9-17e9-40bc-a8d7-6ef2a91e9aee'

  const [qmsAnnexure, setQMSAnnxeure] = useState({
    iaf_1_1: '01,03, 30',
    iaf_1_2: '01, 30',
    iaf_1_3: '3',
    iaf_1_4: '',
    iaf_1_5: '',
    iaf_1_6: '',
    iaf_1_7: '',
    iaf_1_8: '',

    iaf_1_9: '17,18,19, 20, 22',
    iaf_1_10: ' 17,18,19',
    iaf_1_11: ' 20 or 22',
    iaf_1_12: '',
    iaf_1_13: '',
    iaf_1_14: '',
    iaf_1_15: '',
    iaf_1_16: '',

    iaf_1_17: ' 07a, 08, 09',
    iaf_1_18: ' 07a, 08',
    iaf_1_19: ' 9',
    iaf_1_20: '',
    iaf_1_21: '',
    iaf_1_22: '',
    iaf_1_23: '',
    iaf_1_24: '',

    iaf_1_25: '02,15 and 16',
    iaf_1_26: '16',
    iaf_1_27: '02 or 15',
    iaf_1_28: '',
    iaf_1_29: '',
    iaf_1_30: '',
    iaf_1_31: '',
    iaf_1_32: '',

    iaf_1_33: '28 and 34',
    iaf_1_34: '34',
    iaf_1_35: '28',
    iaf_1_36: '',
    iaf_1_37: '',
    iaf_1_38: '',
    iaf_1_39: '',
    iaf_1_40: '',

    iaf_1_41: '04,05,06,14, 23',
    iaf_1_42: ' 04,06, 23',
    iaf_1_43: '05 or 14',
    iaf_1_44: '',
    iaf_1_45: '',
    iaf_1_46: '',
    iaf_1_47: '',
    iaf_1_48: '',

    iaf_1_49: '07b,10 and 12',
    iaf_1_50: '07b,10',
    iaf_1_51: '12',
    iaf_1_52: '',
    iaf_1_53: '',
    iaf_1_54: '',
    iaf_1_55: '',
    iaf_1_56: '',

    iaf_1_57: ' 25,26,27',
    iaf_1_58: ' 25, 27',
    iaf_1_59: '26',
    iaf_1_60: '',
    iaf_1_61: '',
    iaf_1_62: '',
    iaf_1_63: '',
    iaf_1_64: '',

    iaf_1_65: '24,31,39',
    iaf_1_66: '31,39',
    iaf_1_67: '24',
    iaf_1_68: '',
    iaf_1_69: '',
    iaf_1_70: '',
    iaf_1_71: '',
    iaf_1_72: '',

    iaf_1_73: '29, 32, 33, 35, 36, 37',
    iaf_1_74: '29,32, 35, 36',
    iaf_1_75: '33 or 37',
    iaf_1_76: '',
    iaf_1_77: '',
    iaf_1_78: '',
    iaf_1_79: '',
    iaf_1_80: '',

    iaf_1_81: '11',
    iaf_1_82: 'Nil',
    iaf_1_83: '11',
    iaf_1_84: '',
    iaf_1_85: '',
    iaf_1_86: '',
    iaf_1_87: '',
    iaf_1_88: '',

    iaf_1_89: '13',
    iaf_1_90: 'Nil',
    iaf_1_91: '13',
    iaf_1_92: '',
    iaf_1_93: '',
    iaf_1_94: '',
    iaf_1_95: '',
    iaf_1_96: '',

    iaf_1_97: '21',
    iaf_1_98: 'Nil',
    iaf_1_99: '21',
    iaf_1_100: '',
    iaf_1_101: '',
    iaf_1_102: '',
    iaf_1_103: '',
    iaf_1_104: '',

    iaf_1_105: '38',
    iaf_1_106: 'Nil',
    iaf_1_107: '38',
    iaf_1_108: '',
    iaf_1_109: '',
    iaf_1_110: '',
    iaf_1_111: '',
    iaf_1_112: '',
  })
  useEffect(() => {
    const getQMSannexureData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/arc/anexxure/report/list/?cab=${userId}&assessor=${ARC_member}&scheme=${QmsSchemeID}&create_at=&update_at=`
        )

        if (response.status === 200 || response.status === 201) {
          console.log(response.data, 'DATA')
          const jsonData = response.data[0]?.json_value // Assuming json_value is in the first object
          // setReportId(response.data[0]?.id)
          if (jsonData) {
            setQMSAnnxeure((prevState) => ({
              ...prevState,
              ...jsonData,
            }))
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    getQMSannexureData()
  }, [])
  return (
    <div
      className=' mb-5 mb-xl-10'
      id='kt_profile_details_view'
      style={{width: '100%', background: '#fff', padding: '25px', borderRadius: '5px'}}
    >
      <div className='card-body p-9'>
        <h2 className='text-center'>Quality Management Systems QMS (IAF Codes 1 to 39)</h2>
        <h3 className='text-center mb-10 py-5 fs-5 ' style={{borderBottom: '1px solid #f3f3f3'}}>
          CHECKLIST FOR THE AWARD OF SCOPES FOR MS DURING INITIAL ACCREDITATION
        </h3>
        <div className='mb-5 col-lg-12'>
          <div className=' card-xl-stretch mb-5 mb-xl-10 '>
            {/* <label className='required form-label'>Date/s of Witness assessment/s </label> */}
            <table
              className='table align-middle table-row-dashed fs-6 gy-5 px-5'
              id='kt_table_users'
            >
              <thead>
                <tr className='text-start  fw-bold  text-uppercase gs-0'>
                  <th className='w-150px pe-2 fs-7'>IAF Codes</th>
                  <th className='w-150px pe-2 fs-7'>Non-Critical Codes</th>
                  <th className='w-150px pe-2 fs-7'>Critical Codes</th>
                  <th className='w-150px pe-2 fs-7'>Codes Applied By CAB</th>

                  <th className='w-250px pe-2 fs-7'>
                    Details of the demonstration of the competent personnel for non-critical IAF
                    codes during office assessment
                  </th>
                  <th className='w-250px pe-2 fs-7'>
                    Witness Details for Critical Codes, (IAF Code, Date of Witness and Assessment
                    Number)
                  </th>
                  <th className='w-250px pe-2 fs-7'>
                    Remarks by the Senior Executive/Manager Accreditation for the codes to be
                    awarded based on the requirements
                  </th>
                  <th className='w-250px pe-2 fs-7'>
                    Decision /Remarks by ARC Member (Awarded/Refused)
                  </th>
                </tr>
              </thead>
              <tbody
                className='text-gray-600 fw-semibold'
                style={{maxHeight: '400px', overflowY: 'auto'}}
              >
                {qmsAnnexure?.iaf_1_4 ||
                  qmsAnnexure?.iaf_1_5 ||
                  qmsAnnexure?.iaf_1_6 ||
                  qmsAnnexure?.iaf_1_7 ||
                  qmsAnnexure?.iaf_1_8 ? (
                    <tr>
                      <td>
                        <textarea
                          className='form-control form-control-solid'
                          rows={5}
                          value={qmsAnnexure.iaf_1_1}
                          readOnly
                        />
                      </td>
                      <td>
                        <textarea
                          className='form-control form-control-solid'
                          rows={5}
                          name='uaf_requirement'
                          // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_2: e?.target?.value })}
                          value={qmsAnnexure.iaf_1_2}
                          readOnly
                        />
                      </td>
                      <td>
                        {' '}
                        <textarea
                          className='form-control form-control-solid'
                          rows={5}
                          name='uaf_requirement'
                          // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_3: e?.target?.value })}
                          value={qmsAnnexure.iaf_1_3}
                          readOnly
                        />
                      </td>
                      <td>
                        <textarea
                          className='form-control form-control-solid'
                          rows={5}
                          name='uaf_requirement'
                          // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_4: e?.target?.value })}
                          value={qmsAnnexure?.iaf_1_4}
                          readOnly
                        />
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_5: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_5}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        {' '}
                        <div className='text-gray-800 mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_6: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_6}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_7: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_7}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_8: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_8}
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}
                {/* <tr>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={5}
                      value={qmsAnnexure.iaf_1_1}
                      readOnly
                    />
                  </td>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={5}
                      name='uaf_requirement'
                      // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_2: e?.target?.value })}
                      value={qmsAnnexure.iaf_1_2}
                      readOnly
                    />
                  </td>
                  <td>
                    {' '}
                    <textarea
                      className='form-control form-control-solid'
                      rows={5}
                      name='uaf_requirement'
                      // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_3: e?.target?.value })}
                      value={qmsAnnexure.iaf_1_3}
                      readOnly
                    />
                  </td>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={5}
                      name='uaf_requirement'
                      // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_4: e?.target?.value })}
                      value={qmsAnnexure?.iaf_1_4}
                      readOnly
                    />
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={5}
                        name='uaf_requirement'
                        // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_5: e?.target?.value })}
                        value={qmsAnnexure?.iaf_1_5}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    {' '}
                    <div className='text-gray-800 mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={5}
                        name='uaf_requirement'
                        // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_6: e?.target?.value })}
                        value={qmsAnnexure?.iaf_1_6}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={5}
                        name='uaf_requirement'
                        // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_7: e?.target?.value })}
                        value={qmsAnnexure?.iaf_1_7}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={5}
                        name='uaf_requirement'
                        // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_8: e?.target?.value })}
                        value={qmsAnnexure?.iaf_1_8}
                        readOnly
                      />
                    </div>
                  </td>
                </tr> */}
                {qmsAnnexure?.iaf_1_12 ||
                  qmsAnnexure?.iaf_1_13 ||
                  qmsAnnexure?.iaf_1_14 ||
                  qmsAnnexure?.iaf_1_15 ||
                  qmsAnnexure?.iaf_1_16 ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_9: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_9}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <div className=''>
                            <textarea
                              className='form-control form-control-solid'
                              rows={5}
                              name='uaf_requirement'
                              // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_10: e?.target?.value })}
                              value={qmsAnnexure?.iaf_1_10}
                              readOnly
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_11: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_11}
                            readOnly
                          />
                        </div>
                      </td>

                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_12: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_12}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_13: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_13}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_14: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_14}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_15: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_15}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_16: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_16}
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}

                {qmsAnnexure?.iaf_1_20 ||
                  qmsAnnexure?.iaf_1_21 ||
                  qmsAnnexure?.iaf_1_22 ||
                  qmsAnnexure?.iaf_1_23 ||
                  qmsAnnexure?.iaf_1_24 ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_17: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_17}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <div className=''>
                            <textarea
                              className='form-control form-control-solid'
                              rows={5}
                              name='uaf_requirement'
                              // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_18: e?.target?.value })}
                              value={qmsAnnexure?.iaf_1_18}
                              readOnly
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_19: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_19}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_20: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_20}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_21: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_21}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_22: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_22}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_23: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_23}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_24: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_24}
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}
                {qmsAnnexure?.iaf_1_28 ||
                  qmsAnnexure?.iaf_1_29 ||
                  qmsAnnexure?.iaf_1_30 ||
                  qmsAnnexure?.iaf_1_31 ||
                  qmsAnnexure?.iaf_1_32 ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_25: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_25}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <div className=''>
                            <textarea
                              className='form-control form-control-solid'
                              rows={5}
                              name='uaf_requirement'
                              // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_26: e?.target?.value })}
                              value={qmsAnnexure?.iaf_1_26}
                              readOnly
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_27: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_27}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_28: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_28}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_29: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_29}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_30: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_30}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_31: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_31}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_32: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_32}
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}

                {qmsAnnexure?.iaf_1_36 ||
                  qmsAnnexure?.iaf_1_37 ||
                  qmsAnnexure?.iaf_1_38 ||
                  qmsAnnexure?.iaf_1_39 ||
                  qmsAnnexure?.iaf_1_40 ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_33: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_33}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_34: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_34}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_35: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_35}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_36: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_36}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_37: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_37}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_38: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_38}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_39: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_39}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_40: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_40}
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}

                {qmsAnnexure?.iaf_1_44 ||
                  qmsAnnexure?.iaf_1_45 ||
                  qmsAnnexure?.iaf_1_46 ||
                  qmsAnnexure?.iaf_1_47 ||
                  qmsAnnexure?.iaf_1_48 ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_41: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_41}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_42: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_42}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_43: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_43}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_44: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_44}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_45: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_45}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_46: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_46}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_47: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_47}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_48: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_48}
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}

                {qmsAnnexure?.iaf_1_52 ||
                  qmsAnnexure?.iaf_1_53 ||
                  qmsAnnexure?.iaf_1_54 ||
                  qmsAnnexure?.iaf_1_55 ||
                  qmsAnnexure?.iaf_1_56 ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_49: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_49}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <div className=''>
                            <textarea
                              className='form-control form-control-solid'
                              rows={5}
                              name='uaf_requirement'
                              // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_50: e?.target?.value })}
                              value={qmsAnnexure?.iaf_1_50}
                              readOnly
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            name='uaf_requirement'
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_51: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_51}
                            readOnly
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_52: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_52}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_53: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_53}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_54: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_54}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_55: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_55}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_56: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_56}
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}

                {qmsAnnexure?.iaf_1_60 ||
                  qmsAnnexure?.iaf_1_61 ||
                  qmsAnnexure?.iaf_1_62 ||
                  qmsAnnexure?.iaf_1_63 ||
                  qmsAnnexure?.iaf_1_64 ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_57: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_57}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_58: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_58}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_59: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_59}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_60: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_60}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_61: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_61}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_62: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_62}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_63: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_63}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_64: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_64}
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}

                {qmsAnnexure?.iaf_1_68 ||
                  qmsAnnexure?.iaf_1_69 ||
                  qmsAnnexure?.iaf_1_70 ||
                  qmsAnnexure?.iaf_1_71 ||
                  qmsAnnexure?.iaf_1_72 ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_65: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_65}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_66: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_66}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_67: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_67}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_68: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_68}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_69: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_69}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_70: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_70}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_71: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_71}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_72: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_72}
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}

                {qmsAnnexure?.iaf_1_76 ||
                  qmsAnnexure?.iaf_1_77 ||
                  qmsAnnexure?.iaf_1_78 ||
                  qmsAnnexure?.iaf_1_79 ||
                  qmsAnnexure?.iaf_1_80 ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_73: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_73}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_74: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_74}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_75: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_75}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_76: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_76}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_77: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_77}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_78: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_78}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_79: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_79}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_80: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_80}
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}

                {qmsAnnexure?.iaf_1_84 ||
                  qmsAnnexure?.iaf_1_85 ||
                  qmsAnnexure?.iaf_1_86 ||
                  qmsAnnexure?.iaf_1_87 ||
                  qmsAnnexure?.iaf_1_88 ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_81: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_81}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_82: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_82}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_83: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_83}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_84: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_84}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_85: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_85}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_86: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_86}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_87: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_87}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_88: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_88}
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}

                {qmsAnnexure?.iaf_1_92 ||
                  qmsAnnexure?.iaf_1_93 ||
                  qmsAnnexure?.iaf_1_94 ||
                  qmsAnnexure?.iaf_1_95 ||
                  qmsAnnexure?.iaf_1_96 ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_89: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_89}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_90: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_90}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_91: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_91}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_92: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_92}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_93: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_93}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_94: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_94}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_95: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_95}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_96: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_96}
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}

                {
                  qmsAnnexure?.iaf_1_100 ||
                  qmsAnnexure?.iaf_1_101 ||
                  qmsAnnexure?.iaf_1_102 ||
                  qmsAnnexure?.iaf_1_103 ||
                  qmsAnnexure?.iaf_1_103  ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_97: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_97}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_98: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_98}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_99: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_99}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_100: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_100}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_101: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_101}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_102: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_102}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_103: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_103}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_104: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_104}
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}
                {qmsAnnexure?.iaf_1_108 ||
                  qmsAnnexure?.iaf_1_109 ||
                  qmsAnnexure?.iaf_1_110 ||
                  qmsAnnexure?.iaf_1_111 ||
                  qmsAnnexure?.iaf_1_112 ? (
                    <tr>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_105: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_105}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_106: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_106}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800 mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_107: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_107}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_108: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_108}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_109: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_109}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_110: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_110}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_111: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_111}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='text-gray-800  mb-1'>
                          <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            readOnly
                            // onChange={(e) => setQMSAnnxeure({ ...qmsAnnexure, iaf_1_112: e?.target?.value })}
                            value={qmsAnnexure?.iaf_1_112}
                          />
                        </div>
                      </td>
                    </tr>
                  ):null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenerateQMSax
