import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { FaFolder, FaFileDownload, FaTrash } from 'react-icons/fa'
import { Modal } from 'react-bootstrap'
import { usePermissions } from '../permission_component/PermissionContext'
import JSZip from 'jszip'

interface DocFile {
  file_name: string
  redesign_url: string
  content?: string
}

interface Folder {
  name: string
  path?: string
  children?: Folder[]
  files?: DocFile[]
}

interface Breadcrumb {
  name: string
  data: Folder
}

const AssessorHierarchy: React.FC<{ urlId: string }> = ({ urlId }) => {
  const { assessorPermission, cabPermission, superUserPermission, updatePermissions } = usePermissions();
  const [hierarchy, setHierarchy] = useState<Folder | null>(null)
  const [currentFolder, setCurrentFolder] = useState<Folder | null>(null)
  const [breadcrumb, setBreadcrumb] = useState<Breadcrumb[]>([])
  const [newFolderName, setNewFolderName] = useState<string>('')
  const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set())
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const BASE_URL = process.env.REACT_APP_API_URL || ''

  const openModal = () => setModalIsOpen(true)
  const closeModal = () => setModalIsOpen(false)

  const fetchHierarchy = async () => {
    try {
      if(superUserPermission?.is_superuser === true){
      const response = await axios.get<Folder>(`${BASE_URL}/aws/file/structure/assessor/?assessor=${urlId}`)
      setHierarchy(response.data)
      setCurrentFolder(response.data)
      setBreadcrumb([{name: response.data.name, data: response.data}])
    }else if(assessorPermission?.is_verify_accessor === true){
      const response = await axios.get<Folder>(`${BASE_URL}/aws/file/structure/assessor/external/?assessor=${urlId}`)
      setHierarchy(response.data)
      setCurrentFolder(response.data)
      setBreadcrumb([{name: response.data.name, data: response.data}])
    }
    } 
    catch (err) {
      toast.error('Failed to load folder hierarchy.')
    }
  }

  useEffect(() => {
    fetchHierarchy()
  }, [BASE_URL, urlId])

  const constructPath = () => breadcrumb.map((crumb) => crumb.name).join('/')

  const handleFileSelection = (file_name: string) => {
    setSelectedFiles((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(file_name)) {
        newSet.delete(file_name)
      } else {
        newSet.add(file_name)
      }
      return newSet
    })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0])
    }
  }

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast.error('No file selected!')
      return
    }
    const formData = new FormData()
    formData.append('file', selectedFile)
    formData.append('folder_path', constructPath())
    formData.append('user', urlId)

    try {
      await axios.post(`${BASE_URL}/aws/upload/file/`, formData)
      toast.success('File uploaded successfully!')
      setSelectedFile(null)
      fetchHierarchy()
      closeModal()
    } catch (err) {
      toast.error('Failed to upload file.')
    }
  }

  const handleFolderCreation = async () => {
    if (!newFolderName.trim()) {
      toast.error('Enter Folder Name')
      return
    }

    const fullPath = constructPath()
    try {
      await axios.post(`${BASE_URL}/aws/create/folder/`, {
        folder_name: newFolderName,
        folder_path: fullPath,
        user: urlId,
      })
      toast.success('Folder created successfully!')
      setNewFolderName('')
      fetchHierarchy()
    } catch (err) {
      toast.error('Failed to create folder.')
    }
  }

  const handleDownloadFolder = async () => {
    try {
      if (!currentFolder) return

      const zip = new JSZip()
      const addFilesToZip = (folder: Folder, path = '') => {
        folder.files?.forEach((file) => {
          zip.file(`${path}${file.file_name}`, file.content || '')
        })
        folder.children?.forEach((subFolder) => {
          addFilesToZip(subFolder, `${path}${folder.name}/`)
        })
      }

      addFilesToZip(currentFolder)
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const url = window.URL.createObjectURL(content)
        const a = document.createElement('a')
        a.href = url
        a.download = `${constructPath()}.zip`
        a.click()
        window.URL.revokeObjectURL(url)
      })
    } catch (err) {
      toast.error('Failed to download folder locally.')
    }
  }

  const handleDeleteFolder = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this folder?')
    if (!confirmDelete) return

    try {
      await axios.post(`${BASE_URL}/aws/delete/folder/`, {
        user: urlId,
        path: constructPath(),
      })
      toast.success('Folder deleted successfully!')
      fetchHierarchy()
    } catch (err) {
      toast.error('Failed to delete folder.')
    }
  }

  const handleDeleteFiles = async () => {
    if (selectedFiles.size === 0) {
      toast.error('Please select at least one file to delete!')
      return
    }

    const confirmDelete = window.confirm(
      `Are you sure you want to delete the selected ${selectedFiles.size} file(s)?`
    )
    if (!confirmDelete) return

    try {
      await axios.post(`${BASE_URL}/aws/delete/files/`, {
        user: urlId,
        file_names: Array.from(selectedFiles),
        folder_path: constructPath(),
      })
      toast.success('Files deleted successfully!')
      setSelectedFiles(new Set())
      fetchHierarchy()
    } catch (err) {
      toast.error('Failed to delete files.')
    }
  }

  const navigateBack = (index: number) => {
    const target = breadcrumb[index]
    setCurrentFolder(target.data)
    setBreadcrumb(breadcrumb.slice(0, index + 1))
  }

  const openFolder = (folder: Folder) => {
    setCurrentFolder(folder)
    setBreadcrumb([...breadcrumb, { name: folder.name, data: folder }])
  }

  const renderFiles = (files?: DocFile[]) => {
    // if (!files || files.length === 0) {
    //   return <p>No Documents</p>  // Handle empty or undefined file list
    // }

    return files?.map((file, index) => (
      <li key={index} style={{ margin: '20px 10px', display: 'flex', alignItems: 'center' }}>
        {file.file_name ? (
          <>
            <input
              type="checkbox"
              checked={selectedFiles.has(file.file_name)}
              onChange={() => handleFileSelection(file.file_name)}
              style={{ marginRight: '10px' }}
            />
            <img
              src="https://uafaccreditation.s3.amazonaws.com/global/file/doc.png"
              alt="File Icon"
              style={{ marginRight: '5px' }}
            />
            <a href={file.redesign_url} target="_blank" rel="noopener noreferrer">
              {decodeURIComponent(file.file_name)}
            </a>
          </>
        ):null}
      </li>
    ))
  }

  const renderFolders = (folders?: Folder[]) => {
    if (!folders || folders.length === 0) {
      return null
    }
    return (
      <ul
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '16px',
          padding: '0',
          margin: '0',
          listStyleType: 'none',
        }}
      >
        {folders.map((folder, index) => (
          <li
            key={index}
            onClick={() => openFolder(folder)}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              padding: '15px',
              transition: 'transform 0.2s',
            }}
          >
            <FaFolder style={{ fontSize: '70px', color: 'gold', marginBottom: '10px' }} />
            <span style={{ fontSize: '16px', color: '#333' }}>{decodeURIComponent(folder.name)}</span>
          </li>
        ))}
      </ul>
    )
  }

  const renderBreadcrumb = () =>
    breadcrumb.map((crumb, index) => (
      <span
        key={index}
        onClick={() => navigateBack(index)}
        style={{
          cursor: 'pointer',
          marginRight: '8px',
          fontSize: '14px',
          color: '#007bff',
          textDecoration: 'underline',
        }}
      >
        {decodeURIComponent(crumb.name)} {index < breadcrumb.length - 1 && ' / '}
      </span>
    ))

  return (
    <div>
      <div className="d-flex align-items-center mb-3" style={{ justifyContent: 'space-between' }}>
        <div>{renderBreadcrumb()}</div>
        <div>
          <button
            onClick={handleDownloadFolder}
            className="btn btn-primary"
            style={{ marginRight: '10px' }}
          >
            <FaFileDownload style={{ marginRight: '8px' }} /> Download Folder
          </button>
          <button
            onClick={handleDeleteFolder}
            className="btn btn-danger"
            style={{ marginRight: '10px' }}
          >
            Delete Folder
          </button>
          <button
            onClick={handleDeleteFiles}
            className="btn btn-danger"
            style={{ marginRight: '10px' }}
            disabled={selectedFiles.size === 0}
          >
            Delete Files
          </button>
        </div>
      </div>

      <div className="d-flex mb-5" style={{ width: '50%' }}>
        <input
          style={{ width: '150px', background: '#f2f2f2' }}
          type="text"
          className="form-control form-control-solid"
          value={newFolderName}
          onChange={(e) => setNewFolderName(e.target.value)}
          placeholder="New Folder Name"
        />
        <button className="ms-2 btn btn-primary" onClick={handleFolderCreation}>
          Create Folder
        </button>
        <button onClick={openModal} className="ms-2 btn btn-warning">
          Choose & Upload File
        </button>
      </div>

      <ul className="file-folder" style={{ listStyle: 'none', paddingLeft: '0', margin: '20px 0' }}>
        <>
          {renderFolders(currentFolder?.children)}
          {renderFiles(currentFolder?.files)}
        </>
      </ul>

      <Modal show={modalIsOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Choose Your File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" onChange={handleFileChange} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleFileUpload} className="btn btn-primary">
            Upload File
          </button>
          <button onClick={closeModal} className="btn btn-secondary">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AssessorHierarchy
