import React, {useCallback, useEffect, useMemo, useState} from 'react'
import UAFLoader from '../../loader/UafLoader'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import axios from 'axios'
import Select, {SingleValue} from 'react-select'
import AddToGroup from '../team-mangement/components/settings/cards/AddToGroup'
import {toast} from 'react-toastify'
import {Button, Modal} from 'react-bootstrap'

const BASE_URL = process.env.REACT_APP_API_URL

const GlobalStandard = () => {
  const [value, setValue] = useState<any[]>([])
  const [loader, setLoader] = useState<boolean>(false)
  const [programme, setProgramme] = useState<any[]>([])
  const [addNewScheme, setAddNewScheme] = useState({name: '', scheme: ''})
  const [showEditRole, setShowEditRole] = useState<any>()
  const [showAddScheme, setShowAddScheme] = useState(false)
  const fetchData = async () => {
    try {
      setLoader(true)
      const response = await axios.get(`${BASE_URL}/global/standard/data/list/`)
      setValue(response.data)
      setLoader(false)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchPrograme = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/global/scheme/data/list/`)
      setProgramme(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchPrograme()
    fetchData()
  }, [])

  const Validation = () => {
    if (!addNewScheme?.name) {
      toast.error('Please Enter Stnadard First')
      return false
    } else if (!addNewScheme?.scheme) {
      toast.error('Please Enter Scheme First')
      return false
    }

    return true
  }
  const AddScheme = async () => {
    if (!Validation()) return
    try {
      setLoader(true)
      const response = await axios.post(`${BASE_URL}/global/standard/post/`, addNewScheme)
      if (response.status === 201 || response.status === 200) {
        setLoader(false)
        fetchData()
        setAddNewScheme({name: '', scheme: ''})
        toast.success('Standard Created Successfully')
      }
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  const handleEditScheme = async (id, name) => {
    try {
      
      const response = await axios.put(`${BASE_URL}/global/standard/update/${id}/`, {name})
      if (response.status === 200) {
        
        fetchData()
        toast.success('Standard Updated Successfully')
      }
    } catch (error) {
      console.log(error)
      toast.error('Error Updating Role')
    }
  }

  const handleShowScheme = async () => {
    setShowAddScheme(true)
  }
  return (
    <>{loader ? <UAFLoader /> : null}
    <div id='text_context'>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <h1 className='mb-5'>Standards</h1>
          <div className='card card-xl-stretch mb-xl-8'>
            <div className='card-header border-0 pt-5'>
              {/* <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Standard</span>
              </h3> */}
              <div className='me-0'>
                <button
                  className='btn btn-sm btn-primary '
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={handleShowScheme}
                >
                  + Add Standard
                </button>
              </div>
            </div>

            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-150px'>Name </th>
                      <th className='min-w-150px'>Scheme</th>
                      <th className='min-w-140px'>Date Created</th>
                      <th className='min-w-120px'>Last Update</th>
                      <th className='min-w-100px text-end'>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {value &&
                      value.map((user, index) => (
                        <tr key={index}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                  {user?.name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                  {user?.scheme_name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary d-block fs-6'
                            >
                              {new Date(user?.create_at).toLocaleDateString()}
                            </a>
                          </td>
                          <td className='text-end'>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack mb-2'>
                                <span className='text-muted me-2 fs-7 fw-semibold'>
                                  {new Date(user?.update_at).toLocaleDateString()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                onClick={() => setShowEditRole(user)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTIcon iconName='pencil' className='fs-3' />
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>

            <Modal show={showEditRole} onHide={() => setShowEditRole('')} backdrop='static'>
              <Modal.Header closeButton>
                <Modal.Title>Edit Standard</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <input
                  className='form-control form-control-solid'
                  type='text'
                  onChange={(e) => setShowEditRole({...showEditRole, name: e?.target?.value})}
                  value={showEditRole?.name}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={() => setShowEditRole('')}>
                  Close
                </Button>
                <Button
                  variant='success'
                  onClick={() => {
                    handleEditScheme(showEditRole?.id, showEditRole?.name)
                    setShowEditRole('')
                  }}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showAddScheme} onHide={() => setShowAddScheme(false)} backdrop='static'>
              <Modal.Header closeButton>
                <Modal.Title>Add Standard</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='mb-5 mt-5' style={{color: '#136ba1'}}>
                  <Select
                    className='cab-control'
                    value={
                      addNewScheme?.scheme
                        ? programme.find((p) => p.id === addNewScheme.scheme) // Match with programme by id
                          ? {
                              value: addNewScheme.scheme,
                              label:
                                programme.find((p) => p.id === addNewScheme.scheme)?.name || '',
                            }
                          : null
                        : null
                    }
                    onChange={(selectedOption) =>
                      setAddNewScheme((prev) => ({
                        ...prev,
                        scheme: selectedOption?.value || '', // Update scheme with selected value
                      }))
                    }
                    options={programme.map((item) => ({
                      value: item.id, // Use id as the value
                      label: item.name, // Use name as the label
                    }))}
                    placeholder='Select Scheme'
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderColor: '#136ba1',
                        boxShadow: 'none',
                        '&:hover': {
                          borderColor: '#136ba1',
                        },
                      }),
                      option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isSelected ? '#136ba1' : 'white',
                        color: state.isSelected ? 'white' : '#136ba1',
                        '&:hover': {
                          backgroundColor: '#136ba1',
                          color: 'white',
                        },
                      }),
                    }}
                  />
                </div>
                <div className='mb-5'>
                  <label className='form-label fw-bold'>Standard:</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Enter Standard Name'
                    value={addNewScheme.name || ''}
                    onChange={(e) =>
                      setAddNewScheme((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={() => setShowAddScheme(false)}>
                  Close
                </Button>
                <Button
                  variant='success'
                  onClick={() => {
                    if (addNewScheme.scheme && addNewScheme.name) {
                      AddScheme()
                      setShowAddScheme(false)
                    } else {
                      alert('Please select a scheme and enter a standard.')
                    }
                  }}
                >
                  Add
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default GlobalStandard
