import axios from 'axios'
import {AnyMxRecord} from 'dns'
import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {toast} from 'react-toastify'
import NumberTOWord from './NumberTOWord'
import _ from 'lodash'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import {DateRange} from 'react-date-range'
import moment from 'moment'
import UAFLoader from '../../loader/UafLoader'
import {Button, Modal} from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'

const CABCertiInvoice = ({selectedOption}) => {
  const BASE_URL = process.env.REACT_APP_API_URL
  interface User {
    id: number
    company: string
  }
  interface SchemeInterface {
    id: string
    scheme: string
  }
  interface Certificate {
    id: string
    status: string
    certificateId: string
    issue_date: string
    expiry_date: string
    scheme_data: {name: string; short_name: string}
    organisation: string | null
    country_name: string | null
  }
  const [pdfInvoice, setPdfInvoice] = useState<File | null>(null)
  const [cabUser, setCabUser] = useState<any>()
  // const [selectionRange, setSelectionRange] = useState({
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: 'selection',
  // })
  // console.log(selectionRange, 'selectionRange')
  const [selectedIssueToDate, setSelectedIssueToDate] = useState<Date | null>(null)
  console.log(selectedIssueToDate, 'selectedIssueToDate')
  const [selectedSchemeName, setSelectedSchemeName] = useState<any>()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [cabId, setCabID] = useState<any>()
  const formattedEndDate = moment(selectedIssueToDate).format('YYYY-MM-DD')

  
  const [showPreview, setShowPreview] = useState(false)
  const [loader, setLoader] = useState<boolean>(false)

  const [invoiceNumber, setInvoiceNumber] = useState<any>()
  const [invoiceDate, setInvoiceDate] = useState(() => new Date().toISOString().split('T')[0])
  const [invoiceDueDate, setInvoiceDueDate] = useState(() => new Date().toISOString().split('T')[0])
  const [toDate, setToDate] = useState(() => new Date().toISOString().split('T')[0])
  const [fromDate, setFromDate] = useState(() => new Date().toISOString().split('T')[0])

  const [certificateData, setCertificateData] = useState<any>([])
  const [certificateCount, setCertificateCount] = useState<any>()
  const [allCertificateData, setAllCertificateData] = useState<any>([])
  const [allCertificateCount, setAllCertificateCount] = useState<any>()
  console.log(certificateData, 'certificateData')

  const [value, setValue] = useState<User[]>([])
  const [scheme, setSchemes] = useState<SchemeInterface[]>([])
  const [userDetails, setUserDetails] = useState<any>()
  const [userSchemeData, setUserSchemeData] = useState<any[]>([])
  console.log(userDetails, 'userDetails')

  const [selectedScheme, setSelectedScheme] = useState<any>()

  const [selectedSchemeID, setSelectedSchemeID] = useState<any>()
  console.log(selectedScheme, 'selectedScheme')
  const getUserData = () => {
    axios
      .get(`${BASE_URL}/account/patch/user/${cabUser?.value}/`)
      .then((response) => {
        const result = response.data
        setUserDetails(result)
        setUserSchemeData(result?.scheme_names)
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }
  useEffect(() => {
    getUserData()
  }, [cabUser?.value])

  const schemeData = userSchemeData.map((item) => ({
    value: item.id, // This is the unique ID for the option
    label: item.short_name, // This is the name displayed in the dropdown
  }))
  console.log(schemeData, 'schemeData')

  useEffect(() => {
    axios
      .get(`${BASE_URL}/account/approved/cab/user/`)
      .then((response) => {
        const data = response.data?.map((item) => ({
          label: `${item?.company}`,
          value: item?.id,
        }))
        setValue(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    axios
      .get(`${BASE_URL}/global/scheme/list/`)
      .then((response) => {
        const data = response.data?.map((item) => ({
          label: `${item?.short_name}`,
          value: item?.id,
        }))
        setSchemes(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  // &issue_date_start=${selectedScheme}&issue_date_end${selectedScheme}
  // const fetchCertificateData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${BASE_URL}/certificate/filter/pagination/?cab=${cabId}&scheme=${selectedScheme}`
  //     )
  //     if (response.status === 200) {
  //       setCertificateData(response?.data?.results)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // useEffect(()=>{
  //   fetchCertificateData()
  // },[cabId,selectedScheme])

  const fetchCertificateData = async () => {
    // Ensure both cabId and selectedScheme are defined
    if (!cabId) {
      return
    }

    const formattedEndDate = moment(selectedIssueToDate).format('YYYY-MM-DD')
    try {
      const response = await axios.get(
        `${BASE_URL}/certificate/filter/?cab=${cabId}&status=Active&issue_date_end=${formattedEndDate}`
      )
      if (response.status === 200) {
        setCertificateData(response?.data)
        setCertificateCount(response?.data?.length)
      }
    } catch (error) {
      console.error('Error fetching certificate data:', error)
    }
  }

  useEffect(() => {
    fetchCertificateData()
  }, [cabId, selectedIssueToDate])
  const fetchAllCertificate = async () => {
    // Ensure both cabId and selectedScheme are defined
    if (!cabId) {
      return
    }

    const formattedEndDate = moment(selectedIssueToDate).format('YYYY-MM-DD')
    try {
      const response = await axios.get(
        `${BASE_URL}/certificate/filter/?cab=${cabId}&status=Active&issue_date_end=${formattedEndDate}`
      )
      if (response.status === 200) {
        setAllCertificateData(response?.data)
        setAllCertificateCount(response?.data?.length)
      }
    } catch (error) {
      console.error('Error fetching certificate data:', error)
    }
  }

  useEffect(() => {
    fetchAllCertificate()
  }, [cabId, selectedIssueToDate])
  const groupedData: Record<string, Certificate[]> = _.groupBy(
    allCertificateData,
    (item) => item.scheme_data.name
  )

  // const fetchCountData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${BASE_URL}/certificate/total/count/data/?cab=${cabUser?.value}`
  //     )
  //     setCountData(response?.data)
  //   } catch (error) {
  //     console.error('Error fetching cluster data:', error)
  //   }
  // }
  // const fetchCertificateData = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/certificate/programme/list/?${cabUser?.value}`)
  //     setCertificateData(response?.data?.certificates)
  //   } catch (error) {
  //     console.error('Error fetching cluster data:', error)
  //   }
  // }
  // const fetchProgramList = async () => {
  //   try {
  //     const responses = await axios.get(`${BASE_URL}/global/programme/list/`)
  //     setProgrammes(responses?.data)
  //   } catch (error) {
  //     console.error('Error fetching cluster data:', error)
  //   }
  // }
  // useEffect(() => {
  //   const mergeData = () => {
  //     if (certificateData.length && programmes.length) {
  //       const tempMergedData: any[] = programmes.map((program) => {
  //         const certificateItem = certificateData.find((item) => item.programme === program.id) || {
  //           programme: program.id,
  //           programme__name: program.name,
  //           active: 0,
  //           suspended: 0,
  //           withdrawn: 0,
  //         }
  //         return certificateItem
  //       })

  //       setMergedData(tempMergedData)

  //       const activeSum = tempMergedData.reduce((sum, item) => sum + item.active, 0)
  //       const suspendedSum = tempMergedData.reduce((sum, item) => sum + item.suspended, 0)
  //       const withdrawnSum = tempMergedData.reduce((sum, item) => sum + item.withdrawn, 0)

  //       setTotalActive(activeSum)
  //       setTotalSuspended(suspendedSum)
  //       setTotalWithdrawn(withdrawnSum)
  //     }
  //   }

  //   mergeData()
  // }, [certificateData, programmes])
  // useEffect(() => {
  //   fetchProgramList()
  // }, [])
  // useEffect(() => {
  //   if (cabUser) {
  //     fetchCountData()
  //     fetchCertificateData()
  //   }
  // }, [cabUser])

  const [table_data, setRows] = useState([
    {id: 1, details: ' ', total_certificate: 0, certificate_fee: 0, totalAmount: 0},
  ])
  const [subTotal, setSubTotal] = useState<any>(0)
  const [grandTotal, setGrandTotal] = useState<any>(0)
  const [netPayable, setNetPayable] = useState<any>(0)

  console.log(table_data, 'SHOW ROW')
  const [tax, setTax] = useState<any>(0)
  const addRow = () => {
    const newId = table_data.length + 1
    const newRow = {
      id: newId,
      details: '',
      total_certificate: 0,
      certificate_fee: 0,
      totalAmount: 0,
    }
    setRows([...table_data, newRow])
  }

  const calculateSubtotal = () => {
    return table_data.reduce((sum, row) => sum + row.totalAmount, 0)
  }
  const calculateGrandTotal = () => {
    return calculateSubtotal() + (parseFloat(tax) || 0) // Ensure tax is a valid number
  }

  const handleInputChange = (index, field, value) => {
    const updatedRows = table_data.map((row, i) => {
      if (i === index) {
        // Update the row based on the field being edited
        const updatedRow = {
          ...row,
          [field]:
            field === 'total_certificate' || field === 'certificate_fee'
              ? parseFloat(value.replace(/^0+/, '')) // Remove leading zeros for numbers
              : value, // For details, use the value as-is
        }

        // Automatically calculate the total amount if fee or mandayRate is updated
        if (field === 'certificate_fee') {
          updatedRow.totalAmount = updatedRow.certificate_fee * updatedRow.total_certificate
        }

        return updatedRow
      }
      return row
    })

    setRows(updatedRows)
  }
  useEffect(() => {
    const newSubtotal = calculateSubtotal()
    const newGrandTotal = newSubtotal + (parseFloat(tax) || 0)
    setSubTotal(newSubtotal)
    setGrandTotal(newGrandTotal)
    setNetPayable(newGrandTotal)
  }, [table_data, tax]) // Trigger recalculation whenever rows or tax change

  const handleSubmit = async () => {
    if (!invoiceDate) {
      toast.error('Invoice date is required.')
      return
    }

    if (!invoiceNumber) {
      toast.error('Invoice number is required.')
      return
    }

    if (subTotal <= 0) {
      toast.error('Sub-total must be greater than zero.')
      return
    }
    if (grandTotal <= 0) {
      toast.error('Grand total must be greater than zero.')
      return
    }
    if (netPayable <= 0) {
      toast.error('Net payable amount must be greater than zero.')
      return
    }

    if (!table_data || table_data.length === 0) {
      toast.error('Details and respective cannot be empty.')
      return
    }
    try {
      //   const jsonData = JSON.stringify(table_data)

      //   const response = await axios.post(`${BASE_URL}/payment/certification/cab/invoice/`, {
      //     tax: parseFloat(tax) || 0,
      //     cab: cabId,
      //     invoice_date: invoiceDate,
      //     invoice_due_date: invoiceDueDate,
      //     invoice_number: invoiceNumber,
      //     sub_total: subTotal,
      //     grand_total: grandTotal,
      //     net_payable: netPayable,
      //     table_data: jsonData,
      //   })
      //   if (response.status === 200 || response.status === 201) {
      //     toast.success('Certificate fee generated successfully.')
      //     setInvoiceDate('')
      //    setInvoiceDueDate('')
      //     setInvoiceNumber('')
      //     setSubTotal(0)
      //     setGrandTotal(0)
      //     setNetPayable(0)
      //     setTax(0)
      //     setRows([{id: 1, details: ' ', total_certificate: 0, certificate_fee: 0, totalAmount: 0}])
      //   } else {
      //     toast.error('Please try again.')
      //   }
      // } catch (error) {
      //   console.log(error)
      // }
      setLoader(true)
      const formData = new FormData()

      // Append other fields
      formData.append('tax', tax)
      formData.append('cab', cabId)
      formData.append('invoice_date', invoiceDate)
      formData.append('invoice_number', invoiceNumber)
      formData.append('sub_total', subTotal)
      formData.append('grand_total', grandTotal)
      formData.append('net_payable', netPayable)
      formData.append('last_issue_date', formattedEndDate)
      formData.append('table_data', JSON.stringify(table_data))

      // Append the PDF file
      if (pdfInvoice instanceof File) {
        formData.append('pdf_invoice', pdfInvoice)
      } else {
        console.error('pdfInvoice is not a valid File object')
      }

      // Make the POST request
      const response = await axios.post(
        `${BASE_URL}/payment/certification/cab/invoice/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Important for file uploads
          },
        }
      )

      if (response.status === 200 || response.status === 201) {
        setLoader(false)
        toast.success('Invoice sent successfully.')
        setInvoiceDate('')
        setInvoiceNumber('')
        setSubTotal(0)
        setGrandTotal(0)
        setNetPayable(0)
        setTax(0)
        setRows([{id: 1, details: ' ', total_certificate: 0, certificate_fee: 0, totalAmount: 0}])
      } else {
        setLoader(false)
        toast.error('Please try again.')
      }
    } catch (error) {
      setLoader(false)
      console.log(error)
      toast.error('An error occurred while generating the annual fee.')
    }
  }
  // const handleDateSelect = (ranges) => {
  //   setSelectionRange({
  //     ...selectionRange,
  //     startDate: ranges.selection.startDate,
  //     endDate: ranges.selection.endDate,
  //   })
  // }

  // Prepare filtered data based on date range
  // const filteredData = () => {
  //   const {startDate, endDate} = selectionRange

  //   return {
  //     issue_date_start: moment(startDate).format('YYYY-MM-DD'),
  //     issue_date_end: moment(endDate).format('YYYY-MM-DD'),
  //   }
  // }

  // const toggleDatePicker = () => {
  //   setShowDatePicker((prev) => !prev)
  // }
  const CreatePDF = async (): Promise<File> => {
    return new Promise((resolve, reject) => {
      const input = document.getElementById('pdfContent')
      if (input) {
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'mm', 'a4')
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = pdf.internal.pageSize.getHeight()

            // Define margins
            const margin = 10 // 10mm margin
            const contentWidth = pdfWidth - margin * 2
            const contentHeight = pdfHeight - margin * 2
            const imgProps = pdf.getImageProperties(imgData)
            const imgHeight = (imgProps.height * contentWidth) / imgProps.width

            let heightLeft = imgHeight
            let position = margin
            pdf.addImage(imgData, 'PNG', margin, position, contentWidth, imgHeight)
            heightLeft -= contentHeight

            // Add additional pages if needed
            while (heightLeft > 0) {
              position = heightLeft - (imgHeight + margin)
              pdf.addPage()
              pdf.addImage(imgData, 'PNG', margin, position, contentWidth, imgHeight)
              heightLeft -= contentHeight
            }
            const pdfBlob = pdf.output('blob')
            const file = new File([pdfBlob], `Certification_fee}.pdf`, {
              type: 'application/pdf',
            })
            resolve(file) // Return the file
          })
          .catch((error) => {
            console.error('Error creating PDF:', error)
            reject(error)
          })
      } else {
        console.error("Element with id 'pdfContent' not found.")
        reject(new Error("Element with id 'pdfContent' not found."))
      }
    })
  }
  // const generatePDF = () => {
  //   const input = document.getElementById('pdfContent')
  //   if (input) {
  //     html2canvas(input).then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png')
  //       const pdf = new jsPDF('p', 'mm', 'a4')
  //       const pdfWidth = pdf.internal.pageSize.getWidth()
  //       const pdfHeight = pdf.internal.pageSize.getHeight()

  //       const imgProps = pdf.getImageProperties(imgData)
  //       const imgWidth = pdfWidth - 20
  //       const imgHeight = (imgProps.height * imgWidth) / imgProps.width

  //       pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight)
  //       pdf.save(`Assessment Fee Invoice`)
  //       })
  //   } else {
  //     console.error("Element with id 'pdfContent' not found.")
  //   }
  // }
  const generatePDF = () => {
    const input = document.getElementById('pdfContent')
    if (input) {
      setLoader(true)
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'mm', 'a4')
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()

        // Define margins
        const margin = 10 // 10mm margin
        const contentWidth = pdfWidth - margin * 2
        const contentHeight = pdfHeight - margin * 2

        const imgProps = pdf.getImageProperties(imgData)
        const imgHeight = (imgProps.height * contentWidth) / imgProps.width

        let heightLeft = imgHeight
        let position = margin

        // Add the first page
        pdf.addImage(imgData, 'PNG', margin, position, contentWidth, imgHeight)
        heightLeft -= contentHeight

        // Add additional pages if needed
        while (heightLeft > 0) {
          position = heightLeft - (imgHeight + margin)
          pdf.addPage()
          pdf.addImage(imgData, 'PNG', margin, position, contentWidth, imgHeight)
          heightLeft -= contentHeight
        }
        setLoader(false)
        pdf.save('certificate_fee_invoice.pdf')
      })
    } else {
      console.error("Element with id 'pdfContent' not found.")
      setLoader(false)
    }
  }

  const handleSaveAndSubmit = async () => {
    try {
      const pdfFile = await CreatePDF()
      setPdfInvoice(pdfFile)
    } catch (error) {
      console.error('Error during Save & Submit:', error)
    }
  }
  useEffect(() => {
    if (pdfInvoice) {
      handleSubmit()
    }
  }, [pdfInvoice])
  return (
    <>
      {loader ? <UAFLoader /> : null}
      <div className='container'>
        <div className='row'>
          <div className='mb-5 col-6'>
            <label className='required form-label'>CAB User</label>
            <Select
              id='select_user'
              name='User'
              options={value}
              className='react-select-styled'
              classNamePrefix='react-select'
              value={cabUser}
              onChange={(e) => {
                setCabUser(e)
                setCabID(e?.value)
              }}
            />
          </div>
          {/* <div className='mb-5 col-6'>
            <label className='required form-label'>Select Scheme</label>
            <Select
              id='select_user'
              name='User'
              options={schemeData}
              className='react-select-styled'
              classNamePrefix='react-select'
              value={selectedScheme}
              onChange={(e) => {
                setSelectedScheme(e)
                setSelectedSchemeID(e?.value)
              }}
            />
          </div> */}
          <div className='mb-5 col-6'>
            {/* <button className='btn btn-primary mt-2' onClick={toggleDatePicker}>
            Select Date Range
          </button> */}
            {/* {showDatePicker && (
            <div className='mt-3'>
              <DateRange
                ranges={[selectionRange]}
                onChange={handleDateSelect}
                moveRangeOnFirstSelection={true}
                retainEndDateOnFirstSelection={true}
              />
            </div>
          )} */}
            <div className='mb-5'>
              <label className='required form-label'>Select Date</label>
              <ReactDatePicker
                className='react_date_picker form-control form-control-solid'
                selected={selectedIssueToDate}
                placeholderText='Select till date to Certificate'
                onChange={(date) => setSelectedIssueToDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
              />
            </div>
          </div>
          {certificateCount !== undefined ? (
            <>
              {certificateCount !== 0 ? (
                <div className='mb-5 col-6 '>
                  <label className='required form-label'>Total Certificates</label>
                  {/* <h3>Total Certificates: {certificateCount}</h3> */}
                  <p
                    style={{
                      fontSize: '16px',
                      border: '1px solid #f1f1f2',
                      padding: '8px',
                      borderRadius: '5px',
                    }}
                  >
                    Active Certificates count: {certificateCount}
                  </p>

                  {/* <div style={{marginBottom: '20px'}}>
                   
                    <table
                      className='table-bordered'
                      style={{width: '100%', borderCollapse: 'collapse'}}
                    >
                      <thead>
                        <tr>
                          <th>S. No.</th>
                          <th>Status</th>
                          <th>Certificate ID</th>
                          <th>Issue Date</th>
                          <th>Expiry Date</th>
                          <th>Scheme</th>
                          <th>Organisation</th>
                          <th>Country Name</th>
                        </tr>
                      </thead>
                      <tbody>
                      {certificateData.map((entries,index) => (
                          <tr key={entries.id}>
                            <td>{index + 1}</td>
                            <td>{entries.status}</td>
                            <td>{entries.certificateId}</td>
                            <td>{new Date(entries.issue_date).toLocaleDateString()}</td>
                            <td>{new Date(entries.expiry_date).toLocaleDateString()}</td>
                            <td>{entries.scheme_data.short_name}</td>
                            <td>{entries.organisation || 'N/A'}</td>
                            <td>{entries.country_name || 'N/A'}</td>
                          </tr>
                        ))} 
                      </tbody>
                    </table>
                  </div> */}
                </div>
              ) : (
                <p>No Active Certificate</p>
              )}
            </>
          ) : null}
        </div>
        <div className=''>
          <div className='mb-5'>
            <div className=''>
              <div className='' style={{border: '1px solid #333'}}>
                <div className='d-flex'>
                  <div className=''>
                    <img
                      alt='Logo'
                      src={'/media/logos/uaf_logo.png'}
                      className='p-1  app-sidebar-logo-default theme-light-show'
                      style={{
                        width: '250px',
                        height: '120px',
                        border: '1px solid #333',
                      }}
                    />
                  </div>
                  <div className='text-center' style={{border: '1px solid #333'}}>
                    <h2 style={{padding: '25px 10px 0px 10px'}}>
                      UNITED ACCREDITATION FOUNDATION INC, 1060 LASKIN ROAD, SUITE 12B/13B, VIRGINIA
                      BEACH, VA 23451
                    </h2>
                  </div>
                </div>
                <div className='row' style={{padding: '0px 10px 0px 10px', fontSize: '18px'}}>
                  <div className='col-3' style={{border: '1px solid #333'}}>
                    <div className='p-2'>Invoice Number </div>
                  </div>
                  <div className='col-3' style={{border: '1px solid #333'}}>
                    <div className='p-2'>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={invoiceNumber}
                        onChange={(e) => setInvoiceNumber(e?.target?.value)}
                      />
                    </div>
                  </div>
                  <div className='col-3' style={{border: '1px solid #333'}}>
                    <div className='p-2'>Dated</div>
                  </div>
                  <div className='col-3' style={{border: '1px solid #333'}}>
                    <div className='p-2'>
                      {' '}
                      <input
                        type='Date'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={invoiceDate}
                        onChange={(e) => setInvoiceDate(e?.target?.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className='row' style={{padding: '0px 10px 0px 10px', fontSize: '18px'}}>
                 
                  <div className='col-6' style={{border: '1px solid #333'}}>
                    <div className='p-2'>To</div>
                  </div>
                  <div className='col-6' style={{border: '1px solid #333'}}>
                    <div className='p-2'> {moment(selectedIssueToDate).format('MM-DD-YYYY')}</div>
                  </div>
                </div>
                <div style={{padding: '0px 10px 0px 10px', border: '1px solid #333'}}>
                  <div className='row text-center' style={{fontSize: '18px'}}>
                    <h2 style={{padding: '20px 0px 20px 0px'}}>Certification Fee</h2>
                  </div>
                </div>
                <div className='table-responsive'>
                  <table className='table-bordered table' style={{border: '1px solid #333'}}>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Details</th>
                        <th>Total Certificate </th>
                        <th>Certificate Fee</th>
                        <th>Total Amount USD($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {table_data.map((row, index) => (
                        <tr key={index}>
                          <td>{row.id}</td>
                          <td>
                            <input
                              type='text'
                              placeholder='Details'
                              value={row.details}
                              onChange={(e) => handleInputChange(index, 'details', e.target.value)}
                            />
                          </td>
                          <td>
                            <input
                              type='number'
                              placeholder='Total Certificate'
                              value={row.total_certificate}
                              onChange={(e) =>
                                handleInputChange(index, 'total_certificate', e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <input
                              type='number'
                              placeholder='Certificate Fee'
                              value={row.certificate_fee}
                              onChange={(e) =>
                                handleInputChange(index, 'certificate_fee', e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <input
                              type='number'
                              placeholder='Total'
                              value={row.totalAmount}
                              readOnly
                            />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                          Sub Total Pre Taxes:
                        </td>
                        <td>
                          <strong>{subTotal.toFixed(2)}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} style={{textAlign: 'right'}}>
                          Taxes, if any:
                        </td>
                        <td>
                          <input
                            type='number'
                            placeholder='Enter Taxes'
                            value={tax}
                            onChange={(e) => setTax(e.target.value.replace(/^0+/, ''))}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                          Grand Total
                        </td>
                        <td>
                          <strong>{grandTotal.toFixed(2)}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                          Net Payable on account of this Invoice
                        </td>
                        <td>
                          <strong>{netPayable.toFixed(2)}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                          Net Amount of Invoice USD ($):
                        </td>
                        <td>
                          <strong>({NumberTOWord(Math.round(netPayable))})</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                          Invoice Due Date
                        </td>
                        <td>
                          <div className='p-2'>
                            {' '}
                            <input
                              type='Date'
                              className='form-control form-control-solid'
                              placeholder='--'
                              value={invoiceDueDate}
                              onChange={(e) => setInvoiceDueDate(e?.target?.value)}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button onClick={addRow}>Add More</button>
              </div>
            </div>
            <div className='text-gray-800  mb-1 mt-2'>
              <button
                id='create_assessment_inovice'
                className='btn btn-warning'
                onClick={() => setShowPreview(true)} /*  */
              >
                Preview
              </button>
            </div>
          </div>
        </div>
        <Modal show={showPreview} onHide={() => setShowPreview(false)} size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>Preview Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id='pdfContent'>
              <div className=''>
                <div className='' style={{border: '1px solid #333'}}>
                  <div className='d-flex'>
                    <div className=''>
                      <img
                        alt='Logo'
                        src={'/media/logos/uaf_logo.png'}
                        className='p-1  app-sidebar-logo-default theme-light-show'
                        style={{
                          width: '250px',
                          height: '120px',
                          border: '1px solid #333',
                        }}
                      />
                    </div>
                    <div className='text-center' style={{border: '1px solid #333'}}>
                      <h2 style={{padding: '35px 120px 0px 121px'}}>
                        UNITED ACCREDITATION FOUNDATION INC,
                        <br /> 1060 LASKIN ROAD, SUITE 12B/13B, VIRGINIA BEACH, VA 23451
                      </h2>
                    </div>
                  </div>
                  <div className='row' style={{padding: '0px 10px 0px 10px', fontSize: '18px'}}>
                    <div className='col-3' style={{border: '1px solid #333'}}>
                      <div className='p-2'>Invoice Number </div>
                    </div>
                    <div className='col-3' style={{border: '1px solid #333'}}>
                      <div className='p-2'>{invoiceNumber}</div>
                    </div>
                    <div className='col-3' style={{border: '1px solid #333'}}>
                      <div className='p-2'>Dated</div>
                    </div>
                    <div className='col-3' style={{border: '1px solid #333'}}>
                      <div className='p-2'> {invoiceDate}</div>
                    </div>
                  </div>
                  <div className='row' style={{padding: '0px 10px 0px 10px', fontSize: '18px'}}>
                    {/* <div className='col-3' style={{border: '1px solid #333'}}>
                  <div className='p-2'>From </div>
                </div>
                <div className='col-3' style={{border: '1px solid #333'}}>
                  <div className='p-2'>
                  
                  CAB Registered
                  </div>
                </div> */}
                    <div className='col-6' style={{border: '1px solid #333'}}>
                      <div className='p-2'>To</div>
                    </div>
                    <div className='col-6' style={{border: '1px solid #333'}}>
                      <div className='p-2'> {moment(selectedIssueToDate).format('MM-DD-YYYY')}</div>
                    </div>
                  </div>
                  <div style={{padding: '0px 10px 0px 10px', border: '1px solid #333'}}>
                    <div className='row text-center'>
                      <div style={{padding: '20px 0px 20px 0px'}}>
                        <h2 style={{fontSize: '22px'}}>{userDetails?.company}</h2>
                        <span style={{fontSize: '16px'}}>{userDetails?.address}</span>
                      </div>
                    </div>
                  </div>
                  <div style={{padding: '0px 10px 0px 10px', border: '1px solid #333'}}>
                    <div className='row text-center' style={{fontSize: '18px'}}>
                      <h2 style={{padding: '20px 0px 20px 0px'}}>Certificate Fee</h2>
                    </div>
                  </div>
                  <div className='table-responsive m-0'>
                    <table className='table-bordered m-0 table' style={{border: '1px solid #333'}}>
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Details</th>
                          <th>Total Certificate </th>
                          <th>Certificate Fee</th>
                          <th>Total Amount USD($)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {table_data.map((row, index) => (
                          <tr key={index}>
                            <td>{row.id}</td>
                            <td>{row.details}</td>
                            <td>{row.total_certificate}</td>
                            <td>{row.certificate_fee}</td>
                            <td>{row.totalAmount}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                            Sub Total Pre Taxes:
                          </td>
                          <td>
                            <strong>{subTotal.toFixed(2)}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{textAlign: 'right'}}>
                            Taxes, if any:
                          </td>
                          <td>{tax}</td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                            Grand Total
                          </td>
                          <td>
                            <strong>{grandTotal.toFixed(2)}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                            Net Payable on account of this Invoice
                          </td>
                          <td>
                            <strong>{netPayable.toFixed(2)}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                            Net Amount of Invoice USD ($):
                          </td>
                          <td>
                            <strong>({NumberTOWord(Math.round(netPayable))})</strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} style={{textAlign: 'right', fontWeight: 'bold'}}>
                            Invoice Due Date
                          </td>
                          <td>{invoiceDueDate}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className='card-title m-0 justify-content-between align-items-center w-100 px-4 py-5'
                    style={{border: '1px solid #333'}}
                  >
                    <h4 className='fw-bolder m-0 pb-2'>
                      International Transfers can be made to the:
                    </h4>
                    <p className='inline_master_head m-0'>
                      Account Name: United Accreditation Foundation Inc.{' '}
                    </p>
                    <p className='inline_master_head m-0'>
                      Bank of America, N.A., New York, NY , United States, 901796 ,{' '}
                    </p>
                    <p className='inline_master_head m-0'>Account Number: 435036712144 </p>
                    <p className='inline_master_head m-0'>ACH ROUTING NO. 051000017,</p>
                    <p className='inline_master_head m-0'>WIRE ROUTING NO: 026009593,</p>
                    <p className='inline_master_head m-0'>A/c with Institution BIC: BOFAUS3N.</p>
                    <p className='inline_master_head m-0'>
                      Being a computer generated document, signature is not required
                    </p>
                  </div>
                </div>
              </div>
              <div className=' mt-10 p-10'>
                <h3>Total Certificates: {allCertificateCount}</h3>
                {Object.entries(groupedData).map(([scheme, entries]) => (
                  <div key={scheme} style={{marginBottom: '20px'}}>
                    <h2 style={{fontSize: '20px'}}>
                      {scheme} (Count: {entries.length})
                    </h2>
                    <table
                      className='table-bordered'
                      style={{width: '100%', borderCollapse: 'collapse', fontSize: '15px'}}
                    >
                      <thead>
                        <tr>
                          <th>S. No.</th>
                          <th>Status</th>
                          <th>Certificate ID</th>
                          <th>Issue Date</th>
                          <th>Expiry Date</th>
                          <th>Scheme</th>
                          <th>Organisation</th>
                          <th>Country Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entries.map((entry, index) => (
                          <tr key={entry.id}>
                            <td>{index + 1}</td>
                            <td>{entry.status}</td>
                            <td>{entry.certificateId}</td>
                            <td>{new Date(entry.issue_date).toLocaleDateString()}</td>
                            <td>{new Date(entry.expiry_date).toLocaleDateString()}</td>
                            <td>{entry.scheme_data.short_name}</td>
                            <td>{entry.organisation || 'N/A'}</td>
                            <td>{entry.country_name || 'N/A'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setShowPreview(false)}>
              Close
            </Button>
            <Button variant='primary' onClick={handleSaveAndSubmit}>
              Save & Submit
            </Button>

            <Button variant='info' onClick={generatePDF}>
              Download as PDF
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default CABCertiInvoice
