import React, { useEffect, useState, ChangeEvent } from "react";
import { Modal } from "react-bootstrap";
import { KTIcon } from "../../../../../_metronic/helpers";
import axios from "axios";
import Select from 'react-select';


import ReactDatePicker from 'react-datepicker';




const AssessmentPlannerDetailTab = ({ tabChangeValue }) => {


    return (

            <div className='card-body p-9'>
                <div className='row gy-10 gx-xl-10'>
                    {/* <div className='card-title m-0 d-flex w-100 justify-content-end'>

                        <button type='button' className='btn btn-primary float-right' onClick={() => setAddLocationModal(true)}>
                            <KTIcon iconName='plus' className='fs-2' />
                            Add Location
                        </button>
                    </div> */}
                    <div className='col-xl-12'>
                        <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
                            <div className="container">
                                <div className="row p-5">
                                    <div className="mb-5 col-6">
                                        <label className="required form-label">Remark</label>
                                        <input
                                            type="number" className="form-control form-control-solid" placeholder="--"
                                            value={tabChangeValue?.remark}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-5 col-6">
                                        <label className="required form-label"> Assessment Duration</label>
                                        <input
                                            type="number" className="form-control form-control-solid" placeholder="--"
                                            value={tabChangeValue?.assessment_duration}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-5 col-6">
                                        <label className="required form-label">In case of witness assessment,Please write IAF Codes for QMS,EMS and OHS, Sub Categories for FSMS , technical areas for MDQMS </label>
                                        <input
                                            type="number" className="form-control form-control-solid" placeholder="--"
                                            // value={tabChangeValue?.assessment_duration}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-5 col-6">
                                        <label className="required form-label">  MAJOR NC CAP DATE</label>
                                        <ReactDatePicker
                                            className='react_date_picker form-control form-control-solid '
                                            selected={tabChangeValue?.major_nc_cab_start_date}
                                            placeholder='--'
                                            disabled
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </div>
                                    <div className="mb-5 col-6">
                                        <label className="required form-label">  MAJOR NC CLOSURE DATE</label>
                                        <ReactDatePicker
                                            className='react_date_picker form-control form-control-solid '
                                            selected={tabChangeValue?.major_nc_cab_closer_date}
                                            placeholder='--'
                                            disabled
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </div>
                                    <div className="mb-5 col-6">
                                        <label className="required form-label"> MINOR NC CAP DATE</label>
                                        <ReactDatePicker
                                            className='react_date_picker form-control form-control-solid '
                                            selected={tabChangeValue?.minor_nc_cab_start_date}
                                            placeholder='--'
                                            disabled
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </div>
                                    <div className="mb-5 col-6">
                                        <label className="required form-label">   MINOR NC CLOSURE DATE</label>
                                        <ReactDatePicker
                                            className='react_date_picker form-control form-control-solid '
                                            selected={tabChangeValue?.minor_nc_cab_end_date}
                                            placeholder='--'
                                            disabled
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </div>
                                    <div className="mb-5 col-6">
                                        <label className="required form-label"> In case the IAF Code or cateogry or technical area is not written in  witness report, please write the scope</label>
                                        <input
                                            type="number" className="form-control form-control-solid" placeholder="--"
                                            // value={tabChangeValue?.assessment_duration}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>




                            {tabChangeValue?.assessor_data?.length ? 
                                <div className="container">
                                    <table className="p-5\table align-middle table-row-dashed fs-6 gy-5 location_table_show" id="kt_table_users">
                                        <thead>
                                            <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                                <th >S. No. </th>
                                                <th className="min-w-125px">Location</th>
                                                <th className="min-w-125px">Assessor Name</th>

                                                <th className="min-w-125px">COI CAB</th>
                                                <th className="min-w-125px">COI ASSESSOR</th>
                                                <th className="min-w-130px"> JOB Order Status</th>
                                                <th className="min-w-130px"> JOB Order Number</th>




                                            </tr>

                                        </thead>
                                        <tbody className="text-gray-600 fw-semibold">
                                            {tabChangeValue?.assessor_data?.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item?.location ? item?.location : "--"}</td>
                                                        <td>{`${item?.assessor?.first_name} ${item?.assessor?.last_name
                                                            }`}</td>
                                                      {item?.coi_cab === true ?

                                                            <td>
                                                                <div className="badge badge-light fw-bolder">
                                                                    <div className="badge badge-light-success fw-bold">
                                                                        Approved
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            :
                                                            <td>

                                                                <div className="badge badge-light fw-bolder">
                                                                    <div className="badge badge-light-danger fw-bold">
                                                                        Pending...
                                                                    </div>

                                                                </div>
                                                            </td>

                                                        }
                                                        {item?.coi_assessor === true ?

                                                            <td>
                                                                <div className="badge badge-light fw-bolder">
                                                                    <div className="badge badge-light-success fw-bold">
                                                                        Approved
                                                                    </div>

                                                                </div>
                                                            </td>


                                                            :
                                                            <td>

                                                                <div className="badge badge-light fw-bolder">
                                                                    <div className="badge badge-light-danger fw-bold">
                                                                        Pending...
                                                                    </div>

                                                                </div>
                                                            </td>

                                                        }



                                                        {item?.assessor_job_order?.[0]?.is_accept === true ?

                                                            <td>
                                                                <div className="badge badge-light fw-bolder">
                                                                    <div className="badge badge-light-success fw-bold">
                                                                        Approved
                                                                    </div>

                                                                </div>
                                                            </td>


                                                            :
                                                            <td>

                                                                <div className="badge badge-light fw-bolder">
                                                                    <div className="badge badge-light-danger fw-bold">
                                                                        Pending...
                                                                    </div>

                                                                </div>
                                                            </td>

                                                        }


                                                        {
                                                            item?.assessor_job_order?.length ?

                                                                <td>
                                                                    <div className="badge badge-light fw-bolder">
                                                                        <div className="badge badge-light-success fw-bold">
                                                                            {item?.assessor_job_order?.[0]?.job_order_number}
                                                                        </div>

                                                                    </div>
                                                                </td>


                                                                :
                                                                <td>

                                                                    <div className="badge badge-light fw-bolder">
                                                                        <div className="badge badge-light-danger fw-bold">
                                                                            Pending...
                                                                        </div>

                                                                    </div>
                                                                </td>

                                                        }
                                                    </tr>

                                                )
                                            })}
                                        </tbody>

                                    </table>
                                </div>

                             : null}


                        </div>
                    </div>
                </div>
            </div >



    );
};
export default AssessmentPlannerDetailTab;