import axios from 'axios'
import {AnyMxRecord} from 'dns'
import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {toast} from 'react-toastify'
import NumberTOWord from './NumberTOWord'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
import {Button, Modal} from 'react-bootstrap'
import UAFLoader from '../../loader/UafLoader'

const CabInitialFee = ({selectedOption}) => {
  const BASE_URL = process.env.REACT_APP_API_URL
  interface User {
    id: number
    company: string
  }
  const [cabUser, setCabUser] = useState<any>()
  const [pdfInvoice, setPdfInvoice] = useState<File | null>(null)
  const [cabId, setCabID] = useState<any>()
  const [invoiceNumber, setInvoiceNumber] = useState<any>()
  const [invoiceDate, setInvoiceDate] = useState(() => new Date().toISOString().split('T')[0])
  const [value, setValue] = useState<User[]>([])
  const [showPreview, setShowPreview] = useState(false)
  const [loader, setLoader] = useState<boolean>(false)
  const [userDetails, setUserDetails] = useState<any>()
  const getUserData = () => {
    axios
        .get(`${BASE_URL}/account/patch/user/${cabUser?.value}/`)
        .then((response) => {
            const result = response.data;
            setUserDetails(result);
        })
        .catch((error) => {
            console.error('API Error:', error)
        })
}
useEffect(()=>{
  getUserData()
},[cabUser?.value])
  console.log(cabUser, 'cabUser')
  useEffect(() => {
    axios
      .get(`${BASE_URL}/account/approved/cab/user/`)
      .then((response) => {
        const data = response.data?.map((item) => ({
          label: `${item?.company}`,
          value: item?.id,
        }))
        setValue(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const [table_data, setRows] = useState([
    {id: 1, details: ' ', fee: 0, mandays:0,mandayRate: 0, totalAmount: 0},
  ])
  const [subTotal, setSubTotal] = useState<any>(0)
  const [grandTotal, setGrandTotal] = useState<any>(0)
  const [netPayable, setNetPayable] = useState<any>(0)
  //   const [summary, setSummary] = useState({
  //     subtotal: 0,
  //     grandTotal: 0,
  //     netPayable: 0,
  //   })
  console.log(table_data, 'SHOW ROW')
  const [tax, setTax] = useState<any>(0)
  const addRow = () => {
    const newId = table_data.length + 1
    const newRow = {id: newId, details: '', fee: 0,mandays:0, mandayRate: 0, totalAmount: 0}
    setRows([...table_data, newRow])
  }

  const calculateSubtotal = () => {
    return table_data.reduce((sum, row) => sum + row.totalAmount, 0)
  }
  const calculateGrandTotal = () => {
    return calculateSubtotal() + (parseFloat(tax) || 0) // Ensure tax is a valid number
  }

  const handleInputChange = (index, field, value) => {
    const updatedRows = table_data.map((row, i) => {
      if (i === index) {
        // Update the row based on the field being edited
        const updatedRow = {
          ...row,
          [field]:
            field === 'fee' || field === 'mandayRate' || field === 'mandays'
              ? parseFloat(value.replace(/^0+/, '')) // Remove leading zeros for numbers
              : value, // For details, use the value as-is
        }

        // Automatically calculate the total amount if fee or mandayRate is updated
        if (field === 'fee' || field === 'mandayRate' || field === 'mandays') {
          updatedRow.totalAmount = updatedRow.fee + (updatedRow.mandayRate * updatedRow.mandays)
        }

        return updatedRow
      }
      return row
    })

    setRows(updatedRows)
  }
  useEffect(() => {
    const newSubtotal = calculateSubtotal()
    const newGrandTotal = newSubtotal + (parseFloat(tax) || 0)
    setSubTotal(newSubtotal)
    setGrandTotal(newGrandTotal)
    setNetPayable(newGrandTotal)
  }, [table_data, tax]) // Trigger recalculation whenever rows or tax change

  // const handleSubmit = () => {
  //   const dataToSubmit = {
  //     rows,
  //     tax: parseFloat(tax) || 0,
  //     cab:cabId,
  //     invoice_date:invoiceDate,
  //     invoice_number:invoiceNumber,
  //     sub_total: subTotal,
  //     grand_total: grandTotal,
  //     net_payable: netPayable,
  //   }
  //   console.log('Data to submit:', dataToSubmit)
  //   // Add your submission logic here, e.g., send to an API
  // }
  const handleSubmit = async () => {
    if (!invoiceDate) {
      toast.error('Invoice date is required.')
      return
    }
    if (!invoiceNumber) {
      toast.error('Invoice number is required.')
      return
    }

    if (subTotal <= 0) {
      toast.error('Sub-total must be greater than zero.')
      return
    }
    if (grandTotal <= 0) {
      toast.error('Grand total must be greater than zero.')
      return
    }
    if (netPayable <= 0) {
      toast.error('Net payable amount must be greater than zero.')
      return
    }

    if (!table_data || table_data.length === 0) {
      toast.error('Details and respective cannot be empty.')
      return
    }
    // try {
    //   const jsonData = JSON.stringify(table_data)

    //   const response = await axios.post(`${BASE_URL}/payment/application/cab/invoice/ `, {
    //     tax: parseFloat(tax) || 0,
    //     cab: cabId,
    //     invoice_date: invoiceDate,
    //     invoice_number: invoiceNumber,
    //     sub_total: subTotal,
    //     grand_total: grandTotal,
    //     net_payable: netPayable,
    //     table_data: jsonData,
    //   })
    //   if (response.status === 200 || response.status === 201) {
    //     toast.success('Application fee generated successfully.')
    //     setInvoiceDate('')
    //     setInvoiceNumber('')
    //     setSubTotal(0)
    //     setGrandTotal(0)
    //     setNetPayable(0)
    //     setTax(0)
    //     setRows([{id: 1, details: ' ', fee: 0, mandayRate: 0, totalAmount: 0}])
    //   } else {
    //     toast.error('Please try again.')
    //   }
    // } catch (error) {
    //   console.log(error)
    // }
    try {
      setLoader(true)
      const formData = new FormData();
    
      // Append other fields
      formData.append('tax', tax);
      formData.append('cab', cabId);
      formData.append('invoice_date', invoiceDate);
      formData.append('invoice_number', invoiceNumber);
      formData.append('sub_total', subTotal);
      formData.append('grand_total', grandTotal);
      formData.append('net_payable', netPayable);
      formData.append('table_data', JSON.stringify(table_data));
    
      // Append the PDF file
      if (pdfInvoice instanceof File) {
        formData.append('pdf_invoice', pdfInvoice);
      } else {
        console.error('pdfInvoice is not a valid File object');
      }
    
      // Make the POST request
      const response = await axios.post(`${BASE_URL}/payment/application/cab/invoice/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
        },
      });
    
      if (response.status === 200 || response.status === 201) {
        setLoader(false)
        toast.success('Invoice sent successfully.');
        setInvoiceDate('');
        setInvoiceNumber('');
        setSubTotal(0);
        setGrandTotal(0);
        setNetPayable(0);
        setTax(0);
        setRows([{id: 1, details: ' ', fee: 0,mandays:0, mandayRate: 0, totalAmount: 0}])
      } else {
        setLoader(false)
        toast.error('Please try again.');
      }
    } catch (error) {
      setLoader(false)
      console.log(error);
      toast.error('An error occurred while generating the annual fee.');
    }
  }
  const CreatePDF = async (): Promise<File> => {
    return new Promise((resolve, reject) => {
      const input = document.getElementById('pdfContent')
      if (input) {
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'mm', 'a4')
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = pdf.internal.pageSize.getHeight()

            const imgProps = pdf.getImageProperties(imgData)
            const imgWidth = pdfWidth - 20
            const imgHeight = (imgProps.height * imgWidth) / imgProps.width

            pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight)
            const pdfBlob = pdf.output('blob')
            const file = new File([pdfBlob], `application_fee_${cabUser?.label}.pdf`, {type: 'application/pdf'})
            resolve(file) // Return the file
          })
          .catch((error) => {
            console.error('Error creating PDF:', error)
            reject(error)
          })
      } else {
        console.error("Element with id 'pdfContent' not found.")
        reject(new Error("Element with id 'pdfContent' not found."))
      }
    })
  }
  const generatePDF = () => {
    const input = document.getElementById('pdfContent')
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'mm', 'a4')
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()

        const imgProps = pdf.getImageProperties(imgData)
        const imgWidth = pdfWidth - 20
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width

        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight)
        pdf.save(`application_fee_${cabUser?.label}.pdf`)
        })
    } else {
      console.error("Element with id 'pdfContent' not found.")
    }
  }
  const handleSaveAndSubmit = async () => {
    try {
      // Await the PDF generation to get the resolved File object
      const pdfFile = await CreatePDF()

      setPdfInvoice(pdfFile)
    } catch (error) {
      console.error('Error during Save & Submit:', error)
    }
  }
    useEffect(() => {
      if (pdfInvoice) {
        handleSubmit()
      }
    }, [pdfInvoice]);
  return (
    <>
    {loader ? <UAFLoader /> : null}
    <div className='container'>
      <div className='row'>
        <div className='mb-5 col-6'>
          <label className='required form-label'>CAB User</label>
          <Select
            id='select_user'
            name='User'
            options={value}
            className='react-select-styled'
            classNamePrefix='react-select'
            value={cabUser}
            onChange={(e) => {
              setCabUser(e)
              setCabID(e?.value)
            }}
          />
        </div>
        <div className='mb-5 col-6 px-10 py-5 '>
          <h4 className='fw-bolder mb-2'>
            CAB: <span className='inline_master_head'>{cabUser?.label}</span>
          </h4>
        </div>
      </div>
      {cabId ? (
        <div className='py-3'>
          {/* end::Table container */}
          <div className=''>
            <div className='' style={{border: '1px solid #333'}}>
              <div className='d-flex'>
                <div className=''>
                  <img
                    alt='Logo'
                    src={'/media/logos/uaf_logo.png'}
                    className='p-1  app-sidebar-logo-default theme-light-show'
                    style={{
                      width: '250px',
                      height: '120px',
                      border: '1px solid #333',
                    }}
                  />
                </div>
                <div className='text-center' style={{border: '1px solid #333'}}>
                  <h2 style={{padding: '25px 10px 0px 10px'}}>
                    UNITED ACCREDITATION FOUNDATION INC, 1060 LASKIN ROAD, SUITE 12B/13B, VIRGINIA
                    BEACH, VA 23451
                  </h2>
                </div>
              </div>
              <div className='row' style={{padding: '0px 10px 0px 10px', fontSize: '18px'}}>
                <div className='col-3' style={{border: '1px solid #333'}}>
                  <div className='p-2'>Invoice Number </div>
                </div>
                <div className='col-3' style={{border: '1px solid #333'}}>
                  <div className='p-2'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='--'
                      value={invoiceNumber}
                      onChange={(e) => setInvoiceNumber(e?.target?.value)}
                    />
                  </div>
                </div>
                <div className='col-3' style={{border: '1px solid #333'}}>
                  <div className='p-2'>Dated</div>
                </div>
                <div className='col-3' style={{border: '1px solid #333'}}>
                  <div className='p-2'>
                    {' '}
                    <input
                      type='Date'
                      className='form-control form-control-solid'
                      placeholder='--'
                      value={invoiceDate}
                      onChange={(e) => setInvoiceDate(e?.target?.value)}
                    />
                  </div>
                </div>
              </div>
              <div style={{padding: '0px 10px 0px 10px', border: '1px solid #333'}}>
                <div className='row text-center' style={{fontSize: '18px'}}>
                  <h2 style={{padding: '20px 0px 20px 0px'}}>Application Fee</h2>
                </div>
              </div>
              <div className='table-responsive'>
                <table
                  className='table-bordered table '
                  style={{border: '1px solid #333', width: ' -webkit-fill-available'}}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Details</th>
                      <th>Fee</th>
                      <th>Mandays</th>
                      <th>Manday Rate ($)</th>
                      <th>Total Amount USD($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {table_data.map((row, index) => (
                      <tr key={index}>
                        <td>{row.id}</td>
                        <td>
                          <input
                            type='text'
                            placeholder='Details'
                            value={row.details}
                            onChange={(e) => handleInputChange(index, 'details', e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            placeholder='Fee'
                            value={row.fee}
                            onChange={(e) => handleInputChange(index, 'fee', e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            placeholder='Mandays'
                            value={row.mandays}
                            onChange={(e) => handleInputChange(index, 'mandays', e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            placeholder='Manday Rate'
                            value={row.mandayRate}
                            onChange={(e) => handleInputChange(index, 'mandayRate', e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            placeholder='Total'
                            value={row.totalAmount}
                            readOnly
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={5} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        Sub Total Pre Taxes:
                      </td>
                      <td>
                        <strong>{subTotal.toFixed(2)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5} style={{textAlign: 'right'}}>
                        Taxes, if any:
                      </td>
                      <td>
                        <input
                          type='number'
                          placeholder='Enter Taxes'
                          value={tax}
                          onChange={(e) => setTax(e.target.value.replace(/^0+/, ''))}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        Grand Total
                      </td>
                      <td>
                        <strong>{grandTotal.toFixed(2)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        Net Payable on account of this Invoice
                      </td>
                      <td>
                        <strong>{netPayable.toFixed(2)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        Net Amount of Invoice USD ($):
                      </td>
                      <td>
                        <strong>({NumberTOWord(Math.round(netPayable))})</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button onClick={addRow}>Add More</button>
            </div>
          </div>

          <div className='text-gray-800  mb-1 mt-2'>
          <button
                id='create_assessment_inovice'
                className='btn btn-warning'
                onClick={() => setShowPreview(true)} /*  */
              >
                Preview
              </button>
          </div>
        </div>
      ) : null}
       <Modal show={showPreview} onHide={() => setShowPreview(false)} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Preview Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id='pdfContent'>
            <div className=''>
              <div className='' style={{border: '1px solid #333'}}>
                <div className='d-flex'>
                  <div className=''>
                    <img
                      alt='Logo'
                      src={'/media/logos/uaf_logo.png'}
                      className='p-1  app-sidebar-logo-default theme-light-show'
                      style={{
                        width: '250px',
                        height: '120px',
                        border: '1px solid #333',
                      }}
                    />
                  </div>
                  <div className='text-center' style={{border: '1px solid #333'}}>
                    <h2 style={{padding: '35px 120px 0px 121px'}}>
                      UNITED ACCREDITATION FOUNDATION INC, <br />1060 LASKIN ROAD,
                       SUITE 12B/13B, VIRGINIA BEACH, VA 23451
                    </h2>
                  </div>
                </div>
                <div className='row' style={{padding: '0px 10px 0px 10px', fontSize: '18px'}}>
                  <div className='col-3' style={{border: '1px solid #333'}}>
                    <div className='p-2'>Invoice Number </div>
                  </div>
                  <div className='col-3' style={{border: '1px solid #333'}}>
                    <div className='p-2'>
                     {invoiceNumber}
                        
                    </div>
                  </div>
                  <div className='col-3' style={{border: '1px solid #333'}}>
                    <div className='p-2'>Dated</div>
                  </div>
                  <div className='col-3' style={{border: '1px solid #333'}}>
                    <div className='p-2'>
                      {' '}
                      {invoiceDate}
                        
                    </div>
                  </div>
                </div>
                <div style={{padding: '0px 10px 0px 10px', border: '1px solid #333'}}>
                  <div className='row text-center' >
                    <div style={{padding: '20px 0px 20px 0px'}}>
                    <h2 style={{fontSize: '22px'}}>{userDetails?.company}</h2>
                    <span style={{fontSize:"16px"}}>
                    {userDetails?.address}
                    </span>
                    </div>
                    
                  </div>
                </div>
                <div style={{padding: '0px 10px 0px 10px', border: '1px solid #333'}}>
                  <div className='row text-center' style={{fontSize: '18px'}}>
                    <h2 style={{padding: '20px 0px 20px 0px'}}>Application Fee</h2>
                  </div>
                </div>
                <div className='table-responsive m-0'>
                <table
                  className='table-bordered table  m-0'
                  style={{border: '1px solid #333', width: ' -webkit-fill-available'}}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Details</th>
                      <th>Fee</th>
                      <th>Mandays</th>
                      <th>Manday Rate ($)</th>
                      <th>Total Amount USD($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {table_data.map((row, index) => (
                      <tr key={index}>
                        <td>{row.id}</td>
                        <td>
                         {row.details}
                           </td>
                        <td>
                          {row.fee}
                        </td>
                        <td>{row.mandays}
                             </td>
                        <td>{row.mandayRate}
                             </td>
                        <td>{row.totalAmount}
                            </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={5} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        Sub Total Pre Taxes:
                      </td>
                      <td>
                        <strong>{subTotal.toFixed(2)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5} style={{textAlign: 'right'}}>
                        Taxes, if any:
                      </td>
                      <td>
                        {tax}</td>
                    </tr>
                    <tr>
                      <td colSpan={5} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        Grand Total
                      </td>
                      <td>
                        <strong>{grandTotal.toFixed(2)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        Net Payable on account of this Invoice
                      </td>
                      <td>
                        <strong>{netPayable.toFixed(2)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5} style={{textAlign: 'right', fontWeight: 'bold'}}>
                        Net Amount of Invoice USD ($):
                      </td>
                      <td>
                        <strong>({NumberTOWord(Math.round(netPayable))})</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
                <div
                    className='card-title m-0 justify-content-between align-items-center w-100 px-4 py-5'
                    style={{border: '1px solid #333'}}
                  >
                    <h4 className='fw-bolder m-0 pb-2'>International Transfers can be made to the:</h4>
                    <p className='inline_master_head m-0'>Account Name: United Accreditation Foundation Inc. </p>
                    <p className='inline_master_head m-0'>Bank of America, N.A., New York, NY , United States, 901796 , </p>
                    <p className='inline_master_head m-0'>Account Number: 435036712144 </p>
                    <p className='inline_master_head m-0'>ACH ROUTING NO. 051000017,</p>
                    <p className='inline_master_head m-0'>WIRE ROUTING NO: 026009593,</p>
                    <p className='inline_master_head m-0'>A/c with Institution BIC: BOFAUS3N.</p>
                    <p className='inline_master_head m-0'>Being a computer generated document, signature is not required</p>
                  </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowPreview(false)}>
            Close
          </Button>
           <Button variant='primary' onClick={handleSaveAndSubmit}>
            Save & Submit
          </Button>

          <Button variant='info' onClick={generatePDF}>
            Download as PDF
          </Button>
         </Modal.Footer>
      </Modal>
    </div>
    </>
  )
}

export default CabInitialFee
