import React, {useEffect, useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {KTIcon} from '../../../../../../_metronic/helpers'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'

import Select from 'react-select'
import ReactDatePicker from 'react-datepicker'
import NewAssementModal from '../../../../apps/create-assessment/create-assessment-components/modals/NewAssementModal'

type Props = {
  show: boolean
  handleClose: () => void
  getAssessmentPlannerData: () => void
  showAssessentClicked: any
}
const API_URL = process.env.REACT_APP_API_URL

const EditAssessmentModal = ({
  show,
  handleClose,
  showAssessentClicked,
  getAssessmentPlannerData,
}: Props) => {
  console.log(showAssessentClicked, 'showAssessentClicked')
  const BASE_URL = process.env.REACT_APP_API_URL
  interface User {
    value: string
    label: string
  }

  const [assessmentPlannerDetails, setAssessmentPlannerDetails] = useState({
    cab: '',
    scheme: '',
    assessment_type: '',
    assessment_number: '',
    assessment_stage: '',
    assessment_stage_name: '',
    // Initialize multi_date as a stringified array or empty array
    multi_date: '[]', // Default is a stringified empty array
  })

  console.log(assessmentPlannerDetails, 'assessmentPlannerDetails gk')

  const [cabUser, setCabUser] = useState<User[]>([])
  console.log(cabUser, 'cabUser')

  const [schemeData, setSchemeData] = useState<any>([])
  const [cabUserData, setCabUserData] = useState<any>([])
  const [assessmentSchemeData, setAssessmentSchemeData] = useState<any>()
  console.log(assessmentSchemeData, 'assessmentSchemeData')


  console.log(cabUser, 'cabUser')
  const [startDateAssessment, setStartDate] = useState(new Date())

  // const [showNewAssessmentModal, setShowNewAssessmentModal] = useState<boolean>(false)
  const [assessmentType, setAssessmentType] = useState<string>('')
  const [assessmentList, setAssessmentList] = useState<any>([])
  const [assessmentStageList, setAssessmentStageList] = useState<any>([])
  console.log(assessmentList, 'assessmentList')
  const [dateRange, setDateRange] = useState([null])

  useEffect(() => {
    if (showAssessentClicked) {
        console.log(showAssessentClicked.multi_date, "showAssessentClicked.multi_date");
      
        const sanitizedDates = showAssessentClicked.multi_date
          ?.replace(/'/g, '"') || '[]'; 
        let parsedDates = [];
      
        try {
          parsedDates = JSON.parse(sanitizedDates).map((date) => {
            const dateObj = new Date(date);
            const formattedDate = [
              String(dateObj.getMonth() + 1).padStart(2, '0'),  // Month (MM)
              String(dateObj.getDate()).padStart(2, '0'),      // Day (DD)
              dateObj.getFullYear()                            // Year (YYYY)
            ].join('-');  // Join as MM-DD-YYYY
            return formattedDate;
          });
        } catch (error) {
          console.error('Failed to parse multi_date:', error);
        }
      
        console.log(parsedDates, "this is my parsed dates");
      
        setAssessmentPlannerDetails({
          ...assessmentPlannerDetails,
          cab: showAssessentClicked?.cab,
          assessment_type: showAssessentClicked?.assessment_type,
          assessment_number: showAssessentClicked?.assessment_number,
          assessment_stage_name: showAssessentClicked?.assessment_stage_name,
          multi_date: parsedDates.join(', '),
        });
      }
      
  }, [showAssessentClicked]);
  
  

  useEffect(() => {
    if (showAssessentClicked?.schemeData?.length) {
      const assessmentData = showAssessentClicked?.schemeData?.map((item) => ({
        scheme: item?.scheme,
        id: item?.id,
        assessment_duration: item?.assessment_duration,
        remark: item?.remark,

        major_nc_cab_start_date: item?.major_nc_cab_start_date,
        major_nc_cab_closer_date: item?.major_nc_cab_closer_date,
        minor_nc_cab_start_date: item?.minor_nc_cab_start_date,
        minor_nc_cab_end_date: item?.minor_nc_cab_end_date,
      }))
      setAssessmentSchemeData(assessmentData)
    }
  }, [showAssessentClicked])

  const handleEditAssessorScheme = async (item) => {
    const data = {
      major_nc_cab_start_date: item?.major_nc_cab_start_date,
      major_nc_cab_closer_date: item?.major_nc_cab_closer_date,
      minor_nc_cab_start_date: item?.minor_nc_cab_start_date,
      minor_nc_cab_end_date: item?.minor_nc_cab_end_date,
      remark: item?.remark,
      assessment_duration: item?.assessment_duration,
    }
    try {
      const response = await axios.put(
        `${BASE_URL}/cab/assessor/planner/scheme/${item?.id}/update/`,
        data
      )

      //  handleSendAgreement(response?.data)

      handleClose()
      getAssessmentPlannerData()
    } catch (error) {
      console.log(error)
    }
  }
  console.log(assessmentPlannerDetails, 'assessmentPlannerDetails')

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (assessmentPlannerDetails?.multi_date) {
      console.log(assessmentPlannerDetails.multi_date, "assessmentPlannerDetails.multi_date");
  
      // Sanitize and format the dates correctly for the input
      try {
        const sanitizedDates = assessmentPlannerDetails.multi_date
          .split(',')  // Split the string into an array
          .map((date) => date.trim()); // Trim each date
  
        // Join the dates in the desired format (MM-DD-YYYY, MM-DD-YYYY)
        setInputValue(sanitizedDates.join(', '));
      } catch (error) {
        console.error('Invalid multi_date format:', error);
        setInputValue(''); // Reset if there's an error
      }
    }
  }, [assessmentPlannerDetails]);
  console.log(inputValue,"inputvalue")
  
  
 

  const [error, setError] = useState('')

  const validateDates = (input) => {
    try {
      const dateStrings = input.split(',').map((date) => date.trim())
      const formattedDates = dateStrings.map((dateString) => {
        const isValidFormat = /^\d{2}-\d{2}-\d{4}$/.test(dateString)
        if (!isValidFormat) {
          throw new Error(`Invalid date format: ${dateString}. Use MM-DD-YYYY.`)
        }
  
        const [month, day, year] = dateString.split('-').map(Number)
        if (month < 1 || month > 12 || day < 1 || day > 31 || year < 1900 || year > 2100) {
          throw new Error(`Invalid date: ${dateString}`)
        }
  
        return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`
      })
  
      setError('') // Clear error if validation passes
      return formattedDates
    } catch (error:any) {
      setError(error.message) // Show error message
      return null
    }
  }
  

  const handleEditAssessor = async () => {
    const validatedDates = validateDates(inputValue);
    if (!validatedDates) return; // Exit if validation fails
  
    const data = {
      multi_date: JSON.stringify(validatedDates), // Ensure it's a valid JSON string
      assessment_number: assessmentPlannerDetails.assessment_number,
    };
  
    try {
      const response = await axios.put(
        `${BASE_URL}/cab/assessor/planner/${showAssessentClicked?.id}/update/`,
        data
      );
      toast.success('Assessment updated successfully!');
      handleClose();
      getAssessmentPlannerData();
  
      // Handling other schemes
      assessmentSchemeData.forEach((item) => {
        if (
          item?.major_nc_cab_closer_date ||
          item?.major_nc_cab_start_date ||
          item?.minor_nc_cab_end_date ||
          item?.minor_nc_cab_start_date ||
          item?.remark ||
          item?.assessment_duration
        ) {
          handleEditAssessorScheme(item);
        }
      });
    } catch (error) {
      toast.error('Failed to update assessment.');
      console.error(error);
    }
  };
  
  

  // get cab user
  useEffect(() => {
    axios
      .get(`${BASE_URL}/account/approved/cab/user/`)

      .then((response) => {
        const data = response.data?.map((item) => ({
          label: `${item?.company} `,
          value: item?.id,
        }))
        console.log(response.data)
        setCabUserData(response.data)

        setCabUser(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const getAssessmentStage = () => {
    axios
      .get(`${BASE_URL}/cab/assessment/stage/list/?cab=${showAssessentClicked?.cab}`)
      .then((response) => {
        console.log(response.data, 'kjdsdsjdksjdkjk')
        const data = response.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }))
        // const sorted = data?.sort(
        //   (a, b) => desiredSequence.indexOf(a.label) - desiredSequence.indexOf(b.label)
        // )
        setAssessmentStageList(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // get assessment list
  const getAssessmentList = () => {
    axios
      .get(`${BASE_URL}/cab/assessment/list/`)
      .then((response) => {
        const data = response.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }))
        setAssessmentList(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    getAssessmentList()
    getAssessmentStage()
  }, [])

  const handleCabUserChange = (e) => {
    setAssessmentPlannerDetails({...assessmentPlannerDetails, cab: e.value})
  }

  const handleAssessmentTypeChange = (e) => {
    setAssessmentPlannerDetails({...assessmentPlannerDetails, assessment_type: e.value})
  }
  const handleAssessmentNumberChange = (e) => {
    setAssessmentPlannerDetails({...assessmentPlannerDetails, assessment_number: e?.target?.value})
  }


  const handleSchemeMajorNCCAPStartDate = (schemeItem, e) => {
    const updatedSchemeData = assessmentSchemeData?.map((item) => {
      if (item.scheme === schemeItem.scheme) {
        return {...item, major_nc_cab_start_date: e}
      }
      return item
    })
    setAssessmentSchemeData(updatedSchemeData)
  }
  const handleSchemeRemark = (schemeItem, e) => {
    const updatedSchemeData = assessmentSchemeData?.map((item) => {
      if (item.scheme === schemeItem.scheme) {
        return {...item, remark: e?.target?.value}
      }
      return item
    })
    console.log(updatedSchemeData, 'updatedSchemeData')

    setAssessmentSchemeData(updatedSchemeData)
  }
  const handleSchemeAssetDuration = (schemeItem, e) => {
    const updatedSchemeData = assessmentSchemeData?.map((item) => {
      if (item.scheme === schemeItem.scheme) {
        return {...item, duration: e?.target?.value}
      }
      return item
    })
    console.log(updatedSchemeData, 'updatedSchemeData')

    setAssessmentSchemeData(updatedSchemeData)
  }
  const handleSchemeMajorNCClosureDate = (schemeItem, e) => {
    const updatedSchemeData = assessmentSchemeData?.map((item) => {
      console.log(item.scheme === schemeItem.scheme, 'kkkekekkekek')
      if (item.scheme === schemeItem.scheme) {
        return {...item, major_nc_cab_closer_date: e}
      }
      return item
    })
    setAssessmentSchemeData(updatedSchemeData)
  }
  const handleSchemeMinorNCCAPstartDate = (schemeItem, e) => {
    const updatedSchemeData = assessmentSchemeData?.map((item) => {
      if (item.scheme === schemeItem.scheme) {
        return {...item, minor_nc_cab_start_date: e}
      }
      return item
    })
    setAssessmentSchemeData(updatedSchemeData)
  }
  const handleSchemeMinorNCClosureDate = (schemeItem, e) => {
    const updatedSchemeData = assessmentSchemeData?.map((item) => {
      if (item.scheme === schemeItem.scheme) {
        return {...item, minor_nc_cab_end_date: e}
      }
      return item
    })
    setAssessmentSchemeData(updatedSchemeData)
  }

  useEffect(() => {
    if (assessmentPlannerDetails?.cab) {
      const filterData = cabUserData?.find((item) => item?.id === assessmentPlannerDetails?.cab)
      setSchemeData(filterData?.scheme_names)
    }
  }, [assessmentPlannerDetails?.cab])

  return (
    <>
      <Modal
        id='assessment-fee'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered '
        show={show}
        onHide={handleClose}
        backdrop={true}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Assessment Planner</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='container'>
            <div className='row p-5'>
              <div className='mb-5 col-4'>
                <label className='required form-label'>CAB </label>
                <Select
                  className='react-select-styled react-select-solid '
                  isDisabled
                  name='User'
                  // options={roleInAssessment?.map(item => ({ value: item?.id, label: item?.name }))}
                  // value={roleInAssessment?.length ? { value: createJobDetail?.assessor_role, label: roleInAssessment.find(item => item.id === createJobDetail?.assessor_role)?.name } : null}
                  options={cabUser}
                  classNamePrefix='react-select'
                  value={
                    assessmentPlannerDetails?.cab
                      ? {
                          value: assessmentPlannerDetails?.cab,
                          label: cabUser?.find(
                            (item) => item.value === assessmentPlannerDetails?.cab
                          )?.label,
                        }
                      : null
                  }
                  onChange={(e) => handleCabUserChange(e)}
                />
              </div>

              <div className='mb-5 col-4'>
                <label className='required form-label'>Assessment Type</label>
                <Select
                  className='react-select-styled react-select-solid '
                  isDisabled
                  name='User'
                  options={assessmentList}
                  classNamePrefix='react-select'
                  onChange={(e) => handleAssessmentTypeChange(e)}
                  value={
                    assessmentPlannerDetails?.assessment_type
                      ? {
                          value: assessmentPlannerDetails?.assessment_type,
                          label: assessmentList?.find(
                            (item) => item.value === assessmentPlannerDetails?.assessment_type
                          )?.label,
                        }
                      : null
                  }
                />
              </div>
              <div className='mb-5 col-4'>
                <label className='required form-label'>Assessment Stage</label>
                <Select
                  className='react-select-styled react-select-solid '
                  isDisabled
                  name='User'
                  options={assessmentStageList}
                  classNamePrefix='react-select'
                  onChange={(e) => handleAssessmentTypeChange(e)}
                  value={
                    assessmentPlannerDetails?.assessment_stage
                      ? {
                          value: assessmentPlannerDetails?.assessment_stage,
                          label: assessmentStageList?.find(
                            (item) => item.value === assessmentPlannerDetails?.assessment_stage
                          )?.label,
                        }
                      : null
                  }
                />
              </div>
            </div>
            {showAssessentClicked?.schemeData?.length ? (
              <table className='table scheme_table'>
                <thead>
                  <tr>
                    <th>Scheme Name</th>
                    <th>Assessment Duration</th>
                    <th>Remark</th>
                    {/* <th >Assessor Name</th> */}
                    {/* <th>In case of witness assessment,Please write IAF Codes for QMS,EMS and OHS, Sub Categories for FSMS , technical areas for MDQMS </th>
                                    <th >Major NC CAP Start Date</th>
                                    <th >Major NC Closure Date</th>
                                    <th >Minor NC CAP start Date</th>
                                    <th >Minor NC Closure Date</th>
                                    <th>In case the IAF Code or cateogry or technical area is not written in  witness report, please write the scope</th> */}
                  </tr>
                </thead>
                <tbody>
                  {showAssessentClicked?.schemeData.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td>
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='--'
                            value={item?.scheme_short_name}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='--'
                            value={
                              assessmentSchemeData?.find((i) => i.scheme === item.scheme)
                                ? assessmentSchemeData?.find((i) => i.scheme === item.scheme)
                                    .assessment_duration
                                : ''
                            }
                            onChange={(e) => handleSchemeAssetDuration(item, e)}
                          />
                        </td>
                        <td>
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='--'
                            defaultValue={
                              assessmentSchemeData?.find((i) => i.scheme === item.scheme)
                                ? assessmentSchemeData?.find((i) => i.scheme === item.scheme).remark
                                : ''
                            }
                            onChange={
                              (e) => handleSchemeRemark(item, e)
                              // console.log(e?.target.value)
                            }
                          />
                        </td>
                        {/* <td>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    placeholder="--"
                                                    
                                                    disabled
                                                />
                                            </td> */}

                        {/* <td>
                                                <ReactDatePicker
                                                    className='react_date_picker form-control form-control-solid '
                                                    selected={assessmentSchemeData?.find(i => i.scheme === item.scheme) ? assessmentSchemeData?.find(i => i.scheme === item.scheme).major_nc_cab_start_date : ""}

                                                    
                                                    onChange={(date) => handleSchemeMajorNCCAPStartDate(item, date)
                                                    }
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />

                                            </td>
                                            <td>
                                                <ReactDatePicker
                                                    className='react_date_picker form-control form-control-solid '
                                                    selected={assessmentSchemeData?.find(i => i.scheme === item.scheme) ? assessmentSchemeData?.find(i => i.scheme === item.scheme).major_nc_cab_closer_date : ""}


                                                   
                                                    onChange={(date) => handleSchemeMajorNCClosureDate(item, date)
                                                    }
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </td>
                                            <td>
                                                <ReactDatePicker
                                                    className='react_date_picker form-control form-control-solid '
                                                    selected={assessmentSchemeData?.find(i => i.scheme === item.scheme) ? assessmentSchemeData?.find(i => i.scheme === item.scheme).minor_nc_cab_start_date : ""}

                                                   
                                                    onChange={(date) => handleSchemeMinorNCCAPstartDate(item, date)
                                                    }
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </td>
                                            <td>
                                                <ReactDatePicker
                                                    className='react_date_picker form-control form-control-solid '
                                                    selected={assessmentSchemeData?.find(i => i.scheme === item.scheme) ? assessmentSchemeData?.find(i => i.scheme === item.scheme).minor_nc_cab_end_date : ""}

                                                  
                                                    onChange={(date) => handleSchemeMinorNCClosureDate(item, date)
                                                    }
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    placeholder="--"
                                                  
                                                    disabled
                                                />
                                            </td> */}
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            ) : null}

            <div className='row p-5'>
              <div className='mb-5 col-6'>
                <label className='required form-label'>Assessment Number</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='--'
                  value={assessmentPlannerDetails?.assessment_number}
                  onChange={(e) => handleAssessmentNumberChange(e)}
                />
              </div>
            </div>

            <div className='row p-5'>
              <div className='mb-5 col-6'>
                <label className='form-label'>Assessment Dates(MM-DD-YYYY)</label>
                <br />
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='MM-DD-YYYY, MM-DD-YYYY'
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  disabled={assessmentPlannerDetails?.assessment_type === 'ddaa2f60-572a-4738-886a-bf97a1a3b4f2'}
                />
                {error && <div className='text-danger mt-2'>{error}</div>}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='success' onClick={handleEditAssessor}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default EditAssessmentModal
