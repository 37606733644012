import axios from 'axios'

import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

const VerifiedAssessorJobOrder = ({userId}) => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const userIdObject = JSON.parse(userId)
  const uuid = userIdObject['*']
  const uuidRegex =
    /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  const uuids = uuid.match(uuidRegex)
  const urlId = uuids[0]
  const [jobOrderData, setjobOrderData] = useState<any[]>([])
  const [arcData, setARCData] = useState<any[]>([])
  const [activeTab, setActiveTab] = useState('Assessment')
  const fetchData = async () => {
    try {
      // Determine the endpoint based on the filter
      const endpoint = `${BASE_URL}/cab/ledger/job/order/?assessor=${urlId}`

      // Make the API request
      const response = await axios.get(endpoint)

      // Extract and handle the data
      const result = response?.data?.results 

      setjobOrderData(result)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  const fetchARCData = async () => {
    try {
      // Determine the endpoint based on the filter
      const endpoint = `${BASE_URL}/arc/ledger/job/order/?assessor=${urlId}`

      // Make the API request
      const response = await axios.get(endpoint)

      // Extract and handle the data
      const result = response?.data?.results 

      setARCData(result)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
    fetchARCData()
  }, [])

  const formatMyDate = (date: Date | string | null) => {
    if (!date) return null;
  
    // If the date is a string, parse it to a Date object
    const parsedDate = typeof date === "string" ? new Date(date) : date;
  
    // Ensure the parsed date is valid
    if (isNaN(parsedDate.getTime())) return null;
  
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
  
    return `${month}-${day}-${year}`;
  };
  return (
    <div className='row gy-10 gx-xl-10'>
      <div className='col-xl-12'>
        <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className=''>
            <div className='card-title m-0'>
              <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 p-4'>
                <li className='nav-item cursor-pointer'>
                  <div
                    className={`nav-link ${activeTab === 'Assessment' ? 'active' : ''}`}
                    onClick={() => setActiveTab('Assessment')}
                  >
                    Assessment Job Order
                  </div>
                </li>
                <li className='nav-item cursor-pointer'>
                  <div
                    className={`nav-link ${activeTab === 'ARC' ? 'active' : ''}`}
                    onClick={() => setActiveTab('ARC')}
                  >
                    ARC Job Order
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {activeTab === 'Assessment' && (
            <div className='card-body p-9'>
              <div className='table-responsive'>
                <table
                  className='table-bordered table '
                  style={{border: '1px solid #333', width: ' -webkit-fill-available'}}
                >
                  <thead>
                    <tr>
                      <th>Job Order</th>
                      <th>Assessment No</th>
                      <th>Date</th>
                      <th>Job Order (Link)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobOrderData?.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.job_order_number}</td>
                        <td>{item?.assessment_number}</td>
                        <td>{formatMyDate(item?.create_at)}</td>
                        <td>
                          <a href={item?.pdf_invoice} target='_blank'>
                            Link
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {activeTab === 'ARC' && (
            <div className='card-body p-9'>
              <div className='table-responsive'>
                <table
                  className='table-bordered table '
                  style={{border: '1px solid #333', width: ' -webkit-fill-available'}}
                >
                  <thead>
                    <tr>
                      <th>Job Order</th>
                      <th>ARC No</th>
                      <th>Date</th>
                      <th>Job Order (Link)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arcData?.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.job_order_number}</td>
                        <td>{item?.arc_number}</td>
                        <td>{formatMyDate(item?.create_at)}</td>
                        <td>
                          <a href={item?.invoice_pdf} target='_blank'>
                            Link
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default VerifiedAssessorJobOrder
