import React, {useEffect, useState} from 'react'
import axios from 'axios'


import Select from 'react-select'
import {toast} from 'react-toastify'
import DatePicker from 'react-multi-date-picker'
import {DateObject} from 'react-multi-date-picker'
import gregorian from 'react-date-object/calendars/gregorian'

const API_URL = process.env.REACT_APP_API_URL

interface Scheme {
  cab_id: string
  cab_first_name: string
  cab_last_name: string
  cab_email: string
  scheme_id: string
  scheme_name: string
  scheme_short_name: string
  location: string
  remark: string
  scheme_date: []
  create_at: string
}

interface DataEntry {
  id: string
  scheme_data: Scheme[] | null
  location: string
  remark: string
  create_at: string
  update_at: string
}
type Props = {
  uniqueCab: string | undefined
}

const GlobalAssessmentProgram = ({uniqueCab}: Props) => {
  const [schemeData, setSchemeData] = useState<any[]>([])
  const [selectedScheme, setSelectedScheme] = useState<string>()
  const [schemeName, setSchemeName] = useState<string>()
  const [accreditationdate, setAccreditationdate] = useState<any>()
  const [reaccreditationdate, setreAccreditationdate] = useState<any>()
  const [reaccreditationduedate, setreAccreditationduedate] = useState<any>()
  const [expirydate, setexpirydate] = useState<any>()
  const [plannedDate, setPlannedDate] = useState<any>()

  const [globalData, setGlobalData] = useState<any>()

  const [allglobalData, setAllglobalData] = useState<any[]>([])
  const [allSchemedata, setAllschemeData] = useState<any[]>([])
  const [accreditationcycle, setAccreditationcycle] = useState<any>()
  const [selectedDates, setSelectedDates] = useState<DateObject[]>([])
  const [location, setlocation] = useState<any>()
  const [remark, setremark] = useState<any>()
  const [selectYear, setselectyear] = useState<any>(null)
  const [selectCycle, setselectCycle] = useState<any>(null)
  console.log(selectCycle, 'thgis is selected Cycle')
  console.log(selectYear, 'thgis is selected year')
  const cabID = uniqueCab

  const yearOptions = [
    {label: 'Year 1', value: 1},
    {label: 'Year 2', value: 2},
    {label: 'Year 3', value: 3},
    {label: 'Year 4', value: 4},
  ]

  useEffect(() => {
    getGlobalDetails()
  }, [cabID])

  useEffect(() => {
    const fetchScheme = async () => {
      try {
        const response = await axios.get(`${API_URL}/account/patch/user/${cabID}/`)
        const filteredData = response.data?.scheme_names?.map((scheme: any) => ({
          id: scheme.id,
          short_name: scheme.short_name,
          name: scheme.name,
        }))
        setSchemeData(filteredData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchScheme()
  }, [])

  useEffect(() => {
    getGlobalDetails()
  }, [])

  const getGlobalDetails = () => {
    axios
      .get(`${API_URL}/cab/global/scheme/data/filter/?cab=${cabID}`)

      .then((response) => {
        const result = response.data.reverse()
        setAllglobalData(result)
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }
  useEffect(() => {
    if (cabID && selectedScheme) {
      getActivityDetails()
    }
  }, [cabID, selectedScheme])

  const getActivityDetails = () => {
    axios
      .get(`${API_URL}/arc/assessment/programme/data/filter/?cab=${cabID}&scheme=${selectedScheme}`)

      .then((response) => {
        const result = response.data.reverse()
        setAllschemeData(result)
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }

  const handleSchemeSelect = (e) => {
    setSelectedScheme(e.value)
    setSchemeName(e.short_name)
  }
  const handleCycleSelect = (e) => {
    console.log(e, 'this is coming e')
    setselectCycle(e.value + 1)
    setGlobalData(allglobalData?.[e.value])
  }
  const handleYearSelect = (e) => {
    console.log(e, 'thi is coming year')
    setselectyear(e.value)
    // setfirstGlobalData(allglobalData?.[e.value])
  }

  const validateDates = (dates) => {
    try {
      const formattedDates = dates.map((date) => {
        const year = String(date.year)
        const month = String(date.month.number).padStart(2, '0')
        const day = String(date.day).padStart(2, '0')

        return `${year}-${month}-${day}`
      })

      return formattedDates
    } catch (error: any) {
      return null
    }
  }

  useEffect(() => {
    if (globalData) {
      // Filter and extract the dates based on the selected scheme
      const filteredDates = globalData?.accreditation_dates?.filter(
        (item) => item.id === selectedScheme
      )
      console.log(filteredDates, 'this is date coming')
      const newAccreditationDate = filteredDates[0]?.date

      setAccreditationdate(newAccreditationDate)

      if (newAccreditationDate) {
        const monthsToAdd = 10 + (selectYear - 1) * 12

        // Adding months to the date string without timezone conversion
        const newPlannedDate = addMonthsToDate(newAccreditationDate, monthsToAdd)

        setPlannedDate(newPlannedDate)
      }

      const filteredreDates = globalData?.reaccreditation_dates?.filter(
        (item) => item.id === selectedScheme
      )

      setreAccreditationdate(filteredreDates[0]?.date)

      const filteredexpiryDates = globalData?.expiry_dates?.filter(
        (item) => item.id === selectedScheme
      )

      setexpirydate(filteredexpiryDates[0]?.date)
      console.log(expirydate, 'this is  my expiry date')

      const filteredredueDates = globalData?.reaccreditation_due_dates?.filter(
        (item) => item.id === selectedScheme
      )

      setreAccreditationduedate(filteredredueDates[0]?.date)
    }
  }, [globalData, selectedScheme, selectYear])

  const addMonthsToDate = (dateStr, monthsToAdd) => {
    const [year, month, day] = dateStr.split('-').map(Number)
    const date = new Date(year, month - 1, day)

    date.setMonth(date.getMonth() + monthsToAdd)
    return date.toISOString().split('T')[0]
  }

  const formatMyDate = (date: string | null) => {
    if (!date) return null
    const [year, month, day] = date.split('-')
    return `${month}-${day}-${year}`
  }

  const handleSubmit = async () => {
    // if (!selectedDates?.length) {
    //   toast.error('Please add at least one date before creating the assessment.')
    //   return false
    // }
    const payload = {
      cab: cabID,
      scheme: selectedScheme,
      re_accreditation_date: accreditationdate,
      expiry_date: expirydate,
      re_accreditation_due_date: reaccreditationduedate,
      // start_date:accreditationdate,
      accreditation_cycle: accreditationcycle,
      cycle: selectCycle,
      year: selectYear,
      planned_date: plannedDate,
      conducted_dates: JSON.stringify(validateDates(selectedDates)),
      location,
      remark,
    }

    try {
      const response = await axios.post(`${API_URL}/arc/assessment/programme/data/post/`, payload)
      if (response.status === 200 || response.status === 201) {
        toast.success('Activity Data Submitted Successfully')
        setremark('')
        setlocation('')
        setAccreditationcycle('')
        setSelectedDates([])
        getActivityDetails()
      } else {
        toast.error('Data not Submitted')
      }
    } catch (error) {
      // console.error('Error submitting data:', error)
      toast.error('An error occurred while submitting data')
    }
  }

  useEffect(() => {
    if (selectedDates.length > 0) {
      const validatedDates = validateDates(selectedDates)
      if (validatedDates) {
        console.log('Validated Dates:', validatedDates) // Replace with your logic to use dates
      }
    } else {
    }
  }, [selectedDates])

  return (
    <div className='mb-5 mb-xl-8'>
      <div className='card-header border-0 py-5 d-flex mb-5'>
        <h3 className='card-title align-items-start flex-column mt-2'>
          <span className='card-label fw-bold fs-1'>Activity Assessment</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='col-xl-12 card card-xl-stretch mb-5 mb-xl-10'>
          <div className='p-3 d-flex justify-content-between w-100 align-items-center'>
            <div className=' col-5 m-2 '>
              {/* <label className='form-label'>Scheme Name</label> */}
              <Select
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Select Scheme'
                options={schemeData?.map((item) => ({
                  label: `${item?.short_name}`,
                  value: item?.id,
                  short_name: item?.short_name,
                }))}
                onChange={handleSchemeSelect}
              />
            </div>
            <div className=' col-3 m-2'>
              <Select
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Select Cycle'
                options={allglobalData?.map((item, index) => ({
                  label: `Cycle${index + 1}`,
                  value: index,
                }))}
                onChange={handleCycleSelect}
              />
            </div>
            <div className=' col-3 m-2'>
              <Select
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Select Year'
                options={yearOptions}
                onChange={handleYearSelect}
              />
            </div>
          </div>
        </div>
        {allSchemedata.length > 0 && (
          <div className='table-responsive mt-8'>
            <table className='align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Management System
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Inital of accredidation (MMDDYYYY)
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    RA due on (MMYYYY)
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Expiry (MMDDYYYY)
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Accredidation Cycle
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Planned (MMYYYY)
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Conducted (MMDDYYYY)
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Non-key Location
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Next Cycle Remark
                  </th>
                </tr>
              </thead>
              <tbody>
                {allSchemedata?.map((item) => {
                  return (
                    <tr key={item?.id}>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            {schemeName}
                          </div>
                        </div>
                      </td>

                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            {formatMyDate(item?.re_accreditation_date)}
                          </div>
                        </div>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            {formatMyDate(item?.re_accreditation_due_date)}
                          </div>
                        </div>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            {formatMyDate(item?.expiry_date)}
                          </div>
                        </div>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            {item?.accreditation_cycle}
                          </div>
                        </div>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            {formatMyDate(item?.planned_date)}
                          </div>
                        </div>
                      </td>

                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        {item?.conducted_dates && item.conducted_dates.trim() !== '[]' ? (
                          JSON.parse(item.conducted_dates.replace(/'/g, '"')).map((date) => (
                            <div key={date}>{formatMyDate(date)}</div>
                          ))
                        ) : (
                          <div></div>
                        )}
                      </td>

                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            {item?.location}
                          </div>
                        </div>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            {item?.remark}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}

        <div className='table-responsive mt-8'>
          <table className='align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Management System
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Initial of accredidation (MMDDYYYY)
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  RA due on (MMYYYY)
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Expiry (MMDDYYYY)
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Accredidation Cycle
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Planned (MMYYYY)
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Conducted (MMDDYYYY)
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Non-key Location
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Next Cycle Remark
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>{schemeName}</div>
                  </div>
                </td>

                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {formatMyDate(accreditationdate)}
                    </div>
                  </div>
                </td>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {formatMyDate(reaccreditationduedate)}
                    </div>
                  </div>
                </td>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {formatMyDate(expirydate)}
                    </div>
                  </div>
                </td>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <textarea
                        name='reason_for_updating'
                        value={accreditationcycle}
                        onChange={(e) => setAccreditationcycle(e.target.value)}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </div>
                  </div>
                </td>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  {plannedDate && (
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        {formatMyDate(plannedDate)}
                      </div>
                    </div>
                  )}
                </td>

                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  {accreditationdate && (
                    <div className='d-flex align-items-center flex-column'>
                      <div className='d-flex justify-content-start flex-column mb-3'>
                        <DatePicker
                          multiple
                          value={selectedDates}
                          onChange={setSelectedDates}
                          calendar={gregorian}
                          format='YYYY-MM-DD'
                          className='form-control form-control-solid'
                          showOtherDays
                          sort
                          render={(value, openCalendar) => (
                            <input
                              readOnly
                              className='form-control form-control-solid'
                              placeholder='Select dates'
                              value={value}
                              onClick={openCalendar}
                            />
                          )}
                          numberOfMonths={1}
                        />
                      </div>
                      {/* {selectedDates?.length > 0 && (
                        <div style={{marginTop: '10px', textAlign: 'left'}}>
                          <strong>Selected Dates:</strong>{' '}
                          {selectedDates
                            .map((date) => new Date(date).toLocaleDateString())
                            .join(', ')}
                        </div>
                      )} */}
                    </div>
                  )}
                </td>

                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <textarea
                        name='reason_for_updating'
                        value={location}
                        onChange={(e) => setlocation(e.target.value)}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </div>
                  </div>
                </td>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <textarea
                        name='reason_for_updating'
                        value={remark}
                        onChange={(e) => setremark(e.target.value)}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='mb-5 mt-5'>
          <button className='btn btn-primary' type='button' onClick={handleSubmit}>
            Save
            <i className='fa fa-angle-double-right' aria-hidden='true' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default GlobalAssessmentProgram
