import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'

import {KTIcon} from '../../../_metronic/helpers'
import {Button, Modal} from 'react-bootstrap'
import {toast} from 'react-toastify'

const News = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const [trainingList, setTrainingList] = useState<any[]>([])
  const [trainingId, setTrainingId] = useState<any>()
  const [pagenumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalcount, settotalcount] = useState(0)
  const [showDeleteTraining, setshowDeleteTraining] = useState<boolean>(false)
 
  const totalPagesArray = Array.from({length: totalPages}, (_, index) => index + 1)
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setPageNumber(page)
    }
  }

  const handleDeleteTraining = async () => {
    try {
      const response = await axios.delete(`${API_URL}/blog/announcement/destroy/${trainingId}/`)
      if (response) {
        toast.success('News Deleted Successfully')
        setshowDeleteTraining(false)
        fetchTrainingDAta()
        setTrainingId('')
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchTrainingDAta = async () => {
    try {
      // setLoader(true);
      const response = await axios.get(`${API_URL}/blog/announcement/get/?page=${pagenumber}`)
      const pages = Math.ceil(response?.data?.count / 10)
      settotalcount(response?.data?.count)
      setTotalPages(pages)
      if (response.status === 200) {
        setTrainingList(response.data?.results)
      }
    } catch (error) {
      console.log(error)
    } finally {
      // setLoader(false);
    }
  }

  useEffect(() => {
    fetchTrainingDAta()
  }, [pagenumber])

  const ShowDeleteModal = (id: any) => {
    setTrainingId(id)
    setshowDeleteTraining(true)
  }



  return (
    <div id='v_blog_01'>
      <div className='row jusify-content-space-between'>
        <div className='col-md-6 d-flex align-items-center justify-content-start'>
          <h2 className='mb-3 p-0 '>
            News
            <span
              className='card-label fw-bold fs-1 m-2'
              style={{backgroundColor: '#136BA1', color: 'white', padding: '0.3em 0.5em'}}
            >
              {totalcount}
            </span>
          </h2>
        </div>
      </div>

      <div className='card '>
        <div className='card-header pt-5 ' style={{borderBottom: '1px solid #333 !important'}}>
          <div className='card-title'></div>
          <div className='card-toolbar'>
            <div
              className='d-flex justify-content-end'
              style={{gap: '10px '}}
              data-kt-user-table-toolbar='base'
            >
              <Link id='le_lu_mai_1' to='/crafted/addNews' className='btn btn-primary'>
                <span className='svg-icon svg-icon-2'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='11.364'
                      y='20.364'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-90 11.364 20.364)'
                      fill='currentColor'
                    />
                    <rect x='4.36396' y='11.364' width='16' height='2' rx='1' fill='currentColor' />
                  </svg>
                </span>
                Add News
              </Link>
            </div>
          </div>
        </div>
        <div className='card-body pt-5'>
          <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
              <tr className='text-start  fw-bold  text-uppercase gs-0'>
                <th className='min-w-125px'>Title</th>
                {/* <th className='min-w-125px'>Type</th> */}
                <th className='min-w-125px'>Date</th>
                <th className='min-w-125px'>Action</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {trainingList?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>{item?.title}</div>
                  </td>
                  {/* <td>
                                        <div className='text-gray-800 text-hover-primary mb-1'>
                                            {item?.anno_type}
                                        </div>
                                    </td> */}
                  <td>{item?.anno_date ? item?.anno_date : '--'}</td>

                  <td>
                    <div className='d-flex justify-content-start flex-shrink-0'>
                      <Link
                        to={`/crafted/addNews/${item?.id}`}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </Link>
                      <a
                        onClick={() => ShowDeleteModal(item?.id)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ul className='pagination'>
            <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
              <a
                id='le_lu_mai_4'
                href='#'
                className='page-link'
                onClick={() => handlePageChange(pagenumber - 1)}
              >
                <i className='previous'></i>
              </a>
            </li>
            {totalPagesArray.map((page, index) => (
              <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
                <a
                  id={`le_lu_page_${index}`}
                  href='#'
                  className='page-link'
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </a>
              </li>
            ))}
            <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
              <a
                id='le_lu_mai_5'
                href='#'
                className='page-link'
                onClick={() => handlePageChange(pagenumber + 1)}
              >
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Modal
        show={showDeleteTraining}
        onHide={() => setshowDeleteTraining(false)}
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete News</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Do you want to delete this News ?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setshowDeleteTraining(false)}>
            Close
          </Button>
          <Button variant='danger' id='delete_cab_lead' onClick={() => handleDeleteTraining()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default News
