/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react'
import UAFLoader from '../../../../../loader/UafLoader'
import Select from 'react-select'
import {Dropdown, DropdownButton, Modal, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import {DateRange} from 'react-date-range'

import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {toast} from 'react-toastify'
import * as XLSX from 'xlsx'

import debounce from 'lodash/debounce'

import {KTIcon} from '../../../../../../_metronic/helpers'

type Auditor = {
  audit_type: string
  auditor_first_name: string
  auditor_email: string
  commenced_date: string | null
  total_days: number
}

type Certificate = {
  cab: string
  certificateId: string
  crt: string
  status: string
  programme: string
  scheme: string
  standard: string
  cluster: string
  issue_date: string | null
  expiry_date: string | null
  description: string
  remarks: string
  organisation_id: string
  organisation: string
  trading_name: string
  primary_contact: string
  organisation_phone_number: string
  organisation_email: string
  location_certified_address: string
  city: string
  state: string
  post_code: string
  country: string
  initial_certificate_date: string | null
}

const AllCabCertificate: React.FC = () => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const [loader, setLoader] = useState<any>(false)

  const [pagenumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalcount, settotalcount] = useState(0)
  const [value, setValue] = useState<any[]>([])
  const [allCertValue, setAllCertValue] = useState<any[]>([])
  const [showDeleteCertificate, setshowDeleteCertificate] = useState<boolean>(false)
  const [certificateId, setCertificateId] = useState<any>()
  const [searchParam, setSearchParam] = useState<any>()
  console.log(value, 'value hello')
  const [filterModel, setFilterModel] = useState(false)
  const [filteredValue, setFilteredValue] = useState<any[]>([])
  const [searchExcelDownload, setSearchExcelDownload] = useState<any[]>([])
  const [filteredCount, setFilteredCount] = useState<any[]>([])
  console.log(searchExcelDownload, 'searchExcelDownload')
  const [dateType, setDateType] = useState('')
  // const [showDropdown, setShowDropdown] = useState(false)

  // Toggle dropdown visibility on hover
  // const handleMouseEnter = () => setShowDropdown(true)
  // const handleMouseLeave = () => setShowDropdown(false)
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })
  const handleDateTypeChange = (event) => {
    setDateType(event.target.value)
  }
  const handleDateSelect = (ranges) => {
    setSelectionRange(ranges.selection)
  }
  const filteredData = () => {
    const {startDate, endDate} = selectionRange
    if (dateType === 'createdDate') {
      return {
        create_at_start: moment(startDate).format('YYYY-MM-DD'),
        create_at_end: moment(endDate).format('YYYY-MM-DD'),
      }
    } else if (dateType === 'updatedDate') {
      return {
        update_at_start: moment(startDate).format('YYYY-MM-DD'),
        update_at_end: moment(endDate).format('YYYY-MM-DD'),
      }
    } else if (dateType === 'expireDate') {
      return {
        expiry_date_start: moment(startDate).format('YYYY-MM-DD'),
        expiry_date_end: moment(endDate).format('YYYY-MM-DD'),
      }
    } else if (dateType === 'issueDate') {
      return {
        issue_date_start: moment(startDate).format('YYYY-MM-DD'),
        issue_date_end: moment(endDate).format('YYYY-MM-DD'),
      }
    }
    return null
  }

  const fetchData = async () => {
    try {
      // setLoader(true);
      const response = await axios.get(
        `${BASE_URL}/certificate/filter/pagination/?page=${pagenumber}`
      )
      const pages = Math.ceil(response?.data?.count / 10)
      settotalcount(response?.data?.count)
      setTotalPages(pages)
      if (response.status === 200) {
        setValue(response.data?.results)
      }
    } catch (error) {
      console.log(error)
    } finally {
      // setLoader(false);
    }
  }

  useEffect(() => {
    if (!searchParam) {
      fetchData()
    }
  }, [pagenumber])
  const fetchAllData = async () => {
    try {
      // setLoader(true);
      const response = await axios.get(`${BASE_URL}/certificate/filter/pagination/`)

      if (response.status === 200) {
        setAllCertValue(response.data?.results)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchAllData()
  }, [])

  const totalPagesArray = Array.from({length: totalPages}, (_, index) => index + 1)
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setPageNumber(page)
      fetchPageData(page)
    }
  }

  const handleSearchExcelDownlaod = useCallback(
    debounce(async (e) => {
      const searchValue = e?.target?.value
      console.log(searchValue, 'searchValue')
      if (searchValue) {
        try {
          setLoader(true)
          const response = await axios.get(`${BASE_URL}/certificate/filter/?search=${searchValue}`)
          // settotalcount(response?.data?.count);
          if (response.status === 200) {
            setSearchExcelDownload(response?.data)
          }
        } catch (error) {
          setLoader(false)
          console.log(error)
        } finally {
          setLoader(false)
        }
      } else {
        setSearchExcelDownload([])
        fetchData()
      }
    }, 500),
    []
  )
  const handleSearchChange = useCallback(
    debounce(async (e) => {
      const searchValue = e?.target?.value
      console.log(searchValue, 'searchValue')
      setSearchParam(searchValue)

      // Only trigger the search API when there is a search value or page number
      if (searchValue || pagenumber) {
        try {
          const response = await axios.get(
            `${BASE_URL}/certificate/filter/pagination/?search=${searchValue}&page=${pagenumber}`
          )

          if (response.status === 200) {
            const pages = Math.ceil(response?.data?.count / 10)
            setFilteredValue(response?.data?.results)
            setFilteredCount(response?.data?.count)
            setTotalPages(pages)
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        setFilteredValue([])
        fetchData()
      }
    }, 500), // debounce only for search
    [pagenumber, searchParam]
  )
  const fetchPageData = async (page) => {
    const searchValue = searchParam || '' // Use current search value or empty string

    try {
      const response = await axios.get(
        `${BASE_URL}/certificate/filter/pagination/?search=${searchValue}&page=${page}`
      )

      if (response.status === 200) {
        const pages = Math.ceil(response?.data?.count / 10)
        setFilteredValue(response?.data?.results)
        setFilteredCount(response?.data?.count)
        setTotalPages(pages)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // const handleSearchExcelDownlaod = useCallback(
  //   debounce(async (e) => {
  //     const searchValue = e?.target?.value
  //     console.log(searchValue, 'searchValue')
  //     setSearchParam(searchValue)

  //     // Only trigger the search API when there is a search value or page number
  //     if (searchValue) {
  //       try {
  //         const response = await axios.get(`${BASE_URL}/certificate/filter/?search=${searchValue}`)

  //         if (response.status === 200) {
  //           setSearchExcelDownload(response?.data)
  //         }
  //       } catch (error) {
  //         console.log(error)
  //       }
  //     } else {
  //       setSearchExcelDownload([])
  //       fetchData()
  //     }
  //   }, 500), // debounce only for search
  //   []
  // )
  // const handleSearchChange = useCallback(
  //     debounce(async (e) => {
  //         const searchValue = e?.target?.value;
  //         console.log(searchValue, 'searchValue');

  //         // Get filtered data (date range)
  //         const filters = filteredData();

  //         // Initialize query string with search
  //         let query = `search=${searchValue}`;

  //         // Add filteredData parameters to the query string if available
  //         if (filters) {
  //             const { startDate, endDate } = filters[Object.keys(filters)[0]];
  //             const key = Object.keys(filters)[0];
  //             query += `&${key}_start=${startDate.toISOString()}&${key}_end=${endDate.toISOString()}`;
  //         }

  //         if (searchValue) {
  //             try {
  //                 // setLoader(true);
  //                 const response = await axios.get(
  //                     `${BASE_URL}/certificate/filter/pagination/?${query}`
  //                 );

  //                 if (response.status === 200) {
  //                     setFilteredValue(response?.data?.results);
  //                 }
  //             } catch (error) {
  //                 console.log(error);
  //             } finally {
  //                 // setLoader(false);
  //             }
  //         } else {
  //             setFilteredValue([]);
  //             fetchData(); // Fetch data with the current filter state when search is cleared
  //         }
  //     }, 500),
  //     [selectionRange, dateType] // Add dependencies for filters
  // );

  const ShowCertificateModal = (id: any) => {
    setCertificateId(id)
    setshowDeleteCertificate(true)
  }

  const handleDeleteCertificate = async () => {
    try {
      const response = await axios.delete(`${BASE_URL}/certificate/${certificateId}/destroy/`)
      if (response) {
        toast.success('Certificate Deleted Successfully')
        setshowDeleteCertificate(false)
        fetchData()
        setCertificateId(null)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const formatDate = (dateString: string) => {
    return moment(dateString).format('DD/MM/YYYY')
  }

  // const downloadSample = async () => {
  //   const workbook = XLSX.utils.book_new()

  //   // Sample data structure
  //   const sheetData = [
  //     {
  //       cab: '',
  //       certificateId: '',
  //       crt: '',
  //       status: '',
  //       programme_data: '',
  //       scheme_data: '',
  //       standard_data: '',
  //       cluster_data: ['', ''].join(', '),
  //       issue_date: '',
  //       expiry_date: '',
  //       description: '',
  //       remarks: '',
  //       organisation_id: '',
  //       organisation: '',
  //       trading_name: '',
  //       primary_contact: '',
  //       organisation_phone_number: '',
  //       organisation_email: '',
  //       location_certified_address: '',
  //       city: '',
  //       state: '',
  //       post_code: '',
  //       country_name: '',
  //       initial_certificate_date: '',
  //       audit_1: ['Auditor_1_Name', 'Auditor_2_Name'].join(', '),
  //       audit_1_days: '10',
  //       audit_1_date: '13-Oct-2424',
  //       audit_2: ['Auditor_1_Name', 'Auditor_2_Name'].join(', '),
  //       audit_2_days: '20',
  //       audit_2_date: '13-Oct-2424',
  //     },
  //   ]

  //   // Convert JSON to worksheet
  //   const worksheet = XLSX.utils.json_to_sheet(sheetData)

  //   // Style for mandatory fields (Yellow background)
  //   const mandatoryStyle = {
  //     fill: {
  //       fgColor: {rgb: 'FFFF00'}, // Yellow background color
  //     },
  //   }

  //   // Define which columns are mandatory
  //   const mandatoryFields = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1']

  //   // Apply mandatory style to required fields
  //   mandatoryFields.forEach((cell) => {
  //     if (worksheet[cell]) {
  //       worksheet[cell].s = mandatoryStyle
  //     }
  //   })

  //   // Append the worksheet to the workbook
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Certificate Data')

  //   // Write file to the system
  //   XLSX.writeFile(workbook, 'Activesample.xlsx')
  // }
  // const downloadTransferredSample = async () => {
  //   const workbook = XLSX.utils.book_new()

  //   // Sample data structure
  //   const sheetData = [
  //     {
  //       cab: '',
  //       certificateId: '',
  //       crt: '',
  //       status: '',
  //       programme_data: '',
  //       scheme_data: '',
  //       standard_data: '',
  //       cluster_data: ['', ''].join(', '),
  //       issue_date: '',
  //       expiry_date: '',
  //       description: '',
  //       remarks: '',
  //       organisation_id: '',
  //       organisation: '',
  //       trading_name: '',
  //       primary_contact: '',
  //       organisation_phone_number: '',
  //       organisation_email: '',
  //       location_certified_address: '',
  //       city: '',
  //       state: '',
  //       post_code: '',
  //       country_name: '',
  //       initial_certificate_date: '',

  //     },
  //   ]

  //   // Convert JSON to worksheet
  //   const worksheet = XLSX.utils.json_to_sheet(sheetData)

  //   // Style for mandatory fields (Yellow background)
  //   const mandatoryStyle = {
  //     fill: {
  //       fgColor: {rgb: 'FFFF00'}, // Yellow background color
  //     },
  //   }

  //   // Define which columns are mandatory
  //   const mandatoryFields = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1']

  //   // Apply mandatory style to required fields
  //   mandatoryFields.forEach((cell) => {
  //     if (worksheet[cell]) {
  //       worksheet[cell].s = mandatoryStyle
  //     }
  //   })

  //   // Append the worksheet to the workbook
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Certificate Data')

  //   // Write file to the system
  //   XLSX.writeFile(workbook, 'Transferredsample.xlsx')
  // }
  // const downloadReaccreditationSample = async () => {
  //   const workbook = XLSX.utils.book_new()

  //   // Sample data structure
  //   const sheetData = [
  //     {
  //       cab: '',
  //       certificateId: '',
  //       crt: '',
  //       status: '',
  //       programme_data: '',
  //       scheme_data: '',
  //       standard_data: '',
  //       cluster_data: ['', ''].join(', '),
  //       issue_date: '',
  //       expiry_date: '',
  //       description: '',
  //       remarks: '',
  //       organisation_id: '',
  //       organisation: '',
  //       trading_name: '',
  //       primary_contact: '',
  //       organisation_phone_number: '',
  //       organisation_email: '',
  //       location_certified_address: '',
  //       city: '',
  //       state: '',
  //       post_code: '',
  //       country_name: '',
  //       initial_certificate_date: '',
  //       audit_1: ['Auditor_1_Name', 'Auditor_2_Name'].join(', '),
  //       audit_1_days: '10',
  //       audit_1_date: '13-Oct-2424',
  //     },
  //   ]

  //   // Convert JSON to worksheet
  //   const worksheet = XLSX.utils.json_to_sheet(sheetData)

  //   // Style for mandatory fields (Yellow background)
  //   const mandatoryStyle = {
  //     fill: {
  //       fgColor: {rgb: 'FFFF00'}, // Yellow background color
  //     },
  //   }

  //   // Define which columns are mandatory
  //   const mandatoryFields = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1']

  //   // Apply mandatory style to required fields
  //   mandatoryFields.forEach((cell) => {
  //     if (worksheet[cell]) {
  //       worksheet[cell].s = mandatoryStyle
  //     }
  //   })

  //   // Append the worksheet to the workbook
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Certificate Data')

  //   // Write file to the system
  //   XLSX.writeFile(workbook, 'Reaccreditationsample.xlsx')
  // }

  const downloadExcel = async () => {
    try {
      // Show a loading message or spinner (optional)
      console.log("Downloading the file...");
  
      // API call to fetch the file
      const response = await axios.get(`${BASE_URL}/certificate/export/csv/`, {
        responseType: 'blob', // Ensure response is a blob for file download
      });
  
      // Extract file name from content-disposition header (if provided)
      const contentDisposition = response.headers['content-disposition'];
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1]?.replace(/"/g, '') || 'certification_data.csv'
        : 'certificate_export.csv'; // Fallback filename
  
      // Create a URL for the blob data
      const url = window.URL.createObjectURL(new Blob([response.data]));
  
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Use extracted or fallback filename
  
      // Append link to the body and trigger the download
      document.body.appendChild(link);
      link.click();
  
      // Clean up: Remove link and revoke URL
      link.remove();
      window.URL.revokeObjectURL(url);
  
      // Log success or show a success toast
      console.log("File downloaded successfully!");
    } catch (error:any) {
      // Log the error and optionally show an error toast
      console.error("Error downloading the file:", error);
  
      // Optional: Handle specific HTTP errors
      if (error.response && error.response.status === 404) {
        console.error("File not found.");
      } else {
        console.error("An unexpected error occurred.");
      }
    }
  };
  



  const filteredExcel = async () => {
    const workbook = XLSX.utils.book_new()

    const sheetData = (
      Array.isArray(searchExcelDownload) && searchExcelDownload.length > 0
        ? searchExcelDownload
        : Array.isArray(allCertValue)
        ? allCertValue
        : []
    )?.map((item, index) => {
      // Base data for the certificate
      const baseData = {
        cab: item?.cab_name?.short_code,
        certificateId: item?.certificateId,
        crt: item?.crt,
        status: item?.status,
        programme_data: item?.programme_data?.name,
        scheme_data: item?.scheme_data?.short_name,
        standard_data: item?.standard_data?.name,
        cluster_data: item?.cluster_data.map((cluster) => cluster.name).join(', '),
        issue_date: item?.issue_date,
        expiry_date: item?.expiry_date,
        description: item?.description,
        remarks: item?.remarks,
        organisation_id: item?.organisation_id,
        organisation: item?.organisation,
        trading_name: item?.trading_name,
        primary_contact: item?.primary_contact,
        organisation_phone_number: item?.organisation_phone_number,
        organisation_email: item?.organisation_email,
        location_certified_address: item?.location_certified_address,
        city: item?.city,
        state: item?.state,
        post_code: item?.post_code,
        country_name: item?.country_name,
        initial_certificate_date: item?.initial_certificate_date,
      }

      // Add auditor data dynamically
      // item?.certification_audit?.forEach((audit, idx) => {
      //   baseData[`audit_${idx + 1}`] = audit?.auditor_first_name
      //   baseData[`audit_${idx + 1}_days`] = audit?.total_days
      //   baseData[`audit_${idx + 1}_date`] = audit?.commenced_date
      // })

      return baseData
    })

    const worksheet = XLSX.utils.json_to_sheet(sheetData)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Certificates')
    XLSX.writeFile(workbook, 'Certificates.xlsx')
  }

  // const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const fileInput = e.target
  //   const file = fileInput?.files?.[0]
  //   if (!file) return

  //   // Create FormData to hold the file
  //   const formData = new FormData()
  //   formData.append('excel_data', file)

  //   try {
  //     // Send the FormData directly to the API
  //     setLoader(true)
  //     const response = await axios.post(`${BASE_URL}/certificate/import/excel/sheet/`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     })

  //     // Handle success response
  //     setLoader(false)
  //     toast.success('Certificate Uploaded Successfully')

  //     fetchData()
  //     fileInput.value = ''
  //     console.log('Upload Response:', response.data)
  //   } catch (error) {
  //     setLoader(false)
  //     console.error('Error during file upload:', error)
  //     toast.error('Error creating certificate')
  //   }
  // }
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target;
    const file = fileInput?.files?.[0];
    if (!file) return;
  
    // Create FormData to hold the file
    const formData = new FormData();
    formData.append('excel_data', file); // Use the appropriate key expected by your API
  
    try {
      setLoader(true);
      // Send the FormData directly to the API
      const response = await axios.post(`${BASE_URL}/certificate/import/excel/sheet/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type for file upload
        },
      });
  
      if (response?.status === 200) {
        // Handle success response
        toast.success('Certificate Uploaded Successfully');
        fetchData();
        fileInput.value = ''; // Reset file input value
        setLoader(false);
        console.log('Upload Response:', response.data);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const errorData = error.response.data;
        console.error('API Error:', error.response.data);
  
        if (errorData?.error === 'The uploaded file is empty.') {
          toast.error('Upload failed: File is empty');
        } else if (errorData.details && Array.isArray(errorData.details)) {
          const errorList = `
            <div style="max-height: 500px; overflow-y: auto;">
              <ul style="margin: 0; padding-left: 20px;">
                ${errorData.details.map((item) => `<li>${item}</li>`).join('')}
              </ul>
            </div>
          `;
          toast.error(<div dangerouslySetInnerHTML={{ __html: errorList }} />);
        } else if (errorData?.error) {
          toast.error(errorData?.error);
        }
      } else {
        console.error('Upload Error:', error);
        toast.error('An error occurred during upload.');
      }
      setLoader(false);
    } finally {
      fileInput.value = ''; // Ensure the file input value is reset after every attempt
    }
  };
  
//   const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
//     const fileInput = e.target
//     const file = fileInput?.files?.[0]
//     if (!file) return

//     // Create FormData to hold the file
//     const formData = new FormData()
//     formData.append('excel_data', file) // Use the appropriate key expected by your API

//     try {
//       // Send the FormData directly to the API
// setLoader(true);
//       const response = await axios.post(`${BASE_URL}/certificate/import/excel/sheet/`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data', // Set the content type for file upload
//         },
//       })
// if(response?.status === 200){
//       // Handle success response

//       toast.success('Certificate Uploaded Successfully')

//       fetchData()
//       fileInput.value = ''
//       setLoader(false);
//       console.log('Upload Response:', response.data)}

//     } catch (error) {
//       if (axios.isAxiosError(error) && error.response) {
//         const errorData = error.response.data
//         console.error('API Error:', error.response.data)
//         if (errorData?.error === 'The uploaded file is empty.') {
//           // If response data is empty
//           toast.error('Upload failed: File is empty')
//           setLoader(false);
//         } else if (errorData.details && Array.isArray(errorData.details)) {
//           // If response data contains details
//           const errorList = `
//             <div style="max-height: 500px; overflow-y: auto;">
//               <ul style="margin: 0; padding-left: 20px;">
//                 ${errorData.details.map((item) => `<li>${item}</li>`).join('')}
//               </ul>
//             </div>
//           `

//           toast.error(<div dangerouslySetInnerHTML={{__html: errorList}} />)
//           setLoader(false);
//         } else if (errorData?.error) {
//           toast.error(errorData?.error)
//           setLoader(false);
//         }
//       } else {
//         console.error('Upload Error:', error)
//         toast.error('An error occurred during upload.')
//         setLoader(false);
//       }
//     }
//   }
  const downloadFile = async () => {
    try {
      // Send a GET request to fetch the file as a Blob
      const response = await axios.get(
        'https://backendapi.uafaccreditation.org/api/file/structure/download/csv/',
        {
          responseType: 'blob', // Important: Tell axios to treat the response as a Blob
        }
      )

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(new Blob([response.data]))

      // Create an anchor element and trigger the download
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'certificate.xlsx') // File name
      document.body.appendChild(link)
      link.click()

      // Clean up the DOM
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }
  return (
    <div id='v_ass_01'>
      {loader ? <UAFLoader /> : null}
      <div className='row jusify-content-space-between'>
        <div className='col-md-6 d-flex align-items-center justify-content-start'>
          <h2 className='mb-5 p-0 '>
            CAB Certificates
            <span
              className='card-label fw-bold fs-1 m-2'
              style={{backgroundColor: '#136BA1', color: 'white', padding: '0.3em 0.5em'}}
            >
              {filteredValue?.length > 0 ? filteredCount : totalcount}
            </span>
          </h2>
        </div>
      </div>
      <div className='card  card-flush overflow-auto'>
        <div className='card-header pt-5'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='currentColor'
                  />
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <input
                id='le_lu_ri'
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                onChange={(e) => {
                  handleSearchChange(e)
                  handleSearchExcelDownlaod(e)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearchChange(e)
                  }
                }}
                placeholder='Search user'
              />
            </div>
          </div>
          <div className='mt-4'>
            <Link to={`/cab/certificates/add`} className='btn btn-primary float-right me-2'>
              <KTIcon iconName='plus' className='fs-3' />
              Add Certificate
            </Link>
            <label className='btn btn-primary float-right me-2'>
              <KTIcon iconName='file-down' className='fs-3' />
              Import
              <input
                type='file'
                accept='.xlsx, .xls'
                onChange={handleFileUpload}
                style={{display: 'none'}} // Hide the file input
              />
            </label>
            {searchExcelDownload?.length > 0 ? (
              <a onClick={filteredExcel} className='btn btn-primary float-right me-2'>
                <KTIcon iconName='file-up' className='fs-3' />
                Export
              </a>
            ) : (
              <a onClick={downloadExcel} className='btn btn-primary float-right me-2'>
                <KTIcon iconName='file-up' className='fs-3' />
                Export
              </a>
            )}
            <a
              // href='https://backendapi.uafaccreditation.org/api/file/structure/download/csv/'
              // download='certificate.xlsx'
              // target='_blanck'
              onClick={downloadFile}
              className='btn btn-primary float-right me-2'
              style={{display: 'inline-block'}}
            >
              <KTIcon iconName='cloud-download' className='fs-3' />
              Download Template
            </a>
            {/* <div
              className='dropdown'
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{position: 'relative', display: 'inline-block'}}
            >
              <a
              
                className='btn btn-primary float-right me-2'
                style={{display: 'inline-block'}}
              >
                <KTIcon iconName='cloud-download' className='fs-3' />
                Download Template
              </a>

             
              {showDropdown && (
                <div
                  className='dropdown-menu show'
                  
                  style={{position: 'absolute', top: '100%', left: 0}}
                >
                  <a className='dropdown-item'  onClick={downloadSample}>
                    Initial

                  </a>
                  <a className='dropdown-item' onClick={downloadTransferredSample}>
                    Transferred
                  </a>
                  <a className='dropdown-item' onClick={downloadReaccreditationSample}>
                    Re-accreditation
                  </a>
                </div>
              )}
            </div> */}
            <a onClick={() => setFilterModel(true)} className='btn btn-primary float-right me-2'>
              <KTIcon iconName='filter-search' className='fs-3' />
              Filter
            </a>
          </div>
        </div>

        <div className='card-body pt-5'>
          <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
              <tr className='text-start  fw-bold  text-uppercase gs-0'>
                {/* <th className='w-10px pe-2'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                         checked={selectAll}

                         onChange={handleSelectAll}
                    />
                  </div>
                </th> */}
                <th className='w-10px pe-2'>Sr No</th>
                <th className='min-w-125px'>CAB</th>
                <th className='min-w-125px'>Status</th>
                <th className='min-w-125px'>Created At</th>
                <th className='min-w-125px'>Updated At</th>
                <th className='min-w-125px'>Issue Date</th>
                <th className='min-w-125px'>Expire Date</th>
                <th className='min-w-125px'>Scheme</th>
                <th className='min-w-125px'>Standard</th>
                <th className='min-w-125px'>Cert Id</th>
                <th className='min-w-125px'>Orgnisation</th>
                <th className='min-w-125px'>Action</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {(filteredValue?.length > 0 ? filteredValue : value)?.map((item, index) => (
                <tr key={index}>
                  <td>
                    {/* <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        onChange={() => handleChangeId(item)}
                        checked={selectedItems?.includes(item?.id)}
                        id='le_lu_mai_2'
                      />
                    </div> */}
                    {index + 1}
                  </td>
                  <td>
                    <Link id='le_lu_mai_3' to='' className='text-gray-800 text-hover-primary mb-1'>
                      {item?.cab_name?.short_code}
                    </Link>
                  </td>
                  <td>
                    <p className='text-gray-800 mb-1'>{item?.status}</p>
                  </td>
                  <td>
                    <p className='text-gray-800 mb-1'>
                      {item.create_at
                        ? moment(new Date(item.create_at)).format('DD/MMM/YYYY')
                        : '--'}
                    </p>
                  </td>
                  <td>
                    <p className='text-gray-800 mb-1'>
                      {item.update_at
                        ? moment(new Date(item.update_at)).format('DD/MMM/YYYY')
                        : '--'}
                    </p>
                  </td>
                  <td>
                    <p className='text-gray-800 mb-1'>
                      {item.issue_date
                        ? moment(new Date(item.issue_date)).format('DD/MMM/YYYY')
                        : '--'}
                    </p>
                  </td>
                  <td>
                    <p className='text-gray-800 mb-1'>
                      {item.expiry_date
                        ? moment(new Date(item.expiry_date)).format('DD/MMM/YYYY')
                        : '--'}
                    </p>
                  </td>
                  <td>
                    <p className='text-gray-800 mb-1'>{item?.scheme_data?.short_name}</p>
                  </td>
                  <td>
                    <p className='text-gray-800 mb-1'>{item?.standard_data?.name}</p>
                  </td>
                  <td>
                    <p className='text-gray-800 mb-1'>{item?.certificateId}</p>
                  </td>
                  <td>
                    <p className='text-gray-800 mb-1'>{item?.organisation}</p>
                  </td>
                  <td>
                    <div className='d-flex justify-content-start flex-shrink-0'>
                      <Link
                        // to={`/apps/all/cab/certificates/${item?.id}`}
                        to={`/cab/certificate/${item?.cab_name?.id}/?id=${item?.id}`}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </Link>

                      <a
                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                        onClick={() => ShowCertificateModal(item?.id)}
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ul className='pagination'>
            <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
              <a
                id='le_lu_mai_4'
                href='#'
                className='page-link'
                onClick={() => handlePageChange(pagenumber - 1)}
              >
                <i className='previous'></i>
              </a>
            </li>
            {/* Show previous page if not the first */}
            {pagenumber > 1 && (
              <li className='page-item'>
                <a href='#' className='page-link' onClick={() => handlePageChange(pagenumber - 1)}>
                  {pagenumber - 1}
                </a>
              </li>
            )}
            {/* Show current page */}
            <li className='page-item active'>
              <a href='#' className='page-link'>
                {pagenumber}
              </a>
            </li>
            {/* Show next page if not the last */}
            {pagenumber < totalPages && (
              <li className='page-item'>
                <a href='#' className='page-link' onClick={() => handlePageChange(pagenumber + 1)}>
                  {pagenumber + 1}
                </a>
              </li>
            )}
            <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
              <a
                id='le_lu_mai_5'
                href='#'
                className='page-link'
                onClick={() => handlePageChange(pagenumber + 1)}
              >
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Modal show={filterModel} onHide={() => setFilterModel(false)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Advance Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
              <div className='row'>
                <div className='col'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='dateType'
                      id='createdDate'
                      value='createdDate'
                      onChange={handleDateTypeChange}
                    />
                    <label className='form-check-label' htmlFor='createdDate'>
                      Created Date
                    </label>
                  </div>
                </div>
                <div className='col'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='dateType'
                      id='updatedDate'
                      value='updatedDate'
                      onChange={handleDateTypeChange}
                    />
                    <label className='form-check-label' htmlFor='updatedDate'>
                      Updated Date
                    </label>
                  </div>
                </div>
                <div className='col'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='dateType'
                      id='expireDate'
                      value='expireDate'
                      onChange={handleDateTypeChange}
                    />
                    <label className='form-check-label' htmlFor='expireDate'>
                      Expire Date
                    </label>
                  </div>
                </div>
                <div className='col'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='dateType'
                      id='issueDate'
                      value='issueDate'
                      onChange={handleDateTypeChange}
                    />
                    <label className='form-check-label' htmlFor='issueDate'>
                      Issue Date
                    </label>
                  </div>
                </div>
              </div>
            </form>

            {/* Conditionally render DateRange based on selected radio button */}
            <div className='mt-20 text-center'>
              {dateType && (
                <DateRange
                  ranges={[selectionRange]}
                  onChange={handleDateSelect}
                  moveRangeOnFirstSelection={true}
                  retainEndDateOnFirstSelection={true}
                />
              )}
            </div>

            {/* Filtered data for API or other use */}
            {/* <pre>{JSON.stringify(filteredData(), null, 2)}</pre> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setFilterModel(false)}>
            Close
          </Button>
          <Button
            id='nand_le'
            variant='success'
            onClick={async () => {
              const filters = filteredData()
              if (filters) {
                try {
                  // Construct query string for date range filtering
                  const queryParams = new URLSearchParams()
                  Object.entries(filters).forEach(([key, value]) => {
                    if (value) {
                      queryParams.append(key, value)
                    }
                  })

                  const query = `${BASE_URL}/certificate/filter/pagination/?${queryParams.toString()}`

                  const response = await axios.get(query)
                  if (response.status === 200) {
                    if (response?.data?.results?.length === 0) {
                      toast.error('No Data Found')
                    } else {
                      setFilteredValue(response?.data?.results)
                    }
                  }
                } catch (error) {
                  console.log(error)
                }
              }

              setFilterModel(false) // Close the modal after submitting
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeleteCertificate}
        onHide={() => setshowDeleteCertificate(false)}
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Do you want to delete this Certificate ?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setshowDeleteCertificate(false)}>
            Close
          </Button>
          <Button variant='danger' id='delete_cab_lead' onClick={() => handleDeleteCertificate()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export {AllCabCertificate}
