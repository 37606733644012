import {useEffect, useState} from 'react'
import {KTCard, KTIcon} from '../../../../../_metronic/helpers'
import Select from 'react-select'
import axios from 'axios'
import {toast} from 'react-toastify'
import {Button, Modal} from 'react-bootstrap'
import DatePicker from "react-multi-date-picker";
import gregorian from "react-date-object/calendars/gregorian";
import NewAssementModal from '../../../apps/create-assessment/create-assessment-components/modals/NewAssementModal'
import {useNavigate} from 'react-router-dom'
import { DateObject } from "react-multi-date-picker";
import {FormatDate} from '../../../../utils/formateDate'

const API_URL = process.env.REACT_APP_API_URL

const CreateAssessmentPlannerWrapper = () => {
  const navigate = useNavigate()
  const BASE_URL = process.env.REACT_APP_API_URL
  interface User {
    value: string
    label: string
  }

  const [assessmentPlannerDetails, setAssessmentPlannerDetails] = useState({
    cab: '',
    scheme: '',
    multi_date: [new Date()],
    assessment_type: '',
    assessment_stage: '',
    assessment_number: '',
  })
  console.log(assessmentPlannerDetails, 'assessmentPlannerDetails  ')

  const [assessmentSchemeData, setAssessmentSchemeData] = useState<any>()

  const [cabUser, setCabUser] = useState<User[]>([])
  const [schemeTable, setSchemeTable] = useState<any>([])
  const [cabUserData, setCabUserData] = useState<any>([])
  const [schemeData, setSchemeData] = useState<any>([])

  const [startDateAssessment, setStartDate] = useState(new Date())

  const [showNewAssessmentModal, setShowNewAssessmentModal] = useState<boolean>(false)
  const [successModal, setShowSuccessModal] = useState<boolean>(false)
  const [assessmentType, setAssessmentType] = useState<string>('')
  const [reponseAssessmentNumber, setResponseAssessmentNumber] = useState<string>('')
  const [assessmentStageList, setAssessmentStageList] = useState<any>([])
  const [addNewStage, setAddNewStage] = useState<string>('')
  const [assessmentList, setAssessmentList] = useState<any>([])
  console.log(assessmentPlannerDetails, 'assessmentPlannerDetails')
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [selectedDates, setSelectedDates] = useState<DateObject[]>([]);
  const [error, setError] = useState("");
  const [assessmentNumber, setAssessmentNumber] = useState<string>('')

  console.log(checkedIds, 'checkedIds')
  console.log(selectedDates, 'selectedDates')

  const [selectedAssessment, setSelectedAssessment] = useState<any>([])
  const [assessmentTypeModal, setAssessmentTypeModal] = useState<boolean>(false)
  const [dateRange, setDateRange] = useState([null, null])
  const desiredSequence = [
    'Document Review',
    'Initial Office Assessment',
    'Surveillance Office Assessment',
    'Reaccreditation Office Assessment',
    'Witness Assessment',
    'Scope extension',
  ]

  // get cab user
  useEffect(() => {
    // fetchData();
    axios
      .get(`${BASE_URL}/account/approved/cab/user/`)

      .then((response) => {
        console.log(response.data, 'helloosss')
        const data = response.data?.map((item) => ({
          label: item?.company,
          value: item?.id,
        }))
        setCabUserData(response.data)
        setCabUser(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const getAssessmentStage = () => {
    axios
      .get(`${BASE_URL}/cab/assessment/stage/list/?cab=${assessmentPlannerDetails?.cab}`)
      .then((response) => {
        console.log(response.data, 'kjdsdsjdksjdkjk')
        const data = response.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }))
        // const sorted = data?.sort(
        //   (a, b) => desiredSequence.indexOf(a.label) - desiredSequence.indexOf(b.label)
        // )
        setAssessmentStageList(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // get assessment list
  const getAssessmentList = () => {
    axios
      .get(`${BASE_URL}/cab/assessment/list/`)
      .then((response) => {
        console.log(response.data, 'kjdsdsjdksjdkjk')
        const data = response.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }))
        const sorted = data?.sort(
          (a, b) => desiredSequence.indexOf(a.label) - desiredSequence.indexOf(b.label)
        )
        setAssessmentList(sorted)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    getAssessmentStage()
    getAssessmentList()
  }, [])
  useEffect(() => {
    if (assessmentPlannerDetails?.cab) {
      getAssessmentStage()
    }
  }, [assessmentPlannerDetails?.cab])

  // create new assessment type function
  const handleNewAssessmentType = async () => {
    const data = {
      name: assessmentType,
    }
    try {
      const response = await axios.post(`${BASE_URL}/cab/assessment/create/`, data)
      const newData = {
        label: response?.data?.name,
        value: response?.data?.id,
      }
      getAssessmentList()
      setAssessmentType('')
      setShowNewAssessmentModal(false)
      setCheckedIds([...checkedIds, response?.data?.id])
      const prevData = [...selectedAssessment, newData]
      setSelectedAssessment(prevData)
    } catch (error) {
      console.log(error)
    }
  }



  const handleCabUserChange = (e) => {
    setAssessmentPlannerDetails({...assessmentPlannerDetails, cab: e.value})
  }
  const handleCabSchemeChange = (e) => {
    setSchemeTable(e)
    setAssessmentPlannerDetails({...assessmentPlannerDetails, scheme: e?.map((i) => i?.value)})
  }
  useEffect(() => {
    const assessmentData = schemeTable?.map((item) => ({
      scheme: item?.value,
      assessment_duration: assessmentSchemeData.find((i) => i.scheme === item.value)
        ? assessmentSchemeData.find((i) => i.scheme === item.value).assessment_duration
        : '',
      remark: assessmentSchemeData.find((i) => i.scheme === item.value)
        ? assessmentSchemeData.find((i) => i.scheme === item.value).remark
        : '',
      assessor: '',
      major_nc_cab_start_date: '',
      major_nc_cab_closer_date: '',
      minor_nc_cab_start_date: '',
      minor_nc_cab_end_date: '',
    }))
    setAssessmentSchemeData(assessmentData)
  }, [schemeTable])

  useEffect(() => {
    if (assessmentPlannerDetails?.cab) {
      const filterData = cabUserData?.find((item) => item?.id === assessmentPlannerDetails?.cab)
      const alldata = filterData?.scheme_names?.map((e) => ({value: e?.id, label: e?.name}))
      setSchemeData(alldata)
    }
  }, [assessmentPlannerDetails?.cab])
  const handleAssessmentTypeChange = (e) => {
    setAssessmentPlannerDetails({...assessmentPlannerDetails, assessment_type: e.value})
  }

  const handleAssessmentStageChange = (e) => {
    setAssessmentPlannerDetails({...assessmentPlannerDetails, assessment_stage: e.value})
  }


  const validateDates = (dates) => {
    try {
      const formattedDates = dates.map((date) => {
        const year = String(date.year);
        const month = String(date.month.number).padStart(2, "0");
        const day = String(date.day).padStart(2, "0");
  
       
        return `${year}-${month}-${day}`;
      });
  
      setError(""); 
      return formattedDates;
    } catch (error:any) {
      setError(error.message); 
      return null;
    }
  };
  
  
  

  const AddGroup = async () => {
    // Check if the `addNewStage` is valid
    if (!addNewStage || addNewStage.trim() === '') {
      toast.error('Please provide a valid stage name.') // Clearer error message
      return // Stop further execution
    }

    try {
      // Make API call to create a new stage
      const response = await axios.post(`${BASE_URL}/cab/assessment/stage/create/`, {
        name: addNewStage,
        cab: assessmentPlannerDetails.cab,
      })
      if (response.status === 201) {
        toast.success('Stage created successfully!') // Show success message
        setAddNewStage('') // Reset the stage name field
        getAssessmentStage() // Refresh the list of stages
      } else {
        toast.error('Failed to create stage. Please try again.') // Handle unexpected responses
      }
    } catch (error) {
      // Log the error for debugging
      console.error('Error creating stage:', error)
    }
  }


  const handleCreateAssessmentPlanner = async () => {
    if (!assessmentPlannerDetails.assessment_number) {
      toast.error('Assessment number is required.');
      return;
    }
  
    if (!assessmentPlannerDetails.assessment_stage) {
      toast.error('Assessment Stage is required.');
      return;
    }
  
    try {
      const selectedAssessmentType = assessmentList?.find(
        (item) => item.value === assessmentPlannerDetails?.assessment_type
      )?.label;
  
      // If the assessment type is "Document Review", ensure `multi_date` is an empty array
      if (selectedAssessmentType === 'Document Review') {
        assessmentPlannerDetails.multi_date = [];
      } else {
        // If the user has entered dates, ensure they're properly formatted
        if (selectedDates.length === 0) {
          toast.error('Please provide at least one date for the assessment.');
          return;
        }
  
        // Convert `selectedDates` from `DateObject[]` to `Date[]`
        const validatedDates = validateDates(selectedDates);
  
        if (!validatedDates) return; // Return early if validation fails
  
        assessmentPlannerDetails.multi_date = validatedDates; // Use the validated dates
      }
  
      const data = {
        ...assessmentPlannerDetails,
        scheme: assessmentSchemeData, // Include scheme data if necessary
      };
  
      const response = await axios.post(`${API_URL}/cab/create/assessor/planner/`, data);
  
      // Reset fields after successful submission
      setAssessmentPlannerDetails({
        ...assessmentPlannerDetails,
        cab: '',
        assessment_type: '',
        assessment_stage: '',
        assessment_number: '',
        multi_date: [], 
      });
      setSelectedDates([]); 
      setResponseAssessmentNumber(response?.data?.assessment_number);
      console.log(response, 'response');
  
      setShowSuccessModal(true);
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.error || 'An unexpected error occurred.');
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    }
  };
  
  
  


  const [redirectTimer, setRedirectTimer] = useState(5)

  useEffect(() => {
    if (successModal) {
      const timer = setInterval(() => {
        setRedirectTimer((prevTimer) => prevTimer - 1)
      }, 1000)

      if (redirectTimer === 0) {
        clearInterval(timer)
        navigate('/organisation/AssessmentPlanner')
      }

      return () => clearInterval(timer)
    }
  }, [successModal, redirectTimer])

  const handleSchemeAssetDuration = (schemeItem, event) => {
    const updatedSchemeData = assessmentSchemeData?.map((item) => {
      if (item.scheme === schemeItem.value) {
        return {...item, assessment_duration: event.target.value}
      }
      return item
    })
    setAssessmentSchemeData(updatedSchemeData)
  }
  const handleSchemeRemark = (schemeItem, event) => {
    const updatedSchemeData = assessmentSchemeData?.map((item) => {
      console.log(item.scheme === schemeItem.value, 'kkkekekkekek')
      if (item.scheme === schemeItem.value) {
        return {...item, remark: event.target.value}
      }
      return item
    })
    setAssessmentSchemeData(updatedSchemeData)
  }
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = event.target
    if (checked) {
      setCheckedIds([...checkedIds, name])
    } else {
      setCheckedIds(checkedIds.filter((e) => e !== name))
    }
  }
  const handleSelectedAssessments = () => {
    const selectedData = schemeData?.filter((item) => checkedIds?.includes(item?.value))
    console.log()

    setSchemeTable(selectedData)
    setAssessmentPlannerDetails({
      ...assessmentPlannerDetails,
      scheme: selectedData?.map((i) => i?.value),
    })
  }
  useEffect(() => {
    if (selectedDates.length > 0) {
      const validatedDates = validateDates(selectedDates);
      if (validatedDates) {
        console.log("Validated Dates:", validatedDates); // Replace with your logic to use dates
      }
    } else {
      setError(""); // Clear error if no dates are selected
    }
  }, [selectedDates]);
  return (
    <>
      <KTCard>
        <div className='container'>
          <div className='row p-5'>
            <div className='mb-5 col-6'>
              <label className='required form-label'>CAB Organisation</label>

              <Select
                className='react-select-styled react-select-solid '
                name='User'
                options={cabUser}
                classNamePrefix='react-select'
                value={
                  assessmentPlannerDetails?.cab
                    ? {
                        value: assessmentPlannerDetails?.cab,
                        label: cabUser?.find((item) => item.value === assessmentPlannerDetails?.cab)
                          ?.label,
                      }
                    : null
                }
                onChange={(e) => handleCabUserChange(e)}
              />
            </div>
            <div className='mb-5 col-6'>
              <label className='required form-label'>CAB Scheme</label>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder='--'
                value={schemeData
                  ?.filter((item) => checkedIds?.includes(item?.value))
                  ?.map((item) => item?.label)
                  ?.join(', ')}
                onClick={() => setAssessmentTypeModal(true)}
              />
              {/* <Select
                                className='react-select-styled react-select-solid '
                                isMulti
                                name="User"
                                options={schemeData}
                                classNamePrefix='react-select'
                                onChange={(e) => handleCabSchemeChange(e)}

                            /> */}
            </div>
          </div>
          {schemeTable?.length ? (
            <table className='table scheme_table'>
              <thead>
                <tr>
                  <th className='col-6'>Scheme Name</th>
                  <th className='col-3'>Assessment Duration</th>
                  <th className='col-3'>Remark</th>
                </tr>
              </thead>
              <tbody>
                {schemeTable.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={item?.label}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={
                          assessmentSchemeData.find((i) => i.scheme === item.value)
                            ? assessmentSchemeData.find((i) => i.scheme === item.value)
                                .assessment_duration
                            : ''
                        }
                        onChange={(e) => handleSchemeAssetDuration(item, e)}
                      />
                    </td>
                    <td>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={
                          assessmentSchemeData.find((i) => i.scheme === item.value)
                            ? assessmentSchemeData.find((i) => i.scheme === item.value).remark
                            : ''
                        }
                        onChange={(e) => handleSchemeRemark(item, e)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}

          <div className='row p-5'>
            <div className='mb-5 col-5'>
              <label className='required form-label'>Assessment Stage</label>
              <Select
                className='react-select-styled react-select-solid '
                name='User'
                options={assessmentStageList}
                classNamePrefix='react-select'
                onChange={(e) => handleAssessmentStageChange(e)}
                value={
                  assessmentPlannerDetails?.assessment_stage
                    ? {
                        value: assessmentPlannerDetails?.assessment_stage,
                        label: assessmentStageList?.find(
                          (item) => item.value === assessmentPlannerDetails?.assessment_stage
                        )?.label,
                      }
                    : null
                }
                isDisabled={!assessmentPlannerDetails?.cab}
              />
            </div>
            <div className='me-0 mt-7  col-1'>
              <button
                className='btn btn-sm btn-primary '
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                disabled={!assessmentPlannerDetails?.cab}
              >
                +Add
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                data-kt-menu='true'
              >
                <div className='px-7 py-5'>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Stage:</label>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Add New Assessment Stage'
                      value={addNewStage}
                      onChange={(e) => setAddNewStage(e?.target?.value)}
                    />
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='submit'
                      onClick={AddGroup}
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-5 col-6'>
              <label className='required form-label'>Assessment Type</label>
              <Select
                className='react-select-styled react-select-solid '
                name='User'
                options={assessmentList}
                classNamePrefix='react-select'
                onChange={(e) => handleAssessmentTypeChange(e)}
                value={
                  assessmentPlannerDetails?.assessment_type
                    ? {
                        value: assessmentPlannerDetails?.assessment_type,
                        label: assessmentList?.find(
                          (item) => item.value === assessmentPlannerDetails?.assessment_type
                        )?.label,
                      }
                    : null
                }
              />
            </div>
            <div className='mb-5 col-6'>
              <label className='required form-label'>Assessment Number</label>
              <input
                // id='assessment_number'
                type='text'
                className='form-control form-control-solid'
                placeholder='--'
                value={assessmentPlannerDetails?.assessment_number || ''}
                onChange={(e) =>
                  setAssessmentPlannerDetails((prevDetails) => ({
                    ...prevDetails,
                    assessment_number: e.target.value,
                  }))
                }
              />
            </div>
            {assessmentPlannerDetails?.assessment_type &&
        assessmentList?.find(
          (item) => item.value === assessmentPlannerDetails?.assessment_type
        )?.label !== "Document Review" && (
          <div className="col-6 mt-5">
            <label className="form-label">Select Dates</label>
            <DatePicker
              multiple
              value={selectedDates}
              onChange={setSelectedDates}
              calendar={gregorian} // Use Gregorian calendar
              format="YYYY-MM-DD" // Enforce consistent format
              className="form-control form-control-solid"
              showOtherDays // Shows other days in the calendar view
              sort // Sorts selected dates automatically
              render={(value, openCalendar) => (
                <input
                  readOnly
                  className="form-control form-control-solid"
                  placeholder="Select dates"
                  value={value}
                  onClick={openCalendar}
                />
              )}
              numberOfMonths={1} // Show one month at a time
            />
            {error && <div className="text-danger mt-2">{error}</div>}
          </div>
        )}


                </div>
          {/* <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="required form-label">Remark</label>
                            <input
                                type="text" className="form-control form-control-solid" placeholder="--" value={assessmentPlannerDetails?.remark}
                                onChange={(e) => setAssessmentPlannerDetails({ ...assessmentPlannerDetails, remark: e.target.value })
                                }

                            />
                        </div>
                        <div className="mb-5 col-6">
                            <label className="required form-label">Assessment Duration </label>
                            <input
                                type="number" className="form-control form-control-solid" placeholder="--" value={assessmentPlannerDetails?.assessment_duration}
                                onChange={(e) => setAssessmentPlannerDetails({ ...assessmentPlannerDetails, assessment_duration: e.target.value })
                                }

                            />
                        </div>


                    </div> */}

          <div className='row p-5'>
            <div className='mb-5 col-6'>
              <button className='thm-btn' onClick={handleCreateAssessmentPlanner}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </KTCard>
      <NewAssementModal
        show={showNewAssessmentModal}
        handleClose={() => setShowNewAssessmentModal(false)}
        assessmentType={assessmentType}
        setAssessmentType={setAssessmentType}
        handleNewAssessmentType={handleNewAssessmentType}
      />
      {/* assign role modal  */}
      <Modal show={successModal} size='lg' backdrop='static'>
        <Modal.Header>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card-body'>
            <div className='row mb-7'>
              Assessment Planner Created Successfully
              <br />
              Assessment Number:{reponseAssessmentNumber}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>Redirecting in {redirectTimer} seconds...</Modal.Footer>
      </Modal>
      <Modal
        show={assessmentTypeModal}
        onHide={() => setAssessmentTypeModal(false)}
        size='lg'
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Schemes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card-body '>
            <div className='row mb-7'>
              {schemeData?.map((item, index) => (
                <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                  <div className=' d-flex'>
                    <input
                      type='checkbox'
                      name={item.value}
                      checked={checkedIds.includes(item.value)}
                      onChange={handleCheckboxChange}
                      id={`assess_${index}`}
                    />
                    <label
                      className=' '
                      style={{padding: '4px 0 0 14px'}}
                      htmlFor={`assess_${index}`}
                    >
                      {item.label}
                    </label>
                  </div>
                </div>
              ))}
            </div>{' '}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setAssessmentTypeModal(false)}>
            Close
          </Button>
          <Button
            variant='success'
            onClick={() => {
              handleSelectedAssessments()
              setAssessmentTypeModal(false)
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default CreateAssessmentPlannerWrapper
