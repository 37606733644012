import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'

import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
import moment from 'moment'
const API_URL = process.env.REACT_APP_API_URL

const CABNCReport = () => {
  //   const userIdObject = JSON.parse(userId)
  //   const uuid = userIdObject['*']
  //   const uuidRegex =
  //     /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  //   const uuids = uuid.match(uuidRegex)
  //   const urlId = uuids[0]
  //   const navigate = useNavigate()

  const {id} = useParams()

  console.log(id, 'this isncid')

  const [cabNCData, setCabNCData] = useState<any>()
  const [remarkData, setRemarkData] = useState<any[]>([])
  const [evidenceData, setEvidenceData] = useState<any[]>([])

  const getCABNC = async () => {
    try {
      const response = await axios.get(`${API_URL}/nc/management/${id}/patch/`)
      setCabNCData(response?.data)
    } catch (error) {
      console.error(error)
    }
  }

  const getCABremark = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/nc/management/remark/filter/?nc_management=${id}`
      )
      setRemarkData(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const getCABevidence = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/nc/management/evidence/filter/?nc_management=${id}`
      )
      setEvidenceData(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getCABNC()
    getCABremark()
    getCABevidence()
  }, [id])

  const doc = new jsPDF()
  //   const handlebutton = (id) => {
  //     if (id) {
  //       const url = `/apps/cab/accreditation-schedule/${id}`
  //       window.open(url, '_blank')
  //     }
  //   }

  const generatePDF = () => {
    const input = document.getElementById('pdfContent')
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'mm', 'a4')
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()
        const imgProps = pdf.getImageProperties(imgData)
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width

        let heightLeft = imgHeight
        let position = 0

        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight)
        heightLeft -= pdfHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          pdf.addPage()
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight)
          heightLeft -= pdfHeight
        }

        pdf.save('NCReport.pdf')
      })
    } else {
      console.error("Element with id 'pdfContent' not found.")
    }
  }

  const formatMyDate = (date: Date | string | null) => {
    if (!date) return null

    // If the date is a string, parse it to a Date object
    const parsedDate = typeof date === 'string' ? new Date(date) : date

    // Ensure the parsed date is valid
    if (isNaN(parsedDate.getTime())) return null

    const year = parsedDate.getFullYear()
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0')
    const day = String(parsedDate.getDate()).padStart(2, '0')

    return `${month}-${day}-${year}`
  }

  return (
    <div className='row gy-10 gx-xl-10'>
      <div className='col-xl-12'>
        <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer d-flex justify-content-between'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-2'>NC Report</h3>
            </div>
          </div>
          <div className='card-body p-9'>
            <div className='accordion' id='kt_accordion_1'>
              {/* {cabNCData?.map((item, index) => ( */}
              <div className='accordion-item assessment_detail_accordion'>
                <h2 className='accordion-header' id={`kt_accordion_1_header`}>
                  <div
                    className='accordion-button fs-4 fw-bold collapsed w-100 d-flex justify-content-between'
                    data-bs-toggle='collapse'
                    data-bs-target={`#kt_accordion_1_body_${cabNCData?.create_at}`}
                    aria-expanded='false'
                    aria-controls={`kt_accordion_1_body_${cabNCData?.create_at}`}
                  >
                    <span> Created At: {formatMyDate(cabNCData?.create_at)}</span>
                  </div>
                </h2>
                <div
                  id={`kt_accordion_1_body_${cabNCData?.create_at}`}
                  className='accordion-collapse collapse'
                  aria-labelledby={`kt_accordion_1_header_${cabNCData?.create_at}`}
                  data-bs-parent='#kt_accordion_1'
                >
                  <div id='pdfContent' className='accordion-body px-20'>
                    <div className='row'>
                      <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                        <img
                          alt='Logo'
                          src={'/media/logos/uaf_logo.png'}
                          className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
                        />
                        <div className='uaf_title py-5 text-center'>
                          <h2 className=''>
                            NC Report <br />
                          </h2>
                          <span className='fs-18'>
                            United Accreditation Foundation
                            <br />
                            1060 Laskin Road, Suite 12B/13B, Virginia Beach VA 23451  United
                            States of America{' '}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='row gy-10 gx-xl-10'>
                      <div className='col-xl-12'>
                        <div className='card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
                          <table className='table table-bordered'>
                            <tbody>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Assessment No</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.assessment_number}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Assessment Type</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.assessment_name}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Last Updated</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>
                                    {cabNCData?.update_at
                                      ? formatMyDate(new Date(cabNCData?.update_at))
                                      : '--'}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>CAB Organisation</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.registrar_company}</span>
                                </td>
                              </tr>

                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>NCR Number</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.ncr_number}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>NC Status</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.ncr_status_name}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>NC Category</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.category_name}</span>
                                </td>
                              </tr>

                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Standard/Clause No.</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.standard_clause}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Scheme</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.scheme_text}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Team Leader</label>
                                </td>
                                <td className='col-lg-6'>
                                  {cabNCData?.team_leader_list?.map((item) => (
                                    <span key={item?.id}>
                                      {item?.first_name + ' ' + item?.last_name}
                                    </span>
                                  ))}
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Team Member</label>
                                </td>
                                <td className='col-lg-6'>
                                  {cabNCData?.team_member_list?.map((item) => (
                                    <span key={item?.id}>
                                      {item?.first_name + ' ' + item?.last_name}{' '}
                                    </span>
                                  ))}
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>UAF Requirement</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.uaf_requirements}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Non Conformity Observed</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.non_conformity_observed}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Assessor Remark</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.remarks}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div
                            className='mb-10 mt-10 pb-10 text-center'
                            style={{borderBottom: '1px dotted #d3d3d3'}}
                          >
                            <h2>Remarks:</h2>
                            {remarkData.map((item) => (
                              <div key={item?.id} className='mb-10'>
                                <h5>Date: {formatMyDate(item?.create_at)}</h5>
                                {item?.rca_remark && (
                                  <div className='mb-5'>
                                    <label className='form-label'>Root Cause Analysis (RCA)</label>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      placeholder='Enter Root Cause Analysis'
                                      name='rca_remark'
                                      value={item?.rca_remark || ''}
                                      readOnly
                                    />
                                  </div>
                                )}
                                {item?.pc_remark && (
                                  <div className='mb-5'>
                                    <label className='form-label'>
                                      Proposed Correction and Corrective Action
                                    </label>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      placeholder='Enter Proposed Correction and Corrective Action'
                                      name='pc_remark'
                                      value={item?.pc_remark || ''}
                                      readOnly
                                    />
                                  </div>
                                )}
                                {item?.cab_remark && (
                                  <div className='mb-5'>
                                    <label className='form-label'>CAB Remarks</label>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      placeholder='Enter CAB Remarks'
                                      name='cab_remark'
                                      value={item?.cab_remark || ''}
                                      readOnly
                                    />
                                  </div>
                                )}
                                {item?.document_list?.length > 0 && (
                                  <div className='mt-7 mb-5'>
                                    <label className='required form-label'>
                                      Uploaded Document:
                                    </label>
                                    <div className='d-flex flex-wrap gap-3 justify-content-center'>
                                      {item.document_list.map((doc) => (
                                        <div key={doc?.doc_id} className='text-center'>
                                          <a
                                            href={doc?.document}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='d-block'
                                          >
                                            <img src='/media/doc.png' alt='Doc' />
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {item?.assessor_remark && (
                                  <div className='mb-5'>
                                    <label className='form-label'>Assessor Remarks</label>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      placeholder='Enter Assessor Remarks'
                                      name='assessor_remark'
                                      value={item?.assessor_remark || ''}
                                      readOnly
                                    />
                                  </div>
                                )}
                                <span
                                  style={{
                                    backgroundColor: item?.is_assessor_approved ? 'green' : 'red',
                                    color: 'white',
                                    padding: '2px 5px',
                                    borderRadius: '4px',
                                  }}
                                >
                                  Status: {item?.is_assessor_approved ? 'Accepted' : 'Rejected'}
                                </span>
                              </div>
                            ))}
                            {evidenceData.map(
                              (item, index) =>
                                item?.document_list?.length > 0 && (
                                  <div key={index} className='mb-10'>
                                    <h5>Date: {moment(item?.create_at).format('MM/DD/YY')}</h5>
                                    <div className='mt-7 mb-5'>
                                      <label className='form-label'>Uploaded Evidence:</label>
                                      <div className='d-flex flex-wrap gap-3 justify-content-center'>
                                        {item.document_list.map((doc) => (
                                          <div key={doc?.doc_id} className='text-center'>
                                            <a
                                              href={doc?.document}
                                              target='_blank'
                                              rel='noopener noreferrer'
                                              className='d-block'
                                            >
                                              <img src='/media/doc.png' alt='Doc' />
                                            </a>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                    {item?.evidence_remark && (
                                      <div className='mb-5'>
                                        <label className='form-label'>Assessor Remarks:</label>
                                        <textarea
                                          className='form-control form-control-solid'
                                          rows={5}
                                          placeholder='Enter Evidence Remark'
                                          name='evidence_remark'
                                          value={item?.evidence_remark || ''}
                                          readOnly
                                        />
                                       
                                      </div>
                                    )}
                                     {item?.is_assessor_approved && <h5>NC Closed:{moment(item?.update_at).format("MM-DD-YYYY")}</h5>}
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-end m-5'>
                    <button className='btn btn-primary' onClick={generatePDF}>
                      Download
                    </button>
                  </div>
                </div>
              </div>
              {/* ))} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CABNCReport
