import axios from 'axios';
import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify';

type Props={
userId: string | undefined;
ARC_member: string | undefined;
ARC_no: string | undefined;
documentNumber:string |undefined
}
interface DocumentReview {
    id: string;
    review_report_qa: string;
    review_report_qb: string;
    cab: string;
    review: string[];
  }
  type Assessor = {
    id: string;
    first_name: string;
    last_name: string;
  };

  type AssessorData = {
    update_at?: string;
    scheme_name?: string;
    assessment_number?: string;
    assessor_data?: Assessor[];
    assessor_planner_updated_at?: string;

  };

  type DocumentReviewData = {
    assessor_data?: AssessorData[];
    review_report_qa?: string;
    review_report_qb?: string;
  };
const GenerateDr = ({userId ,ARC_member,ARC_no,documentNumber}:Props) => {
    const API_URL = process.env.REACT_APP_API_URL;
  const [documentReview, setDocumentReview] = useState<any>();
  console.log("documentReview:",documentReview);

  const [documentReviewData, setDocumentReviewData] = useState<DocumentReviewData | null>(null);

  const [assessorPlannerDataFromAssessment, setAssessorPlannerDataFromAssessment] = useState<any>([])
  const [documentReviewed, setDocumentReviewed] = useState<any>([]);
  console.log("documentReview:", documentReview);
  console.log("documentReviewData:", documentReviewData);
  const [dreviewreportQa, setDreviewreportQa] = useState<any>()
  const [dreviewreportQb, setDreviewreportQb] = useState<any>()

  const formatMyDate = (date: Date | string | null) => {
    if (!date) return null;
  
    // If the date is a string, parse it to a Date object
    const parsedDate = typeof date === "string" ? new Date(date) : date;
  
    // Ensure the parsed date is valid
    if (isNaN(parsedDate.getTime())) return null;
  
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
  
    return `${month}-${day}-${year}`;
  };
  useEffect(() => {
    if (userId) {
  const getAssessorDataByAssessment = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/cab/filter/scheme/assessor/planner/?cab=${userId}&assessor=&scheme=&assessor_planner=&assessment_type=&assessment_type_name=Document+Review&assessment_number=${documentNumber}&create_at=&update_at=`
      )
      setAssessorPlannerDataFromAssessment(response.data)
    } catch (error) {
      //console.log(error)
    }
  }
  getAssessorDataByAssessment();
}
}, [userId]);
  useEffect(() => {
    if (userId) {
      const getDocumentReview = async () => {
        try {
          const response = await axios.get(`${API_URL}/arc/document/review/get/?cab=${userId}&assessor=${ARC_member}`);
          setDocumentReview(response?.data?.id);
          setDocumentReviewed(response?.data?.review_remark)
          setDreviewreportQa(response?.data?.review_report_qa)
          setDreviewreportQb(response?.data?.review_report_qb)

        } catch (error) {
          console.error('API Error:', error);
          toast.error("problem to fetch CAB Information")
        }
      };
      getDocumentReview();
    }
  }, [userId]);
  // useEffect(() => {
  //   if (documentReview) {
  //     const getDocumentReviewData = async () => {
  //       try {
  //         const response = await axios.get(`${API_URL}/arc/document/review/${documentReview}/get/`);
  //         setDocumentReviewData(response.data);
  //       } catch (error) {
  //         console.error('API Error:', error);
  //         toast.error("problem to fetch document review")
  //       }
  //     };
  //     getDocumentReviewData();
  //   }
  // }, [documentReview]);

  return (
    <div className=' mb-5 mb-xl-10' id='kt_profile_details_view'
    style={{width:"100%",
      background: "#fff",
      padding: "25px",
      borderRadius: "5px"
    }}
    >
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              Document Review Report<span style={{ color: 'red' }}>*</span>
            </h3>
          </div>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                          <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                              <th className='w-10px pe-2 fs-7'> Date(s)</th>
                              <th className='w-10px pe-2 fs-7'>Schemes</th>
                              <th className='w-10px pe-2 fs-7'>Assessment Number</th>
                              <th className='w-10px pe-2 fs-7'>Assessor Name</th>
                            </tr>
                          </thead>

                          <tbody className='text-gray-600 fw-semibold'>
                            {assessorPlannerDataFromAssessment?.map((item) => (
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item && item.update_at ? formatMyDate(item.update_at) : '--'}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.scheme_name}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.assessment_number}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.assessor_name?.map((assessor, index) => (
                                      <span key={assessor[0].id}>
                                        {assessor[0].first_name} {assessor[0].last_name}
                                        {index < item.assessor_name.length - 1 ? ', ' : ''}
                                      </span>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            ))}

                          </tbody>
                        </table>
          </div>
          <div className='row'>
                      <div className='mb-5 col-lg-12'>
                        <h3>Competence verification of Assessment Team for document review, if applicable </h3>
                        <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                          <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                              <th className='w-10px pe-2 fs-7'>Assessor Name</th>
                              <th className='w-10px pe-2 fs-7'>Schemes</th>

                              <th className='w-10px pe-2 fs-7'>Competence Verification </th>
                              <th className='w-10px pe-2 fs-7'>Remarks</th>
                            </tr>
                          </thead>
                          <tbody className='text-gray-600 fw-semibold'>
                            {assessorPlannerDataFromAssessment?.map((item) => (
                              <tr key={item.id}>
                                <td>
                                  <div className="text-gray-800 mb-1">
                                    <ul style={{ listStyle: 'none' }}>
                                      {item?.assessor_name?.map((assessor) => (
                                        <li className="mb-1" key={assessor[0].id}>
                                          {assessor[0].first_name} {assessor[0].last_name}

                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </td>
                                <td>
                                  <div className="text-gray-800 mb-1">
                                  {item?.scheme_name}
                                  </div>
                                </td>
                                <td>
                                  <div className="text-gray-800 mb-1">
                                  Verified
                                  </div>
                                </td>
                                <td>
                                  <div className=" mb-1">


                                        {documentReviewed?.map((reviewItem: any) => {
                                          if (reviewItem.review === item.id) {
                                            return (
                                              <div
                                                key={reviewItem.id}
                                                className="">

                                                {reviewItem.remark || ''}

                                              </div>
                                            );
                                          }
                                          return null;
                                        })}


                                 </div>
                                </td>
                              </tr>
                            ))}

                          </tbody>
                        </table>
                      </div>
                    </div>
          <div className='row mt-10'>
            <div className='mb-5 col-lg-12'>
              <h3>Review of Document Review Report: </h3>
              <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                <thead>
                  <tr className='text-start  fw-bold  text-uppercase gs-0'>
                    <th style={{ width: "50%" }} className='pe-2 fs-7'>Q</th>
                    <th style={{ width: "50%" }} className='pe-2 fs-7'>A</th>

                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold'>
                  <tr>
                    <td ><div className='text-gray-800 mb-1'>
                      Is there sufficient evidence that the nonconformities/findings have been closed or CA Accepted prior to office Assessment?
                    </div></td>
                    <td>
                      <div className='text-gray-800 mb-1'>
                        {dreviewreportQa}

                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td ><div className='text-gray-800 mb-1'>
                      Is a clear recommendation available in the Document review report to proceed for office assessment?
                    </div></td>
                    <td>
                      <div className='text-gray-800 mb-1'>
                        {dreviewreportQb}
                      </div>
                    </td>


                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  )
}

export default GenerateDr