
import React, {useEffect, useState} from 'react'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
import axios from 'axios'
import UAFLoader from '../../../../../loader/UafLoader'
import {Button, Modal} from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { usePermissions } from '../../../../../permission_component/PermissionContext'

const ARCTab = ({urlId}) => {
  const navigate = useNavigate()
  const { assessorPermission, cabPermission, superUserPermission, updatePermissions } = usePermissions();
  const [scheduleRow, setScheduleRow] = useState<any[]>([])
  const [loader, setLoader] = useState<any>(false)
  const [clickValue, setClickValue] = useState<any>()
  const [showInoviceModal, setShowInoviceModal] = useState<boolean>(false)
  const [editValue, setEditValue] = useState<any>();

  const formatMyDate = (date: Date | string | null) => {
    if (!date) return null;
  
    // If the date is a string, parse it to a Date object
    const parsedDate = typeof date === "string" ? new Date(date) : date;
  
    // Ensure the parsed date is valid
    if (isNaN(parsedDate.getTime())) return null;
  
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
  
    return `${month}-${day}-${year}`;
  };

  const fetchData = async () => {
    try {
      setLoader(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/performance/arc/monitor/list/?assessor=${urlId}`
      )
      setScheduleRow(response?.data)
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  }
  useEffect(() => {
    if (urlId) {
      fetchData()
    }
  }, [urlId])
  const generatePDF = () => {
    const input = document.getElementById('pdfContentARC')
    if (input) {
      html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png') // Ensure the MIME type is 'image/png'
          const pdf = new jsPDF('p', 'mm', 'a4')
          const imgProps = pdf.getImageProperties(imgData)
          const pdfWidth = pdf.internal.pageSize.getWidth()
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
          pdf.save('download.pdf')
        })
        .catch((error) => {
          console.error('Error generating canvas:', error)
        })
    } else {
      console.error("Element with id 'pdfContentARC' not found.")
    }
  }
  const handleRemove = async () => {

    try {
      setLoader(true)
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/performance/arc/monitor/destroy/${clickValue}/`
      )
      setLoader(false)
      toast.success("Removed Successfully")
      setShowInoviceModal(false)
      fetchData();
      clickValue(null);
    } catch (error) {
      setLoader(false)
    }

  }
  const editData = async (id) => {
    
    navigate(`/apps/performance-monitoring/arc-member-monitoring/edit/${id}`);
  };
  return (
    <>
      {loader ? <UAFLoader /> : null}
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          {/* <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer d-flex justify-content-between'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>ARC Performance</h3>
            </div>
          </div> */}
          <div className='card-body p-9'>
            <div className='accordion' id='kt_accordion_1'>
              {scheduleRow?.map((item, index) => (
                <div className='accordion-item assessment_detail_accordion' key={index}>
                  <h2 className='accordion-header' id={`kt_accordion_1_header_${index}`}>
                    <div
                      className='accordion-button fs-4 fw-bold collapsed w-100 d-flex justify-content-between'
                      data-bs-toggle='collapse'
                      data-bs-target={`#kt_accordion_1_body_${index}`}
                      aria-expanded='false'
                      aria-controls={`kt_accordion_1_body_${index}`}
                    >
                      <span> Created At: {formatMyDate(item?.create_at)}</span>

                      {superUserPermission?.is_superuser === true && (
                        <>

                      <span style={{marginLeft:'auto'}}>
                        <button
                          className='thm-btn'
                          
                          onClick={() => {
                            setClickValue(item?.id)
                            setShowInoviceModal(true)
                          }}
                        >
                          Remove
                        </button>
                      </span>
                      <span style={{margin:'2px 5px'}} >
                        <button
                          className='thm-btn'
                          
                          onClick={() => {
                            setEditValue(item?.id)
                            // setShowInoviceModal(true)
                             editData(item?.id);
                          }}
                        >
                          Edit
                        </button>
                      </span>
                      </>)}
                    </div>
                  </h2>
                  <div
                    id={`kt_accordion_1_body_${index}`}
                    className='accordion-collapse collapse'
                    aria-labelledby={`kt_accordion_1_header_${index}`}
                    data-bs-parent='#kt_accordion_1'
                  >
                    <div id='pdfContentARC' className='accordion-body px-20'>
                      <div className='row'>
                        <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                          <img
                            alt='Logo'
                            src={'/media/logos/uaf_logo.png'}
                            className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
                          />
                          <div className='uaf_title py-5 text-center'>
                            <h2 className=''>
                              Application Reviewer Performance
                              <br />
                              Issued by
                            </h2>
                            <span className='fs-18'>
                              United Accreditation Foundation
                              <br />
                              400, North Center Dr, STE 202, Norfolk, VA 23502, United States of
                              America{' '}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='row gy-10 gx-xl-10'>
                        <div className='col-xl-12'>
                          <div
                            className='card-xl-stretch mb-5 mb-xl-10'
                            id='kt_profile_details_view'
                          >
                            <div
                              className='mb-10 mt-10  pb-10 '
                              style={{borderBottom: '1px dotted #d3d3d3'}}
                            >
                              <h4>DATED:{formatMyDate(item?.monitor_date)}</h4>
                              <h4>NAME:{item?.assessor_data?.name}</h4>
                              <h4>DESIGNATION:ARC Member</h4>
                            </div>
                            <table className='table table-bordered'>
                              <tbody>
                                <tr>
                                  <th className='form-label'>S.NO.</th>
                                  <th className='form-label'>PARAMETERS </th>
                                  <th className='form-label'>SATISFACTORY / NEEDS IMPROVEMENT</th>
                                </tr>

                                <tr>
                                  <td className='col-lg-1'>1</td>
                                  <td className='col-lg-4'>
                                    <label className='form-label'>
                                      Knowledge of UAF accreditation’s rules and processes
                                    </label>
                                  </td>
                                  <td className='col-lg-7'>
                                    <span>{item?.accreditation_rules}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='col-lg-1'>2</td>
                                  <td className='col-lg-4'>
                                    <label className='form-label'>
                                      Knowledge of assessment principles, practices and techniques
                                    </label>
                                  </td>
                                  <td className='col-lg-7'>
                                    <span>{item?.assessment_principle}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='col-lg-1'>3</td>
                                  <td className='col-lg-4'>
                                    <label className='form-label'>
                                      Knowledge of general management system principles and tools
                                    </label>
                                  </td>
                                  <td className='col-lg-7'>
                                    <span>{item?.management_principles}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='col-lg-1'>4</td>
                                  <td className='col-lg-4'>
                                    <label className='form-label'>
                                      Knowledge of accreditation relevant guidance documents
                                      (UAF-GEN-CAB-01 & UAF-GEN-CAB-02) and application documents
                                    </label>
                                  </td>
                                  <td className='col-lg-7'>
                                    <span>{item?.relevant_guidance}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='col-lg-1'>5</td>
                                  <td className='col-lg-4'>
                                    <label className='form-label'>
                                      Knowledge of conformity assessment scheme requirements other
                                      procedures and methods used by the conformity assessment body
                                    </label>
                                  </td>
                                  <td className='col-lg-7'>
                                    <span>{item?.conformity_assessment}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='col-lg-1'>6</td>
                                  <td className='col-lg-4'>
                                    <label className='form-label'>
                                      Knowledge of risk based assessment principles
                                    </label>
                                  </td>
                                  <td className='col-lg-7'>
                                    <span>{item?.risk_assessment}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='col-lg-1'>7</td>
                                  <td className='col-lg-4'>
                                    <label className='form-label'>
                                      Knowledge of general regulatory requirements related to the
                                      conformity assessment activities.
                                    </label>
                                  </td>
                                  <td className='col-lg-7'>
                                    <span>{item?.general_requirement}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='col-lg-1'>8</td>
                                  <td className='col-lg-4'>
                                    <label className='form-label'>
                                      Evaluating the outcomes of the assessment and report writing{' '}
                                    </label>
                                  </td>
                                  <td className='col-lg-7'>
                                    <span>{item?.outcomes}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='col-lg-1'>9</td>
                                  <td className='col-lg-4'>
                                    <label className='form-label'>
                                      Time frame adherence as per UAF policies{' '}
                                    </label>
                                  </td>
                                  <td className='col-lg-7'>
                                    <span>{item?.time_frame}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='col-lg-1'>10</td>
                                  <td className='col-lg-4'>
                                    <label className='form-label'>
                                      Bring out issues /comments{' '}
                                    </label>
                                  </td>
                                  <td className='col-lg-7'>
                                    <span>{item?.issues}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <div
                              className='mb-10 mt-10 pb-10 text-center'
                              style={{borderBottom: '1px dotted #d3d3d3'}}
                            >
                              <h2 className=''>Remark: </h2>
                              <span className='mt-10 mb-20'>{item?.remark}</span>
                            </div>

                            <div className=' mt-10 text-end'>
                              <h2 style={{margin: '0 190px 0 0'}}>
                                Monitoring Done by:{item?.evaluator_name}{' '}
                              </h2>
                              <img src={item?.signature} alt='ARC Member Signature' />
                            </div>
                            <div className='mt-0 text-end'>
                              <h2 style={{margin: '0 120px 0 0'}}>
                                {formatMyDate(item?.update_at)}{' '}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='text-end m-5'>
                      <button className='btn btn-primary' onClick={generatePDF}>
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <Modal show={showInoviceModal} onHide={() => setShowInoviceModal(false)} backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>Invoice Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-6'>
            <label className='col-lg-12 col-form-label fw-bold fs-6'>
              Do You Want To Remove it ?
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
           onClick={handleRemove}
          >
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ARCTab
