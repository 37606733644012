import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../../_metronic/helpers'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'

import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
import UAFLoader from '../../../../../loader/UafLoader'
import NumberToWord from '../../../../invoice-module/NumberTOWord'
const API_URL = process.env.REACT_APP_API_URL
type Props = {
  show: boolean
  handleClose: () => void
  showAssessentClicked: any
  approval: () => void
}
const AssessmentPlannerModal = ({show, handleClose, showAssessentClicked, approval}: Props) => {
  interface FormData {
    cab: string
    assessment?: any
    sub_total_pre_taxes: any
    taxes_if_any: any
    grand_total: any
    net_payable_amount: any
    net_amount_invoice: any
    assessor_planner: any
    any_other_charges: any
    remarks: any
    invoice: any
    pdf_invoice: File | null
  }
  const initialFormData: FormData = {
    cab: '',
    assessment: [],
    sub_total_pre_taxes: '',
    taxes_if_any: 0,
    grand_total: 0,
    net_payable_amount: 0,
    net_amount_invoice: 0,
    assessor_planner: '',
    any_other_charges: 0,
    remarks: '',
    invoice: '',
    pdf_invoice: null,
  }
  interface AccData {
    Ac_with_institution_bic: string
    account_name: string
    account_number: string
    ach_routing_no: string
    bank_address: string
    id: string
    wire_routing_no: string
  }
  const initialAccData: AccData = {
    Ac_with_institution_bic: '',
    account_name: '',
    account_number: '',
    ach_routing_no: '',
    bank_address: '',
    id: '',
    wire_routing_no: '',
  }

  const navigate = useNavigate()
  const [formData, setFormData] = useState<FormData>(initialFormData)
  const [loader, setLoader] = useState<boolean>(false)
  console.log(showAssessentClicked, 'showAssessentClicked')
  const [assessment, setAssessment] = useState<any>()
  const [showPreview, setShowPreview] = useState(false)
  const [userData, setUserData] = useState<any>()
  const [accountDetails, setAccountDetails] = useState<AccData>(initialAccData)
  const [totalAssessorDay, setTotalAssessorDay] = useState<string>('')
  const [assessorDayFee, setAssessorDayFee] = useState<any>('')
  const [totalAmountUsd, setTotalAmountUsd] = useState<string>('')
  console.log(userData, 'userData')
  const getBankList = () => {
    axios
      .get(`${API_URL}/cab/bank/list/`)
      .then((response) => {
        const result = response.data?.[0]
        const data = {
          Ac_with_institution_bic: result?.Ac_with_institution_bic,
          account_name: result?.account_name,
          account_number: result?.account_number,
          ach_routing_no: result?.ach_routing_no,
          bank_address: result?.bank_address,
          id: result?.id,
          wire_routing_no: result?.wire_routing_no,
        }
        setAccountDetails(data)
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }
  const handleUpdateBankList = () => {
    const {id, ...accData} = accountDetails
    axios
      .put(`${API_URL}/cab/bank/${id}/update/`, accData)
      .then((response) => {
        getBankList()
        console.log('PUT request successful:', response.data)
      })
      .catch((error) => {
        console.error('Error sending PUT request:', error)
      })
  }
  useEffect(() => {
    getBankList()
  }, [])
  const validateForm = () => {
    if (!assessorDayFee || assessorDayFee.trim() === '') {
      toast.error('Please Enter assessor day fee.')
      return false
    }
    if (!formData.invoice || formData.invoice.trim() === '') {
      toast.error('Please Enter Invoice Number.')
      return false
    }
    // if (!formData.taxes_if_any || formData.taxes_if_any.trim() === '') {
    //   toast.error('Please Enter Other taxes.')
    //   return false
    // }
    if (!formData.grand_total || formData.grand_total.trim() === '') {
      toast.error('Please Enter Grand Total.')
      return false
    }
    // if (!formData.net_payable_amount || formData.net_payable_amount.trim() === '') {
    //   toast.error('Please Enter Net Payable on account of this Invoice.')
    //   return false
    // }
    // if (!formData.net_amount_invoice || formData.net_amount_invoice.trim() === '') {
    //   toast.error('Please Enter Net Amount of Invoice.')
    //   return false
    // }
    if (!accountDetails.account_name || accountDetails.account_name.trim() === '') {
      toast.error('Please Enter Account Name.')
      return false
    }
    if (!accountDetails.bank_address || accountDetails.bank_address.trim() === '') {
      toast.error('Please Enter Bank Address.')
      return false
    }
    if (!accountDetails.account_number || accountDetails.account_number.trim() === '') {
      toast.error('Please Enter Account Number.')
      return false
    }
    if (!accountDetails.ach_routing_no || accountDetails.ach_routing_no.trim() === '') {
      toast.error('Please Enter ACH Routing Number.')
      return false
    }
    if (!accountDetails.wire_routing_no || accountDetails.wire_routing_no.trim() === '') {
      toast.error('Please Enter Wire Routing Number.')
      return false
    }
    if (
      !accountDetails.Ac_with_institution_bic ||
      accountDetails.Ac_with_institution_bic.trim() === ''
    ) {
      toast.error('Please Enter A/c with Institution BIC.')
      return false
    }
    return true
  }
  // const handleEstimationData = async () => {
  //   if (!validateForm()) return
  //   const data = formData
  //   data.cab = showAssessentClicked?.cab
  //   data.sub_total_pre_taxes =
  //     showAssessentClicked?.schemeData
  //       ?.filter((i) => i?.assessment_duration !== undefined && i?.assessment_duration !== null)
  //       ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) * assessorDayFee
  //   data.any_other_charges = formData?.any_other_charges
  //   data.remarks = formData?.remarks
  //   data.taxes_if_any = formData?.taxes_if_any
  //   data.grand_total = formData?.grand_total
  //   data.net_payable_amount = formData?.net_payable_amount
  //   data.assessor_planner = showAssessentClicked?.id
  //   data.net_amount_invoice = formData?.net_amount_invoice
  //   data.assessment = [
  //     {
  //       assessment: showAssessentClicked?.assessment_type,
  //       remark: showAssessentClicked?.remark,
  //       total_assessor_day: showAssessentClicked?.schemeData
  //         ?.filter((i) => i?.assessment_duration !== undefined && i?.assessment_duration !== null)
  //         ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0),
  //       assessor_day_fee: assessorDayFee,
  //       total_amount_usd:
  //         showAssessentClicked?.schemeData
  //           ?.filter((i) => i?.assessment_duration !== undefined && i?.assessment_duration !== null)
  //           ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) * assessorDayFee,
  //     },
  //   ]

  //   try {
  //     const response = await axios.post(`${API_URL}/cab/send/assessment/estimation/`, data)

  //     toast.success('Assessment Invoice sent successfully.')
  //     GenerateMasterFormLink(response?.data?.id)
  //     setFormData(initialFormData)
  //     handleClose()
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  const handleEstimationData = async () => {
    if (!validateForm()) return;
  
    const data = {
      ...formData, // Spread the form data into the object
      cab: showAssessentClicked?.cab,
      sub_total_pre_taxes: showAssessentClicked?.schemeData
        ?.filter((i) => i?.assessment_duration !== undefined && i?.assessment_duration !== null)
        ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) * assessorDayFee,
      any_other_charges: formData?.any_other_charges,
      remarks: formData?.remarks,
      taxes_if_any: formData?.taxes_if_any,
      grand_total: formData?.grand_total,
      net_payable_amount: formData?.net_payable_amount,
      assessor_planner: showAssessentClicked?.id,
      net_amount_invoice: formData?.net_amount_invoice,
      assessment: [
        {
          assessment: showAssessentClicked?.assessment_type,
          remark: showAssessentClicked?.remark,
          total_assessor_day: showAssessentClicked?.schemeData
            ?.filter((i) => i?.assessment_duration !== undefined && i?.assessment_duration !== null)
            ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0),
          assessor_day_fee: assessorDayFee,
          total_amount_usd: showAssessentClicked?.schemeData
            ?.filter((i) => i?.assessment_duration !== undefined && i?.assessment_duration !== null)
            ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) * assessorDayFee,
        },
      ],
    };
  
    // Use FormData for file upload
    const formDataToSend = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'pdf_invoice' && formData?.pdf_invoice instanceof File) {
        formDataToSend.append(key, formData.pdf_invoice); // Append file
      } else if (key === 'assessment') {
        formDataToSend.append(key, JSON.stringify(data[key])); // Stringify nested object
      } else {
        formDataToSend.append(key, data[key]);
      }
    });
  
    try {
      setLoader(true)
      const response = await axios.post(`${API_URL}/cab/send/assessment/estimation/`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file upload
        },
      });
      if(response?.status === 200 || response?.status === 201 ){
        setLoader(false)
        toast.success('Invoice sent successfully.');
        GenerateMasterFormLink(response?.data?.id);
        setFormData(initialFormData);
      }
      
    } catch (error) {
      console.log(error);
      setLoader(false)
      toast.error('Assessment Invoice failed to send.');
    }
  };
  const handleSendEstimation = () => {
    handleEstimationData()
    handleUpdateBankList()
  }
  const GenerateMasterFormLink = (id) => {
    axios
      .get(
        `${API_URL}/cab/send/estimation/assessment/expire/link/${showAssessentClicked?.cab}/${id}/`
      )
      .then((response) => {
        const result = response.data
        console.log(result, 'result')
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }
  const generatePDF = () => {
    const input = document.getElementById('pdfContent')
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'mm', 'a4')
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()

        const imgProps = pdf.getImageProperties(imgData)
        const imgWidth = pdfWidth - 20
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width

        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight)
        pdf.save(`Assessment Fee Invoice`)
        // const pdfBlob = pdf.output('blob')

        // Save Blob in state
        // setPdfFile(pdfBlob)
      })
    } else {
      console.error("Element with id 'pdfContent' not found.")
    }
  }
  const CreatePDF = async (): Promise<File> => {
    return new Promise((resolve, reject) => {
      const input = document.getElementById('pdfContent')
      if (input) {
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'mm', 'a4')
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = pdf.internal.pageSize.getHeight()

            const imgProps = pdf.getImageProperties(imgData)
            const imgWidth = pdfWidth - 20
            const imgHeight = (imgProps.height * imgWidth) / imgProps.width

            pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight)
            const pdfBlob = pdf.output('blob')
             const file = new File([pdfBlob], 'assessment_fee.pdf', {type: 'application/pdf'})
            resolve(file) // Return the file
          })
          .catch((error) => {
            console.error('Error creating PDF:', error)
            reject(error)
          })
      } else {
        console.error("Element with id 'pdfContent' not found.")
        reject(new Error("Element with id 'pdfContent' not found."))
      }
    })
  }
  const handleSaveAndSubmit = async () => {
    try {
      // Await the PDF generation to get the resolved File object
      const pdfFile = await CreatePDF();
  
      // Update formData state with the resolved pdfFile
      setFormData((prevFormData) => ({
        ...prevFormData,
        pdf_invoice: pdfFile, // Now pdf_invoice is a File, not a Promise
      }));
    } catch (error) {
      console.error('Error during Save & Submit:', error);
    }
  };
   useEffect(() => {
      if (formData?.pdf_invoice) {
        console.log(formData?.pdf_invoice)
        handleEstimationData();
      }
    }, [formData?.pdf_invoice]);

 useEffect(() => {
    // Calculate total assessor days
    const totalAssessorDays = showAssessentClicked?.schemeData
      ?.filter(
        (i) =>
          i?.assessment_duration !== undefined && i?.assessment_duration !== null
      )
      ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) || 0;

    // Calculate grand total
    const grandTotal =
      totalAssessorDays * assessorDayFee +
      parseFloat(formData.any_other_charges || 0) +
      parseFloat(formData.taxes_if_any || 0);

    setFormData((prev) => ({
      ...prev,
      grand_total: grandTotal.toFixed(2), // Format to 2 decimal places
    }));
  }, [showAssessentClicked, assessorDayFee, formData.any_other_charges, formData.taxes_if_any]);


  const formatMyDate = (date: Date | string | null) => {
    if (!date) return null;
  
    // If the date is a string, parse it to a Date object
    const parsedDate = typeof date === "string" ? new Date(date) : date;
  
    // Ensure the parsed date is valid
    if (isNaN(parsedDate.getTime())) return null;
  
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
  
    return `${month}-${day}-${year}`;
  };





  return (
    <>
    {loader ? <UAFLoader /> : null}
      <Modal
        id='assessment-fee'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        show={show}
        onHide={handleClose}
        backdrop={true}
      >
        <div className='modal-header'>
          <h2>Assessment Fee Invoice</h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTIcon className='fs-1' iconName='cross' />
          </div>
          {/* end::Close */}
        </div>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='container'>
            <div className='card mb-5 mb-xl-10'>
              <div className='card-header cursor-pointer app_fee_card'>
                <div className='card-title m-0 d-flex justify-content-between align-items-center w-100'>
                  <h4 className='fw-bolder m-0'>
                    Invoice Number:{' '}
                    <input
                      style={{marginTop: '10px', width: '50%'}}
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Enter Invoice Number'
                      value={formData?.invoice}
                      onChange={(e) => setFormData({...formData, invoice: e?.target?.value})}
                    />
                  </h4>
                  <h4 className='fw-bolder m-0'>
                    Dated:{' '}
                    <span className='inline_master_head'>
                      {formatMyDate(new Date(showAssessentClicked?.create_at))}
                    </span>
                  </h4>
                </div>
                <div className='card-title m-0 d-flex justify-content-start align-items-center w-100'>
                  <span className='fs-18 pt-5'>
                    <strong>CAB Organisation: </strong>
                    {showAssessentClicked?.cab_company}
                  </span>
                </div>
              </div>
              <div className='card-body px-9 py-5'>
              <table className="table align-middle table-row-dashed fs-6 gy-5 app_fee_form">
      <thead>
        <tr className="text-start fw-bold text-uppercase gs-0">
          <th className="max-w-100px"></th>
          <th className="max-w-100px">Total Assessor days</th>
          <th className="max-w-100px">Assessor day Fee ($)</th>
          <th className="max-w-100px">Total Amount USD($)</th>
        </tr>
      </thead>
      <tbody className="text-gray-600 fw-semibold">
        <tr>
          <td>
            <div className="d-flex gap-5">
              <span className="min-w-150px d-flex align-items-center">
                <strong>{showAssessentClicked?.assessment_name}</strong>
              </span>
            </div>
          </td>
          <td>
            <input
              type="number"
              className="form-control form-control-solid"
              placeholder="--"
              value={showAssessentClicked?.schemeData
                ?.filter(
                  (i) =>
                    i?.assessment_duration !== undefined && i?.assessment_duration !== null
                )
                ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) || 0}
              readOnly
            />
          </td>
          <td>
            <input
              type="number"
              className="form-control form-control-solid"
              placeholder="--"
              value={assessorDayFee}
              onChange={(e) => setAssessorDayFee(e.target.value)}
            />
          </td>
          <td>
            <input
              type="number"
              className="form-control form-control-solid"
              placeholder="--"
              value={
                showAssessentClicked?.schemeData
                  ?.filter(
                    (i) =>
                      i?.assessment_duration !== undefined &&
                      i?.assessment_duration !== null
                  )
                  ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) *
                assessorDayFee || 0
              }
              readOnly
            />
          </td>
        </tr>
        <tr>
          <td>Sub Total Pre Taxes</td>
          <td>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder="--"
              value={
                showAssessentClicked?.schemeData
                  ?.filter(
                    (i) =>
                      i?.assessment_duration !== undefined &&
                      i?.assessment_duration !== null
                  )
                  ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) *
                assessorDayFee || 0
              }
              readOnly
            />
          </td>
        </tr>
        <tr>
          <td>Any Other Charges</td>
          <td>
            <input
              type="number"
              className="form-control form-control-solid"
              placeholder="--"
              value={formData.any_other_charges}
              onChange={(e) =>
                setFormData({ ...formData, any_other_charges: e.target.value })
              }
            />
          </td>
        </tr>
        <tr>
          <td>Taxes, if any</td>
          <td>
            <input
              type="number"
              className="form-control form-control-solid"
              placeholder="--"
              value={formData.taxes_if_any}
              onChange={(e) =>
                setFormData({ ...formData, taxes_if_any: e.target.value })
              }
            />
          </td>
        </tr>
        <tr>
          <td>Grand Total</td>
          <td>
            <input
              type="number"
              className="form-control form-control-solid"
              placeholder="--"
              value={formData.grand_total}
              readOnly
            />
          </td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
              </div>
            </div>
            <div className='card mb-5 mb-xl-10'>
              <div className='card-header cursor-pointer app_fee_card'>
                <div className='card-title m-0 d-flex justify-content-start align-items-center w-100'>
                  <span className='fs-18'>International Transfers can be made to the:</span>
                </div>
              </div>
              <div className='card-body px-9 py-5'>
                <div className='mb-5'>
                  <label className=' form-label'>Account Name: United Accreditation Foundation Inc.</label>
                  {/* <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Enter Account Name'
                    value={accountDetails?.account_name}
                    onChange={(e) =>
                      setAccountDetails({...accountDetails, account_name: e?.target?.value})
                    }
                  /> */}
                </div>
                <div className='mb-5'>
                  <label className=' form-label'>Bank of America, N.A., New York, NY , United States, 901796 ,</label>
                  {/* <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Enter Address'
                    value={accountDetails?.bank_address}
                    onChange={(e) =>
                      setAccountDetails({...accountDetails, bank_address: e?.target?.value})
                    }
                  /> */}
                </div>
                <div className='mb-5'>
                  <label className=' form-label'>Account Number: 435036712144</label>
                  {/* <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Enter Account Number'
                    value={accountDetails?.account_number}
                    onChange={(e) =>
                      setAccountDetails({...accountDetails, account_number: e?.target?.value})
                    }
                  /> */}
                </div>
                <div className='mb-5'>
                  <label className=' form-label'>ACH ROUTING NO. 051000017,</label>
                  {/* <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Enter ACH ROUTING NO.'
                    value={accountDetails?.ach_routing_no}
                    onChange={(e) =>
                      setAccountDetails({...accountDetails, ach_routing_no: e?.target?.value})
                    }
                  /> */}
                </div>
                <div className='mb-5'>
                  <label className=' form-label'>WIRE ROUTING NO: 026009593,</label>
                  {/* <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Enter WIRE ROUTING NO'
                    value={accountDetails?.wire_routing_no}
                    onChange={(e) =>
                      setAccountDetails({...accountDetails, wire_routing_no: e?.target?.value})
                    }
                  /> */}
                </div>
                <div className='mb-5'>
                  <label className=' form-label'>A/c with Institution BIC: BOFAUS3N.</label>
                  {/* <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Enter A/c with Institution BIC'
                    value={accountDetails?.Ac_with_institution_bic}
                    onChange={(e) =>
                      setAccountDetails({
                        ...accountDetails,
                        Ac_with_institution_bic: e?.target?.value,
                      })
                    }
                  /> */}
                </div>
                <div className='mb-5'>
                  <label htmlFor='remarks' className='form-label'>
                  Being a computer generated document, signature is not required									
                  </label>
                  {/* <textarea
                    id='remarks'
                    rows={3}
                    className='form-control form-control-solid'
                    placeholder='Remarks'
                    onChange={(e) => setFormData({...formData, remarks: e?.target?.value})}
                  /> */}
                </div>
              </div>
            </div>
            <div className='d-flex gap-5'>
              <button
                className='btn btn-warning'
                onClick={() => setShowPreview(true)} /* onClick={handleSendEstimation} */
              >
                Preview
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={showPreview} onHide={() => setShowPreview(false)} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Preview Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id='pdfContent'>
            <div className=''>
              <div className='' style={{border: '1px solid #333'}}>
                <div className='d-flex'>
                  <div className=''>
                    <img
                      alt='Logo'
                      src={'/media/logos/uaf_logo.png'}
                      className='p-1  app-sidebar-logo-default theme-light-show'
                      style={{
                        width: '250px',
                        height: '120px',
                        border: '1px solid #333',
                      }}
                    />
                  </div>
                  <div className='text-center' style={{border: '1px solid #333'}}>
                    <h2 style={{padding: '35px 120px 0px 121px'}}>
                      UNITED ACCREDITATION FOUNDATION INC,<br />  1060 LASKIN ROAD, SUITE 12B/13B, VIRGINIA
                      BEACH, VA 23451
                    </h2>
                  </div>
                </div>
                <div className='row' style={{padding: '0px 10px 0px 10px', fontSize: '18px'}}>
                  <div className='col-3' style={{border: '1px solid #333'}}>
                    <div className='p-2'>Invoice Number </div>
                  </div>
                  <div className='col-3' style={{border: '1px solid #333'}}>
                    <div className='p-2'>{formData?.invoice}</div>
                  </div>
                  <div className='col-3' style={{border: '1px solid #333'}}>
                    <div className='p-2'>Dated</div>
                  </div>
                  <div className='col-3' style={{border: '1px solid #333'}}>
                    <div className='p-2'>
                      {' '}
                      {formatMyDate(new Date(showAssessentClicked?.create_at))}
                    </div>
                  </div>
                </div>
                <div style={{padding: '0px 10px 0px 10px', border: '1px solid #333'}}>
                  <div className='row text-center' style={{fontSize: '25px'}}>
                    <div className='' style={{padding: '20px 0px 20px 0px'}}>
                      <h2 style={{fontSize: '25px'}}>{showAssessentClicked?.cab_company}</h2>
                      <span style={{fontSize: '18px'}}>{showAssessentClicked?.cab_address}</span>
                    </div>
                  </div>
                </div>
                <div style={{padding: '0px 10px 0px 10px', border: '1px solid #333'}}>
                  <div className='row text-center' style={{fontSize: '18px'}}>
                    <div className='' style={{padding: '20px 0px 20px 0px'}}>
                      <h2>Assessment Invoice Fee</h2>
                      <span>{showAssessentClicked?.assessment_number}</span>
                    </div>
                  </div>
                </div>
                <div className='table-responsive'>
                  <table
                    className=' table align-middle table-row-dashed fs-6 gy-5 app_fee_form'
                    style={{border: '1px solid #333', marginBottom: 0}}
                  >
                    <thead>
                      {/* <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'> */}
                      <tr className='text-start  fw-bold  text-uppercase gs-0'>
                        <th className='min-w-20px ' style={{paddingLeft: '20px'}}>
                          S.No
                        </th>
                        <th className='min-w-50px'>Details</th>
                        <th className='min-w-50px'>Total Assessor days</th>
                        <th className='min-w-50px'>Assessor day Fee ($)</th>
                        <th className='min-w-50px'>Total Amount USD($)</th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-semibold'>
                      <tr>
                        <td style={{paddingLeft: '25px'}}>1</td>
                        <td>{showAssessentClicked?.assessment_name}</td>

                        <td>
                          {showAssessentClicked?.schemeData
                            ?.filter(
                              (i) =>
                                i?.assessment_duration !== undefined &&
                                i?.assessment_duration !== null
                            )
                            ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0)}
                        </td>
                        <td>{assessorDayFee}</td>
                        <td>
                          {showAssessentClicked?.schemeData
                            ?.filter(
                              (i) =>
                                i?.assessment_duration !== undefined &&
                                i?.assessment_duration !== null
                            )
                            ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) *
                            assessorDayFee}
                        </td>
                      </tr>

                      <tr>
                        <td style={{paddingLeft: '25px'}}>2</td>
                        <td>Sub Total Pre Taxes,</td>
                        <td></td>
                        <td></td>
                        <td>
                          {showAssessentClicked?.schemeData
                            ?.filter(
                              (i) =>
                                i?.assessment_duration !== undefined &&
                                i?.assessment_duration !== null
                            )
                            ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) *
                            assessorDayFee}
                        </td>
                      </tr>
                      <tr>
                        <td style={{paddingLeft: '25px'}}>3</td>
                        <td>Any Other Charges </td>
                        <td></td>
                        <td></td>
                        <td>{formData?.any_other_charges}</td>
                      </tr>
                      <tr>
                        <td style={{paddingLeft: '25px'}}>4</td>
                        <td>Taxes, if any</td>
                        <td></td>
                        <td></td>
                        <td>{formData?.taxes_if_any}</td>
                      </tr>
                      <tr>
                        <td style={{paddingLeft: '25px'}}>5</td>
                        <td>Grand Total ($) </td>
                        <td></td>
                        <td></td>
                        <td>{formData?.grand_total}</td>
                      </tr>
                      {/* <tr>
                        <td style={{paddingLeft: '25px'}}>6</td>

                        <td>Net Payable on account of this Invoice </td>
                        <td></td>
                        <td></td>
                        <td>{formData?.net_payable_amount}</td>
                      </tr> */}
                      <tr>
                        <td style={{paddingLeft: '25px'}}>7</td>
                        <td colSpan={2}>Net Amount of Invoice USD </td>
                        <td>{NumberToWord(Math.round(formData.grand_total))}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    className='card-title m-0 justify-content-between align-items-center w-100 px-4 py-5'
                    style={{border: '1px solid #333'}}
                  >
                    <h4 className='fw-bolder m-0 pb-2'>International Transfers can be made to the:</h4>
                    <p className='inline_master_head m-0'>Account Name: United Accreditation Foundation Inc. </p>
                    <p className='inline_master_head m-0'>Bank of America, N.A., New York, NY , United States, 901796 , </p>
                    <p className='inline_master_head m-0'>Account Number: 435036712144 </p>
                    <p className='inline_master_head m-0'>ACH ROUTING NO. 051000017,</p>
                    <p className='inline_master_head m-0'>WIRE ROUTING NO: 026009593,</p>
                    <p className='inline_master_head m-0'>A/c with Institution BIC: BOFAUS3N.</p>
                    <p className='inline_master_head m-0'>Being a computer generated document, signature is not required</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowPreview(false)}>
            Close
          </Button>
          {/* <Button
         variant='primary'
         onClick={async () => {
           await CreatePDF()
           handleEstimationData()
           // test()
         }}
       >
         Save & Submit
       </Button> */}
          <Button variant='primary' onClick={handleSaveAndSubmit}>
            Save & Submit
          </Button>

          <Button variant='info' onClick={generatePDF}>
            Download as PDF
          </Button>
          {/* {pdfFile && (
     <a
       href={URL.createObjectURL(pdfFile)}
       download="Assessment_Fee_Invoice.pdf"
     >
       Download PDF
     </a>
   )} */}
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default AssessmentPlannerModal
