import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import axios from 'axios'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import UAFLoader from '../../loader/UafLoader'
import {useNavigate} from 'react-router-dom'
import Select, {ActionMeta, SingleValue} from 'react-select'
import _ from 'lodash'
import {usePermissions} from '../../permission_component/PermissionContext'
const API_URL = process.env.REACT_APP_API_URL

interface DataItem {
  id: string
  name: string
  short_name: string
  assessment_number: string
  cab: string
}
interface CategoryItem {
  id: string
  name: string
}
interface StatusItem {
  id: string
  name: string
}
// interface AssessmentItem {
//   id: string
//   assessment_number: string
//   cab_id: string
// }
interface CabInterface {
  id: string
  programme_names: string[]
  scheme_names: {name: string; id: string; short_name: string}[]
  // standard_names: {name: string; id: string}[]
  assessor_names: {first_name: string; id: string; last_name: string}[]

  first_name: string
  last_name: string
  email: string
  phone: string
  company: string

  director: string
  certification_manager: string

  remark: string
  graduation_date: string | null
  cab_profile: string | null
  profile: string | null
  address: string | null
  store_password: string
  date_joined: string
  is_active: boolean
  img: string | null
  is_cab_user: boolean
  is_cab_team: boolean

  certification: any[]
  assessment: any[]
  team: any[]

  assign_user: any[]
}
interface FormState {
  assessment: string
  registrar: string
  cab_id: string
  uaf_requirement: string
  non_conformity: string
  scheme: string
  category: string
  standard_clause: string
  ncr_number: string
  ncr_status: string
  action_status: string

  evidence: File[]
  certification: string[]
  evidence_written: string
  email: string[]
  cab_rep: string
  leader: {first_name: string; id: string; last_name: string}[]
  team_member: string[]
  remark: string
}

interface NCData {
  id: string
  assessor_planner__assessment_number: string
  assessment_number: string
  cab: string
}

const NCManagementForm = () => {
  const [value, setValue] = useState<FormState>({
    assessment: '',
    registrar: '',
    cab_id: '',
    uaf_requirement: '',
    non_conformity: '',
    scheme: '',
    action_status: '',
    category: '',
    standard_clause: '',
    ncr_number: '',
    ncr_status: '',
    team_member: [],
    evidence_written: '',
    email: [],
    cab_rep: '',
    leader: [],
    evidence: [],
    certification: [],
    remark: '',
  })
  const {assessorPermission, cabPermission, superUserPermission, updatePermissions} =
    usePermissions()

  const [assessmentData, setAssessmentData] = useState<any[]>([])
  const [loader, setLoader] = useState<any>(false)
  console.log(assessmentData, 'asss')
  const [cabData, setCabData] = useState<any>()
  const [categoryData, setCategoryData] = useState<CategoryItem[]>([])
  const [statusData, setStatusData] = useState<StatusItem[]>([])
  const [ncrDate, setNcrDate] = useState<Date | null>(null)
  const [rcaDate, setRcaDate] = useState<Date | null>(null)
  const [evidenceDate, setEvidenceDate] = useState<Date | null>(null)
  const [categoryID, setCategoryID] = useState<DataItem>()
  const [selectedLeaders, setSelectedLeaders] = useState<string[]>([])
  const [selectedMembers, setSelectedMembers] = useState<string[]>([])
  const [assessorList, setassessorList] = useState<any[]>([])
  const [userId, setuserId] = useState<string>()
  const [cabid, setCabid] = useState<any>()

  const [files, setFiles] = useState<{index: number; file: File}[]>([])

  const navigate = useNavigate()
  console.log(cabData, 'shdcgfyjdsfs')

  useEffect(() => {
    const userId = localStorage.getItem('current_user_id')
    if (userId) {
      // updatePermissions(userId);
      setuserId(userId)
    }
  }, [])

  const handleleaderSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value
    if (selectedId && !selectedLeaders?.includes(selectedId)) {
      setSelectedLeaders([...selectedLeaders, selectedId])
    }
  }

  const handleMemberSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value
    if (selectedId && !selectedMembers?.includes(selectedId)) {
      setSelectedMembers([...selectedMembers, selectedId])
    }
  }

  const handleRemoveLeader = (leaderId: string) => {
    setSelectedLeaders(selectedLeaders?.filter((id) => id !== leaderId))
  }

  const handleRemoveMember = (memberId: string) => {
    setSelectedMembers(selectedMembers?.filter((id) => id !== memberId))
  }

  const handleAssessmentChange = async (newValue, actionMeta) => {
    if (newValue) {
      const selectedOption = newValue.value
      setValue((prevValue) => ({...prevValue, assessment: selectedOption}))

      let selectedAssessment
      if (assessorPermission?.is_verify_accessor === true) {
        selectedAssessment = assessmentData.find(
          (item) => item?.assessor_planner__assessment_number === selectedOption
        )
      } else if (superUserPermission?.is_superuser === true) {
        selectedAssessment = assessmentData.find(
          (item) => item?.assessment_number === selectedOption
        )
      }

      if (selectedAssessment) {
        const cabid =
          assessorPermission?.is_verify_accessor === true
            ? selectedAssessment?.assessor_planner__assessment_number
            : selectedAssessment?.assessment_number
        setCabid(cabid)

        try {
          const response = await axios.get(
            `${API_URL}/cab/filter/assessment/planner/number/?assessment_number=${cabid}`
          )
          setCabData(response?.data[0])
        } catch (error) {
          console.error('Error fetching cab data:', error)
        }
      }
    }
  }

  const CABoptions = assessorPermission?.is_verify_accessor
    ? assessmentData.map((nc) => ({
        value: nc?.assessor_planner__assessment_number,
        label: nc?.assessor_planner__assessment_number,
      }))
    : superUserPermission?.is_superuser
    ? assessmentData.map((nc) => ({
        value: nc?.assessment_number,
        label: nc?.assessment_number,
      }))
    : []

  useEffect(() => {
    // Calculate RCA date based on selected category and NCR date
    if (ncrDate) {
      const categoryDelay = value?.category === 'Minor' ? 30 : value?.category === 'Major' ? 10 : 0

      const rcaDate = new Date(ncrDate)
      rcaDate.setDate(rcaDate.getDate() + categoryDelay)
      setRcaDate(rcaDate)
    }
  }, [ncrDate, value.category])
  const handleNcrDateChange = (date: Date | null) => {
    setNcrDate(date)
  }

  const handleRcaDateChange = (date: Date | null) => {
    setRcaDate(date)
  }

  const handleEvidenceDateChange = (date: Date | null) => {
    setEvidenceDate(date)
  }

  useEffect(() => {
    // fetchAssessment()
    fetchAssessor()
    fetchCategory()
    fetchStatus()
  }, [])

  useEffect(() => {
    if (cabData) {
      setValue((prevValue) => ({
        ...prevValue,
        email: cabData?.cab_email ? [cabData?.cab_email] : [''],
        registrar: cabData?.cab_company || '',
        cab_id: cabData?.cab ? cabData?.cab : '',

        cab_rep: cabData?.cab_representative,
      }))
    }
  }, [cabData])

  useEffect(() => {
    const fetchAssessment = async () => {
      if (superUserPermission?.is_superuser === true)
        try {
          const response = await axios.get(`${API_URL}/cab/assessment/id/`)
          setAssessmentData(response.data)
        } catch (error) {}
    }
    if (superUserPermission?.is_superuser === true) {
      fetchAssessment()
    }
  }, [superUserPermission])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/account/assessor/assessment/number/${userId}/patch/`
        )
        const uniqueAssessmentNumbers = _.uniqBy(
          response.data?.assessor_assessment_number,
          'assessor_planner__assessment_number'
        )
        setAssessmentData(uniqueAssessmentNumbers)
      } catch (error) {
        console.error('Error fetching assessment data:', error)
      }
    }
    if (userId && assessorPermission?.is_verify_accessor === true) {
      fetchData()
    }
  }, [userId, assessorPermission])

  const fetchCategory = async () => {
    try {
      const response = await axios.get<DataItem[]>(`${API_URL}/nc/category/list/`)
      setCategoryData(response.data)
    } catch (error) {}
  }
  const fetchAssessor = async () => {
    try {
      const response = await axios.get<DataItem[]>(`${API_URL}/team/accessor/verified/list/`)
      const simplifiedAssessors = response.data.map((assessor: any) => ({
        id: assessor.id,
        first_name: assessor.first_name,
        last_name: assessor.last_name,
      }))
      setassessorList(simplifiedAssessors)
    } catch (error) {}
  }
  const fetchStatus = async () => {
    try {
      const response = await axios.get<DataItem[]>(`${API_URL}/nc/status/list/`)
      setStatusData(response.data)
    } catch (error) {}
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files).map((file, index) => ({
        index: files.length + index, // Assign index based on the current length of the `files` array
        file,
      }))
      setFiles([...files, ...newFiles]) // Add new files to the existing files array
    }
  }

  const handleFileRemove = (index: number) => {
    setFiles(files.filter((file) => file.index !== index)) // Remove file by index
  }

  const handleChange = (index, e) => {
    const newEmail = [...value.email]
    newEmail[index] = e.target.value
    setValue({...value, email: newEmail})
  }

  const handleAddEmail = () => {
    setValue({...value, email: [...value.email, '']})
  }

  const handleRemoveEmail = (index) => {
    const newEmail = value?.email?.filter((_, i) => i !== index)
    setValue({...value, email: newEmail})
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    console.log(value, 'Printed Value.......!')

    // Validate mandatory fields
    if (!value.category || !value.ncr_status || !value.ncr_number) {
      toast.error('Please Enter All Required Fields.')
      return
    }

    try {
      setLoader(true)
      const formData = new FormData()
      const cabId = cabData?.id
      const categoryItem = categoryData.find((item) => item?.name === value.category)
      const categoryId = categoryItem ? categoryItem.id : null
      const statusItem = statusData.find((item) => item?.name === value.ncr_status)
      const statusId = statusItem ? statusItem.id : null
      let assessmentId = ''

      if (assessorPermission?.is_verify_accessor === true) {
        const assessmentItem = assessmentData?.find(
          (item) => item?.assessor_planner__assessment_number === value?.assessment
        )
        assessmentId = assessmentItem ? assessmentItem?.assessor_planner__id : ''
      }

      if (superUserPermission?.is_superuser === true) {
        const assessmentItem = assessmentData?.find(
          (item) => item?.assessment_number === value?.assessment
        )
        assessmentId = assessmentItem ? assessmentItem?.id : ''
      }

      formData.append('assessment', assessmentId || '')
      formData.append('registrar', value?.cab_id || '')

      formData.append('action_status', value.action_status)
      formData.append('cab_representative', value.cab_rep)
      formData.append('scheme_text', value.scheme)

      // formData.append('scheme', JSON.stringify(schemeIds))
      formData.append('standard_clause', value.standard_clause)
      formData.append('ncr_number', value.ncr_number)
      formData.append('uaf_requirements', value.uaf_requirement)
      formData.append('non_conformity_observed', value.non_conformity)
      formData.append('cab_representative_email', JSON.stringify(value.email))
      formData.append('evidence_reference', value.evidence_written)
      formData.append('category', categoryId || '')
      formData.append('ncr_status', statusId || '')

      formData.append('team_leader', JSON.stringify(selectedLeaders))
      formData.append('team_member', JSON.stringify(selectedMembers))

      formData.append('date_rca_cap', rcaDate?.toISOString() || '')
      formData.append('date_of_ncr', ncrDate?.toISOString() || '')
      formData.append('date_evidence', evidenceDate?.toISOString() || '')
      formData.append('remarks', value.remark)

      // if (value.evidence && value.evidence instanceof File) {
      //   formData.append('evidence_document', value.evidence)
      // }
      files.forEach((file, index) => {
        formData.append(`evidence_document`, file.file)
      })

      const response = await axios.post(`${API_URL}/nc/management/post/`, formData)
      const responseId = response.data.id

      setLoader(false)

      toast.success('Application Submitted successfully.')
      setValue({
        assessment: '',
        registrar: '',
        uaf_requirement: '',
        non_conformity: '',
        scheme: '',
        action_status: '',
        category: '',
        standard_clause: '',
        ncr_number: '',
        ncr_status: '',
        team_member: [],
        evidence_written: '',
        email: [],
        cab_rep: '',
        leader: [],
        evidence: [],
        certification: [],
        remark: '',
        cab_id: '',
      })
      setNcrDate(null)
      setRcaDate(null)
      setEvidenceDate(null)
      setSelectedLeaders([])
      setSelectedMembers([])
      setCabData(null)

      navigate(`/NCManagement/list/`)

      // navigate(`/NCManagement/cab/${responseId}`);
    } catch (error) {
      setLoader(false)
      toast.error('Something went wrong, please try again')
    }
  }
  const uniqueAssessors = _.uniqBy(cabData?.assessor_list_data, 'assessor_id')

  // console.log(JSON.stringify(formData), "Stringyfy")

  return (
    <div id='raise_nc'>
      {loader ? <UAFLoader /> : null}
      <h2>Raise Non-Conformity</h2>
      <form onSubmit={handleSubmit} id='rasie_nc-1'>
        <div className='py-lg-10 px-lg-10'>
          <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-body p-9'>
              <div className='row'>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Assessment Number</label>
                  {/* <select
                    onChange={handleAssessmentChange}
                    value={value.assessment}
                    className='form-control form-control-solid'
                  >
                    <option value=''>Please Select Assessment</option>
                    {assessmentData.map((item) => (
                      <option key={item.id} value={item.assessment_number}>
                        {item.assessment_number}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    id='rasie_nc_2'
                    className='form-control form-control-solid'
                    value={{value: value.assessment, label: value.assessment}}
                    onChange={handleAssessmentChange}
                    options={CABoptions}
                    placeholder='Please Select Assessment'
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='form-label'>Organisation</label>
                  <input
                    id='rasie_nc_3'
                    value={value?.registrar}
                    className='form-control form-control-solid'
                    type='text'
                  />
                </div>

                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>NCR Number</label>
                  <input
                    id='rasie_nc_4'
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Please Enter A Unique Id For This NCR '
                    name='ncr_number'
                    onChange={(e) => setValue({...value, ncr_number: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Date of NCR</label>
                  <br />
                  <ReactDatePicker
                    id='rasie_nc_5'
                    selected={ncrDate}
                    placeholderText='Select Date'
                    className='react_date_picker form-control form-control-solid'
                    onChange={handleNcrDateChange} // only when value has changed
                    dropdownMode='select'
                    showMonthDropdown
                    showYearDropdown
                  />
                </div>
                <div className='mb-5'>
                  <label className=' form-label'>Standard/UAF Requirements</label>
                  <textarea
                    className='form-control form-control-solid'
                    rows={5}
                    id='rasie_nc_6'
                    //   placeholder='Enter Remarks'
                    name='uaf_requirement'
                    onChange={(e) =>
                      setValue({
                        ...value,
                        uaf_requirement: e.target.value,
                      })
                    }
                  />
                </div>
                <div className='mb-5'>
                  <label className=' form-label'>Non-Conformity Observed</label>
                  <textarea
                    className='form-control form-control-solid'
                    rows={5}
                    //   placeholder='Enter Remarks'
                    name='non_conformity'
                    id='rasie_nc_7'
                    onChange={(e) =>
                      setValue({
                        ...value,
                        non_conformity: e.target.value,
                      })
                    }
                  />
                </div>

                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Scheme</label>
                  <input
                    type='text'
                    id='rasie_nc_8'
                    className='form-control form-control-solid'
                    placeholder='Please Enter Scheme '
                    name='scheme'
                    onChange={(e) => setValue({...value, scheme: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Category</label>
                  <select
                    id='rasie_nc_9'
                    onChange={(e) => setValue({...value, category: e.target.value})}
                    value={value.category}
                    className='form-control form-control-solid'
                  >
                    <option value=''>Please Select Category</option>
                    {categoryData.map((Item) => (
                      <option key={Item?.id} value={Item?.name}>
                        {Item?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Standard / Clause No.</label>

                  <input
                    id='rasie_nc_10'
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Please Enter A Unique Standard/ClauseNo'
                    name='standard_clause'
                    onChange={(e) => setValue({...value, standard_clause: e.target.value})}
                  />
                </div>

                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Team Leader, UAF</label>
                  <select
                    id='rasie_nc_11'
                    className='form-control form-control-solid'
                    onChange={handleleaderSelectChange}
                    value=''
                  >
                    <option value=''></option>
                    {uniqueAssessors
                      .filter((item) => !selectedLeaders?.includes(item.assessor_id))
                      .map((item) => (
                        <option key={item.assessor_id} value={item.assessor_id}>
                          {item.assessor_first_name + ' ' + item.assessor_last_name}
                        </option>
                      ))}
                  </select>
                  <div
                    className='selected-standards mt-3'
                    style={{display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px'}}
                  >
                    {selectedLeaders?.map((leaderId) => {
                      const leader = uniqueAssessors.find((item) => item.assessor_id === leaderId)
                      return (
                        <span
                          key={leaderId}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#e0e0e0',
                            borderRadius: '15px',
                            padding: '5px 10px',
                          }}
                        >
                          {leader?.assessor_first_name + ' ' + leader?.assessor_last_name}
                          <span
                            style={{
                              marginLeft: '10px',
                              cursor: 'pointer',
                              color: 'red',
                              fontWeight: 'bold',
                            }}
                            onClick={() => handleRemoveLeader(leaderId)}
                          >
                            &times;
                          </span>
                        </span>
                      )
                    })}
                  </div>
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Team Member, UAF</label>
                  <select
                    id='rasie_nc_12'
                    className='form-control form-control-solid'
                    onChange={handleMemberSelectChange}
                    value=''
                  >
                    <option value=''></option>
                    {uniqueAssessors
                      .filter((item) => !selectedMembers.includes(item.assessor_id))
                      .map((item) => (
                        <option key={item.assessor_id} value={item.assessor_id}>
                          {item.assessor_first_name + ' ' + item.assessor_last_name}
                        </option>
                      ))}
                  </select>
                  <div
                    className='selected-standards mt-3'
                    style={{display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px'}}
                  >
                    {selectedMembers?.map((memberId) => {
                      const member = uniqueAssessors.find((item) => item.assessor_id === memberId)
                      return (
                        <span
                          key={memberId}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#e0e0e0',
                            borderRadius: '15px',
                            padding: '5px 10px',
                          }}
                        >
                          {member?.assessor_first_name + ' ' + member?.assessor_last_name}
                          <span
                            style={{
                              marginLeft: '10px',
                              cursor: 'pointer',
                              color: 'red',
                              fontWeight: 'bold',
                            }}
                            onClick={() => handleRemoveMember(memberId)}
                          >
                            &times;
                          </span>
                        </span>
                      )
                    })}
                  </div>
                </div>

                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>CAB Representative</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    //   placeholder='Please Enter A Unique Id For This NCR '
                    name='cab_representative'
                    value={value?.cab_rep}
                    onChange={(e) => setValue({...value, cab_rep: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Representative email</label>
                  {value.email.map((email, index) => (
                    <div key={index} className='d-flex mb-2'>
                      <input
                        id='email_hai'
                        type='email'
                        className='form-control form-control-solid'
                        name={`email-${index}`}
                        value={email}
                        onChange={(e) => handleChange(index, e)}
                        readOnly={index === 0 && cabData?.email !== undefined}
                      />
                      {index > 0 && (
                        <button
                          id='email_hai_index'
                          type='button'
                          className='btn btn-danger ml-2'
                          onClick={() => handleRemoveEmail(index)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    id='kar_lo_add'
                    type='button'
                    className='btn btn-primary mt-2'
                    onClick={handleAddEmail}
                  >
                    Add Email
                  </button>
                </div>

                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Evidence reference, if any</label>
                  <input
                    id='evidence_written'
                    type='text'
                    className='form-control form-control-solid'
                    name='evidence_written'
                    onChange={(e) => setValue({...value, evidence_written: e.target.value})}
                  />
                </div>
                {files.map(({index, file}) => (
                  <div key={index} style={{margin: '10px 0'}}>
                    <span>{file.name}</span>
                    <button
                      type='button'
                      onClick={() => handleFileRemove(index)}
                      style={{
                        marginLeft: '10px',
                        color: 'red',
                        cursor: 'pointer',
                        border: 'none',
                        background: 'transparent',
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ))}
                <div className=' text-end'>
                  <label
                    htmlFor='evidence'
                    className={files.length > 0 ? 'file-uploaded-uploaded' : 'file-upload-upload'}
                    style={{width: '155px', marginTop: '25px'}}
                  >
                    {files.length > 0 ? 'Doc Uploaded' : 'Upload Doc'}
                  </label>
                  <input
                    type='file'
                    id='evidence'
                    style={{display: 'none'}}
                    onChange={handleFileChange}
                    multiple
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-body p-9'>
              <h6> Status</h6>
              <div className='row'>
                {/* <div className='mb-5 col-lg-3'>
                  <label className='required form-label'>Action Status</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    name='action_status'
                    onChange={(e) => setValue({...value, action_status: e.target.value})}
                  />
                </div> */}
                <div className='mb-5 col-lg-3'>
                  <label className='required form-label'>NCR Status</label>
                  <select
                    id='hua_change _status'
                    onChange={(e) => setValue({...value, ncr_status: e.target.value})}
                    value={value.ncr_status}
                    className='form-control form-control-solid'
                  >
                    <option value=''>Please Select Status</option>
                    {statusData.map((item) => (
                      <option key={item?.id} value={item?.name}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='mb-5 col-lg-4'>
                  <label className='required form-label'>Date For Submission of RCA and CAP</label>
                  <br />
                  <ReactDatePicker
                    id='hua_change _status_1'
                    selected={rcaDate}
                    placeholderText='Select Date'
                    className='react_date_picker form-control form-control-solid'
                    onChange={handleRcaDateChange}
                    dropdownMode='select'
                    showMonthDropdown
                    showYearDropdown
                  />
                </div>
                {/* <div className='mb-5 col-lg-3'>
                  <label className='required form-label'>Date For Submission of Evidence</label>
                  <br />
                  <ReactDatePicker
                    selected={evidenceDate}
                    placeholderText='Select Date'
                    className='react_date_picker form-control form-control-solid'
                    onChange={handleEvidenceDateChange}
                    dropdownMode='select'
                  />
                </div> */}
              </div>
            </div>
          </div>

          <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-body p-9'>
              <div className='mb-5'>
                <label className=' form-label'>Remarks</label>
                <textarea
                  className='form-control form-control-solid'
                  rows={5}
                  placeholder='Enter Remarks'
                  id='hua_change _status_5'
                  name='remark'
                  onChange={(e) =>
                    setValue({
                      ...value,
                      remark: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className='mb-5'>
            <button id='hua_change _status_8' type='submit' className='btn btn-primary'>
              Save NCR
              <i className='fa fa-angle-double-right' aria-hidden='true' />
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NCManagementForm
