import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../../../accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getUserDetails } from './VerifiedAssessorFunction'
import { useNavigate, useParams } from 'react-router-dom'
import { UserDataType } from '../../AssessorInterface'
import axios from 'axios'

import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import ReviewDocumentModal from '../models/ReviewDocumentModal'
import Select from 'react-select'
import JobOrderPlanner from '../../../organisation/AssessmentPlanner/component/modal/JobOrderPlanner'
import { IconButton, Tooltip } from '@mui/material'
interface AssessorIDProps {
  userId: string
}
interface PendingAssessor {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  is_assessor: boolean
  is_verify_assessor: boolean
  assessor: string
  store_password: string
  scheme_names: { id: string; name: string }[]
  programme_names: { id: string; name: string }[]
  accessor_approve_date: string
  create_at: string
}
const VerifiedAssessorARC: React.FC<AssessorIDProps> = ({ userId }) => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const userIdObject = JSON.parse(userId)
  const uuid = userIdObject['*']
  const uuidRegex =
    /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  const uuids = uuid.match(uuidRegex)
  const urlId = uuids[0]
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const [jobOrderModal, setJobOrderModal] = useState<boolean>(false)

  const [selectedAssessment, setSelectedAssessment] = useState<any>()
  const [accessorList, setAccessorList] = useState<PendingAssessor[]>([])
  const navigate = useNavigate();
  // get assessment list
  const fetchData = async () => {
    try {
      const response = await axios.get<PendingAssessor[]>(
        `${BASE_URL}/team/accessor/verified/list/`
      )
      setAccessorList(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }
  const [userData, setUserData] = useState<UserDataType>()
  console.log(userData, 'this is userData')
  const [loading, setLoading] = useState(false)
  const [showDocumentReviewModal, setShowDocumentReviewModal] = useState<boolean>(false)
  const [arcAssessment, setARCAssessment] = useState<any>(
    []
  )
  console.log(arcAssessment[0]?.assessor_details[0]?.assessor_target, "this is arcAssessment")
  const [singleRow, setSingleRow] = useState<any>([])
  const [rowStatus, setRowStatus] = useState<any>()
  const [showPdfModal, setShowPdfModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')
  const handlePdfIconClick = (url) => {
    setPdfUrl(url)
    setShowPdfModal(true)
  }
  const handleClosePdfModal = () => {
    setShowPdfModal(false)
    setPdfUrl('') // Clear the PDF URL when the modal is closed
  }
  console.log(arcAssessment, 'arcAssessment')
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserDetails(urlId)
        setUserData(result)
      } catch (error) {
        // Handle errors
        console.error('Error:', error)
      }
    }
    fetchData()
  }, [])
  const getAssessorDataByARC = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/arc/management/filter/?cab=&assessor=${urlId}`
      )
      setARCAssessment(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAssessorDataByARC()
  }, [])
  const handleStatus = (e, item) => {
    if (e.value === 'Completed') {
      setShowDocumentReviewModal(true)
      // You can do something with the item (row data) here
      setSingleRow(item)
      setRowStatus(e.value)
    }
  }
  const handleInitiateArc = (cab_id, arc_no, assessor_detils) => {
    console.log(assessor_detils, "this is my assessor_detilss")
    const assessor_target = assessor_detils?.map((item) => item?.assessor_target)
    const targetvalue = assessor_target[0]
    console.log(assessor_target[0], "this is target")
    if (cab_id && arc_no && urlId) {
      navigate(`/accreditation/cab/report/create/cab_id/${cab_id}/arc_member/${urlId}/arc_no/${arc_no}/stage/${targetvalue[0]?.stage}`, {
        state: { targetvalue }
      });
    }
  }
  // const handleReportView = (cab_id, arc_no) => {
  //   if (cab_id && arc_no && urlId) {
  //     const url = `/are/generated/report/cab/${cab_id}/arc/${urlId}/arc_no/${arc_no}`;
  //     window.open(url, '_blank'); // Open the URL in a new tab
  //   }
  // }
  const handleReportView = (cab_id, arc_no, assessor_detils) => {
    if (cab_id && arc_no && urlId) {
      const assessor_target = assessor_detils?.map((item) => item?.assessor_target)
      const targetvalue = assessor_target[0]
      navigate(`/are/generated/report/cab/${cab_id}/arc/${urlId}/arc_no/${arc_no}/stage/${targetvalue[0]?.stage}`, {
        state: { targetvalue }
      });
    }
  };
  const formatMyDate = (date: Date | string | null) => {
    if (!date) return null;
  
    // If the date is a string, parse it to a Date object
    const parsedDate = typeof date === "string" ? new Date(date) : date;
  
    // Ensure the parsed date is valid
    if (isNaN(parsedDate.getTime())) return null;
  
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
  
    return `${month}-${day}-${year}`;
  };
  return (
    <div id="arc_page_id">
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>ARC List</h3>
              </div>
            </div>
            <div className='card-body p-9'>
              <div className='row gy-10 gx-xl-10'>
                <div className='col-xl-12'>
                  <div className=' card-xl-stretch mb-5 mb-xl-10 ' id='kt_profile_details_view'>
                    <table
                      style={{ margin: '0 0 0 25px' }}
                      className='table align-middle table-row-dashed fs-6 gy-5 location_table_show'
                      id='kt_table_users'
                    >
                      <thead>
                        <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                          {/* <th>Assessment#</th> */}
                          <th className='min-w-125px'>ARC ID</th>
                          <th className='min-w-125px'>CAB</th>
                          {/* <th className='min-w-125px'>Job Order</th> */}
                          <th className='min-w-125px'>Status</th>
                          <th className='min-w-125px'>Completion Dates</th>
                          <th className='min-w-125px'>Remarks</th>
                          <th className='min-w-125px'>Report</th>
                          <th className='min-w-125px'>Action</th>
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-semibold'>
                        {arcAssessment?.map((item,indexValue) => {
                          return (
                            <tr>
                              <td>
                                {item?.assess_value}

                              </td>
                              <td>
                                {item?.cab_details?.company}

                              </td>
                              {/* <td>

                                ABC
                              </td> */}


                              <td>
                                {item?.assessor_details?.map((assessor) => {
                                  if (assessor?.assessor === urlId) {
                                    return (
                                      <div key={assessor?.assessor}>

                                        {
                                          assessor?.variable_data?.some(vd => vd?.is_approved)
                                            ? 'Approved'
                                            : assessor.variable_data?.some(vd => vd?.is_reject)
                                              ? 'Reject'
                                              : 'Pending'
                                        }
                                      </div>
                                    );
                                  }
                                  return null;
                                })}

                              </td>
                              <td>
                                {item?.completion_date !== null ? (
                                  <>{formatMyDate(new Date(item?.completion_date))}</>
                                ) : (
                                  <>
                                    --
                                  </>
                                )}


                              </td>

                              <td>
                                {item?.assessor_details?.map((assessor) => {
                                  if (assessor?.assessor === urlId) {

                                    return (
                                      <div key={assessor?.assessor}>
                                        {assessor?.variable_data?.some(vd => vd?.remark) ? (
                                          assessor?.variable_data?.map((vd, index) => (
                                            vd?.remark && <div key={index}>{vd?.remark}</div>
                                          ))
                                        ) : (
                                          "--"
                                        )}
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              </td>
                              <td>
                                {item?.completion_date !== null ? (
                                  <img id = {`arc_hai_${indexValue}`} onClick={() => handleReportView(item.cab_details?.id, item?.assess_value, item?.assessor_details)} src='/media/doc.png' alt='Document' />

                                ) : (
                                  <>
                                    <img src='/media/no-doc.png' alt='' />
                                  </>
                                )}

                              </td>

                              <td>


                                <a
                                id = {`arc_initiate_${indexValue}`}
                                  onClick={() => handleInitiateArc(item?.cab_details?.id, item?.assess_value, item?.assessor_details
                                  )}
                                  className='btn btn-sm btn-primary me-3 p-4'
                                >
                                  Initate ARC
                                </a>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showPdfModal} onHide={handleClosePdfModal} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display the PDF in the modal */}
          {/* <iframe src={pdfUrl} title='PDF Viewer' width='100%' height='500px'></iframe> */}
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
            title='DOCX Viewer'
            width='100%'
            height='500px'
          ></iframe>
        </Modal.Body>
      </Modal>
      <ReviewDocumentModal
        rowStatus={rowStatus}
        singleRow={singleRow}
        show={showDocumentReviewModal}
        handleClose={() => setShowDocumentReviewModal(false)}
        refresh={() => getAssessorDataByARC()}
      />

      <JobOrderPlanner
        selectedAssessment={selectedAssessment}
        show={jobOrderModal}
        handleClose={() => setJobOrderModal(false)}
        accessorList={accessorList}
        urlId={urlId}
        getAssessorDataByAssessment={getAssessorDataByARC}
      />
    </div>
  )
}
export { VerifiedAssessorARC }
