import React, {useEffect, useState} from 'react'
import Select, {SingleValue} from 'react-select'
import ReactDatePicker from 'react-datepicker'
import axios from 'axios'

import {KTIcon} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
const BASE_URL = process.env.REACT_APP_API_URL

const ReportDashboard = () => {
  const [mdReport, setMdReport] = useState<any>([])
  const [schemeFilter, setSchemeFilter] = useState<any>([])
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)
  const [selectedScheme, setSelectedScheme] = useState<string | null>(null)
  console.log(mdReport, 'this is my md report')
  const [userData, setUserData] = useState<any>()
  const [cabUserData, setCabUserData] = useState<any>([])
  const [cabUser, setCabUser] = useState<any[]>([])
  const [selectedYear, setSelectedYear] = useState<any>()

  useEffect(() => {
    // fetchData();
    axios
      .get(`${BASE_URL}/account/approved/cab/user/`)
      .then((response) => {
        console.log(response.data, 'helloosss')
        const data = response.data?.map((item) => ({
          label: item?.company,
          value: item?.id,
        }))
        setCabUser(data)
        setCabUserData(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    const fetchAuditorCertificate = async () => {
      let myurl = `${BASE_URL}/certificate/auditor/count/data/`

      const params = new URLSearchParams()
      if (selectedUserId) params.append('cab', selectedUserId)
      if (selectedScheme) params.append('scheme', selectedScheme)
      if (selectedYear) params.append('year', selectedYear)

      try {
        // Append query parameters to URL
        const response = await axios.get(`${myurl}?${params.toString()}`)
        setMdReport(response.data)
      } catch (error) {
        console.log('Error fetching auditor certificate data:', error)
      }
    }

    fetchAuditorCertificate()
  }, [selectedUserId, selectedScheme, selectedYear])

  const handleCabUserChange = (e) => {
    if (e) {
      setSelectedUserId(e.value)
    }
  }
  const getUserDetails = () => {
    axios
      .get(`${BASE_URL}/account/patch/user/${selectedUserId}/`)
      .then((response) => {
        const result = response.data
        setUserData(result)
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }

  useEffect(() => {
    if (selectedUserId) {
      getUserDetails()
    }
  }, [selectedUserId])

  const handleSchemeChange = (e) => {
    setSelectedScheme(e.value)
  }

  const handleClickApply = async () => {}
  return (
    <div id='dashboard_2'>
      <div className='col-xl-12 card card-xl-stretch mb-5 mb-xl-10'>
        <div className='p-5 d-flex justify-content-between w-100 align-items-center'>
          {/* <div className='m-2 col-3'>
            <label className='required form-label'>CAB Organisation</label>
            <Select
              id="cab_drop"
              className='react-select-styled react-select-solid '
              name='User'
              options={cabUser}
              classNamePrefix='react-select'
              
              onChange={(e) => handleCabUserChange(e)}
            />
          </div> */}
          <div className='m-5 col-lg-4 ' style={{color: '#136ba1'}}>
            <label className='required form-label'>CAB</label>
            <Select
              className='cab-control'
              // value={allOptionValue || selectedCAB}
              onChange={(e) => handleCabUserChange(e)}
              options={cabUser}
              placeholder='CAB Organisation'
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: '#136ba1',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: '#136ba1',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#136ba1' : 'white',
                  color: state.isSelected ? 'white' : '#136ba1',
                  '&:hover': {
                    backgroundColor: '#136ba1',
                    color: 'white',
                  },
                }),
              }}
            />
          </div>
          <div className='m-5 col-lg-4 '>
            <label className='required form-label'>Scheme Name</label>
            <div
              title={!selectedUserId  ? 'Please select CAB  first' : ''}
              style={{pointerEvents: 'auto'}}
            >
            <Select
              // isMulti
              className='react-select-styled react-select-solid '
              name='User'
              classNamePrefix='react-select'
              options={userData?.scheme_names?.map((i) => ({label: i?.name, value: i?.id}))}
              onChange={(e) => handleSchemeChange(e)}
              value={userData?.scheme_names
                ?.filter((item) => item?.id === selectedScheme)
                ?.map((item) => ({label: item.name, value: item.id}))}
              isDisabled={!selectedUserId}
            />
            </div>
          </div>
          <div className='m-2 col-3' style={{position: 'relative'}}>
            <label className='required form-label'>Select Year</label>
            <div
              title={!selectedUserId || !selectedScheme ? 'Please select CAB and Scheme first' : ''}
              style={{pointerEvents: 'auto'}}
            >
              <ReactDatePicker
                className='react_date_picker form-control form-control-solid'
                selected={selectedYear ? new Date(Number(selectedYear), 0) : null}
                placeholderText='--'
                onChange={(date) => {
                  if (date) {
                    setSelectedYear(date.getFullYear())
                  }
                }}
                showYearPicker
                dateFormat='yyyy'
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                disabled={!selectedUserId || !selectedScheme}
              />
            </div>
          </div>

          {/* <div className=' m-5 col-lg-4 mb-2'>
            <button id='apply_button' onClick={handleClickApply} className='thm-btn '>
              Apply
            </button>
          </div> */}
        </div>
      </div>

      <div className='col-xl-12'>
        <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='d-flex justify-content-between w-100 align-items-center'>
            <h2 className='m-5'>Report</h2>
            <Link to={`/cab/reports/add`} className='btn btn-primary float-right me-2'>
              <KTIcon iconName='plus' className='fs-3' />
              Add Report
            </Link>
          </div>

          {selectedYear && selectedUserId && selectedScheme ? (
            <div className='table-responsive'>
              <table className=' align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      S.No
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Country where UAF Accredited Certificates are Issued
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Total Number of Initial and Recertifications Certificates Issued in the
                      country during Janurary to Decemeber
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Total Number of Surveillance certificates continued in the country during
                      Janurary to Decemeber
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Total Number of Certificates Suspended and Withdrawn in the country during
                      Janurary to Decemeber
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Total Number of Auditors (both employed and contracted) operating in the
                      country during the year
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Total Number of Transfer of Certificates accepted during the year in the
                      country
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Total Number of Overdue Audits/Certificates in the country during the year
                      which are not suspended/withdrawn
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Number of Total Audit Mandays Delivered in the country during the year for
                      initial,recertification and surveilalnce certificates
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {mdReport.map((item, index) => (
                    <tr key={item?.id}>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            {index + 1}
                          </div>
                        </div>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            {item?.country_name}
                          </div>
                        </div>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <table style={{width: '100%'}}>
                          <thead>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <th
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.year}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <td
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.certificate_data?.reduce(
                                    (sum, cert) =>
                                      sum + (cert?.initial || 0) + (cert?.ReCertification || 0),
                                    0
                                  )}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <table style={{width: '100%'}}>
                          <thead>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <th
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.year}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {/* <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                                {item[0]?.audit_data[0]?.surveillance_sum}
                              </td> */}
                              {item?.years?.map((yearItem, yearIndex) => (
                                <td
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.audit_data?.surveillance_sum}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <table style={{width: '100%'}}>
                          <thead>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <th
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.year}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <td
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.certificate_data?.reduce(
                                    (sum, cert) =>
                                      sum + (cert?.suspended || 0) + (cert?.withdrawn || 0),
                                    0
                                  )}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <table style={{width: '100%'}}>
                          <thead>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <th
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.year}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <td
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.audit_data?.auditor_count_sum}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <table style={{width: '100%'}}>
                          <thead>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <th
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.year}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <td
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.certificate_data?.transferred}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <table style={{width: '100%'}}>
                          <thead>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <th
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.year}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <td
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.audit_data?.audits_sum}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                        <table style={{width: '100%'}}>
                          <thead>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <th
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.year}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {item?.years?.map((yearItem, yearIndex) => (
                                <td
                                  key={yearIndex}
                                  style={{border: '1px solid #dddddd', padding: '8px'}}
                                >
                                  {yearItem?.audit_data?.mandays_sum}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <table
              className='table align-middle table-row-dashed fs-6 gy-5 location_table_show assessmentPlanner_table p-5'
              id='kt_table_users'
            >
              <thead>
                <tr className='text-start text-muted fw-bold fs-7  gs-0'>
                  <th className='min-w-60px text-center'>Sr No</th>
                  <th className='min-w-125px'>
                    Country where UAF Accredited Certificates are Issued
                  </th>
                  <th className='min-w-125px text-center'>
                    Total Number of Initial and Recertifications Certificates Issued
                  </th>
                  <th className='min-w-125px text-center'>
                    Total Number of Certificates Suspended and Withdrawn
                  </th>
                  <th className='min-w-125px text-center'>
                    Total Number of Auditors (both employed and contracted) operating
                  </th>
                  <th className='min-w-125px text-center'>
                    Total Number of Transfer of Certificates accepted
                  </th>
                  <th className='min-w-125px text-center'>
                    Number of Total Audit Mandays Delivered in the country
                  </th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {mdReport?.map((item, index) => (
                  <tr key={item?.country}>
                    <td className='min-w-60px text-center'>{index + 1}</td>
                    <td>{item.country_name}</td>
                    <td className='text-center'>
                      {item?.certificate_data[0]?.initial +
                        item?.certificate_data[0]?.ReCertification || 0}
                    </td>
                    <td className='text-center'>
                      {item?.certificate_data[0]?.suspended +
                        item?.certificate_data[0]?.withdrawn || 0}
                    </td>
                    <td className='text-center'>{item?.audit_data[0]?.auditor_count_sum || 0}</td>
                    <td className='text-center'>{item?.certificate_data[0]?.transferred || 0}</td>
                    <td className='text-center'>{item?.audit_data[0]?.mandays_sum || 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReportDashboard
