import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
type Props = {
    uniqueCab: string | undefined;
    ARC_member: string | undefined;
    ARC_code: string | undefined;
};

const FSMSannexure = ({ uniqueCab, ARC_member, ARC_code }: Props) => {
    console.log(uniqueCab, "KAKAKAKKAKKA");
    const cabID = uniqueCab;
    const BASE_URL = process.env.REACT_APP_API_URL
    const FSMSSchemeID = "40f65889-bbe5-4784-9e10-38863a220be9"


    const [fsmsAnnexure, setFSMSAnnxeure] = useState({
        cab: cabID,
        scheme: FSMSSchemeID,
        arc: ARC_member,
        arc_code: ARC_code,
        iaf_1_1: "A",
        iaf_1_2_1: "AI",
        iaf_1_2_2: "AII",
        iaf_1_3_1: "",
        iaf_1_3_2: "",
        iaf_1_4_1: "",
        iaf_1_4_2: "",
        iaf_1_5_1: "",
        iaf_1_5_2: "",
        iaf_1_6_1: "",
        iaf_1_6_2: "",
        iaf_1_7_1: "",
        iaf_1_7_2: "",

        iaf_1_8: "B",
        iaf_1_9_1: "BI", iaf_1_9_2: "BII", iaf_1_9_3: "BIII",
        iaf_1_10_1: "", iaf_1_10_2: "", iaf_1_10_3: "",
        iaf_1_11_1: "", iaf_1_11_2: "", iaf_1_11_3: "",
        iaf_1_12_1: "", iaf_1_12_2: "", iaf_1_12_3: "",
        iaf_1_13_1: "", iaf_1_13_2: "", iaf_1_13_3: "",
        iaf_1_14_1: "", iaf_1_14_2: "", iaf_1_14_3: "",

        iaf_1_15: "C",
        iaf_1_16_1: "C0", iaf_1_16_2: "CI", iaf_1_16_3: "CII", iaf_1_16_4: "CIII", iaf_1_16_5: "CIV",
        iaf_1_17_1: "", iaf_1_17_2: "", iaf_1_17_3: "", iaf_1_17_4: "", iaf_1_17_5: "",
        iaf_1_18_1: "", iaf_1_18_2: "", iaf_1_18_3: "", iaf_1_18_4: "", iaf_1_18_5: "",
        iaf_1_19_1: "", iaf_1_19_2: "", iaf_1_19_3: "", iaf_1_19_4: "", iaf_1_19_5: "",
        iaf_1_20_1: "", iaf_1_20_2: "", iaf_1_20_3: "", iaf_1_20_4: "", iaf_1_20_5: "",
        iaf_1_21_1: "", iaf_1_21_2: "", iaf_1_21_3: "", iaf_1_21_4: "", iaf_1_21_5: "",

        iaf_1_22: "D",
        iaf_1_23: "",
        iaf_1_24: "",
        iaf_1_25: "",
        iaf_1_26: "",
        iaf_1_27: "",
        iaf_1_28: "",

        iaf_1_29: "E",
        iaf_1_30: "",
        iaf_1_31: "",
        iaf_1_32: "",
        iaf_1_33: "",
        iaf_1_34: "",
        iaf_1_35: "",


        iaf_1_36: "F",
        iaf_1_37_1: "FI", iaf_1_37_2: "FII",
        iaf_1_38_1: "", iaf_1_38_2: "",
        iaf_1_39_1: "", iaf_1_39_2: "",
        iaf_1_40_1: "", iaf_1_40_2: "",
        iaf_1_41_1: "", iaf_1_41_2: "",
        iaf_1_42_1: "", iaf_1_42_2: "",


        iaf_1_43: "G",
        iaf_1_44: "",
        iaf_1_45: "",
        iaf_1_46: "",
        iaf_1_47: "",
        iaf_1_48: "",
        iaf_1_49: "",

        iaf_1_50: "H",
        iaf_1_51: "",
        iaf_1_52: "",
        iaf_1_53: "",
        iaf_1_54: "",
        iaf_1_55: "",
        iaf_1_56: "",

        iaf_1_57: "I",
        iaf_1_58: "",
        iaf_1_59: "",
        iaf_1_60: "",
        iaf_1_61: "",
        iaf_1_62: "",
        iaf_1_63: "",

        iaf_1_64: "J",
        iaf_1_65: "",
        iaf_1_66: "",
        iaf_1_67: "",
        iaf_1_68: "",
        iaf_1_69: "",
        iaf_1_70: "",

        iaf_1_71: "K",
        iaf_1_72: "",
        iaf_1_73: "",
        iaf_1_74: "",
        iaf_1_75: "",
        iaf_1_76: "",
        iaf_1_77: "",

        iaf_1_78: "L",
        iaf_1_79: "",
        iaf_1_80: "",
        iaf_1_81: "",
        iaf_1_82: "",
        iaf_1_83: "",
        iaf_1_84: "",



    });
    console.log("fsmsAnnexure:", fsmsAnnexure);
    const [reportId, setReportId] = useState<any>();

    useEffect(() => {
        const getAnnexureData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/arc/anexxure/report/list/?cab=${cabID}&assessor=${ARC_member}&scheme=${FSMSSchemeID}&create_at=&update_at=`);

                if (response.status === 200 || response.status === 201) {
                    console.log(response.data, "DATA");
                    const jsonData = response.data[0]?.json_value; // Assuming json_value is in the first object
                    setReportId(response.data[0]?.id)
                    if (jsonData) {
                        setFSMSAnnxeure(prevState => ({
                            ...prevState,
                            ...jsonData
                        }));
                    }
                } else {
                    toast.error('Failed to submit application. Please try again.');
                }
            } catch (error) {
                console.log(error);
                toast.error('An error occurred. Please try again.');
            }
        };


        getAnnexureData();
    }, [])
    const submitFSMSAnnexure = async () => {
        if (!reportId) {
            try {
                // const response = await axios.post(`${BASE_URL}/arc/anexxure/report/create/`, qmsAnnexure);
                // toast.success("QMS Annexure Created Successfully")
                const { cab, scheme, arc, arc_code, ...rest } = fsmsAnnexure;
                const jsonData = JSON.stringify(rest);

                const response = await axios.post(`${BASE_URL}/arc/anexxure/report/create/`, {
                    cab,
                    scheme,
                    arc, arc_code,
                    json_value: jsonData
                });
                if (response.status === 200 || response.status === 201) {
                    toast.success('FSMS Annexure Created Successfully.');


                } else {
                    toast.error('Failed to submit application. Please try again.');
                }
                

            } catch (error) {
                console.log(error);
            }
        } else {
            handleAnnexureUpdate();
        }
    }
    const handleAnnexureUpdate = async () => {

        try {

            const { cab, scheme, ...rest } = fsmsAnnexure;
            const jsonData = JSON.stringify(rest);

            const response = await axios.put(`${BASE_URL}/arc/anexxure/report/${reportId}/update/`, {
                cab,
                scheme,
                json_value: jsonData
            });
            if (response.status === 200 || response.status === 201) {
                toast.success('QMS Annexure Updated Successfully.');


            } else {
                toast.error('Failed to Update application. Please try again.');
            }



        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-body p-9'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
                <h3 className='mt-5 mb-10'>CHECKLIST FOR THE AWARD OF SCOPES FOR MS DURING INITIAL ACCREDITATION</h3>
                <div className='text-gray-800  mb-1'>
                <button className='btn btn-primary' onClick={submitFSMSAnnexure}>
                    save
                    <i className='fa fa-angle-double-right' aria-hidden='true' />
                  </button>

                  </div>              </div>
                <div className='mb-5 col-lg-12'>
                    {/* <label className='required form-label'>Date/s of Witness assessment/s </label> */}
                    <div style={{ maxHeight: '700px', overflowY: 'auto', border: '1px solid #ddd' }}>
                    <table className='annxure-table table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                        <thead style={{ position: 'sticky', top: 0, backgroundColor: '#f2f2f2', zIndex: 10 }}>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                <th className='w-250px pe-2 fs-7'>Category</th>
                                <th className='w-150px pe-2 fs-7'>Sub-Category</th>
                                <th className='w-250px pe-2 fs-7'>Categories Applied by CAB</th>
                                <th className='w-250px pe-2 fs-7'>Details of the demonstration of the competent personnel for all categories during office assessment</th>
                                <th className='w-250px pe-2 fs-7'>Witness Details for the Food Chain Category (Category, Date of Witness and Assessment Number)</th>
                                <th className='w-250px pe-2 fs-7'>Remarks by the Senior Executive/Manager Accreditation for the codes to be awarded</th>
                                <th className='w-250px pe-2 fs-7'>Decision/Remarks by ARC Member
                                    (Awarded/Refused)</th>


                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-semibold' style={{ maxHeight: '400px', overflowY: 'auto' }}>

                            <tr>
                                <td >

                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_1: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_1}
                                        readOnly

                                    />
                                </td>
                                <td>
                                    <div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_2_1: "AI" })}
                                            value="AI"
                                            readOnly
                                        /> </div><div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_2_2: "AII" })}
                                            value="AII"
                                            readOnly
                                        />
                                    </div>
                                </td>

                                <td > <div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_3_1: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_3_1}

                                /> </div><div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_3_2: e?.target?.value })}
                                            value={fsmsAnnexure.iaf_1_3_2}

                                        />
                                    </div>
                                </td>
                                <td >
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_4_1: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_4_1}

                                    /> </div><div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_4_2: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_4_2}

                                    />
                                    </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_5_1: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_5_1}
                                    />
                                </div><div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_5_2: e?.target?.value })}
                                            value={fsmsAnnexure?.iaf_1_5_2}
                                        />
                                    </div></td>
                                <td > <div className='text-gray-800 mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_6_1: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_6_1}
                                    />

                                </div><div className='text-gray-800 mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_6_2: e?.target?.value })}
                                            value={fsmsAnnexure?.iaf_1_6_2}
                                        />

                                    </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_7_1: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_7_1}
                                    />
                                </div><div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_7_2: e?.target?.value })}
                                            value={fsmsAnnexure?.iaf_1_7_2}
                                        />
                                    </div></td>

                            </tr>
                            <tr>
                                <td >

                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={8}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_8: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_8}
                                        readOnly

                                    />
                                </td>
                                <td>
                                    <div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_9_1: "BI" })}
                                            value="BI"
                                            readOnly
                                        /> </div><div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_9_2: "BII" })}
                                            value="BII"
                                            readOnly
                                        />
                                    </div>
                                    <div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_9_3: "BIII" })}
                                            value="BIII"
                                            readOnly
                                        />
                                    </div>
                                </td>

                                <td > <div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_10_1: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_10_1}

                                /> </div><div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_10_2: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_10_2}

                                /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_10_3: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_10_3}

                                    /> </div>
                                </td>
                                <td > <div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_11_1: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_11_1}

                                /> </div><div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_11_2: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_11_2}

                                /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_11_3: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_11_3}

                                    /> </div>
                                </td>
                                <td > <div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_12_1: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_12_1}

                                /> </div><div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_12_2: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_12_2}

                                /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_12_3: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_12_3}

                                    /> </div>
                                </td>
                                <td > <div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_13_1: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_13_1}

                                /> </div><div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_13_2: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_13_2}

                                /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_13_3: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_13_3}

                                    /> </div>
                                </td>
                                <td > <div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_14_1: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_14_1}

                                /> </div><div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_14_2: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_14_2}

                                /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_14_3: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_14_3}

                                    /> </div>
                                </td>

                            </tr>

                            <tr>
                                <td >

                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={15}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_15: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_15}
                                        readOnly

                                    />
                                </td>
                                <td>
                                    <div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_16_1: "C0" })}
                                            value="C0"
                                            readOnly
                                        /> </div><div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_16_2: "CI" })}
                                            value="CI"
                                            readOnly
                                        />
                                    </div>
                                    <div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_16_3: "CII" })}
                                            value="CII"
                                            readOnly
                                        />
                                    </div>
                                    <div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_16_4: "CIII" })}
                                            value="CIII"
                                            readOnly
                                        />
                                    </div>
                                    <div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_16_5: "CIV" })}
                                            value="CIV"
                                            readOnly
                                        />
                                    </div>
                                </td>

                                <td > <div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_17_1: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_17_1}

                                /> </div><div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_17_2: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_17_2}

                                /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_17_3: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_17_3}

                                    /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_17_4: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_17_4}

                                    /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_17_5: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_17_5}

                                    /> </div>
                                </td>
                                <td > <div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_18_1: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_18_1}

                                /> </div><div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_18_2: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_18_2}

                                /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_18_3: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_18_3}

                                    /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_18_4: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_18_4}

                                    /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_18_5: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_18_5}

                                    /> </div>
                                </td>
                                <td > <div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_19_1: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_19_1}

                                /> </div><div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_19_2: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_19_2}

                                /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_19_3: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_19_3}

                                    /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_19_4: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_19_4}

                                    /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_19_5: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_19_5}

                                    /> </div>
                                </td>
                                <td > <div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_20_1: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_20_1}

                                /> </div><div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_20_2: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_20_2}

                                /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_20_3: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_20_3}

                                    /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_20_4: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_20_4}

                                    /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_20_5: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_20_5}

                                    /> </div>
                                </td>
                                <td > <div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_21_1: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_21_1}

                                /> </div><div className='text-gray-800  mb-1'><textarea
                                    className='form-control form-control-solid'
                                    rows={2}
                                    name='uaf_requirement'
                                    onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_21_2: e?.target?.value })}
                                    value={fsmsAnnexure.iaf_1_21_2}

                                /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_21_3: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_21_3}

                                    /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_21_4: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_21_4}

                                    /> </div>
                                    <div className='text-gray-800  mb-1'><textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_21_5: e?.target?.value })}
                                        value={fsmsAnnexure.iaf_1_21_5}

                                    /> </div>
                                </td>

                            </tr>

                            {/* <tr>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_8: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_8}
                                    />
                                </div></td>

                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_9: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_9}
                                    />
                                </div></td>
                                <td>
                                    <div className='text-gray-800 mb-1'>
                                        <div className=''>
                                            <textarea
                                                className='form-control form-control-solid'
                                                rows={2}
                                                name='uaf_requirement'
                                                onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_10: e?.target?.value })}
                                                value={fsmsAnnexure?.iaf_1_10}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_11: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_11}
                                    />
                                </div></td>


                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_12: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_12}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_13: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_13}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_14: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_14}
                                    />
                                </div></td>

                            </tr>


                            <tr>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_15: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_15}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_16: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_16}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_17: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_17}
                                    />
                                </div></td>
                                <td>
                                    <div className='text-gray-800 mb-1'>
                                        <div className=''>
                                            <textarea
                                                className='form-control form-control-solid'
                                                rows={2}
                                                name='uaf_requirement'
                                                onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_18: e?.target?.value })}
                                                value={fsmsAnnexure?.iaf_1_18}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_19: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_19}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_20: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_20}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_21: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_21}
                                    />
                                </div></td>

                            </tr>
*/}
                            <tr>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_22: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_22}
                                        readOnly
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_23: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_23}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_24: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_24}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_25: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_25}
                                    />
                                </div></td>
                                <td>
                                    <div className='text-gray-800 mb-1'>
                                        <div className=''>
                                            <textarea
                                                className='form-control form-control-solid'
                                                rows={2}
                                                name='uaf_requirement'
                                                onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_26: e?.target?.value })}
                                                value={fsmsAnnexure?.iaf_1_26}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_27: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_27}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_28: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_28}
                                    />
                                </div></td>

                            </tr>

                            <tr>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_29: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_29}
                                        readOnly
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_30: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_30}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_31: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_31}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_32: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_32}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_33: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_33}
                                    />
                                </div></td>
                                <td>
                                    <div className='text-gray-800 mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_34: e?.target?.value })}
                                            value={fsmsAnnexure?.iaf_1_34}
                                        />
                                    </div>
                                </td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_35: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_35}
                                    />
                                </div></td>

                            </tr>

                            <tr>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_36: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_36}
                                        readOnly
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_37_1: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_37_1}
                                        readOnly
                                    />
                                </div>
                                    <div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_37_2: e?.target?.value })}
                                            value={fsmsAnnexure?.iaf_1_37_2}
                                            readOnly
                                        />
                                    </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_38_1: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_38_1}
                                    />
                                </div>
                                    <div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_38_2: e?.target?.value })}
                                            value={fsmsAnnexure?.iaf_1_38_2}
                                        />
                                    </div>
                                </td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_39_1: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_39_1}
                                    />
                                </div>
                                    <div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_39_2: e?.target?.value })}
                                            value={fsmsAnnexure?.iaf_1_39_2}
                                        />
                                    </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_40_1: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_40_1}
                                    />
                                </div>
                                    <div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_40_2: e?.target?.value })}
                                            value={fsmsAnnexure?.iaf_1_40_2}
                                        />
                                    </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_41_1: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_41_1}
                                    />
                                </div>
                                    <div className='text-gray-800  mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_41_2: e?.target?.value })}
                                            value={fsmsAnnexure?.iaf_1_41_2}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className='text-gray-800 mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_42_1: e?.target?.value })}
                                            value={fsmsAnnexure?.iaf_1_42_1}
                                        />
                                    </div>
                                    <div className='text-gray-800 mb-1'>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={2}
                                            name='uaf_requirement'
                                            onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_42_2: e?.target?.value })}
                                            value={fsmsAnnexure?.iaf_1_42_2}
                                        />
                                    </div>
                                </td>

                            </tr>

                            <tr>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_43: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_43}
                                        readOnly
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_44: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_44}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_45: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_45}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_46: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_46}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_47: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_47}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_48: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_48}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_49: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_49}
                                    />
                                </div></td>
                            </tr>
                            <tr>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_50: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_50}
                                        readOnly
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_51: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_51}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_52: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_52}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_53: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_53}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_54: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_54}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_55: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_55}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_56: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_56}
                                    />
                                </div></td>
                            </tr>
                            <tr>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_57: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_57}
                                        readOnly
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_58: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_58}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_59: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_59}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_60: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_60}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_61: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_61}
                                    />
                                </div></td>

                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_62: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_62}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_63: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_63}
                                    />
                                </div></td>
                            </tr>
                            <tr>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_64: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_64}
                                        readOnly
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_65: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_65}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_66: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_66}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_67: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_67}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_68: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_68}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_69: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_69}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_70: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_70}
                                    />
                                </div></td>
                            </tr>
                            <tr>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_71: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_71}
                                        readOnly
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_72: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_72}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_73: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_73}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_74: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_74}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_75: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_75}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_76: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_76}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_77: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_77}
                                    />
                                </div></td>
                            </tr>
                            <tr>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_78: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_78}
                                        readOnly
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_79: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_79}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_80: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_80}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_81: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_81}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_82: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_82}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_83: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_83}
                                    />
                                </div></td>
                                <td ><div className='text-gray-800  mb-1'>
                                    <textarea
                                        className='form-control form-control-solid'
                                        rows={2}
                                        name='uaf_requirement'
                                        onChange={(e) => setFSMSAnnxeure({ ...fsmsAnnexure, iaf_1_84: e?.target?.value })}
                                        value={fsmsAnnexure?.iaf_1_84}
                                    />
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    {/* <div className='text-gray-800  mb-1'>
                        <button className='btn btn-primary' onClick={submitFSMSAnnexure}>Save </button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default FSMSannexure