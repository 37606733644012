import React from 'react'
import QMSform from './QMSform'
import EMSform from './EMSform'
import EnMSform from './EnMSform'
import SCSMSform from './SCSMSform'
import ABMSform from './ABMSform'
import IBform from './IBform'
import OHSMSform from './OHSMSform'
import FMMSform from './FMMSform'
import HHCMSform from './HHCMSform'
import PIMSform from './PIMSform'
import RTSMSform from './RTSMSform'
import FSMSform from './FSMSform'
import PrCMform from './PrCMform'
import ISMSform from './ISMSform'
import MDQMSform from './MDQMSform'
import EOMSform from './EOMSform'
import PCBform from './PCBform'
import CMSform from './CMSform'
import ItSMSform from './ItSMSform'
import ESMSform from './ESMSform'
import GCHMSform from './GCHMSform'
import GCHMSPIIform from './GCHMSPIIform'
import TLform from './TLForm'
import QMSform2015 from './QMS2015'
import PISMSform from './PISMS'
import OHSASform from './OHSASform'
import MTLform from './MTLform'
import MDQMSRform from './MDQMSRform'
import IMSform from './IMSform'

import FMSform from './FMSform'
import EnMS2018form from './EnMS2018form'
import BCMSform from './BCMSform'
import AMSform from './AMSform'

interface FormRendererProps {
  formId: string | undefined
  userData: any
}

const FormRenderer: React.FC<FormRendererProps> = ({formId, userData}) => {
  // Function to fetch and render form component based on ID
  const renderForm = (id: string | undefined) => {
    switch (id) {
      case '745048c9-17e9-40bc-a8d7-6ef2a91e9aee':
        return <QMSform userData={userData} />
      case '5e26665a-01e1-41c1-9e29-b2c8a977c252':
        return <AMSform userData={userData} />
      case '982d83a9-444c-476a-b98e-c2e091de07ab':
        return <QMSform2015 userData={userData} />
      case '88f8ea2b-09fa-4498-ba23-a24cc3e9e066':
        return <PISMSform userData={userData} />
      case '07f858f5-7e61-4780-baf0-b1ff97210de1':
        return <EMSform userData={userData} />

      case 'e924f344-0c9d-421a-b978-cc57750a362b':
        return <EnMSform userData={userData} />

      case '8e7d34b5-c2a4-4ff3-8530-86170935b9ff':
        return <EnMS2018form userData={userData} />

      case '63e89052-a0a1-4030-a250-d790606ab17c':
        return <SCSMSform userData={userData} />

      case '955b2bd7-ada0-4b50-987a-7d1c1db3c8d2':
        return <FMSform userData={userData} />

      case 'd3efa0f7-afdb-480b-b46e-db870832123c':
        return <ABMSform userData={userData} />

      case '5af37fb8-713e-4aea-bf43-e75981b6163e':
        return <IBform userData={userData} />

      case 'df28ddf3-b330-47d8-abec-a44333e4aabb':
        return <OHSMSform userData={userData} />
      case 'c65d3465-2fda-4554-840c-fdd60854c111':
        return <OHSASform userData={userData} />

      case "444457f7-0373-47d5-9772-1a195191be8e":
      return <FMMSform userData={userData}/>;

      case '6d374618-27f5-40ac-8532-33266ae7b19f':
        return <HHCMSform userData={userData} />

      case '5ae51072-e5a0-4587-9844-873444722551':
        return <PIMSform userData={userData} />

      case 'ec05cb93-0162-4ce9-8353-dd2d82fcdf67':
        return <RTSMSform userData={userData} />

      case '40f65889-bbe5-4784-9e10-38863a220be9':
        return <FSMSform userData={userData} />



      case 'a8e13e13-b5b2-4e7d-8194-a424fabff5a1':
        return <PrCMform userData={userData} />

      case '6cf8cc83-36dd-4661-a0a7-ff87224bcace':
        return <ISMSform userData={userData} />

      case '3d350ed1-ae27-44fe-8b2a-3c6a6029b774':
        return <MDQMSform userData={userData} />

      case '82303cb9-1da9-48ef-89fa-7bb2fa46b4f1':
        return <MDQMSRform userData={userData} />

      case 'ae382191-c0b8-4ca5-ac73-83b6b1031eaa':
        return <MTLform userData={userData} />

      case '6172d32a-e39d-456f-8200-2b8cb2aec8ea':
        return <EOMSform userData={userData} />

      case '0d883a70-a406-47de-bc0a-80a77371e71b':
        return <PCBform userData={userData} />

      case '9cd7f6b3-63de-4e8a-bf46-061be3e06496':
        return <CMSform userData={userData} />

      case '8ebb9841-7b28-4c62-9f1a-212ed206e315':
        return <BCMSform userData={userData} />

      case '01071834-dfd5-4798-8eaa-658da454dc67':
        return <ItSMSform userData={userData} />

      case 'eb88b103-d9a6-4695-a7f8-0b8ed3736a2c':
        return <IMSform userData={userData} />

      case 'a77e93c3-dc34-47b9-a0b8-f27d262a9cdf':
        return <ESMSform userData={userData} />

      case '695abb08-af5b-4a60-af3d-18b03386a873':
        return <GCHMSform userData={userData} />

      case '42340bbd-d58d-407c-b6a2-b10703d0c950':
        return <GCHMSPIIform userData={userData} />

      case '99df8dd6-1cb6-4221-afe1-3f3ac1b34c29':
        return <TLform userData={userData} />

      // Add cases for other form IDs as needed
      default:
        return <div>Form not found</div>
    }
  }

  return (
    <>
      <div>{renderForm(formId)}</div>
    </>
  )
}

export default FormRenderer
