import axios from 'axios';
import React, { useEffect, useState } from 'react'



const VerifiedAssessorAllDoc : React.FC<{urlId: string}> = ({urlId}) => {
    const[documentData,setdocumentData] = useState<any>([]);
    const BASE_URL = process.env.REACT_APP_API_URL

    const fetchuafDocumentData = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/global/uaf/documents/get/`)
          setdocumentData(response.data)
        } catch (error) {
          console.log(error)
        }
      }
      useEffect(() => {
        fetchuafDocumentData()
      }, [])
  return (
    <div>
         {documentData?.length ? (
        <div className='col-xl-12'>
          <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5 location_table_show'
              id='kt_table_users'
            >
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-80px text-center'>S No</th>
                  <th className=''>Description</th>
                  <th className='min-w-125px'>Document</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {documentData?.map((item, index) => {
                  return (
                    <tr key={item?.id}>
                      <td className='min-w-80px text-center'>{index + 1}</td>
                      <td>{item?.description}</td>
                      <td>
                        <a href={item.document} target='_blank' rel='noopener noreferrer'>
                          <img src='/media/doc.png' alt='Doc' />
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null} 
    </div>
  )
}

export default VerifiedAssessorAllDoc