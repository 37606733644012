import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';

type Props={
  userId: string | undefined;
  ARC_member: string | undefined;
  ARC_no: string | undefined;
  officeNumber:string |undefined
  }
interface OfficeReview {
    id: string;
    review_report_qa: string;
    review_report_qb: string;
    cab: string;
    review: string[];
}
type Assessor = {
    id: string;
    first_name: string;
    last_name: string;
};

type AssessorData = {
    update_at?: string;
    scheme_name?: string;
    assessment_number?: string;
    assessor_data?: Assessor[];
    assessor_planner_updated_at?: string;

};

type OfficeReviewData = {
    assessor_data?: AssessorData[];
    review_report_qa?: string;
    review_report_qb?: string;
};
const GenerateOa = ({ userId ,ARC_member,ARC_no,officeNumber }: Props) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [officeReview, setOfficeReview] = useState<any>()
    const [officeReviewed, setOfficeReviewed] = useState<any>()
    const [officeReviewData, setOfficeReviewData] = useState<OfficeReviewData | null>(null);
    const [reviewReportA, setReviewReportA] = useState<any>()
    const [reviewReportB, setReviewReportB] = useState<any>()
    const [reviewReportC, setReviewReportC] = useState<any>()
    const [reviewReportD, setReviewReportD] = useState<any>()
    const [reviewReportE, setReviewReportE] = useState<any>()
    const [reviewReportF, setReviewReportF] = useState<any>()
    const [reviewReportG, setReviewReportG] = useState<any>()
    const [reviewReportH, setReviewReportH] = useState<any>()
    const [reviewReportI, setReviewReportI] = useState<any>()
    const [reviewReportJ, setReviewReportJ] = useState<any>()
    const [reviewReportK, setReviewReportK] = useState<any>()
    const [reviewReportL, setReviewReportL] = useState<any>()
    const [assessorPlannerDataFromAssessment, setAssessorPlannerDataFromAssessment] = useState<any>([])
    //   console.log("documentReview:", documentReview);
    //   console.log("officeReviewData:", documentReviewData);


    const formatMyDate = (date: Date | string | null) => {
      if (!date) return null;
    
      // If the date is a string, parse it to a Date object
      const parsedDate = typeof date === "string" ? new Date(date) : date;
    
      // Ensure the parsed date is valid
      if (isNaN(parsedDate.getTime())) return null;
    
      const year = parsedDate.getFullYear();
      const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
      const day = String(parsedDate.getDate()).padStart(2, "0");
    
      return `${month}-${day}-${year}`;
    };
    useEffect(() => {
        if (userId) {
      const getAssessorDataByAssessment = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/cab/filter/scheme/assessor/planner/?cab=${userId}&assessor=&scheme=&assessor_planner=&assessment_type=&assessment_type_name=Office+Assessment&assessment_number=${officeNumber}&create_at=&update_at=`
          )
          setAssessorPlannerDataFromAssessment(response.data)
        } catch (error) {
          //console.log(error)
        }
      }
      getAssessorDataByAssessment();
    }
    }, [userId]);
    useEffect(() => {
        if (userId) {
            const getOfficeReview = async () => {
                try {
                    const response = await axios.get(`${API_URL}/arc/office/assessment/get/?cab=${userId}&assessor=${ARC_member}`);
                    setOfficeReview(response?.data?.id);
                    setOfficeReviewed(response?.data?.review_remark)

                    setReviewReportA(response?.data?.review_report_qa)
                    setReviewReportB(response?.data?.review_report_qb)
                    setReviewReportC(response?.data?.review_report_qc)
                    setReviewReportD(response?.data?.review_report_qd)
                    setReviewReportE(response?.data?.review_report_qe)
                    setReviewReportF(response?.data?.review_report_qf)
                    setReviewReportG(response?.data?.review_report_qg)
                    setReviewReportH(response?.data?.review_report_qh)
                    setReviewReportI(response?.data?.review_report_qi)
                    setReviewReportJ(response?.data?.review_report_qj)
                    setReviewReportK(response?.data?.review_report_qk)
                    setReviewReportL(response?.data?.review_report_ql)
                } catch (error) {
                    console.error('API Error:', error);
                    toast.error("problem to fetch Office review")
                }
            };
            getOfficeReview();
        }
    }, [userId]);
    // useEffect(() => {
    //     if (officeReview) {
    //         const getOfficeReviewData = async () => {
    //             try {
    //                 const response = await axios.get(`${API_URL}/arc/document/review/${officeReview}/get/`);
    //                 setOfficeReviewData(response.data);
    //             } catch (error) {
    //                 console.error('API Error:', error);
    //                 toast.error("problem to fetch Office review")
    //             }
    //         };
    //         getOfficeReviewData();
    //     }
    // }, [officeReview]);

    return (
        <div className=' mb-5 mb-xl-10' id='kt_profile_details_view'
        style={{width:"100%",
          background: "#fff",
          padding: "25px",
          borderRadius: "5px"
        }}>
            <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>
                        Office Review Report<span style={{ color: 'red' }}>*</span>
                    </h3>
                </div>
            </div>
            <div className='card-body p-9'>
                <div className='row mb-7'>
                <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                          <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                              <th className='w-10px pe-2 fs-7'>Date(s)</th>
                              <th className='w-10px pe-2 fs-7'>Schemes</th>
                              <th className='w-10px pe-2 fs-7'>Assessment Number</th>
                              <th className='w-10px pe-2 fs-7'>Assessment Duration</th>
                              <th className='w-10px pe-2 fs-7'>Assessor Name</th>
                            </tr>
                          </thead>

                          <tbody className='text-gray-600 fw-semibold'>
                            {assessorPlannerDataFromAssessment?.map((item) => (
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item && item.update_at ? formatMyDate(item.update_at) : '--'}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.scheme_name}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.assessment_number}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.assessment_duration}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.assessor_name?.map((assessor, index) => (
                                      <span key={assessor[0].id}>
                                        {assessor[0].first_name} {assessor[0].last_name}
                                        {index < item.assessor_name.length - 1 ? ', ' : ''}
                                      </span>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            ))}

                          </tbody>
                        </table>
                </div>
                <div className='row'>
                      <div className='mb-5 col-lg-12'>
                        <h3>Competence verification of Assessment Team for office assessment, if applicable </h3>
                        <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                          <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                              <th className='w-10px pe-2 fs-7'>Assessor Name</th>
                              <th className='w-10px pe-2 fs-7'>Schemes</th>

                              <th className='w-10px pe-2 fs-7'>Competence Verification </th>
                              <th className='w-10px pe-2 fs-7'>Remarks</th>
                            </tr>
                          </thead>
                          <tbody className='text-gray-600 fw-semibold'>
                            {assessorPlannerDataFromAssessment?.map((item) => (
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <ul style={{ listStyle: "none" }}>
                                      {item?.assessor_name?.map((assessor) => (
                                        <li className="mb-1" key={assessor[0].id}>
                                          {assessor[0].first_name} {assessor[0].last_name}

                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </td>

                                <td>
                                  <div className='text-gray-800 mb-1'>
                                  {item?.scheme_name}
                                  </div>
                                </td>

                                <td>
                                  <div className='text-gray-800 mb-1'>
                                  Verified
                                  </div>
                                </td>
                                <td>
                                  {/* <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      value={oRRemarks[item.id] || ''}
                                      onChange={(e) => handleORRemarksChange(item.id, e.target.value)}
                                      name='uaf_requirement'
                                    />
                                  </div> */}





                                  <div className="text-gray-800 mb-1">

                                        {officeReviewed?.map((reviewItem: any) => {
                                          if (reviewItem.review === item.id) {
                                            return (
                                              <div
                                                key={reviewItem.id}
                                               >
                                               {reviewItem.remark || ''}

                                              </div>
                                            );
                                          }
                                          return null;
                                        })}





                                  </div>
                                </td>
                              </tr>
                            ))}

                          </tbody>
                        </table>
                      </div>
                    </div>
                <div className='row mt-10'>
                    <div className='mb-5 col-lg-12'>
                        <h3>Review of Office Review Report: </h3>
                        <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                            <thead>
                                <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                    <th style={{ width: "50%" }} className='pe-2 fs-7'>Q</th>
                                    <th style={{ width: "50%" }} className='pe-2 fs-7'>A</th>

                                </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-semibold'>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        A - Is the Assessment plan accepted by CAB prior to assessment?
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                           {reviewReportA}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        B -   An opening Meeting reference and the purpose of the assessment and accreditation available in the assessment report requirements are clearly defined and the assessment plan as well as the scope for the assessment are confirmed.
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                        {reviewReportB}
                                        </div>
                                    </td>


                                </tr>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        C - Verification of Professional Indemnity Insurance and reserves
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                        {reviewReportC}
                                        </div>
                                    </td>


                                </tr>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        D -  Verification of Corrective Action implementation from previous assessment (nonconformities), if any.
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                        {reviewReportD}
                                        </div>
                                    </td>


                                </tr>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        E - Number of Active Client Files sampled:
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                        {reviewReportE}
                                        </div>
                                    </td>


                                </tr>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        F - Number of Withdrawn/Suspended Client Files sampled:
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                        {reviewReportF}
                                        </div>
                                    </td>


                                </tr>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        G -  Number of Major/Minor Non-Conformities Issued, if any.
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                        {reviewReportG}
                                        </div>
                                    </td>


                                </tr>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        H - Is there sufficient evidence that the nonconformities/findings have been closed out?
                                        Latest Date of closure

                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                        {reviewReportH}
                                        </div>
                                    </td>


                                </tr>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        I - Closing Meeting reference</div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                        {reviewReportI}
                                        </div>
                                    </td>


                                </tr>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        J -  Does the Assessment report contain comments on the competence as determined through conformity and are they adequate to support the conclusions arising from the assessment?
                                        (Were the human resources competency verified and adequate to support the applied scopes?)

                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                        {reviewReportJ}
                                        </div>
                                    </td>


                                </tr>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        K -  Does the Assessment report contain scope/ criteria assessed and clear recommendations w.r.to assessment scope/ criteria?
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                        {reviewReportK}
                                        </div>
                                    </td>


                                </tr>

                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        L -  Has the lead assessor made recommendations/decisions based on objective evidence?
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                        {reviewReportL}
                                        </div>
                                    </td>


                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GenerateOa