import React, { useCallback, useEffect, useState } from 'react'

import BusinessUnitFilter from '../models/businessUniterFilter'
import Select from 'react-select'
import { Dropdown, DropdownButton, Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'

import CreateManualAssesor from '../../assessorLead/models/CreateManualAssesor'
import UAFLoader from '../../../../loader/UafLoader'
import { KTIcon } from '../../../../../_metronic/helpers'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx';
import { usePermissions } from '../../../../permission_component/PermissionContext'
import debounce from 'lodash/debounce'


interface PendingAssessor {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  is_assessor: boolean
  is_verify_assessor: boolean
  assessor: string
  store_password: string
  scheme_names: { id: string; name: string }[]
  programme_names: { id: string; name: string }[]
  accessor_approve_date: string
  create_at: string
}
const formatMyDate = (date: Date | string | null) => {
  if (!date) return null;

  // If the date is a string, parse it to a Date object
  const parsedDate = typeof date === "string" ? new Date(date) : date;

  // Ensure the parsed date is valid
  if (isNaN(parsedDate.getTime())) return null;

  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");

  return `${month}-${day}-${year}`;
};
const ArcMemberLists = () => {

  const { assessorPermission, cabPermission, superUserPermission, updatePermissions } = usePermissions();
  const BASE_URL = process.env.REACT_APP_API_URL

  const [filteredValue, setFilteredValue] = useState<PendingAssessor[]>([])
  const [pagenumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1);
  const [totalcount, settotalcount] = useState(0);
  const [value, setValue] = useState<any[]>([])
  console.log(value, "valuevaluevaluevaluevalue");


  const totalPagesArray = Array.from({ length: totalPages }, (_, index) => index + 1);
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setPageNumber(page);
    }
  };

  const fetchData = async () => {
    try {
      // setLoader(true);
      const response = await axios.get(
        `${BASE_URL}/team/accessor/verified/list/pagination/?page=${pagenumber}&assign_role_name=ARC`
      );
      const pages = Math.ceil(response?.data?.count / 10);
      settotalcount(response?.data?.count);
      setTotalPages(pages);
      if (response.status === 200) {
        setValue(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagenumber]);

  const handleSearchChange = useCallback(
    debounce(async (e) => {
      const searchValue = e?.target?.value;
      console.log(searchValue, 'searchValue');
      if (searchValue) {
        try {
          // setLoader(true);
          const response = await axios.get(
            `${BASE_URL}/team/accessor/verified/list/pagination/?search=${searchValue}&assign_role_name=ARC`
          );
          // settotalcount(response?.data?.count);
          if (response.status === 200) {
            setFilteredValue(response.data?.results);
          }
        } catch (error) {
          console.log(error);
        } finally {
          // setLoader(false);
        }
      } else {
        setFilteredValue([]);
        fetchData();
      }
    }, 500),
    []
  );

  return (
    <div id="arc_member_list">
      <div className='row jusify-content-space-between'>
        <div className='col-md-6 d-flex align-items-center justify-content-start'>

          <h2 className='mb-10 p-0 '>ARC Member{totalcount > 0 && (
            <span
              className='card-label fw-bold fs-1 m-2'
              style={{ backgroundColor: '#136BA1', color: 'white', padding: '0.3em 0.5em' }}
            >
              {totalcount}
            </span>
          )} </h2>
        </div>


      </div>


      <div className='card card-flush'>
        <div className='card-header pt-5'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='currentColor'
                  />
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <input
                type="text"
                id="arc_filter"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-250px ps-14"
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearchChange(e);
                  }
                }}
                placeholder="Search user"
              />
            </div>
          </div>

        </div>
        <div className='card-body pt-5'>
          <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
              <tr className='text-start  fw-bold  text-uppercase gs-0'>
                <th className='min-w-125px'>Assessor Name</th>
                <th className='min-w-125px'>Contact Info</th>
                <th className='min-w-125px'>Schemes</th>
                <th className='min-w-125px'>Status</th>
                <th className='min-w-125px'>Approve Date</th>
                {/* <th className='text-center min-w-100px'>Actions</th> */}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {(filteredValue?.length > 0 ? filteredValue : value)?.map((item, index) => (
                <tr key={index}>

                  <td>
                    <Link
                    id="member_profile_link"
                      to={`/assessor/verified/${item?.id}/overview`}
                      className='text-gray-800 text-hover-primary mb-1'
                    >
                      {item?.first_name} {item?.last_name}
                    </Link>
                  </td>
                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>{item?.email}</div>
                  </td>
                  <td>
                    <DropdownButton id='dropdown-basic-button' title='Schemes'>
                      {item.scheme_names?.map((certification, index) => (
                        <Dropdown.Item key={index}>{certification?.name}</Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </td>
                  <td>
                    <div className='badge badge-light-primary fw-bold'>Approved</div>
                  </td>
                  <td>{item && item.create_at ? formatMyDate(item.create_at) : ''}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <ul className='pagination'>
            <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
              <a  id="page_link_frw" href='#' className='page-link' onClick={() => handlePageChange(pagenumber - 1)}>
                <i className='previous'></i>
              </a>
            </li>
            {totalPagesArray.map((page,index) => (
              <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
                <a id={`hello_${index}`} href='#' className='page-link' onClick={() => handlePageChange(page)}>
                  {page}
                </a>
              </li>
            ))}
            <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
              <a id="page_link_back" href='#' className='page-link' onClick={() => handlePageChange(pagenumber + 1)}>
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>
      </div >
    </div>
  )
}

export default ArcMemberLists