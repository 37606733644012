import React, { useEffect, useState } from "react";
import Select from 'react-select'
import { Modal } from "react-bootstrap";
import { KTIcon } from "../../../../_metronic/helpers";

import axios from "axios";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL


const ArcJobOrderModal = ({ show, handleClose }) => {
    const [createJobDetail, setCreateJobDetail] = useState({
        cab: "",
        assessment: "",
        assessor: "",
        assessor_role: "",
        system: "",
        on_off_site: "",
        total_manday: "",
        manday_rate: "",
        arrangements_assessor: "",
        value_of_job_usd: "",
        job_order_approved: "",
        job_order_issued: "",
        value_of_job_words: ""

    })

const [roleList, setRoleList] = useState<any>();

    const fetchRoleData = async () => {
        try {
            const response = await axios.get(
                `${API_URL}/role/list/`
            )
            const filter = response.data?.map((item) => ({
                label: item?.name,
                value: item?.id
            }))
            setRoleList(filter)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchRoleData();
    }, [])
    const validate = () => {
        // if (createJobDetail.system?.trim() === "" || !createJobDetail.system) {
        //     toast.error("Please Enter Systems")
        //     return false
        // }
        if (createJobDetail.total_manday?.trim() === "" || !createJobDetail.total_manday) {
            toast.error("Please Enter Total Mandays")
            return false
        }
        if (createJobDetail.manday_rate?.trim() === "" || !createJobDetail.manday_rate) {
            toast.error("Please Enter Manday Rate")
            return false
        }
        if (createJobDetail.arrangements_assessor?.trim() === " " || !createJobDetail.arrangements_assessor) {
            toast.error("Please Enter Travel Arrangements")
            return false
        }
        if (createJobDetail.value_of_job_usd?.trim() === "" || !createJobDetail.value_of_job_usd) {
            toast.error("Please Enter Value of Job Order")
            return false
        }
        if (createJobDetail.job_order_approved?.trim() === "" || !createJobDetail.job_order_approved) {
            toast.error("Please Enter Job Order Approved By")
            return false
        }
        if (createJobDetail.job_order_issued?.trim() === "" || !createJobDetail.job_order_issued) {
            toast.error("Please Enter Job Order Issued By")
            return false
        }
        if (createJobDetail.value_of_job_words?.trim() === "" || !createJobDetail.value_of_job_words) {
            toast.error("Please Enter Total Value")
            return false
        }

        return true
    };

    // Post Job
    const createJobPost = async () => {
        if (!validate()) return


        try {
            const data=createJobDetail
            data.assessment=show.id
            data.cab=show.cabId
            data.assessor=show.assessor
            data.assessor_role=roleList?.find(item=>item.label==show.assessor_role__name)?.value
            data.on_off_site=show.assessor_location
            console.log(data,'dfkjhkda');
            const response = await axios.post(`${API_URL}/arc/job/order/create/`, data);
            toast.success("Job Order Created Successfully")
            handleClose();
            setCreateJobDetail({
                ...createJobDetail,
                assessment: "",
                assessor: "",
                assessor_role: "",
                // system: "",
                on_off_site: "",
                total_manday: "",
                manday_rate: "",
                arrangements_assessor: "",
                value_of_job_usd: "",
                job_order_approved: "",
                job_order_issued: "",
                value_of_job_words: ""
            })


        } catch (error) {
            console.log(error);
        }
    }
    const formatMyDate = (date: Date | string | null) => {
        if (!date) return null;
      
        // If the date is a string, parse it to a Date object
        const parsedDate = typeof date === "string" ? new Date(date) : date;
      
        // Ensure the parsed date is valid
        if (isNaN(parsedDate.getTime())) return null;
      
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
        const day = String(parsedDate.getDate()).padStart(2, "0");
      
        return `${month}-${day}-${year}`;
      };

    return (

        <Modal
            id='create_arc_job_order'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            backdrop="static"
        >
            <div className='modal-header'>
                <h2>Create Job Order</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
                {/* end::Close */}
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
                <div className="container">
                    <div className='card mb-5 mb-xl-10'>
                        <div className='card-body px-9 py-5'>
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder mb-3'>Job Order issued to: </h4>
                              <input  type="text" className="form-control form-control-solid w-50" placeholder="--" disabled value={`${show?.assessor__first_name} ${show?.assessor__last_name}`} />

                            </div>

                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Job Order Number: <span className="inline_master_head">XXXX-XXX</span></h4>
                                <h4 className='fw-bolder m-0'>Job Order date: <span className="inline_master_head">{formatMyDate(new Date())}</span></h4>
                            </div>

                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Role in Assessment:</h4>
                             <input type="text" className="form-control form-control-solid w-50" placeholder="--" disabled value={show?.assessor_role__name} />


                            </div>
                            <hr className="jo_hr"/>
                            {/* <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>SYSTEMS:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, system: e.target.value || '' })} value={createJobDetail?.system} />
                            </div>
                            <hr className="jo_hr" /> */}
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Onsite & Offsite:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" disabled value={show?.assessor_location} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Total Mandays:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, total_manday: e.target.value || '' })} value={createJobDetail?.total_manday} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Manday Rate:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, manday_rate: e.target.value || '' })} value={createJobDetail?.manday_rate} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Travel Arrangements by Assessors if any:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, arrangements_assessor: e.target.value || '' })} value={createJobDetail?.arrangements_assessor} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Value of Job Order in USD:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, value_of_job_usd: e.target.value || '' })} value={createJobDetail?.value_of_job_usd} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Job Order Approved By:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, job_order_approved: e.target.value || '' })} value={createJobDetail?.job_order_approved} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Job Order Issued By:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, job_order_issued: e.target.value || '' })} value={createJobDetail?.job_order_issued} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Total Value of Job Order:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, value_of_job_words: e.target.value || '' })} value={createJobDetail?.value_of_job_words} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0 pb-2'>Issued on behalf of Director</h4>
                                <p className="inline_master_head m-0">United Accreditation Foundation Inc (UAF) </p>
                                <img
                                    alt='Logo'
                                    src={('/media/logos/uaf_logo.png')}
                                    className='h-30px h-lg-60px py-2 app-sidebar-logo-default theme-light-show'
                                />
                                <p className="inline_master_head m-0">400 North Center Dr, STE 202 </p>
                                <p className="inline_master_head m-0">Norfolk, VA 23502 </p>
                                <p className="inline_master_head m-0">United States of America</p>
                                <p className="inline_master_head m-0">+1-757-228-5581</p>
                                <p className="inline_master_head m-0">www.uafaccreditation.org </p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex gap-5">
                        <button id='create_arc_job_order_3' className='thm-btn' onClick={createJobPost}>
                            Send
                        </button>
                    </div>

                </div>

            </div>

        </Modal>

    );
};

export default ArcJobOrderModal;