/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import {DateTime} from 'luxon'
import React, {useCallback, useEffect, useState} from 'react'
import AssessmentPlannerTableModal from './modal/AssessmentPlannerModal'
import EditAssessmentModal from './modal/EditAssessmentModal'
import {Button, Modal} from 'react-bootstrap'
import Select, {components} from 'react-select'
import JobOrderPlanner from './modal/JobOrderPlanner'
import {KTIcon} from '../../../../../_metronic/helpers'
import {toast} from 'react-toastify'
// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx'
import {Link, useNavigate} from 'react-router-dom'
import UAFLoader from '../../../../loader/UafLoader'
import {Tooltip} from '@mui/material'
import debounce from 'lodash/debounce'
import {ExportAllAssessments} from './ExportAllAssessment'
import {MultiValue} from 'react-select'
import _ from 'lodash'

const API_URL = process.env.REACT_APP_API_URL

// type Props = {
//   className: string
// }
type Props = {
  handleClose: () => void
}
type RoleOption = {
  value: string // or the appropriate type for your value
  label: string
}

interface PendingAssessor {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  is_assessor: boolean
  is_verify_assessor: boolean
  assessor: string
  store_password: string
  scheme_names: {id: string; name: string}[]
  programme_names: {id: string; name: string}[]
  accessor_approve_date: string
  create_at: string
}

// const BusinessUnitList = ({ className }: Props) => (
const AssessmentPlannerWrapper: React.FC = () => {
  const AssessmentLocationArr = [
    {value: 'Remote', label: 'Remote'},
    {value: 'Onsite', label: 'Onsite'},
    {value: 'Offsite', label: 'Offsite'},
  ]

  const [loader, setLoader] = useState<boolean>(false)
  const [assessmentPlannerTableData, setAssessmentPlannerTableData] = useState<any>([])
  const [assessorRemarks, setAssessorRemarks] = useState<any>()
  const [editUser, setEditUser] = useState<any>([])
  const [assesorTypeOption, setAssesorTypeOption] = useState<any>([])

  console.log(assesorTypeOption, 'assesorTypeOption')
  const [editAssessorRole, setEditAssessorRole] = useState<string | null>(null)
  const [editAssessorType, setEditAssessorType] = useState<string | null>(null)
  const [userClusters, setUserClusters] = useState<Array<{value: string; label: string}>>([])
  const [editAssessorId, setEditAssessorId] = useState<any>()
  const [editAssessorLoc, setEditAssessorLoc] = useState<any>()
  const [editAssessorRemarks, setEditAssessorRemarks] = useState<any>()
  console.log(
    editAssessorRole,
    editAssessorId,
    editAssessorLoc,
    editAssessorRemarks,
    editAssessorType,
    'assessorRemarks'
  )
  console.log(editAssessorId, 'editAssessorId')

  const [showAssessmentTableModal, setShowAssessmentTableModal] = useState<boolean>(false)
  const [showAssessorModal, setShowAssessorModal] = useState<boolean>(false)
  const [showDelModal, setShowDelModal] = useState<boolean>(false)
  const [editAssessmentModal, setEditAssesmentModal] = useState<boolean>(false)
  const [jobOrderModal, setJobOrderModal] = useState<boolean>(false)
  const [showAssessentClicked, setShowAssessmentClicked] = useState<any>()
  const [assessorSchemeList, setAssessorSchemeList] = useState<any>()
  console.log(assessorSchemeList, 'assessorSchemeList')

  //console.log(assessorSchemeList,"assessorSchemeListassessorSchemeListassessorSchemeListassessorSchemeListassessorSchemeList");

  const [assessmentSchemeData, setAssessmentSchemeData] = useState<any>()
  const [roleID, setRoleId] = useState<any>()
  const [typeID, setTypeId] = useState<any>()
  const [sortByCluster, setSortByCluster] = useState<any>()
  const [assessmntId, setAssessmntId] = useState<any>()
  //console.log(roleID, "roleID");

  const [techExpertPlayer, setTechExpertPlayer] = useState<any[]>([])
  //console.log(assessorSchemeList, 'assessorSchemeList')
  const navigate = useNavigate()

  const handleAssessorSchemeList = (item) => {
    //console.log(item, 'itemdsfdsfd')
    const data = item?.schemeData?.map((i, index) => ({
      schemeId: i?.id,
      label: i?.scheme_short_name,
      value: i?.scheme,
      role: '',
      assessor: [],
      selectedAssessor: [],
      id: index + 1,
      schemeCluster: i?.scheme_cluster,
    }))
    setAssessmntId(item?.id)
    setAssessorSchemeList(data)
  }

  const handleAddRowSchemeAssessor = (item) => {
    const data = [...assessorSchemeList]
    setAssessorSchemeList([
      ...data,
      {
        ...item,
        role: '',
        assessor: [],
        selectedAssessor: [],
        id: data.length + 1,
        assessmentId: '',
      },
    ])
  }
  const handleRemoveRowSchemeAssessor = (item) => {
    setAssessorSchemeList((prevList) => prevList.filter((row) => row.id !== item.id))
  }

  //console.log(roleID, 'roleID')
  // useEffect(() => {
  //     if (showAssessentClicked) {

  //         setRoleId(showAssessentClicked?.schemeData)
  //     }
  // }, [showAssessentClicked])
  const [selectedTechPlayer, setSelectedTechPlayer] = useState<any[]>([])
  //console.log(selectedTechPlayer, 'selectedTechPlayer')

  const [accessorList, setAccessorList] = useState<PendingAssessor[]>([])
  const [assessorValue, setAssessorValue] = useState<any>()
  //console.log(assessorValue, 'assessorValue')
  const [roleList, setRoleList] = useState<any>()
  const [teamLeadData, setTeamLeadData] = useState<any>()
  const [dummyData, setDummyData] = useState<any>([])
  const [dummyAssess, setDummyAssess] = useState<any>([])
  const [allFilters, setAllFilters] = useState<any>([])
  const [assessorNameSelect, setAssessorNameSelect] = useState<any>([])
  console.log(roleList, 'roleList')
  //console.log(showAssessentClicked, 'showAssessentClicked')
  const [isChecked, setIsChecked] = useState(false)
  const [isTechExpertChecked, setIsTechExpertChecked] = useState(false)
  const [teamMemberData, setTeamMemberData] = useState<any>()
  //console.log(teamMemberData, 'teamMemberData')
  const [teamMemberDataScehme, setTeamMemberDataScehme] = useState<any>()
  //console.log(teamMemberDataScehme, 'teamMemberDataScehme')
  const [schemeAssessorSelect, setSchemeAssessorSelect] = useState<any>([])
  const [editAssessorItem, setEditAssessorItem] = useState<any>([])
  const [editScheme, setEditScheme] = useState<any>([])
  console.log(editScheme, 'editScheme')

  const [searchValue, setSearchValue] = useState<any>()
  //console.log(schemeAssessorSelect, 'schemeAssessorSelect')
  //console.log(searchValue, 'searchValue')

  const [selectedTechScheme, setSelectedTechScheme] = useState<any>()
  const [techexpertRole, setTechexpertRole] = useState<any>()
  //console.log('techexpertRole:', techexpertRole)
  //console.log('selectedTechScheme:', selectedTechScheme)
  const [showDeleteAssessment, setShowDeleteAssessment] = useState<boolean>(false)
  const [editAssessor, setEditAssessor] = useState<boolean>(false)
  const [pagenumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const BASE_URL = process.env.REACT_APP_API_URL

  // get assessment list
  const fetchData = async () => {
    try {
      setLoader(true)
      const response = await axios.get<PendingAssessor[]>(
        `${BASE_URL}/team/accessor/verified/list/`
      )
      setAccessorList(response.data)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }
  const fetchRoleData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/role/list/`)
      const filter = response.data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }))
      setRoleList(filter)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchRoleData()
    fetchData()
  }, [])

  useEffect(() => {
    getAssessmentPlannerData()
  }, [pagenumber])

  const getAssessmentPlannerData = () => {
    setLoader(true)
    axios
      .get(`${API_URL}/cab/get/assessor/planner/pagination/?page=${pagenumber}`)
      .then((response) => {
        const result = response.data.results // Assuming API returns paginated data in 'results'
        // const total = response.data.total;

        // Calculate total pages
        const pages = Math.ceil(response?.data?.count / 10) // Each page has 10 items
        setTotalPages(pages)

        // Sort the result array by createdAt field in descending order
        result.sort((a, b) => {
          // Convert createdAt strings to Date objects for comparison
          const dateA = new Date(a.create_at).getTime()
          const dateB = new Date(b.create_at).getTime()

          // Compare the dates
          return dateB - dateA
        })

        setAssessmentPlannerTableData(result)
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        console.error('API Error:', error)
      })
  }

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setPageNumber(page)
    }
  }
  // useEffect(() => {
  //   getAssessmentPlannerData()
  // }, [])
  const handleAssessmentNameChange = (schemeItem, e) => {
    setAssessorNameSelect(e)

    const updatedSchemeData = assessorSchemeList?.map((item) => {
      if (item.id === schemeItem.id) {
        return {...item, selectedAssessor: e}
      }
      return item
    })
    setAssessorSchemeList(updatedSchemeData)
    setAssessorValue(updatedSchemeData)
  }
  // useEffect(() => {
  //     if (assessorValue) {

  //         const filter1 = assessorValue?.filter((item) => item?.assessorName?.length);
  //         console.log(filter1, 'filter1')
  //         // setDummyAssess([...dummyAssess, ...filter1]);
  //         setAllFilters(prevFilters => [...prevFilters, ...filter1]);

  //     }
  // }, [assessorValue]);
  useEffect(() => {
    if (assessorValue) {
      const filter1 = assessorValue?.filter((item) => item?.assessorName?.length)

      setAllFilters((prevFilters) => {
        // Create a map of previous filters by id
        const prevFiltersMap = new Map(prevFilters.map((item) => [item.id, item]))

        // Update the map with new filter1 values
        filter1.forEach((item) => {
          prevFiltersMap.set(item.id, item)
        })

        // Convert the map back to an array
        return Array.from(prevFiltersMap.values())
      })
    }
  }, [assessorValue])

  const handleAssessmentLocationChange = (selectedOption, assessor, scheme) => {
    let updatedSchemeAssessorSelect = [...assessorSchemeList]
    let schemeToUpdate = updatedSchemeAssessorSelect.find((s) => s.id === scheme.id)

    if (schemeToUpdate) {
      let assessorToUpdate = schemeToUpdate.selectedAssessor.find((a) => a.value === assessor.value)
      if (assessorToUpdate) {
        assessorToUpdate.assessmentLocation = selectedOption.value
      }
    }
    setAssessorSchemeList(updatedSchemeAssessorSelect)
    setSchemeAssessorSelect(updatedSchemeAssessorSelect)
  }
  const handleRemarksChange = (e, assessor, schemeItem) => {
    const updatedRemarks = [...assessorSchemeList] // create a copy of the existing roleId array
    let schemeToUpdate = updatedRemarks.find((s) => s.id === schemeItem.id)
    if (schemeToUpdate) {
      let assessorToUpdate = schemeToUpdate.selectedAssessor.find((a) => a.value === assessor.value)
      if (assessorToUpdate) {
        assessorToUpdate.remark = e.target.value
      }
    }

    setAssessorRemarks(updatedRemarks)
    setAssessorSchemeList(updatedRemarks)
  }

  useEffect(() => {
    if (allFilters) {
      const updatedTeamMemberData = assessorValue?.map((item) => {
        const filterResult = allFilters?.find((filterItem) => filterItem.id === item.id)
        return filterResult ? filterResult : item
      })
      setSchemeAssessorSelect(updatedTeamMemberData)
    }
  }, [allFilters])

  const handleAssignAssessor = async (item) => {
    const assessorData =
      item?.selectedAssessor?.map((i) => ({
        assessor: i?.value,
        location: i?.assessmentLocation || 'None',
        assessor_role: item?.role,
        assessor_type: item?.type,
        remark: i?.remark || '',
      })) || []
    const assessor = item?.selectedAssessor?.map((i) => i?.value) || []

    const data = {
      assessor: assessor,
      assessor_data: assessorData,
      assessmntId: assessmntId,
    }
    try {
      await axios.post(`${BASE_URL}/cab/assign/assessor/${item?.schemeId}/scheme/`, data)

      setShowAssessorModal(false)
      getAssessmentPlannerData()
    } catch (error) {
      console.error('Error assigning assessor:', error)
    }
  }

  const CoiFOrAssesssor = async () => {
    try {
      await axios.post(`${BASE_URL}/cab/send/email/assessor/`, {assessmntId: assessmntId})
    } catch (error) {
      console.error('Error COI assessor:', error)
    }
  }

  const handleTechAssessor = (event) => {
    if (event) {
      const selectedTechPlayerData = techExpertPlayer.find((player) => player.id === event.value)
      setSelectedTechPlayer(selectedTechPlayerData)
    }
  }

  const handleAddAssessor = async () => {
    assessorSchemeList?.forEach((item) => {
      if (item?.selectedAssessor?.length) {
        handleAssignAssessor(item)
      }
    })
  }

  const handleSchemeAssessorCluster = (schemeItem, selectedOptions) => {
    const updatedSchemeData = [...assessorSchemeList]
    const index = updatedSchemeData.findIndex((item) => item.id === schemeItem.id)
    const selectedCluster = selectedOptions.map((option) => option.value)
    if (index !== -1) {
      updatedSchemeData[index] = {
        ...updatedSchemeData[index],
        selectedCluster,
      }
    } else {
      updatedSchemeData.push({
        ...schemeItem,
        selectedCluster,
      })
    }
    setSortByCluster(updatedSchemeData)
    setAssessorSchemeList(updatedSchemeData)
  }

  const handleSchemeAssessorRole = (schemeItem, e) => {
    const updatedSchemeData = [...assessorSchemeList] // create a copy of the existing roleId array
    const index = updatedSchemeData.findIndex((item) => item.id === schemeItem.id)
    if (index !== -1) {
      updatedSchemeData[index] = {...updatedSchemeData[index], role: e.value}
    } else {
      updatedSchemeData.push({...schemeItem, role: e.value})
    }
    setRoleId(updatedSchemeData)
    setAssessorSchemeList(updatedSchemeData)
  }
  const handleSchemeAssessorType = (schemeItem, e) => {
    const updatedSchemeData = [...assessorSchemeList] // create a copy of the existing roleId array
    const index = updatedSchemeData.findIndex((item) => item.id === schemeItem.id)
    if (index !== -1) {
      updatedSchemeData[index] = {...updatedSchemeData[index], type: e.value}
    } else {
      updatedSchemeData.push({...schemeItem, type: e.value})
    }
    setTypeId(updatedSchemeData)
    setAssessorSchemeList(updatedSchemeData)
  }
  const [dataWithScheme, setDataWithScheme] = useState<any>([])
  const [dummyDataWithScheme, setDummyDataWithScheme] = useState<any>([])
  //console.log(dataWithScheme, 'dataWithScheme')

  const fetchAssessmentSchemeData = async () => {
    const responses = await Promise.all(
      assessmentPlannerTableData?.map(async (assessment) => {
        try {
          setLoader(true)
          const response = await axios.get(
            `${API_URL}/cab/get/assessor/planner/scheme/${assessment.id}/data`
          )
          return {...assessment, schemeData: response.data}
        } catch (error) {
          console.error('API Error:', error)
          return {...assessment, schemeData: []}
        }
      })
    )
    const validResponses = responses?.filter((response) => response !== null)
    setDataWithScheme(responses)
    setDummyDataWithScheme(responses)
    setLoader(false)
  }
  useEffect(() => {
    fetchAssessmentSchemeData()
  }, [assessmentPlannerTableData])

  const fetchTeamMemberData = async () => {
    const reponses = await Promise.all(
      assessorSchemeList?.map(async (assess) => {
        if (assess?.role) {
          try {
            const response = await axios.get(
              `${BASE_URL}/team/assessor/filter/?user=&user_schemes=${assess?.value}&user_clusters=&user_standards=&assign_role=${assess?.role}&user_programmes=&is_verify_accessor=unknown&is_accessor=unknown&is_cab_verify=unknown&create_at=&last_login=`
            )
            const data = {...assess, assessor: response.data}
            return data
          } catch (error) {
            console.error('API Error:', error)
            return {...assess, assessor: []}
          }
        } else {
          return {...assess}
        }
      })
    )

    const validResponses = reponses.filter((response) => response !== null)
    setAssessorSchemeList(reponses)
    setTeamMemberData(validResponses)
  }
  const shortTeamMemberData = async () => {
    const reponses = await Promise.all(
      assessorSchemeList?.map(async (assess) => {
        if (assess?.role) {
          const selectedClusterIds = assess.selectedCluster?.join(',') || '' // Assuming `selectedClusters` is the array of selected cluster IDs within each `assess` object

          try {
            const response = await axios.get(
              `${BASE_URL}/team/assessor/filter/?user=&user_schemes=${assess?.value}&user_clusters=${selectedClusterIds}&user_standards=&assign_role=${assess?.role}&user_programmes=&is_verify_accessor=unknown&is_accessor=unknown&is_cab_verify=unknown&create_at=&last_login=`
            )
            const data = {...assess, assessor: response.data}
            return data
          } catch (error) {
            console.error('API Error:', error)
            return {...assess, assessor: []}
          }
        } else {
          return {...assess}
        }
      })
    )
    const validResponses = reponses.filter((response) => response !== null)
    setAssessorSchemeList(reponses)
    setTeamMemberData(validResponses)
  }

  useEffect(() => {
    if (teamMemberData) {
      const filter1 = teamMemberData?.filter((item) => item?.teamMember?.length)
      setDummyData([...dummyData, ...filter1])
    }
  }, [teamMemberData])

  useEffect(() => {
    if (dummyData) {
      const updatedTeamMemberData = teamMemberData?.map((item) => {
        const filterResult = dummyData?.find((filterItem) => filterItem?.id === item?.id)
        return filterResult ? filterResult : item
      })
      setTeamMemberDataScehme(updatedTeamMemberData)
    }
  }, [dummyData])

  useEffect(() => {
    if (roleID) {
      // fetchTeamLeadData();
      fetchTeamMemberData()
    }
  }, [roleID])
  useEffect(() => {
    if (sortByCluster) {
      // fetchTeamLeadData();
      shortTeamMemberData()
    }
  }, [sortByCluster])

  const handleDeleteAssessment = async (item) => {
    try {
      const response = await axios.delete(`${BASE_URL}/cab/assessor/planner/${item}/destroy/`)
      if (response.status === 200 || response.status === 204) {
        toast.success('Assessment Deleted Successfully')
      }
      setTimeout(() => {
        getAssessmentPlannerData()
      }, 3000)
    } catch (error) {
      console.log(error)
    }
  }

  // const filteredData = () => {
  //   const filtered = dataWithScheme?.filter((item) => {
  //     // Check for match in cab_name, assessment_name, assessment_number
  //     const matchesItem =
  //       item?.cab_name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
  //       item?.assessment_name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
  //       item?.assessment_number?.toLowerCase().includes(searchValue?.toLowerCase()) ||
  //       // Check for match in schemeData's scheme_name
  //       item?.schemeData.some(
  //         (scheme) =>
  //           scheme.scheme_name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
  //           // Check for match in assessor_data's assessor__first_name and assessor__last_name
  //           scheme.assessor_data?.some(
  //             (assessor) =>
  //               assessor.assessor__first_name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
  //               assessor.assessor__last_name?.toLowerCase().includes(searchValue?.toLowerCase())
  //           )
  //       )

  //     return matchesItem
  //   })

  //   setDataWithScheme(filtered)
  // }

  // useEffect(() => {
  //   if (searchValue) {
  //     filteredData()
  //   } else {
  //     setDataWithScheme(dummyDataWithScheme)
  //   }
  // }, [searchValue])

  const handleSearchChange = useCallback(
    debounce(async (e) => {
      const searchValue = e?.target?.value
      //console.log(searchValue, 'searchValue');
      if (searchValue) {
        try {
          // setLoader(true);
          const response = await axios.get(
            `${BASE_URL}/cab/get/assessor/planner/pagination/?search=${searchValue}`
          )

          if (response.status === 200) {
            setAssessmentPlannerTableData(response.data?.results)
          }
        } catch (error) {
          console.log(error)
        } finally {
          // setLoader(false);
        }
      } else {
        // setFilteredValue([]);
        getAssessmentPlannerData()
        // fetchAssessmentSchemeData()
      }
    }, 500),
    []
  )

  const handleTechExpertCheck = (event) => {
    setIsChecked(event.target.checked)
    if (event.target.checked) {
      setTechexpertRole('Tech Expert')
    } else {
      setTechexpertRole(null)
    }
  }
  const handleSchemeTechExpert = (scheme) => {
    setSelectedTechScheme(scheme)
  }
  useEffect(() => {
    if (selectedTechScheme) {
      const fetchTechExpertData = async () => {
        try {
          const response = await axios.get(
            `${BASE_URL}/team/assessor/filter/?user=&user_schemes=${selectedTechScheme}&user_clusters=&user_standards=&assign_role_name=${techexpertRole}&assign_role=&user_programmes=&is_verify_accessor=unknown&is_accessor=unknown&is_cab_verify=unknown&create_at=&last_login=`
          )
          setTechExpertPlayer(response?.data)
          //console.log('fetchTechExpertData:', response?.data)
        } catch (error) {
          console.error('API Error:', error)
        }
      }

      fetchTechExpertData()
    }
  }, [selectedTechScheme, techexpertRole])

  const handleNavigate = (item) => {
    navigate('/organisation/AssessmentPlanner/detail', {state: {rowData: item}})
  }
  const handleEditAssessor = async (item) => {
    const data = {
      assessor: editAssessorId?.value,
      assessor_role: editAssessorRole,
      assessmntId: assessmntId,
      remark: editAssessorRemarks,
      location: editAssessorLoc,
      assessor_type: editAssessorType,
    }
    try {
      const response = await axios.put(
        `${API_URL}/cab/assessor/planner/location/${item}/update/`,
        data
      )
      if (response.status === 200 || response.status === 201) {
        toast.success('Assessor updated successfully')
        // setShowAssessorModal(true);
        fetchAssessmentSchemeData()
        setEditAssessorId('')
      }
    } catch (error) {
      console.error('API Error:', error)
      toast.error('something went wrong')
    }
  }
  const handleDelAssessor = async (item) => {
    try {
      const response = await axios.delete(
        `${API_URL}/cab/assessor/planner/location/${item}/destroy/`
      )
      if (response.status === 204) {
        toast.success('Assessor remove successfully')
        // setShowAssessorModal(true);
        setShowDelModal(false)
        fetchAssessmentSchemeData()
        setEditAssessorId('')
      }
    } catch (error) {
      console.error('API Error:', error)
      toast.error('something went wrong')
      setShowDelModal(false)
    }
  }
  const totalPagesArray = Array.from({length: totalPages}, (_, index) => index + 1)

  // const CustomOption = (props) => {
  //   return (
  //     <components.Option {...props}>
  //       <div className="option-container" style={{ display: 'flex', alignItems: 'center' }}>
  //         <span>{props.data.label}</span>
  //         <Tooltip title={props.data.info} className="info-tooltip">
  //           <i className="fas fa-info-circle" style={{ marginLeft: '8px', cursor: 'pointer' }}></i>
  //         </Tooltip>
  //       </div>
  //     </components.Option>
  //   );
  // };

  const fetchClusters = async (schemeId) => {
    setLoader(true)
    try {
      const response = await axios.get(`${API_URL}/global/cluster/filter/?scheme=${schemeId}`)
      if (response.status === 200 || response.status === 201) {
        // toast.success("Assessor updated successfully")
        setEditScheme(response?.data)
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      console.error('API Error:', error)
      toast.error('something went wrong')
    }
  }

  const fetchSingleUser = async (
    roleID: string | null,
    user_clusters: Array<{value: string; label: string}>
  ) => {
    setLoader(true)
    try {
      const clusters = user_clusters.map((cluster) => cluster.value).join(',')
      const response = await axios.get(
        `${API_URL}/team/assessor/filter/?assign_role=${roleID}&user_clusters=${clusters}`
      )
      if (response.status === 200 || response.status === 201) {
        // toast.success("Assessor updated successfully")
        setEditUser(response?.data)
        setLoader(false)
      }
    } catch (error) {
      if (roleID == null) {
        setLoader(false)
        toast.error('Please select Role')
      } else {
        setLoader(false)
        console.error('API Error:', error)
        toast.error('Something went wrong')
      }
    }
  }
  const againGetAssessor = async (roleID) => {
    setLoader(true)
    try {
      const response = await axios.get(`${API_URL}/team/assessor/filter/?assign_role=${roleID}`)
      if (response.status === 200 || response.status === 201) {
        // toast.success("Assessor updated successfully")
        setEditUser(response?.data)
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      console.error('API Error:', error)
      toast.error('something went wrong')
    }
  }
  /* get assessor type */
  const getAssessorType = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/team/assessor/stage/list/`)
      const filter = response.data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }))
      setAssesorTypeOption(filter)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAssessorType()
  }, [])

  const formatMyDate = (date: string | null) => {
    if (!date) return '--'

    // Parse the date as UTC
    const parsedDate = DateTime.fromISO(date, {zone: 'utc'})

    // Ensure the parsed date is valid
    if (!parsedDate.isValid) return '--'

    // Format the date to MM-DD-YYYY
    return parsedDate.toFormat('MM-dd-yyyy')
  }

  return (
    <>
      {loader ? <UAFLoader /> : null}

      <div className='card card-flush'>
        <div className='card-header pt-5'>
          <div className='card-title w-100 d-flex justify-content-between'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='currentColor'
                  />
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search...'
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearchChange(e)
                  }
                }}
              />
            </div>
            {/* <button className='thm-btn' onClick={downloadExcel}>
              Download Report
            </button> */}
            <ExportAllAssessments />
          </div>
        </div>
        <div className='card-body pt-5 assesment_planner_table'>
          <table
            className='table align-middle table-row-dashed fs-6 gy-5 assessmentPlanner_table'
            id='kt_table_users'
          >
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>Assessment Number</th>
                <th className='min-w-125px'>CAB Organisation</th>
                <th className='min-w-125px'>Assessment Created</th>
                <th className='min-w-125px'>Assessment Stage</th>
                <th className='min-w-125px'>Assessment Type</th>
                <th className='min-w-125px'>Assessment Dates</th>
                {/* <th className='min-w-125px'>Schemes Type/ Remark</th> */}
                <th className='min-w-125px'>Schemes Type/ Mandays</th>
                {/* <th className='min-w-125px'>Assessment Invoice</th> */}
                <th className='min-w-125px'>Team Lead/ Team Member</th>
                <th className='text-end min-w-100px'>Actions</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {dataWithScheme?.map((item, index) => (
                <tr key={item?.id}>
                  <td onClick={() => handleNavigate(item)}>
                    <a
                      className='text-gray-800 text-hover-primary mb-1'
                      style={{cursor: 'pointer'}}
                    >
                      {item?.assessment_number || '--'}
                    </a>
                  </td>
                  <td>
                    {' '}
                    <Link
                      to={`/crafted/pages/profile/${item?.cab}/overview`}
                      className='text-gray-800 text-hover-primary mb-1'
                    >
                      {item?.cab_company || '--'}
                    </Link>
                  </td>
                  <td>{formatMyDate(item?.create_at)}</td>
                  <td>{item?.assessment_stage_name}</td>
                  <td>{item?.assessment_name}</td>
                  <td>
                    {item?.multi_date
                      ? (() => {
                          try {
                            // Replace 'None' with null and ensure JSON parsing works
                            const fixedMultiDate = item.multi_date
                              .replace(/None/g, 'null')
                              .replace(/'/g, '"')
                            const dates = JSON.parse(fixedMultiDate)

                            return Array.isArray(dates)
                              ? dates.map((date: string, index: number) => (
                                  <React.Fragment key={index}>
                                    {date ? formatMyDate(date) : '--'}
                                    <br />
                                  </React.Fragment>
                                ))
                              : '--'
                          } catch (e) {
                            return '--'
                          }
                        })()
                      : '--'}
                  </td>

                  {/* <td style={{paddingLeft: '0.75rem'}}>
                    {item?.schemeData?.map((i) => (
                      <React.Fragment key={index}>
                        {i?.scheme_short_name} - {i?.remark}
                        <br />
                      </React.Fragment>
                    ))}
                  </td> */}
                  <td style={{paddingLeft: '0.75rem'}}>
                    {item?.schemeData?.map((i) => (
                      <React.Fragment key={index}>
                        {i?.scheme_short_name} - {i?.assessment_duration}
                        <br />
                      </React.Fragment>
                    ))}
                  </td>

                  {/* {item?.is_approved === true ? (
                    <td>
                      <div className='badge badge-light fw-bolder'>
                        <div className='badge badge-light-success fw-bold'>{item?.invoice}</div>
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div className='badge badge-light fw-bolder'>
                        <div className='badge badge-light-danger fw-bold'>Pending...</div>
                      </div>
                    </td>
                  )} */}
                  <td style={{paddingLeft: '0.75rem'}}>
                    {_.uniqBy(item?.get_assessment_location, 'assessorId')?.map((locationItem) => (
                      <React.Fragment key={index}>
                        {locationItem?.assessor_name}
                        <br />
                      </React.Fragment>
                    ))}
                  </td>
                  <td className='text-end'>
                    <div className='dropdown'>
                      <button
                        className='btn btn-light btn-active-light-primary btn-sm dropdown-toggle'
                        type='button'
                        id={`dropdownMenuButton${index}`}
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        Actions
                      </button>

                      <ul className='dropdown-menu' aria-labelledby={`dropdownMenuButton${index}`}>
                        <li>
                          <a
                            className='dropdown-item'
                            href='#'
                            onClick={() => {
                              setShowAssessmentClicked(item)
                              setEditAssesmentModal(true)
                            }}
                          >
                            Edit
                          </a>
                        </li>
                        <li>
                          <a
                            className='dropdown-item'
                            href='#'
                            // onClick={() => handleDeleteAssessment(item)}
                            onClick={() => {
                              setShowAssessmentClicked(item)
                              setShowDeleteAssessment(true)
                            }}
                          >
                            Delete
                          </a>
                        </li>
                        <li>
                          <a
                            className='dropdown-item'
                            href='#'
                            onClick={() => {
                              setShowAssessmentClicked(item)
                              setShowAssessmentTableModal(true)
                            }}
                          >
                            Generate Assessment Invoice
                          </a>
                        </li>
                        <li>
                          <a
                            className='dropdown-item'
                            href='#'
                            onClick={() => {
                              setShowAssessmentClicked(item)
                              setShowAssessorModal(true)
                              handleAssessorSchemeList(item)
                              // handleAssessmentId()
                            }}
                          >
                            Assign Assessor
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ul className='pagination'>
            <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
              <a
                id='link_id_1'
                href='#'
                className='page-link'
                onClick={() => handlePageChange(pagenumber - 1)}
              >
                <i className='previous'></i>
              </a>
            </li>
            {totalPagesArray.map((page, index) => (
              <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
                <a
                  id={`page_number_${index}`}
                  href='#'
                  className='page-link'
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </a>
              </li>
            ))}
            <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
              <a
                href='#'
                id='link_id_2'
                className='page-link'
                onClick={() => handlePageChange(pagenumber + 1)}
              >
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>
        {assessmentPlannerTableData?.length && (
          <AssessmentPlannerTableModal
            approval={() => getAssessmentPlannerData()}
            show={showAssessmentTableModal}
            handleClose={() => setShowAssessmentTableModal(false)}
            showAssessentClicked={showAssessentClicked}
          />
        )}
        {/* <JobOrderPlanner accessorList={accessorList} show={jobOrderModal} handleClose={() => setJobOrderModal(false)} showAssessentClicked={showAssessentClicked} getAssessmentPlannerData={getAssessmentPlannerData} /> */}

        <EditAssessmentModal
          show={editAssessmentModal}
          handleClose={() => setEditAssesmentModal(false)}
          showAssessentClicked={showAssessentClicked}
          getAssessmentPlannerData={getAssessmentPlannerData}
        />

        <Modal
          show={showAssessorModal}
          onHide={() => {
            setShowAssessorModal(false)
          }}
          size='xl'
        >
          <div className='modal-header'>
            <h2>Assign Assessors</h2>

            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => {
                setShowAssessorModal(false)
              }}
            >
              <KTIcon className='fs-1' iconName='cross' />
            </div>
          </div>
          <div className='modal-body'>
            <div className='container'>
              {showAssessentClicked?.schemeData?.map((assess) => (
                <div className='row'>
                  {assess?.assessor_name?.length ? (
                    <div className='col-12'>
                      <table className='table table-bordered mt-3'>
                        <thead>
                          <tr>
                            <th className='col-3'>Assessor Name</th>
                            <th className='col-3'>Assessor Role</th>
                            <th className='col-3'>Assessor Type</th>
                            <th className='col-3'>Assessor Location</th>
                            <th className='col-3'>Remarks</th>
                            <th className='col-3'>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {assess?.assessor_name?.map((assessor, idx) => (
                            <tr key={idx}>
                              <td>
                                {assessor?.[0]?.first_name} {assessor?.[0]?.last_name}
                              </td>
                              <td>{assessor?.[0]?.role}</td>
                              <td>{assessor?.[0]?.assessor_type}</td>
                              <td>{assessor?.[0]?.location}</td>
                              <td>{assessor?.[0]?.remark}</td>
                              <td>
                                <div className='d-flex '>
                                  <a
                                    onClick={() => {
                                      setShowAssessorModal(false)
                                      setEditAssessor(true)
                                      setEditAssessorItem(assessor)
                                      fetchClusters(assess?.scheme)
                                    }}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  >
                                    <i className='ki-duotone ki-pencil fs-3'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  </a>
                                  <a
                                    onClick={() => {
                                      setShowDelModal(true)
                                      setEditAssessorItem(assessor)
                                      setShowAssessorModal(false)
                                    }}
                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                  >
                                    <KTIcon iconName='trash' className='fs-3' />
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                  {assessorSchemeList
                    ?.filter((i) => i?.value == assess?.scheme)
                    ?.map((item, index) => (
                      <div className='row p-5'>
                        <div className='col-md-2'>
                          <p className='d-flex w-100 align-items-center mb-0 h-100'>
                            {item?.label}
                          </p>
                        </div>
                        <div className='col-md-2'>
                          <Select
                            id='slelect_role_list'
                            className='react-select-styled react-select-solid '
                            classNamePrefix='react-select'
                            options={roleList}
                            // options={accessorList?.map(item => ({ value: item?.id, label: `${item?.first_name} ${item?.last_name}` }))}
                            placeholder='Select Role'
                            onChange={(e) => handleSchemeAssessorRole(item, e)}
                          />
                        </div>
                        <div className='col-md-2'>
                          <Select
                            id='slelect_role_list'
                            className='react-select-styled react-select-solid '
                            classNamePrefix='react-select'
                            options={assesorTypeOption}
                            // options={accessorList?.map(item => ({ value: item?.id, label: `${item?.first_name} ${item?.last_name}` }))}
                            placeholder='Select Type'
                            onChange={(e) => handleSchemeAssessorType(item, e)}
                          />
                        </div>
                        <div className='col-md-3'>
                          <Select
                            id='slelect_role_list_1'
                            isMulti
                            className='react-select-styled react-select-solid '
                            classNamePrefix='react-select'
                            options={
                              item?.schemeCluster?.length > 0
                                ? item?.schemeCluster[0]?.map((cluster) => ({
                                    value: cluster?.id,
                                    label: `${cluster?.name} `,
                                  }))
                                : null
                            }
                            // options={accessorList?.map(item => ({ value: item?.id, label: `${item?.first_name} ${item?.last_name}` }))}
                            placeholder='Select Codes'
                            onChange={(selectedOptions) =>
                              handleSchemeAssessorCluster(item, selectedOptions)
                            }
                          />
                        </div>
                        <div className='col-md-2'>
                          <Select
                            isMulti
                            id='slelect_role_list_2'
                            className='react-select-styled react-select-solid '
                            classNamePrefix='react-select'
                            isDisabled={item?.assessor?.length <= 0}
                            options={
                              item?.assessor?.length > 0
                                ? item?.assessor?.map((aa) => ({
                                    value: aa?.id,
                                    label: `${aa?.first_name} ${aa?.last_name}`,
                                    // info: `${aa?.first_name}`
                                  }))
                                : null
                            }
                            placeholder='Select Assessor'
                            // components={{ Option: CustomOption }}
                            onChange={(e) => handleAssessmentNameChange(item, e)}
                          />
                        </div>
                        {/* {assessorSchemeList?.filter((i) => i?.value == assess?.scheme)?.length ==
                          1 ||
                        assessorSchemeList?.filter((i) => i?.value == assess?.scheme)?.length - 1 ==
                          index ? (
                          <div
                            className='col-md-1'
                            onClick={() => handleAddRowSchemeAssessor(item)}
                          >
                            <span
                              className='card-label fw-bold fs-1 m-2'
                              style={{
                                backgroundColor: '#136ba1',
                                color: 'white',
                                padding: '0.3em 0.5em',
                              }}
                            >
                              +
                            </span>
                          </div>
                        ) : null} */}
                        {assessorSchemeList?.filter((i) => i?.value === assess?.scheme)?.length ===
                          1 ||
                        assessorSchemeList?.filter((i) => i?.value === assess?.scheme)?.length -
                          1 ===
                          index ? (
                          <div className='col-md-1 d-flex'>
                            {/* Add Row Button */}
                            <div onClick={() => handleAddRowSchemeAssessor(item)} className='me-2'>
                              <span
                                className='card-label fw-bold fs-1 m-2'
                                style={{
                                  backgroundColor: '#136ba1',
                                  color: 'white',
                                  padding: '0.3em 0.5em',
                                  cursor: 'pointer',
                                }}
                              >
                                +
                              </span>
                            </div>

                            {/* Remove Row Button */}
                            {assessorSchemeList.filter((row) => row.value === assess?.scheme)
                              .length > 1 && (
                              <div onClick={() => handleRemoveRowSchemeAssessor(item)}>
                                <span
                                  className='card-label fw-bold fs-1 m-2'
                                  style={{
                                    backgroundColor: '#d9534f',
                                    color: 'white',
                                    padding: '0.3em 0.5em',
                                    cursor: 'pointer',
                                  }}
                                >
                                  -
                                </span>
                              </div>
                            )}
                          </div>
                        ) : null}
                        {item?.selectedAssessor?.length ? (
                          <>
                            <div className='col-12'>
                              <table className='table table-bordered mt-3'>
                                <thead>
                                  <tr>
                                    <th className='col-4'>Assessor Name</th>
                                    <th className='col-4'>Assessment Location</th>
                                    <th className='col-4'>Remarks</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {item?.selectedAssessor.map((assessor, idx) => (
                                    <tr key={idx}>
                                      <td>{assessor?.label}</td>
                                      <td>
                                        <Select
                                          id='slelect_role_list_4'
                                          className='react-select-styled react-select-solid '
                                          name='User'
                                          options={AssessmentLocationArr}
                                          classNamePrefix='react-select'
                                          onChange={(e) =>
                                            handleAssessmentLocationChange(e, assessor, item)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <textarea
                                          className='form-control form-control-solid'
                                          rows={1}
                                          name='uaf_requirement'
                                          onChange={(e) => handleRemarksChange(e, assessor, item)}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : null}
                      </div>
                    ))}
                </div>
              ))}

              <div className='row'>
                <div className='col-md-12'>
                  <button
                    className='thm-btn '
                    style={{float: 'right'}}
                    id='slelect_role_list_6'
                    onClick={() => {
                      handleAddAssessor() // This will execute immediately
                      setTimeout(() => {
                        CoiFOrAssesssor() // This will execute after 5 seconds
                      }, 5000) // 5000 milliseconds = 5 seconds
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          show={showDelModal}
          onHide={() => {
            setShowDelModal(false)
          }}
        >
          <div className='modal-header'>
            <h2>Confirm Delete Assessor</h2>

            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => {
                setShowDelModal(false)
              }}
            >
              <KTIcon className='fs-1' iconName='cross' />
            </div>
          </div>
          <div className='modal-body'>
            <div className='container'>
              <p> Are you sure to remove assessor</p>

              <div className='row'>
                <div className='col-md-12'>
                  <button
                    className='thm-btn '
                    style={{float: 'right'}}
                    id='slelect_role_list_6'
                    onClick={() => {
                      handleDelAssessor(editAssessorItem?.[0]?.id)
                      setShowDelModal(false)
                      setEditUser('')
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          show={showDeleteAssessment}
          onHide={() => setShowDeleteAssessment(false)}
          backdrop='static'
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete Assessment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Do you want to delete this assessment ?</h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setShowDeleteAssessment(false)}>
              Close
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                handleDeleteAssessment(showAssessentClicked?.id)
                setShowDeleteAssessment(false)
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={editAssessor}
          onHide={() => setEditAssessor(false)}
          backdrop='static'
          size='xl'
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Assessor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <table className='table table-bordered mt-3'>
                    <thead>
                      <tr>
                        <th className='col-3'>Assessor Name</th>
                        <th className='col-3'>Assessor Role</th>
                        <th className='col-3'>Assessor Type</th>
                        <th className='col-3'>Assessor Location</th>
                        <th className='col-3'>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {editAssessorItem?.[0]?.first_name} {editAssessorItem?.[0]?.last_name}
                        </td>
                        <td>{editAssessorItem?.[0]?.role}</td>
                        <td>{editAssessorItem?.[0]?.assessor_type}</td>
                        <td>{editAssessorItem?.[0]?.location}</td>
                        <td>{editAssessorItem?.[0]?.remark}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className='row p-5'>
                  <div className='col-md-2'>
                    <p className='d-flex w-100 align-items-center mb-0 h-100'>
                      {editScheme?.length > 0 ? editScheme[0]?.scheme_short_name : null}
                    </p>
                  </div>
                  <div className='col-md-3'>
                    <Select
                      id='select_role_list'
                      className='react-select-styled react-select-solid'
                      classNamePrefix='react-select'
                      options={roleList}
                      placeholder='Select Role'
                      onChange={(selectedOption) => {
                        const roleValue = (selectedOption as RoleOption).value
                        setEditAssessorRole(roleValue)
                        fetchSingleUser(roleValue, userClusters) // Fetch with both role and clusters
                      }}
                    />
                  </div>
                  <div className='col-md-2'>
                          <Select
                            id='slelect_role_list'
                            className='react-select-styled react-select-solid '
                            classNamePrefix='react-select'
                            options={assesorTypeOption}
                            onChange={(selectedOption) => {
                              const typeValue = (selectedOption as RoleOption).value
                              setEditAssessorType(typeValue)
                               // Fetch with both role and clusters
                            }}
                            // options={accessorList?.map(item => ({ value: item?.id, label: `${item?.first_name} ${item?.last_name}` }))}
                            placeholder='Select Type'
                            // onChange={(e) => handleSchemeAssessorType(item, e)}
                          />
                        </div>
                  <div className='col-md-3'>
                    <Select
                      id='slelect_role_list_1'
                      isMulti
                      className='react-select-styled react-select-solid'
                      classNamePrefix='react-select'
                      options={
                        editScheme?.length > 0
                          ? editScheme?.map((cluster) => ({
                              value: cluster?.id,
                              label: `${cluster?.name}`,
                            }))
                          : null
                      }
                      placeholder='Select Codes'
                      onChange={(selectedOptions: MultiValue<{value: string; label: string}>) => {
                        const mutableSelectedOptions = [...selectedOptions] // Convert to mutable array
                        setUserClusters(mutableSelectedOptions)
                        fetchSingleUser(editAssessorRole, mutableSelectedOptions) // Fetch with both role and clusters
                      }}
                    />
                  </div>
                  <div className='col-md-2'>
                    <Select
                      id='slelect_role_list_2'
                      className='react-select-styled react-select-solid '
                      classNamePrefix='react-select'
                      isDisabled={editUser?.length <= 0}
                      options={
                        editUser.length > 0
                          ? editUser.map((aa) => ({
                              value: aa?.id,
                              label: `${aa?.first_name} ${aa?.last_name}`,
                            }))
                          : null
                      }
                      placeholder='Select Team Member'
                      onChange={(selectedOption) => setEditAssessorId(selectedOption as RoleOption)}
                    />
                  </div>
                </div>

                {editAssessorId ? (
                  <>
                    <div className='col-12'>
                      <table className='table table-bordered mt-3'>
                        <thead>
                          <tr>
                            <th className='col-4'>Assessor Name</th>
                            <th className='col-4'>Assessment Location</th>
                            <th className='col-4'>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{editAssessorId?.label}</td>
                            <td>
                              <Select
                                id='slelect_role_list_4'
                                className='react-select-styled react-select-solid '
                                name='User'
                                options={AssessmentLocationArr}
                                classNamePrefix='react-select'
                                onChange={(selectedOption) =>
                                  setEditAssessorLoc(selectedOption?.value)
                                }
                              />
                            </td>
                            <td>
                              <textarea
                                className='form-control form-control-solid'
                                rows={1}
                                name='uaf_requirement'
                                onChange={(e) => setEditAssessorRemarks(e?.target?.value)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null}
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <button
                    className='thm-btn '
                    style={{float: 'right'}}
                    id='slelect_role_list_6'
                    onClick={() => {
                      handleEditAssessor(editAssessorItem?.[0]?.id)
                      setEditAssessor(false)
                      setEditAssessorId('')
                      setEditUser('')
                      setEditAssessorLoc('')
                      setEditAssessorRemarks('')
                      setUserClusters([])
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}
export {AssessmentPlannerWrapper}
