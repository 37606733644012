import axios from 'axios'
import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {toast} from 'react-toastify'

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  uniqueCab: string | undefined
}

interface transferCertificateData {
  transferred: string
  country: string
  country__name: string
  total_count: string
}

const OtherEdit = ({uniqueCab}: Props) => {
  const [schemeData, setSchemeData] = useState<any[]>([])
  const [selectedScheme, setSelectedScheme] = useState<string>()
  const [schemeName, setSchemeName] = useState<string>()
  const [transferCertificate, setTransferCertificate] = useState<transferCertificateData[]>([])
  // const [witnessPlanningYear, setWitnessPlanningYear] =  useState<string>('')
  const [witnessConductingYear, setConductingPlanningYear] = useState<Date | null>(null)
  // const [otherProgramDetails, setOtherProgramDetails] = useState<any[]>([])
  const [otherProgramScheme, setOtherProgramScheme] = useState<any[]>([])

  console.log(otherProgramScheme, 'this is other Program details')
  // const [witnessDateAndAssessmentNumber, setWitnessDateAndAssessmentNumber] = useState<string>('')
  // const [clientFilesReview, setClientFilesReview] = useState<string>('')

  const cabID = uniqueCab

  useEffect(() => {
    if (cabID && selectedScheme) {
      const fetchTransferCertificate = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/certificate/scheme/list/data/?cab=${cabID}&scheme=${selectedScheme}`
          )
          setTransferCertificate(response?.data?.certificates)
        } catch (error) {
          console.error('Error fetching standard data:', error)
        }
      }
      fetchTransferCertificate()
    }
  }, [cabID, selectedScheme])

  // useEffect(() => {
  //   if (cabID && selectedScheme) {
  //     const fetchOtherProgram = async () => {
  //       try {
  //         const response = await axios.get(
  //           `${API_URL}/cab/global/data/scheme/filter/?cab=${cabID}&scheme=${selectedScheme}`
  //         )
  //         setOtherProgramDetails(response?.data.reverse())
  //       } catch (error) {
  //         console.error('Error fetching standard data:', error)
  //       }
  //     }
  //     fetchOtherProgram()
  //   }
  // }, [cabID, selectedScheme])

  useEffect(() => {
    if (cabID && selectedScheme) {
      const fetchOtherProgramData = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/cab/global/data/scheme/filter/?cab=${cabID}&scheme=${selectedScheme}`
          )
          // const allData = response?.data
          // const lastFourItems = allData.slice(-4)
          setOtherProgramScheme(response?.data.reverse())
        } catch (error) {
          console.error('Error fetching standard data:', error)
        }
      }
      fetchOtherProgramData()
    }
  }, [cabID, selectedScheme])

  useEffect(() => {
    const fetchScheme = async () => {
      try {
        const response = await axios.get(`${API_URL}/account/patch/user/${cabID}/`)
        const filteredData = response.data?.scheme_names?.map((scheme: any) => ({
          id: scheme.id,
          short_name: scheme.short_name,
          name: scheme.name,
        }))
        setSchemeData(filteredData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchScheme()
  }, [])

  const handleSchemeSelect = (e) => {
    setSelectedScheme(e.value)
    setSchemeName(e.short_name)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const updatePromises = otherProgramScheme.map(async (program) => {
        const dataToSubmit = {
          cab: cabID,
          scheme: program.scheme_data.id,
          witness_planning_year: program.witness_planning_year || '',
          witness_conducting_year: program.witness_conducting_year || null,
          witness_date_assessment: program.witness_date_assessment || '',
          client_file_review: program.client_file_review || '',
          program_specific_data: program,
        }

        return axios.put(`${API_URL}/cab/global/data/scheme/${program.id}/update/`, dataToSubmit)
      })

      await Promise.all(updatePromises)

      toast.success('Data Updated successfully')
    } catch (error) {
      console.error('Error submitting data:', error)
      toast.error('Error submitting data')
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='mb-5 col-6'>
        <label className='form-label'>Scheme Name</label>
        <Select
          className='react-select-styled react-select-solid'
          classNamePrefix='react-select'
          placeholder='Select Scheme'
          options={schemeData?.map((item) => ({
            label: `${item?.short_name}`,
            value: item?.id,
            short_name: item?.short_name,
          }))}
          onChange={handleSchemeSelect}
        />
      </div>
      <div className='mb-5 mb-xl-8'>
        <div className='card-header border-0 py-5 d-flex mb-5'>
          <h3 className='card-title align-items-start flex-column mt-2'>
            <span className='card-label fw-bold fs-1'>{schemeName} Assessment</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Witness planning year
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Scheme accredited
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Witness to be planned in the year / Witness Date and Assessment Number
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Planning the review of client files or verification of adequate competence for
                    non-critical codes
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Total number of certificates issued, including details of the six countries with
                    the highest number of certificates issued since accreditation.
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Total number of certificates transferred, including details of the six countries
                    with the highest number of certificates transferred since accreditation.
                  </th>
                </tr>
              </thead>
              <tbody>
                {otherProgramScheme ? (
                  <>
                    {otherProgramScheme.map((item, index) => (
                      <tr key={item?.id}>
                        <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                            <textarea
                                value={item?.witness_planning_year}
                                onChange={(e) => {
                                  const updatedScheme = otherProgramScheme.map((scheme) =>
                                    scheme.id === item.id
                                      ? {...scheme, witness_planning_year: e.target.value}
                                      : scheme
                                  )
                                  setOtherProgramScheme(updatedScheme)
                                }}
                                className='form-control'
                                rows={2}
                              ></textarea>
                              
                            </div>
                          </div>
                        </td>
                        <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {item?.scheme_data?.name}
                            </div>
                          </div>
                        </td>
                        <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <DatePicker
                                selected={
                                  item?.witness_conducting_year
                                    ? new Date(item?.witness_conducting_year, 0)
                                    : witnessConductingYear
                                }
                                onChange={(date) => {
                                  const updatedScheme = otherProgramScheme.map((scheme) =>
                                    scheme.id === item.id
                                      ? {...scheme, witness_conducting_year: date.getFullYear()}
                                      : scheme
                                  )
                                  setOtherProgramScheme(updatedScheme)
                                  setConductingPlanningYear(date)
                                }}
                                showYearPicker
                                dateFormat='yyyy'
                                className='form-control mb-2'
                              />
                              <textarea
                                value={item?.witness_date_assessment}
                                onChange={(e) => {
                                  const updatedScheme = otherProgramScheme.map((scheme) =>
                                    scheme.id === item.id
                                      ? {...scheme, witness_date_assessment: e.target.value}
                                      : scheme
                                  )
                                  setOtherProgramScheme(updatedScheme)
                                }}
                                className='form-control'
                                rows={2}
                              ></textarea>
                            </div>
                          </div>
                        </td>
                        <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <textarea
                                value={item?.client_file_review}
                                onChange={(e) => {
                                  const updatedScheme = otherProgramScheme.map((scheme) =>
                                    scheme.id === item.id
                                      ? {...scheme, client_file_review: e.target.value}
                                      : scheme
                                  )
                                  setOtherProgramScheme(updatedScheme)
                                }}
                                className='form-control'
                                rows={4}
                              ></textarea>
                            </div>
                          </div>
                        </td>
                        <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {transferCertificate.map((transferItem, transferIndex) => (
                                <div key={transferIndex}>
                                  {transferItem?.country__name}: {transferItem?.total_count}
                                </div>
                              ))}
                            </div>
                          </div>
                        </td>
                        <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {transferCertificate.map((transferItem, transferIndex) => (
                                <div key={transferIndex}>
                                  {transferItem?.country__name}: {transferItem?.transferred}
                                </div>
                              ))}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
          <div className='mb-5 mt-5'>
            <button type='submit' className='btn btn-primary'>
              Update
              <i className='fa fa-angle-double-right' aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default OtherEdit
