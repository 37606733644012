
import React, {useEffect, useState} from 'react'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
import axios from 'axios'
import UAFLoader from '../../../../../loader/UafLoader'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { usePermissions } from '../../../../../permission_component/PermissionContext'


const AssessorTab = ({urlId}) => {
  const navigate = useNavigate()
  const { assessorPermission, cabPermission, superUserPermission, updatePermissions } = usePermissions();
  const [scheduleRow, setScheduleRow] = useState<any[]>([])
  const [loader, setLoader] = useState<any>(false);
  const [clickValue, setClickValue] = useState<any>()
  const [showInoviceModal, setShowInoviceModal] = useState<boolean>(false)
  const [editValue, setEditValue] = useState<any>();
  const formatMyDate = (date: Date | string | null) => {
    if (!date) return null;
  
    // If the date is a string, parse it to a Date object
    const parsedDate = typeof date === "string" ? new Date(date) : date;
  
    // Ensure the parsed date is valid
    if (isNaN(parsedDate.getTime())) return null;
  
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
  
    return `${month}-${day}-${year}`;
  };

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/performance/monitor/list/?assessor=${urlId}`
      )
      setScheduleRow(response?.data)
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  }
  useEffect(() => {
  

    fetchData()
  }, [urlId])
  const generatePDF = () => {
    const input = document.getElementById('pdfContentassessor');
    console.log(input, "this is input");
  
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
        let heightLeft = imgHeight;
        let position = 0;
  
        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;
  
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
          heightLeft -= pdfHeight;
        }
  
        pdf.save('AssessorReport.pdf');
      });
    } else {
      console.error("Element with id 'pdfContent' not found.");
    }
  };

  const handleRemove = async () => {

    try {
      setLoader(true)
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/performance/monitor/destroy/${clickValue}/`
      )
      setLoader(false)
      toast.success("Removed Successfully")
      setShowInoviceModal(false)
      fetchData();
      clickValue(null);
    } catch (error) {
      setLoader(false)
    }

  }
  const editData = async (id) => {
    
    navigate(`/apps/performance-monitoring/assessor-monitoring/edit/${id}`);
  };
  return (
    <>{loader ? <UAFLoader /> : null}
    <div className='row gy-10 gx-xl-10'>
      <div className='col-xl-12'>
        {/* <div className='card card-xl-stretch mb-5 mb-xl-10 ' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer d-flex justify-content-between'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'> Performance</h3>
            </div>
            </div> */}
            <div className='card-body p-9'>
              <div className='accordion' id='kt_accordion_1'>
                {scheduleRow?.map((item, index) => (
                  <div className='accordion-item assessment_detail_accordion' key={index}>
                    <h2 className='accordion-header' id={`kt_accordion_1_header_${index}`}>
                      <div
                        className='accordion-button fs-4 fw-bold collapsed w-100 d-flex justify-content-between'
                        data-bs-toggle='collapse'
                        data-bs-target={`#kt_accordion_1_body_${index}`}
                        aria-expanded='false'
                        aria-controls={`kt_accordion_1_body_${index}`}
                      >
                        <span> Created At: {formatMyDate(item?.create_at)}</span>
                        {superUserPermission?.is_superuser === true && (
                        <>
                        <span style={{marginLeft:'auto'}}>
                        <button
                          className='thm-btn'
                          
                          onClick={() => {
                            setClickValue(item?.id)
                            setShowInoviceModal(true)
                          }}
                        >
                          Remove
                        </button>
                      </span>
                      <span style={{margin:'2px 5px'}} >
                        <button
                          className='thm-btn'
                          
                          onClick={() => {
                            setEditValue(item?.id)
                            // setShowInoviceModal(true)
                             editData(item?.id);
                          }}
                        >
                          Edit
                        </button>
                      </span>
                      </>)}
                      </div>
                    </h2>
                    <div
                      id={`kt_accordion_1_body_${index}`}
                      className='accordion-collapse collapse'
                      aria-labelledby={`kt_accordion_1_header_${index}`}
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div id='pdfContentassessor' className='accordion-body px-20'>
                        <div className='row'>
                          <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                            <img
                              alt='Logo'
                              src={'/media/logos/uaf_logo.png'}
                              className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
                            />
                            <div className='uaf_title py-5 text-center'>
                              <h2 className=''>
                                Assessor Performance
                                <br />
                                Issued by
                              </h2>
                              <span className='fs-18'>
                                United Accreditation Foundation
                                <br />
                                400, North Center Dr, STE 202, Norfolk, VA 23502, United States of
                                America{' '}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='row gy-10 gx-xl-10'>
                          <div className='col-xl-12'>
                            <div
                              className='card-xl-stretch mb-5 mb-xl-10'
                              id='kt_profile_details_view'
                            >
                              <div
                                className='mb-10 mt-10  pb-10 '
                                style={{borderBottom: '1px dotted #d3d3d3'}}
                              >
                                <h4>DATED:{formatMyDate(item?.monitor_date)}</h4>
                                <h4>NAME:{item?.assessor_data?.name}</h4>
                                <h4>DESIGNATION:Assessor</h4>
                                <h4 className='mb-5 col-lg-12'>
                                  Approved for scheme(s):
                                  {item?.assessor_data?.scheme
                                    ?.map((item) => item.short_name)
                                    .join(', ')}
                                </h4>
                              </div>
                              <table
                                className='table align-middle table-row-dashed fs-6 gy-5'
                                id='kt_table_users'
                              >
                                <thead>
                                  <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                    <th className='col-1' style={{border: '1px solid black'}}>
                                      S No
                                    </th>
                                    <th className='col-4 ' style={{border: '1px solid black'}}>
                                      PARAMETERS
                                    </th>
                                    <th className='' style={{border: '1px solid black'}}>
                                      Remark{' '}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className='text-gray-600 fw-semibold'>
                                  <tr>
                                    <td className='p-1' style={{border: '1px solid black'}}>
                                      1
                                    </td>
                                    <td className='p-1' style={{border: '1px solid black'}}>
                                      <div className='p-0'>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          <h5>Relevant clause of ISO/IEC 17011</h5>
                                        </div>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          <h5>6.1.2.2</h5>
                                        </div>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          assessment principles, practices, and techniques
                                        </div>
                                        <div
                                          className='p-2'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          general management system principles and tools
                                        </div>
                                      </div>

                                      <div className='p-0'>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          <h5>6.1.2.3</h5>
                                        </div>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          accreditation body's rules and processes
                                        </div>
                                        <div
                                          className='p-2'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          accreditation and accreditation scheme requirements and
                                          relevant guidance and application documents
                                        </div>
                                        <div
                                          className='p-2'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          conformity assess assessment scheme requirements, other
                                          procedures and methods used by the conformity assessment
                                          body.
                                        </div>
                                      </div>

                                      <div className='p-0'>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          <h5>6.1.2.4</h5>
                                        </div>
                                        <div
                                          className='p-2'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          knowledge of risk-based assessment principles
                                        </div>
                                      </div>
                                      <div className='p-0'>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          <h5>6.1.2.5</h5>
                                        </div>
                                        <div
                                          className='p-2'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          general regulatory requirements related to the conformity
                                          assessment activities.
                                        </div>
                                      </div>
                                      <div className='p-0'>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          <h5>6.1.2.6</h5>
                                        </div>
                                        <div
                                          className='p-2'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          knowledge of practices and processes of the conformity
                                          assessment body business environment
                                        </div>
                                        <div
                                          className='p-2'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          communication skills appropriate to interact with all
                                          levels within the conformity assessment body
                                        </div>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          note-taking and report-writing skills
                                        </div>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          opening and closing meeting skills
                                        </div>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          interviewing skills
                                        </div>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          assessment management skills.
                                        </div>
                                      </div>
                                      <div className='p-0'>
                                        <div
                                          className='p-1'
                                          style={{borderBottom: '1px solid black'}}
                                        >
                                          <h5>6.1.2.7</h5>
                                        </div>
                                        <div className='p-2'>
                                          note­taking and report writing skills
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      className='p-0 align-top'
                                      style={{border: '1px solid black'}}
                                    >
                                      <div
                                        className='p-0'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        <textarea
                                          className='form-control form-control-solid '
                                          rows={7}
                                          value={item?.relevant_clause}
                                        />
                                      </div>
                                      <div
                                        className='p-0'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        <textarea
                                          className='form-control form-control-solid '
                                          rows={9}
                                          value={item?.relevant_clause2}
                                        />
                                      </div>
                                      <div
                                        className='p-0'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        <textarea
                                          className='form-control form-control-solid '
                                          rows={3}
                                          value={item?.relevant_clause3}
                                        />
                                      </div>
                                      <div
                                        className='p-0'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        <textarea
                                          className='form-control form-control-solid '
                                          rows={3}
                                          value={item?.relevant_clause4}
                                        />
                                      </div>
                                      <div
                                        className='p-0'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        <textarea
                                          className='form-control form-control-solid '
                                          rows={13}
                                          value={item?.relevant_clause5}
                                        />
                                      </div>
                                      <div className='p-0'>
                                        <textarea
                                          className='form-control form-control-solid '
                                          rows={2}
                                          name='relevant_clause6'
                                          value={item?.relevant_clause6}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      2
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        <h5>Preparation of assessment</h5>{' '}
                                      </div>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        Preparation prior to the assessment
                                      </div>
                                      <div className='p-2'>
                                        Communication with the CB prior to the assessment
                                      </div>
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={7}
                                        value={item?.preparation}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      3
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        <h5>Document review</h5>{' '}
                                      </div>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        Appropriate selection of items to review, preparation of the
                                        issues to be confirmed on site
                                      </div>
                                      <div className='p-2'>Systematic and comprehensive review</div>
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={7}
                                        value={item?.document_review}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      4
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        <h5>Assessment Execution</h5>
                                      </div>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        Ability to identify the concerns w.r.to accreditation
                                        requirements
                                      </div>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        Ability to identify areas of improvement.
                                      </div>
                                      <div className='p-2'>
                                        Clear evaluation/ evidence based recommendation
                                      </div>
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={7}
                                        value={item?.assessment_execution}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      5
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        <h5>Gist of assessment</h5>{' '}
                                      </div>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        Coverage of the normative requirements
                                      </div>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        Explanation to CAB w.r.to interpretation of applicable
                                        clause requirements.{' '}
                                      </div>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        Understanding of the CAB’s quality system
                                      </div>
                                      <div className='p-2'>Not providing specific solutions </div>
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={8}
                                        value={item?.assessment_gist}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      6
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        <h5>Team meeting</h5>{' '}
                                      </div>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        Involvement in the decision-making of the assessment team
                                      </div>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        Clear and concise explanation of the information obtained
                                        during assessment to the team members{' '}
                                      </div>
                                      <div className='p-2'>
                                        Conclusion on the overall effectiveness of the CAB’s
                                        management system
                                      </div>
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={8}
                                        value={item?.team_meeting}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      7
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        <h5>Assessment reporting</h5>{' '}
                                      </div>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        Objective evidence captured w.r.to accreditation scope and
                                        normative requirements in report
                                      </div>
                                      <div className='p-2'>Accurate and concise reporting </div>
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={7}
                                        value={item?.assessment_reporting}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      8
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        <h5>Interested parties feedback</h5>{' '}
                                      </div>
                                      <div
                                        className='p-2'
                                        style={{borderBottom: '1px solid black'}}
                                      >
                                        Feedback from the team members of the assessment team
                                      </div>
                                      <div className='p-2'>
                                        Feedback from CABs and/or other interested parties{' '}
                                      </div>
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={7}
                                        value={item?.feedback}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      9
                                    </td>

                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <h5>
                                        Comment on frequency of performance monitoring of
                                        assessor/TE
                                      </h5>
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={7}
                                        value={item?.comment}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      10
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <h5>Any other Remarks</h5>{' '}
                                    </td>
                                    <td className='p-0' style={{border: '1px solid black'}}>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={7}
                                        value={item?.remark}
                                      />
                                    </td>
                                  </tr>
                                  <tr></tr>
                                </tbody>
                              </table>

                              <div
                                className='mb-10 mt-10 pb-10 text-center'
                                style={{borderBottom: '1px dotted #d3d3d3'}}
                              >
                                <h2 className=''>Result of Evaluation: </h2>
                                <span className='mt-10 mb-20'>{item?.result}</span>
                              </div>

                              <div className=' mt-10 text-end'>
                                <h2 style={{margin: '0 190px 0 0'}}>
                                  Monitoring Done by:{item?.evaluator_name}{' '}
                                </h2>
                                <img src={item?.signature} alt='ARC Member Signature' />
                              </div>
                              <div className='mt-0 text-end'>
                                <h2 style={{margin: '0 120px 0 0'}}>
                                  {formatMyDate(item?.update_at)}{' '}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='text-end m-5'>
                        <button className='btn btn-primary' onClick={generatePDF}>
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          
        {/* </div> */}
      </div>
    </div>
    <Modal show={showInoviceModal} onHide={() => setShowInoviceModal(false)} backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>Invoice Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-6'>
            <label className='col-lg-12 col-form-label fw-bold fs-6'>
              Do You Want To Remove it ?
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
           onClick={handleRemove}
          >
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AssessorTab
