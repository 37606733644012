import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Select, {SingleValue, ActionMeta} from 'react-select'
import moment from 'moment'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import SignaturePadComponent from '../organisation/AssessmentPlanner/component/SignaturePadComponent'
import axios from 'axios'
import {UserDataType} from '../assessor-mangement/AssessorInterface'
import {toast} from 'react-toastify'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import CreateAnnexureReport from './CreateAnnexureReport'
import {usePermissions} from '../../permission_component/PermissionContext'
import UAFLoader from '../../loader/UafLoader'
interface DocumentItem {
  report: string
  remark: string
}
const BASE_URL = process.env.REACT_APP_API_URL
interface OptionType {
  value: string
  label: string
}
interface CabInterface {
  id: string
  programme_names: string[]
  scheme_names: {name: string; id: string; short_name: string}[]
  standard_names: {name: string; id: string}[]
  assessor_names: {first_name: string; id: string; last_name: string}[]
  cluster_names: any[]
  first_name: string
  last_name: string
  email: string
  phone: string
  company: string
  company_web: string
  director: string
  certification_manager: string
  certification_file: string
  license_number: string
  subject: string | null
  remark: string
  graduation_date: string | null
  cab_profile: string | null
  profile: string | null
  address: string | null
  store_password: string
  date_joined: string
  is_active: boolean
  img: string | null
  is_cab_user: boolean
  is_cab_team: boolean
  is_client: boolean
  is_historical_cab: boolean
  is_cab_client: boolean
  is_cab_verify: boolean
  is_cab_rejected: boolean
  is_auditor: boolean
  is_verify_auditor: boolean
  auditor_approve_date: string | null
  is_document_review_pending: boolean
  is_document_pending: boolean
  pending_management_review: boolean
  is_potential_assessor: boolean
  is_potential_assessor_reject: boolean
  is_accessor: boolean
  is_verify_accessor: boolean
  accessor_approve_date: string | null
  is_witness: boolean
  is_verify_witness: boolean
  witness_approve_date: string | null
  is_office_assessment: boolean
  is_verify_office_assessment: boolean
  office_assessment_approve_date: string | null
  is_document_reviewer: boolean
  is_verify_document_reviewer: boolean
  document_reviewer_approve_date: string | null
  cab_value: number
  is_credential: boolean
  create_at: string
  last_login: string
  country: string | null
  language: string | null
  currency: string | null
  auditor: string | null
  accessor: string | null
  witness: string | null
  office_assessment: string | null
  document_reviewer: string | null
  admin_user: string | null
  admin_team: string | null
  cab_user: string | null
  cab_team: string | null
  groups: any[]
  user_permissions: any[]
  user_programmes: string[]
  user_schemes: string[]
  user_standards: string[]
  user_clusters: any[]
  certification: any[]
  assign_role: any[]
  assign_user_role: any[]
  assessment: any[]
  team: any[]
  roles: any[]
  assign_user: any[]
  cab_auditor_assign_user: any[]
  cab_accessor_assign_user: any[]
  cab_document_reviewer_assign_user: any[]
  cab_witness_assign_user: any[]
  cab_office_assessment_assign_user: any[]
}
interface LocationState {
  targetvalue?: any
}
const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'ARC Reports',
    path: '/accreditation/cab/report',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const CreateARCReport: React.FC = () => {
  const {assessorPermission, cabPermission, superUserPermission, updatePermissions} =
    usePermissions()
  console.log(assessorPermission, 'DATATTATTATTATTATA')
  console.log(superUserPermission, 'superUserPermission')
  const navigate = useNavigate()
  const [cabData, setCabData] = useState<CabInterface[]>([])
  const location = useLocation()
  const state = location.state as LocationState
  const {targetvalue} = state || {}
  const [showmulti, setShowMulti] = useState(false)
  const [loader, setLoader] = useState<boolean>(false)
  const [showWRmulti, setShowWRMulti] = useState(false)
  console.log(targetvalue, 'this is the target value')
  const documentReviewItem = targetvalue?.find((item) => item.type === 'Document Review')
  const officeItem = targetvalue?.find((item) => item.type === 'Office Assessment')
  const witnessItem = targetvalue?.find((item) => item.type === 'Witness Assessment')

  // Get the assessment_number of the found item
  const documentNumber = documentReviewItem?.assessment_number
  const officeNumber = officeItem?.assessment_number
  const witnessNumber = witnessItem?.assessment_number
  console.log(witnessItem, 'this is the target witnessNumber')

  // ////console.log("cabData:",cabData);
  // const { id } = useParams();

  const {cab_id, arc_member, arc_no, stage} = useParams()
  const CAD_id = cab_id
  const ARC_member = arc_member
  const stage_id = stage
  const ARC_no = arc_no
  console.log('CAD_id:', CAD_id)
  console.log('targetvalue:', targetvalue)
  console.log('ARC_no:', ARC_no)
  const [singleCab, setSingleCab] = useState<any>()
  const [countryList, setCountryList] = useState<any>()

  const [tabValue, setTabValue] = React.useState('1')
  const [showInfoModal, setShowInfoModal] = useState(false)

  const [assessmentList, setAssessmentList] = useState<any>([])
  const [arcMemberDetails, setARCmemberDetails] = useState<any>([])
  const [assessorDetails, setAssessorDetails] = useState<any>([])
  const [assessorDocuments, setAssessorDocuments] = useState<any>([])
  const [assessorIAFCode, setAssessorIAFCode] = useState<any>([])

  const [showPdfModal, setShowPdfModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')

  const [schemeClusters, setSchemeClusters] = useState<any>([])
  console.log('arcMemberDetails:', arcMemberDetails)

  const [selectedClusters, setSelectedClusters] = useState<any>([])
  const [selectedCountryIds, setSelectedCountryIds] = useState<any>([])
  //console.log("selectedCountryIds:", selectedCountryIds);
  ////console.log("selectedClusters:", selectedClusters);
  /* dr assessment */
  const [dreviewreportQa, setDreviewreportQa] = useState<any>()
  const [dreviewreportQb, setDreviewreportQb] = useState<any>()
  const [documentReviewData, setDocumentReviewData] = useState<any>()
  const [documentReviewId, setDocumentReviewId] = useState<any>()

  const [documentReviewed, setDocumentReviewed] = useState<any>([])
  console.log(documentReviewId, 'documentReviewId')
  console.log(documentReviewed, 'documentReviewed')
  const [competenceDRRemarks, setCompetenceDRRemarks] = useState<any>()
  const [drRemarks, setDRRemarks] = useState<any>([])
  ////console.log(drRemarks, "drRemarks");

  const [oRRemarks, setORRemarks] = useState<any>([])
  const [wRRemarks, setWRRemarks] = useState<any>([])

  ////console.log(drRemarks, "drRemarks");
  ////console.log(oRRemarks, "oRRemarks");
  //console.log(wRRemarks, "wRRemarks");

  /* office asseessment */
  const [reviewReportA, setReviewReportA] = useState<any>()
  const [reviewReportB, setReviewReportB] = useState<any>()
  const [reviewReportC, setReviewReportC] = useState<any>()
  const [reviewReportD, setReviewReportD] = useState<any>()
  const [reviewReportE, setReviewReportE] = useState<any>()
  const [reviewReportF, setReviewReportF] = useState<any>()
  const [reviewReportG, setReviewReportG] = useState<any>()
  const [reviewReportH, setReviewReportH] = useState<any>()
  const [reviewReportI, setReviewReportI] = useState<any>()
  const [reviewReportJ, setReviewReportJ] = useState<any>()
  const [reviewReportK, setReviewReportK] = useState<any>()
  ////console.log(reviewReportK, "reviewReportK");

  const [reviewReportL, setReviewReportL] = useState<any>()
  const [officeTechExpert, setOfficeTechExpert] = useState<any>()
  const [officeReviewData, setOfficeReviewData] = useState<any>()
  const [officeReviewId, setOfficeReviewId] = useState<any>()
  console.log(officeReviewId, 'officeReviewId')

  const [officeReviewed, setOfficeReviewed] = useState<any>([])
  const [competenceOARemarks, setCompetenceOARemarks] = useState<any>()
  /* witness assessment */
  const [witnessReviewId, setWitnessReviewId] = useState<any>()
  ////console.log("witnessReviewId:", witnessReviewId);

  const [witnessReviewData, setWitnessReviewData] = useState<any>()
  console.log('witnessReviewData:', witnessReviewData)
  const [assessmentWitnessNumber, setAssessmentWitnessNumber] = useState<String>()
  const [witnessReviewed, setWitnessReviewed] = useState<any>([])
  const [witnessReviewReportA, setWitnessReviewReportA] = useState<any>()
  const [witnessReviewReportB, setWitnessReviewReportB] = useState<any>()
  const [witnessReviewReportC, setWitnessReviewReportC] = useState<any>()
  const [witnessTechExpert, setWitnessTechExpert] = useState<any>()
  const [competenceWARemarks, setCompetenceWARemarks] = useState<any>()
  /* arc assessment */
  const [recommendedLoaction, setRecommendedLoaction] = useState<any>()
  const [otherLoaction, setOtherLoaction] = useState<any>()
  const [selectedReportCheck, setSelectedReportCheck] = useState<any>()
  const [reportTextValue, setReportTextValue] = useState<any>()
  const [permissionCheck, setPermissionCheck] = useState<any>()
  const [permissionText, setPermissionText] = useState<any>()
  const [anyCondition, setAnyCondition] = useState<any>()
  const [arcReportDate, setArcReportDate] = useState<any>()
  const [arcMemberName, setARCMemberName] = useState<any>()
  const [finalReportId, setFinalReportId] = useState<any>()
  const [signatureImageData, setSignatureImageData] = useState<any>()
  ////console.log("signatureImageData:", signatureImageData);

  ////console.log("permissionCheck:", permissionCheck);
  ////console.log("permissionText:", permissionText);
  ////console.log("selectedReportCheck:", selectedReportCheck);
  ////console.log("reportTextValue:", reportTextValue);
  ////console.log("anyCondition:", anyCondition);
  const [assessorPlannerDataFromAssessment, setAssessorPlannerDataFromAssessment] = useState<any>(
    []
  )
  console.log('assessorPlannerDataFromAssessment:', assessorPlannerDataFromAssessment)
  const [allAssessorPlannerData, setAllAssessorPlannerData] = useState<any>([])
  const [multipleDocuments, setMultipleDocuments] = useState<any[]>([])
  const [multipleWRDocuments, setMultipleWRDocuments] = useState<any[]>([])
  console.log('multipleDocuments:', multipleDocuments)
  const [aRCclusters, setARCclusters] = useState<any>([])
  console.log(aRCclusters, 'aRCclusters')

  // const validateForm = () => {
  //   if (dreviewreportQa == undefined) {
  //     toast.error(
  //       'Is there sufficient evidence that the nonconformities/findings have been closed or CA Accepted prior to office Assessment?'
  //     )
  //     return false
  //   }
  //   if (dreviewreportQb == undefined) {
  //     toast.error(
  //       'Is a clear recommendation available in the Document review report to proceed for office assessment?'
  //     )
  //     return false
  //   }
  //   return true
  // }
  const validateArcForm = () => {
    // if (arcMemberName == undefined || arcMemberName == null || arcMemberName == ' ') {
    //   toast.error('Please Enter ARC Member Name')
    //   return false
    // }
    if (arcReportDate == undefined || arcReportDate == null || arcReportDate == ' ') {
      toast.error('Please Enter Review Date')
      return false
    }
    if (!signatureImageData) {
      toast.error('Please draw Signature and save')
      return false
    }
    return true
  }

  const options: OptionType[] = cabData.map((cab) => ({
    value: cab.id,
    label: `${cab.company}`,
  }))
  const [selectedOption, setSelectedOption] = useState<SingleValue<OptionType>>(null)
  ////console.log(selectedOption?.value, "selectedOption");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }
  const handleshowMultipleReport = (docs) => {
    setMultipleDocuments(docs)
    setShowMulti(true)
  }
  const handleshowMultipleWRReport = (docs) => {
    setMultipleWRDocuments(docs)
    setShowWRMulti(true)
  }
  const handleClosemulti = () => {
    setShowMulti(false)
    setMultipleDocuments([])
  }
  const handleWRClosemulti = () => {
    setShowWRMulti(false)
    setMultipleWRDocuments([])
  }
  useEffect(() => {
    if (aRCclusters) {
      const clusters = aRCclusters.flatMap((item) => item.clusters.map((cluster) => cluster.id))
      setSelectedClusters(clusters)
    }
  }, [aRCclusters])
  useEffect(() => {
    axios
      .get(`${BASE_URL}/account/approved/cab/user/`)
      .then((response) => setCabData(response.data))
      .catch((error) => {
        ////console.log(error);
      })
  }, [])
  useEffect(() => {
    if (CAD_id) {
      const getCABUserDetails = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/account/patch/user/${CAD_id}/`)
          setSingleCab(response.data)
        } catch (error) {
          console.error('API Error:', error)
          // throw error;
        }
      }
      getCABUserDetails()
    }
  }, [CAD_id])

  const formatDate = (dateString: string) => {
    return moment(dateString).format('MM/DD/YYYY')
  }
  // get assessment list
  const getAssessmentList = () => {
    axios
      .get(`${BASE_URL}/cab/assessment/list/`)
      .then((response) => {
        const data = response.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }))
        setAssessmentList(data)
      })
      .catch((error) => {
        ////console.log(error);
      })
  }
  useEffect(() => {
    getAssessmentList()
  }, [])

  const getAssessorDataByAssessment = async (type, number) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/cab/filter/scheme/assessor/planner/?cab=${CAD_id}&assessor=&scheme=&assessor_planner=&assessment_type=&assessment_type_name=${type}&assessment_number=${number}&create_at=&update_at=`
      )
      setAssessorPlannerDataFromAssessment(response.data)
    } catch (error) {
      ////console.log(error)
    }
  }

  const getAllAssessment = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/cab/filter/scheme/assessor/planner/?cab=${CAD_id}&assessor=&scheme=&assessor_planner=&assessment_type=&assessment_type_name=&create_at=&update_at=`
      )
      setAllAssessorPlannerData(response.data)
    } catch (error) {
      ////console.log(error)
    }
  }

  // get country list
  const fetchCountryData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/certificate/list/country/`)
      setCountryList(response.data)
    } catch (error) {
      ////console.log(error)
    }
  }
  useEffect(() => {
    fetchCountryData()
  }, [])
  const getSchemeClusterName = async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/global/cluster/filter/?scheme=${id}`)
      return response.data
    } catch (error) {
      console.error('API Error:', error)
      // throw error;
    }
  }

  useEffect(() => {
    const fetchClusterData = async () => {
      const clusterData = {}
      for (const item of assessorPlannerDataFromAssessment) {
        if (item?.scheme) {
          clusterData[item.scheme] = await getSchemeClusterName(item.scheme)
        }
      }
      setSchemeClusters(clusterData)
    }

    if (assessorPlannerDataFromAssessment) {
      fetchClusterData()
    }
  }, [assessorPlannerDataFromAssessment])

  const handleCheckboxChange = (clusterId) => {
    setSelectedClusters((prevSelectedClusters) => {
      if (prevSelectedClusters.includes(clusterId)) {
        return prevSelectedClusters.filter((id) => id !== clusterId)
      } else {
        // Add the clusterId if it is not already selected
        return [...prevSelectedClusters, clusterId]
      }
    })
  }
  /* get assessor Info */
  const getUserDetails = async (userId: string) => {
    try {
      const response = await axios.get(`${BASE_URL}/team/user/data/${userId}/`)
      ////console.log("ASSESSOR:", response.data);
      setAssessorDetails(response?.data?.details)
      setAssessorDocuments(response?.data?.documents)
      setAssessorIAFCode(response?.data?.iaf_critical)
    } catch (error) {
      // Handle errors
      console.error('API Error:', error)
      // throw error;
    }
  }
  const getARCclusters = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/arc/assign/cab/assessor/witness/assessment/?cab=${CAD_id}&arc=${ARC_no}`
      )
      ////console.log("ASSESSOR:", response.data);
      setARCclusters(response?.data?.updated_schemes)
    } catch (error) {
      // Handle errors
      console.error('API Error:', error)
      // throw error;
    }
  }
  useEffect(() => {
    getARCclusters()
  }, [CAD_id, ARC_no])
  const getARCmemberDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/team/user/data/${ARC_member}/`)
      ////console.log("ASSESSOR:", response.data);
      setARCmemberDetails(response?.data?.details)
    } catch (error) {
      // Handle errors
      console.error('API Error:', error)
      // throw error;
    }
  }
  useEffect(() => {
    getARCmemberDetails()
  }, [ARC_member])
  const handleOpenInfoModel = (id) => {
    getUserDetails(id)
    setShowInfoModal(true)
  }
  const handleCloseInfoModel = () => {
    setShowInfoModal(false)
  }
  const handleDocumentReviewChange = (id, value) => {
    setDocumentReviewed((prev) =>
      prev.map((item) => (item.id === id ? {...item, remark: value} : item))
    )
  }

  const handleDRRemarksChange = (id, value) => {
    setDRRemarks((prevRemarks) => ({
      ...prevRemarks,
      [id]: value,
    }))
  }

  const handleOfficeReviewChange = (id, value) => {
    setOfficeReviewed((prev) =>
      prev.map((item) => (item.id === id ? {...item, remark: value} : item))
    )
  }
  const handleWitnessReviewChange = (id, value) => {
    setWitnessReviewed((prev) =>
      prev.map((item) => (item.id === id ? {...item, remark: value} : item))
    )
  }

  const handleORRemarksChange = (id, value) => {
    setORRemarks((prevRemarks) => ({
      ...prevRemarks,
      [id]: value,
    }))
  }
  const handleWRRemarksChange = (id, value) => {
    setWRRemarks((prevRemarks) => ({
      ...prevRemarks,
      [id]: value,
    }))
  }
  const handleCountryChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value)
    setSelectedCountryIds(selectedIds)
  }
  const handleReportChange = (e) => {
    setSelectedReportCheck(e.target.value)
  }
  const handlePermissionChange = (e) => {
    setPermissionCheck(e.target.value)
  }
  const handleReportTextChange = (e) => {
    setReportTextValue(e.target.value)
  }
  // useEffect(()=>{
  //   const selectedCountryData = countryList?.filter(country => selectedCountryIds.includes(country.id));
  //   setSelectedCountryIds(selectedCountryData);
  // },[])

  /* DR submit */

  useEffect(() => {
    const fetchDocumentDetails = async () => {
      if (CAD_id) {
        try {
          ////console.log(`Fetching document review for CAD_id: ${CAD_id}`);
          const reviewResponse = await axios.get(
            `${BASE_URL}/arc/document/review/get/?cab=${CAD_id}&assessor=${ARC_member}`
          )
          ////console.log('Document Review Response:', reviewResponse?.data);
          setDocumentReviewData(reviewResponse?.data)
          setDreviewreportQa(reviewResponse?.data?.review_report_qa)
          setDreviewreportQb(reviewResponse?.data?.review_report_qb)
          setDocumentReviewId(reviewResponse?.data?.id)
          setDocumentReviewed(reviewResponse?.data?.review_remark)

          const initialRemarks = reviewResponse?.data?.review_remark.reduce((acc, remark) => {
            acc[remark.review] = remark.remark
            return acc
          }, {})
          setDRRemarks(initialRemarks)

          // Assuming assessorPlannerDataFromAssessment is part of the response
          // setAssessorPlannerDataFromAssessment(reviewResponse?.data?.assessorPlannerDataFromAssessment || []);
        } catch (error) {
          //console.error('API Error while fetching document details:', error);
          // toast.error('Problem fetching CAB Information');
        }
      }
    }

    fetchDocumentDetails()
  }, [CAD_id])

  const handleDRSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    // if (!validateForm()) return

    if (!singleCab) {
      toast.error('singleCab is undefined')
      return
    }

    // Check if dreviewreportQa, dreviewreportQb, or drRemarks is null or undefined
    if (!documentReviewId) {
      try {
        const requestData: any = {
          cab: singleCab.id,
          arc: ARC_member,
          arc_code: ARC_no,
          review_report_qa: dreviewreportQa,
          review_report_qb: dreviewreportQb,
          dr_remarks: drRemarks,
          document_review: [] as string[], // Initialize document_review as an array
        }

        const uuids = assessorPlannerDataFromAssessment?.map((item) => item.id)
        if (uuids && uuids.length > 0) {
          requestData.document_review = uuids
        }

        const response = await axios.post(`${BASE_URL}/arc/document/review/post/`, requestData)

        if (response.status === 200 || response.status === 201) {
          toast.success('Submitted successfully.')
          setTabValue('3')
          getAssessorDataByAssessment('Office Assessment', officeNumber)
        } else {
          toast.error('Failed to submit application. Please try again.')
        }
      } catch (error) {
        toast.error('Something went wrong')
      }
    } else {
      handleDRUpdate(event)
    }
  }
  const handleDRUpdate = async (event: React.FormEvent) => {
    try {
      const requestData: any = {
        review_report_qa: dreviewreportQa,
        review_report_qb: dreviewreportQb,
      }

      const response = await axios.put(
        `${BASE_URL}/arc/document/review/${documentReviewId}/update/`,
        requestData
      )
      if (response.status === 200 || response.status === 201) {
        toast.success('Application Updated successfully.')
        setTabValue('3')
        getAssessorDataByAssessment('Office Assessment', officeNumber)
      } else {
        toast.error('Failed to submit application. Please try again.')
      }
      await updateDRRemarks()
    } catch (error) {
      toast.error('Something went wrong')
    }
  }
  const updateDRRemarks = async () => {
    try {
      for (const item of documentReviewed) {
        const requestData = {remark: item.remark}
        const response = await axios.put(
          `${BASE_URL}/arc/document/review/remark/${item.id}/update/`,
          requestData
        )
        if (response.status === 200 || response.status === 201) {
          toast.success(`Remark with ID: ${item.id} updated successfully`)
        } else {
          toast.error(`Failed to update remark with ID: ${item.id}`)
        }
      }
    } catch (error) {
      toast.error('Something went wrong while updating remarks')
    }
  }

  /* OA submit */

  useEffect(() => {
    const fetchOfficeDetails = async () => {
      if (CAD_id) {
        try {
          ////console.log(`Fetching document review for CAD_id: ${CAD_id}`);
          const reviewResponse = await axios.get(
            `${BASE_URL}/arc/office/assessment/get/?cab=${CAD_id}&assessor=${ARC_member}`
          )

          ////console.log('office Review Response:', reviewResponse?.data);
          setOfficeReviewData(reviewResponse?.data)
          setOfficeReviewId(reviewResponse?.data?.id)
          setOfficeReviewed(reviewResponse?.data?.review_remark)

          setReviewReportA(reviewResponse?.data?.review_report_qa)
          setReviewReportB(reviewResponse?.data?.review_report_qb)
          setReviewReportC(reviewResponse?.data?.review_report_qc)
          setReviewReportD(reviewResponse?.data?.review_report_qd)
          setReviewReportE(reviewResponse?.data?.review_report_qe)
          setReviewReportF(reviewResponse?.data?.review_report_qf)
          setReviewReportG(reviewResponse?.data?.review_report_qg)
          setReviewReportH(reviewResponse?.data?.review_report_qh)
          setReviewReportI(reviewResponse?.data?.review_report_qi)
          setReviewReportJ(reviewResponse?.data?.review_report_qj)
          setReviewReportK(reviewResponse?.data?.review_report_qk)
          setReviewReportL(reviewResponse?.data?.review_report_ql)

          const initialRemarks = reviewResponse?.data?.review_remark.reduce((acc, remark) => {
            acc[remark.review] = remark.remark
            return acc
          }, {})
          oRRemarks(initialRemarks)

          // Assuming assessorPlannerDataFromAssessment is part of the response
          // setAssessorPlannerDataFromAssessment(reviewResponse?.data?.assessorPlannerDataFromAssessment || []);
        } catch (error) {
          //console.error('API Error while fetching Office details:', error);
          // toast.error('Problem fetching CAB Information');
        }
      }
    }

    fetchOfficeDetails()
  }, [CAD_id])

  const handleOASubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    // if (!validateForm()) return;
    if (!officeReviewId) {
      try {
        if (!singleCab) {
          throw new Error('singleCab is undefined')
        }

        const formData = new FormData()
        formData.append('cab', singleCab.id)
        formData.append('tech_expert', officeTechExpert)
        formData.append('review_report_a', reviewReportA)
        formData.append('review_report_b', reviewReportB)
        formData.append('review_report_qc', reviewReportC)
        formData.append('review_report_qd', reviewReportD)
        formData.append('review_report_qe', reviewReportE)
        formData.append('review_report_qf', reviewReportF)
        formData.append('review_report_qg', reviewReportG)
        formData.append('review_report_qh', reviewReportH)
        formData.append('review_report_qi', reviewReportI)
        formData.append('review_report_qj', reviewReportJ)
        formData.append('review_report_qk', reviewReportK)
        formData.append('review_report_ql', reviewReportL)
        if (arc_member !== undefined) formData.append('arc', arc_member)
        if (arc_no !== undefined) formData.append('arc_code', arc_no)
        formData.append('or_remarks', JSON.stringify(oRRemarks))

        const uuids = assessorPlannerDataFromAssessment?.map((item) => item.id)
        if (uuids && uuids.length > 0) {
          uuids.forEach((uuid) => {
            formData.append('document_review', uuid)
          })
        }
        const response = await axios.post(`${BASE_URL}/arc/office/assessment/post/`, formData)
        if (response.status === 200 || response.status === 201) {
          toast.success(' Submitted successfully.')
          setTabValue('4')
          getAssessorDataByAssessment('witness Assessment', witnessNumber)
        } else {
          toast.error('Failed to submit application. Please try again.')
        }
      } catch (error) {
        toast.error('Something went wrong')
      }
    } else {
      handleORUpdate(event)
    }
  }
  const handleORUpdate = async (event: React.FormEvent) => {
    try {
      const requestData: any = {
        review_report_qa: reviewReportA,
        review_report_qb: reviewReportB,
        review_report_qc: reviewReportC,
        review_report_qd: reviewReportD,
        review_report_qe: reviewReportE,
        review_report_qf: reviewReportF,
        review_report_qg: reviewReportG,
        review_report_qh: reviewReportH,
        review_report_qi: reviewReportI,
        review_report_qj: reviewReportJ,
        review_report_qk: reviewReportK,
        review_report_ql: reviewReportL,
      }

      const response = await axios.put(
        `${BASE_URL}/arc/office/assessment/${officeReviewId}/update/`,
        requestData
      )
      if (response.status === 200 || response.status === 201) {
        toast.success('Submitted successfully.')
        setTabValue('4')
        getAssessorDataByAssessment('witness Assessment', witnessNumber)
      } else {
        toast.error('Failed to submit application. Please try again.')
      }
      await updateORemarks()
    } catch (error) {
      toast.error('Something went wrong')
    }
  }
  const updateORemarks = async () => {
    try {
      for (const item of officeReviewed) {
        const requestData = {remark: item.remark}
        const response = await axios.put(
          `${BASE_URL}/arc/office/assessment/remark/${item.id}/update/`,
          requestData
        )
        if (response.status === 200 || response.status === 201) {
          toast.success(`Remark with ID: ${item.id} updated successfully`)
        } else {
          toast.error(`Failed to update remark with ID: ${item.id}`)
        }
      }
    } catch (error) {
      toast.error('Something went wrong while updating remarks')
    }
  }
  /* WA submit */
  useEffect(() => {
    const fetchWitnessDetails = async () => {
      if (CAD_id && assessmentWitnessNumber) {
        try {
          ////console.log(`Fetching Witness review for CAD_id: ${CAD_id}`);
          const reviewResponse = await axios.get(
            `${BASE_URL}/arc/witness/assessment/report/get/?cab=${CAD_id}&assessor=${ARC_member}&assessment_number=${assessmentWitnessNumber}`
          )
          //console.log('Witness Review Response:', reviewResponse?.data);
          setWitnessReviewData(reviewResponse?.data)
          // setWitnessReviewId(reviewResponse?.data?.id)
          // setWitnessReviewed(reviewResponse?.data?.review_remark)

          // const initialRemarks = reviewResponse?.data?.review_remark.reduce((acc, remark) => {
          //   acc[remark.review] = remark.remark;
          //   return acc;
          // }, {});
          // wRRemarks(initialRemarks);

          // Assuming assessorPlannerDataFromAssessment is part of the response
          // setAssessorPlannerDataFromAssessment(reviewResponse?.data?.assessorPlannerDataFromAssessment || []);
        } catch (error) {
          //console.error('API Error while fetching Witness details:', error);
          // toast.error('Problem fetching CAB Information');
        }
      }
    }

    fetchWitnessDetails()
  }, [CAD_id, assessmentWitnessNumber])

  const handleWASubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (witnessReviewData?.length == 0 || witnessReviewData?.length < 0) {
      try {
        if (!singleCab) {
          throw new Error('singleCab is undefined')
        }

        const requests = assessorPlannerDataFromAssessment?.map((item) => {
          const formData = new FormData()
          formData.append('cab', singleCab.id)
          formData.append('assessment_number', item.assessment_number)
          formData.append('scheme', item.scheme)
          formData.append('assessor_planner', item.id)
          formData.append('review_report_qa', item.witnessReviewReportA || '')
          formData.append('review_report_qb', item.witnessReviewReportB || '')
          formData.append('remark', item.witnessReviewRemarks || '')
          if (arc_member !== undefined) formData.append('assessor', arc_member)
          if (arc_no !== undefined) formData.append('arc_code', arc_no)
          return axios.post(`${BASE_URL}/arc/witness/assessment/report/post/`, formData)
        })

        // Wait for all the requests to complete
        const responses = await Promise.all(requests)

        // Check if all responses are successful
        const allSuccessful = responses.every(
          (response) => response.status === 200 || response.status === 201
        )

        if (allSuccessful) {
          toast.success('Submitted successfully.')
          setAssessorPlannerDataFromAssessment([])
          // setTabValue('5')
        } else {
          toast.error('Failed to submit application. Please try again.')
        }
      } catch (error) {
        toast.error('Something went wrong')
      }
    } else {
      handleWAUpdate(event)
    }
  }
  const handleWAUpdate = async (event: React.FormEvent) => {
    try {
      for (const item of witnessReviewData) {
        const requestData: any = {
          review_report_qa: item?.review_report_qa,
          review_report_qb: item?.review_report_qb,
          remark: item?.remark,
        }

        const response = await axios.put(
          `${BASE_URL}/arc/witness/assessment/report/${item?.id}/update/`,
          requestData
        )
        if (response.status === 200 || response.status === 201) {
          toast.success('Submitted successfully.')
          // setTabValue('5')
          getAllAssessment()
        } else {
          toast.error('Failed to submit application. Please try again.')
        }
      }
    } catch (error) {
      toast.error('Something went wrong')
    }
  }
  /* arc submit */

  useEffect(() => {
    const fetchARCDetails = async () => {
      if (CAD_id) {
        try {
          ////console.log(`Fetching Witness review for CAD_id: ${CAD_id}`);
          const reviewResponse = await axios.get(
            `${BASE_URL}/arc/management/final/report/filter/?cab=${CAD_id}&assessor=${ARC_member}&cluster=&country=&create_at=&update_at=`
          )
          //console.log('fetchARCDetails:', reviewResponse?.data);
          // setWitnessReviewData(reviewResponse?.data);
          setSelectedCountryIds(reviewResponse?.data[0]?.country)
          setOtherLoaction(reviewResponse?.data[0]?.other_location)
          setRecommendedLoaction(reviewResponse?.data[0]?.recommended_location)
          setPermissionCheck(reviewResponse?.data[0]?.accredited_permission)
          setAnyCondition(reviewResponse?.data[0]?.any_arc_condition)
          setPermissionText(reviewResponse?.data[0]?.accredited_permission_treason)
          setSelectedReportCheck(reviewResponse?.data[0]?.accredited_check)
          setReportTextValue(reviewResponse?.data[0]?.accredited_text)
          setArcReportDate(reviewResponse?.data[0]?.arc_report_date)
          setARCMemberName(reviewResponse?.data[0]?.arc_member_name)
          setFinalReportId(reviewResponse?.data[0]?.id)
        } catch (error) {
          //console.log(error);
        }
      }
    }

    fetchARCDetails()
  }, [CAD_id])
  const handleArcSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!validateArcForm()) return
    if (!finalReportId) {
      try {
        setLoader(true)
        if (!singleCab) {
          throw new Error('singleCab is undefined')
        }
        const data = {
          cluster: selectedClusters,
        }
        const requestData = {
          cab: singleCab.id,
          // cluster: selectedClusters,
          country: selectedCountryIds,
          recommended_location: recommendedLoaction || '',
          other_location: otherLoaction || '',
          accredited_check: selectedReportCheck || '',
          accredited_text: reportTextValue || '',
          any_arc_condition: anyCondition || '',
          accredited_permission: permissionCheck || '',
          accredited_permission_treason: permissionText || '',
          arc_member_name: `${arcMemberDetails?.first_name} ${arcMemberDetails?.last_name}`,
          arc_report_date: arcReportDate,
          arc_member_sign: signatureImageData,
          arc: ARC_member || '',
          arc_code: ARC_no || '',
        }

        const response = await axios.post(`${BASE_URL}/arc/final/report/post/`, requestData)
        const responses = await axios.post(
          `${BASE_URL}/account/user/recommended/arc/certification/${CAD_id}/`,
          data
        )
        if (response.status === 200 || response.status === 201) {
          toast.success('Submitted successfully.')
          setLoader(false)
          navigate(
            `/are/generated/report/cab/${singleCab.id}/arc/${ARC_member}/arc_no/${ARC_no}/stage/${stage_id}`,
            {
              state: {targetvalue},
            }
          )
        } else {
          toast.error('Failed to submit application. Please try again.')
          setLoader(false)
        }
      } catch (error) {
        toast.error('Something went wrong')
        setLoader(false)
      }
    } else {
      handleARCupdate()
    }
  }
  const handleARCupdate = async () => {
    try {
      if (!singleCab) {
        throw new Error('singleCab is undefined')
      }
      const data = {
        cluster: selectedClusters,
      }
      const updateData = {
        cab: singleCab.id,
        // cluster: selectedClusters,
        country: selectedCountryIds,
        recommended_location: recommendedLoaction || '',
        other_location: otherLoaction || '',
        accredited_check: selectedReportCheck || '',
        accredited_text: reportTextValue || '',
        any_arc_condition: anyCondition || '',
        accredited_permission: permissionCheck || '',
        accredited_permission_treason: permissionText || '',
        arc_member_name: `${arcMemberDetails?.first_name} ${arcMemberDetails?.last_name}`,
        arc_report_date: arcReportDate,
        arc_member_sign: signatureImageData,
      }
      const responses = await axios.post(
        `${BASE_URL}/account/user/recommended/arc/certification/${CAD_id}/`,
        data
      )
      const response = await axios.put(
        `${BASE_URL}/arc/management/final/report/${finalReportId}/update/`,
        updateData
      )
      if (responses.status === 200 || responses.status === 201) {
        fetchCountryData()
      }
      if (response.status === 200 || response.status === 201) {
        toast.success('Application updated successfully.')

        navigate(
          `/are/generated/report/cab/${singleCab.id}/arc/${ARC_member}/arc_no/${ARC_no}/stage/${stage_id}`,
          {
            state: {targetvalue},
          }
        )
      } else {
        toast.error('Failed to update application. Please try again.')
      }
      if (responses.status === 200 || responses.status === 201) {
        fetchCountryData()
      }
    } catch (error) {
      toast.error('Something went wrong')
    }
  }
  // useEffect(() => {
  //   if (assessorPlannerDataFromAssessment) {
  //     const formattedData = assessorPlannerDataFromAssessment
  //       .map(
  //         (item, index) =>
  //           `Recommendation ${index + 1}:\n` +
  //           `  Scheme: ${item?.scheme_name}\n` +
  //           `    Scopes/Technical Areas:\n${item?.cluster_values
  //             ?.map((value) => `     - ${value?.name}`)
  //             .join('\n')}\n\n`
  //       )
  //       .join('\n')

  //     setReviewReportK(formattedData)
  //   } else {
  //     setReviewReportK('') // Set a default value when data is not available
  //   }
  // }, [assessorPlannerDataFromAssessment])

  const handleClosePdfModal = () => {
    setShowPdfModal(false)
    setPdfUrl('') // Clear the PDF URL when the modal is closed
  }
  return (
    <div>
      {loader ? <UAFLoader /> : null}
      <PageTitle breadcrumbs={accountBreadCrumbs}>Generate Accreditation Report</PageTitle>

      <Box sx={{width: '100%', typography: 'body1'}}>
        <TabContext value={tabValue}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabList onChange={handleTabChange} aria-label='lab API tabs example'>
              <Tab label='CAB Information' value='1' />
              <Tab
                label='Document Review Report'
                value='2'
                onClick={() => getAssessorDataByAssessment('Document Review', documentNumber)}
              />
              <Tab
                label='Office Assessment Report'
                value='3'
                onClick={() => getAssessorDataByAssessment('Office Assessment', officeNumber)}
              />
              <Tab
                label='Witness Assessment Report'
                value='4'
                onClick={() => setAssessorPlannerDataFromAssessment([])}
                // onClick={() => getAssessorDataByAssessment('Witness Assessment', witnessNumber)}
              />
              <Tab label='Annexure Report' value='5' onClick={() => getAllAssessment()} />
              <Tab label='ARC Report' value='6' />
              {/* <Tab label="Item Three" value="3" /> */}
            </TabList>
          </Box>

          <TabPanel value='1'>
            <div className='py-lg-10 px-lg-10'>
              <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-body p-9'>
                  <h3 className='mb-10'>CAB Information</h3>
                  <div className='row'>
                    <div className='mb-5 col-lg-6'>
                      <label className='required form-label'>CAB Name</label>
                      <input
                        value={singleCab?.company}
                        className='form-control form-control-solid'
                        type='text'
                        name='cab_key_address'
                        readOnly
                      />
                      {/* <Select
                        className='cab-control'
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                      /> */}
                    </div>
                    <div className='mb-5 col-lg-6'>
                      <label className='required form-label'>
                        Date of Application, if applicable (YYYY/MM/DD)
                      </label>
                      <input
                        type='text'
                        readOnly
                        className='form-control form-control-solid'
                        placeholder=' Application date '
                        name='application_date'
                        value={singleCab?.application_date}
                      />
                    </div>
                    <div className='mb-5 col-lg-6'>
  <label className='required form-label'>Key Location</label>
  <textarea
                                            className='form-control form-control-solid'
                                            rows={5}
                                            value={(() => {
                                              const keyLocation = singleCab?.cab_country_active?.find(
                                                (location) => location.location_type === 'Key Location'
                                              );
                                              return keyLocation
                                                ? `${keyLocation.address || ''}${
                                                    keyLocation.city__name ? `, ${keyLocation.city__name}` : ''
                                                  }${keyLocation.state__name ? `, ${keyLocation.state__name}` : ''}${
                                                    keyLocation.country__name ? `, ${keyLocation.country__name}` : ''
                                                  }`
                                                : '';
                                            })()}
                                            readOnly
                                          />
  {/* <input
    value={(() => {
      const keyLocation = singleCab?.cab_country_active?.find(
        (location) => location.location_type === 'Key Location'
      );
      return keyLocation
        ? `${keyLocation.address || ''}${
            keyLocation.city__name ? `, ${keyLocation.city__name}` : ''
          }${keyLocation.state__name ? `, ${keyLocation.state__name}` : ''}${
            keyLocation.country__name ? `, ${keyLocation.country__name}` : ''
          }`
        : '';
    })()}
    className='form-control form-control-solid'
    type='text'
    name='cab_key_address'
    readOnly
  /> */}
</div>


<div className='mb-5 col-lg-6'>
  <label className='required form-label'>Other Locations</label>
  <textarea
                                            className='form-control form-control-solid'
                                            rows={5}
                                           value={(() => {
                                              const otherLocations = singleCab?.cab_country_active?.filter(
                                                (location) => location.location_type === 'Others'
                                              );
                                              return otherLocations?.map((location) =>
                                                  `${location.address || ''}${
                                                    location.city__name ? `, ${location.city__name}` : ''
                                                  }${location.state__name ? `, ${location.state__name}` : ''}${
                                                    location.country__name ? `, ${location.country__name}` : ''
                                                  }`
                                                )
                                                .join('; ');
                                            })()}
                                            readOnly
                                          />
  {/* <input
    value={(() => {
      const otherLocations = singleCab?.cab_country_active?.filter(
        (location) => location.location_type === 'Others'
      );
      return otherLocations
        .map((location) =>
          `${location.address || ''}${
            location.city__name ? `, ${location.city__name}` : ''
          }${location.state__name ? `, ${location.state__name}` : ''}${
            location.country__name ? `, ${location.country__name}` : ''
          }`
        )
        .join('; ');
    })()}
    className='form-control form-control-solid'
    type='text'
    name='other_location'
    readOnly
  /> */}
</div>
                
                    <div className='mb-5'>
                      <label className='required form-label'>
                        Scheme(s) of accreditation (MS) for ARC:
                      </label>
                      <textarea
                        className='form-control form-control-solid'
                        rows={5}
                        value={singleCab?.scheme_names
                          ?.map((scheme) => scheme.name + ' --  (' + scheme.short_name + ')')
                          .join('\n')}
                        readOnly
                        name='selected_scheme'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-5 d-flex justify-content-between'>
                <div>
                  {/* <button className='btn btn-primary' onClick={() => setTabValue("4")}>
                      <i className='fa fa-angle-double-left' aria-hidden='true' /> Back
                    </button> */}
                </div>
                <div>
                  <button
                    className='btn btn-primary'
                    onClick={() => {
                      setTabValue('2')
                      getAssessorDataByAssessment('Document Review', documentNumber)
                    }}
                  >
                    Next <i className='fa fa-angle-double-right' aria-hidden='true' />
                  </button>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value='2'>
            <form onSubmit={handleDRSubmit}>
              <div className='py-lg-10 px-lg-10'>
                <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                  <div className='card-body p-9'>
                    {/* <h3 className='mb-10'>  Document Review Details</h3> */}
                    <div className='row'>
                      <div className='mb-5 col-lg-12'>
                        <h3>Document Review Report date, if applicable </h3>
                        <table
                          className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                          id='kt_table_users'
                        >
                          <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                              <th className='w-10px pe-2 fs-7'> Date(s)</th>
                              <th className='w-10px pe-2 fs-7'>Schemes</th>
                              <th className='w-10px pe-2 fs-7'>Assessment Number</th>
                              {/* <th className='w-10px pe-2 fs-7'>DRR</th> */}
                              <th className='w-10px pe-2 fs-7'>Assessor Name</th>
                            </tr>
                          </thead>

                          <tbody className='text-gray-600 fw-semibold'>
                            {assessorPlannerDataFromAssessment?.map((item) => (
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item && item.update_at ? formatDate(item.update_at) : '--'}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>{item?.scheme_name}</div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.assessment_number}
                                  </div>
                                </td>
                                {/* <td>
                                  {item?.multi_documents ? (
                                    <button
                                      className='btn btn-primary btn-sm '
                                      type='button'
                                      onClick={() =>
                                        handleshowMultipleReport(item?.multi_documents)
                                      }
                                    >
                                      Reports
                                    </button>
                                  ) : (
                                    <div className='badge badge-light fw-bolder'>
                                      <div className='badge badge-light-danger fw-bold'>
                                        Pending...
                                      </div>
                                    </div>
                                  )}
                                </td> */}
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {/* {item?.assessor_name?.map((assessor, index) => (
                                      <span key={assessor[0].id}>
                                        {assessor[0].first_name} {assessor[0].last_name}
                                        {index < item.assessor_name.length - 1 ? ', ' : ''}
                                      </span>
                                    ))} */}
                                    {Array.from(
                                      new Set(
                                        item?.assessor_name?.map(
                                          (assessor) =>
                                            `${assessor[0].first_name} ${assessor[0].last_name}`
                                        )
                                      )
                                    ).map((assessor: any, index: any, arr: any[]) => (
                                      <span key={index}>
                                        {assessor}
                                        {index < arr.length - 1 ? ', ' : ''}
                                      </span>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <h3>
                          Document Review Reports:{' '}
                          {assessorPlannerDataFromAssessment[0]?.multi_documents ? (
                            <button
                              className='btn btn-primary btn-sm '
                              type='button'
                              onClick={() =>
                                handleshowMultipleReport(
                                  assessorPlannerDataFromAssessment[0]?.multi_documents
                                )
                              }
                            >
                              Reports
                            </button>
                          ) : (
                            <div className='badge badge-light fw-bolder'>
                              <div className='badge badge-light-danger fw-bold'>Pending...</div>
                            </div>
                          )}
                        </h3>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='mb-5 col-lg-12'>
                        <h3>
                          Competence verification of Assessment Team for document review, if
                          applicable{' '}
                        </h3>
                        <table
                          className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                          id='kt_table_users'
                        >
                          <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                              <th className='w-10px pe-2 fs-7'>Assessor Name</th>
                              <th className='w-10px pe-2 fs-7'>Schemes</th>

                              <th className='w-10px pe-2 fs-7'>
                                Competence Verification By System{' '}
                              </th>
                              <th className='w-10px pe-2 fs-7'>Remarks</th>
                            </tr>
                          </thead>
                          <tbody className='text-gray-600 fw-semibold'>
                            {assessorPlannerDataFromAssessment?.map((item) => (
                              <tr key={item.id}>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <ul style={{listStyle: 'none'}}>
                                      {Array.from(
                                        new Set(
                                          item?.assessor_name?.map(
                                            (assessor) => assessor[0]?.assessor_id
                                          )
                                        )
                                      ).map((uniqueId: any, index) => {
                                        const uniqueAssessor = item?.assessor_name?.find(
                                          (assessor) => assessor[0]?.assessor_id === uniqueId
                                        )
                                        return (
                                          <li className='mb-1' key={uniqueId}>
                                            {uniqueAssessor[0]?.first_name}{' '}
                                            {uniqueAssessor[0]?.last_name}
                                            <i
                                              className='p-2 fs-5 fas fa-info-circle'
                                              onClick={() =>
                                                handleOpenInfoModel(uniqueAssessor[0]?.assessor_id)
                                              }
                                            ></i>
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      value={item?.scheme_name}
                                      readOnly
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      value='Verified'
                                      readOnly
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {!documentReviewId ? (
                                      <>
                                        <textarea
                                          className='form-control form-control-solid'
                                          rows={5}
                                          value={drRemarks[item.id] || ''}
                                          onChange={(e) =>
                                            handleDRRemarksChange(item.id, e.target.value)
                                          }
                                          name='uaf_requirement'
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {documentReviewed?.map((reviewItem: any) => {
                                          if (reviewItem.review === item.id) {
                                            return (
                                              <textarea
                                                key={reviewItem.id}
                                                className='form-control form-control-solid'
                                                rows={5}
                                                value={reviewItem.remark || ''}
                                                onChange={(e) =>
                                                  handleDocumentReviewChange(
                                                    reviewItem.id,
                                                    e.target.value
                                                  )
                                                }
                                                name='uaf_requirement'
                                              />
                                            )
                                          }
                                          return null
                                        })}
                                      </>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className='row mt-10'>
                      <div className='mb-5 col-lg-12'>
                        <h3>Review of Document Review Report: </h3>
                        <table
                          className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                          id='kt_table_users'
                        >
                          <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                              <th style={{width: '50%'}} className='pe-2 fs-7'>
                                Q
                              </th>
                              <th style={{width: '50%'}} className='pe-2 fs-7'>
                                A
                              </th>
                            </tr>
                          </thead>
                          <tbody className='text-gray-600 fw-semibold'>
                            <tr>
                              <td>
                                <div className='text-gray-800 mb-1'>
                                  Is there sufficient evidence that the nonconformities/findings
                                  have been closed or CA Accepted prior to office Assessment?
                                </div>
                              </td>
                              <td>
                                <div className='text-gray-800 mb-1'>
                                  <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    onChange={(e) => setDreviewreportQa(e?.target?.value)}
                                    name='uaf_requirement'
                                    value={dreviewreportQa}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className='text-gray-800 mb-1'>
                                  Is a clear recommendation available in the Document review report
                                  to proceed for office assessment?
                                </div>
                              </td>
                              <td>
                                <div className='text-gray-800 mb-1'>
                                  <textarea
                                    className='form-control form-control-solid'
                                    rows={5}
                                    onChange={(e) => setDreviewreportQb(e?.target?.value)}
                                    name='uaf_requirement'
                                    value={dreviewreportQb}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mb-5 d-flex justify-content-between'>
                  <div>
                    {/* <button className='btn btn-primary' onClick={() => setTabValue("1")}>
                      <i className='fa fa-angle-double-left' aria-hidden='true' /> Back
                    </button> */}
                  </div>
                  <div>
                    <button type='submit' className='btn btn-primary'>
                      save <i className='fa fa-angle-double-right' aria-hidden='true' />
                    </button>
                    {/* <button className='btn btn-primary' onClick={() => setTabValue("3")}>
                      Next <i className='fa fa-angle-double-right' aria-hidden='true' />
                    </button> */}
                  </div>
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel value='3'>
            <form onSubmit={handleOASubmit}>
              <div className='py-lg-10 px-lg-10'>
                <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                  <div className='card-body p-9'>
                    {/* <h3 className='mb-10'> Office Assessment Details</h3> */}
                    <div className='row'>
                      <div className='mb-5 col-lg-12'>
                        <h3>Office Assessment Report date, if applicable </h3>
                        <table
                          className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                          id='kt_table_users'
                        >
                          <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                              <th className='w-10px pe-2 fs-7'>Date(s)</th>
                              <th className='w-10px pe-2 fs-7'>Schemes</th>
                              <th className='w-10px pe-2 fs-7'>Assessment Number</th>
                              <th className='w-10px pe-2 fs-7'>Assessment Duration</th>
                              <th className='w-10px pe-2 fs-7'>Assessor Name</th>
                              {/* <th className='w-10px pe-2 fs-7'>ORR</th> */}
                            </tr>
                          </thead>

                          <tbody className='text-gray-600 fw-semibold'>
                            {assessorPlannerDataFromAssessment?.map((item) => (
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item && item.update_at ? formatDate(item.update_at) : '--'}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>{item?.scheme_name}</div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.assessment_number}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.assessment_duration}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.assessor_name?.map((assessor, index) => (
                                      <span key={assessor[0].id}>
                                        {assessor[0].first_name} {assessor[0].last_name}
                                        {index < item.assessor_name.length - 1 ? ', ' : ''}
                                      </span>
                                    ))}
                                  </div>
                                </td>
                                {/* {item?.multi_documents ? (
                                  <button
                                    className='btn btn-primary btn-sm '
                                    type='button'
                                    onClick={() => handleshowMultipleReport(item?.multi_documents)}
                                  >
                                    Reports
                                  </button>
                                ) : (
                                  <div className='badge badge-light fw-bolder'>
                                    <div className='badge badge-light-danger fw-bold'>
                                      Pending...
                                    </div>
                                  </div>
                                )} */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <h3>
                          Office Assessment Reports:{' '}
                          {assessorPlannerDataFromAssessment[0]?.multi_documents ? (
                            <button
                              className='btn btn-primary btn-sm '
                              type='button'
                              onClick={() =>
                                handleshowMultipleReport(
                                  assessorPlannerDataFromAssessment[0]?.multi_documents
                                )
                              }
                            >
                              Reports
                            </button>
                          ) : (
                            <div className='badge badge-light fw-bolder'>
                              <div className='badge badge-light-danger fw-bold'>Pending...</div>
                            </div>
                          )}
                        </h3>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='mb-5 col-lg-12'>
                        <h3>
                          Competence verification of Assessment Team for office assessment, if
                          applicable{' '}
                        </h3>
                        <table
                          className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                          id='kt_table_users'
                        >
                          <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                              <th className='w-10px pe-2 fs-7'>Assessor Name</th>
                              <th className='w-10px pe-2 fs-7'>Schemes</th>

                              <th className='w-10px pe-2 fs-7'>
                                Competence Verification By System{' '}
                              </th>
                              <th className='w-10px pe-2 fs-7'>Remarks</th>
                            </tr>
                          </thead>
                          <tbody className='text-gray-600 fw-semibold'>
                            {assessorPlannerDataFromAssessment?.map((item) => (
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <ul style={{listStyle: 'none'}}>
                                      {item?.assessor_name?.map((assessor) => (
                                        <li className='mb-1' key={assessor[0].assessor_id}>
                                          {assessor[0].first_name} {assessor[0].last_name}
                                          <i
                                            className='p-2 fs-5 fas fa-info-circle'
                                            onClick={() =>
                                              handleOpenInfoModel(assessor[0].assessor_id)
                                            }
                                          ></i>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </td>

                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      //   placeholder='Enter Remarks'
                                      name='uaf_requirement'
                                      value={item?.scheme_name}
                                      readOnly
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      value='Verified'
                                      name='uaf_requirement'
                                      readOnly
                                    />
                                  </div>
                                </td>
                                <td>
                                  {/* <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      value={oRRemarks[item.id] || ''}
                                      onChange={(e) => handleORRemarksChange(item.id, e.target.value)}
                                      name='uaf_requirement'
                                    />
                                  </div> */}

                                  <div className='text-gray-800 mb-1'>
                                    {officeReviewed?.length == 0 || officeReviewed?.length < 0 ? (
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        value={oRRemarks[item.id] || ''}
                                        onChange={(e) =>
                                          handleORRemarksChange(item.id, e.target.value)
                                        }
                                        name='uaf_requirement'
                                      />
                                    ) : (
                                      <>
                                        {officeReviewed?.map((reviewItem: any) => {
                                          if (reviewItem.review === item.id) {
                                            return (
                                              <textarea
                                                key={reviewItem.id}
                                                className='form-control form-control-solid'
                                                rows={5}
                                                value={reviewItem.remark || ''}
                                                onChange={(e) =>
                                                  handleOfficeReviewChange(
                                                    reviewItem.id,
                                                    e.target.value
                                                  )
                                                }
                                                name='uaf_requirement'
                                              />
                                            )
                                          }
                                        })}
                                      </>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='row'>
                      {/* <div className='mt-10'>
                        <div className='mb-5 col-lg-12'>
                          <h3>Tech Expert </h3>
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            placeholder=' '
                            value={officeTechExpert}
                            onChange={(e) => setOfficeTechExpert(e?.target?.value)}
                          />
                        </div>
                      </div> */}

                      <div className='row mt-10'>
                        <div className='mb-5 col-lg-12'>
                          <h3>Review of Office Assessment Report:</h3>
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                            id='kt_table_users'
                          >
                            <thead>
                              <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                <th style={{width: '50%'}} className='pe-2 fs-7'>
                                  Q
                                </th>
                                <th style={{width: '50%'}} className='pe-2 fs-7'>
                                  A
                                </th>
                              </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-semibold'>
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    A - Is the Assessment plan accepted by CAB prior to assessment?
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      onChange={(e) => setReviewReportA(e?.target?.value)}
                                      name='uaf_requirement'
                                      value={reviewReportA}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    B - An opening Meeting reference and the purpose of the
                                    assessment and accreditation available in the assessment report
                                    requirements are clearly defined and the assessment plan as well
                                    as the scope for the assessment are confirmed.
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      onChange={(e) => setReviewReportB(e?.target?.value)}
                                      name='uaf_requirement'
                                      value={reviewReportB}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    C - Verification of Professional Indemnity Insurance and
                                    reserves
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      onChange={(e) => setReviewReportC(e?.target?.value)}
                                      name='uaf_requirement'
                                      value={reviewReportC}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    D - Verification of Corrective Action implementation from
                                    previous assessment (nonconformities), if any.
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      onChange={(e) => setReviewReportD(e?.target?.value)}
                                      name='uaf_requirement'
                                      value={reviewReportD}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    E - Number of Active Client Files sampled:
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      onChange={(e) => setReviewReportE(e?.target?.value)}
                                      name='uaf_requirement'
                                      value={reviewReportE}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    F - Number of Withdrawn/Suspended Client Files sampled:
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      onChange={(e) => setReviewReportF(e?.target?.value)}
                                      name='uaf_requirement'
                                      value={reviewReportF}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    G - Number of Major/Minor Non-Conformities Issued, if any.
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      onChange={(e) => setReviewReportG(e?.target?.value)}
                                      name='uaf_requirement'
                                      value={reviewReportG}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    H - Is there sufficient evidence that the
                                    nonconformities/findings have been closed out? Latest Date of
                                    closure
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      onChange={(e) => setReviewReportH(e?.target?.value)}
                                      name='uaf_requirement'
                                      value={reviewReportH}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    I - Closing Meeting reference
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      onChange={(e) => setReviewReportI(e?.target?.value)}
                                      name='uaf_requirement'
                                      value={reviewReportI}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    J - Does the Assessment report contain comments on the
                                    competence as determined through conformity and are they
                                    adequate to support the conclusions arising from the assessment?
                                    (Were the human resources competency verified and adequate to
                                    support the applied scopes?)
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      onChange={(e) => setReviewReportJ(e?.target?.value)}
                                      name='uaf_requirement'
                                      value={reviewReportJ}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    K - Does the Assessment report contain scope/ criteria assessed
                                    and clear recommendations w.r.to assessment scope/ criteria?
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {/* <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      name='uaf_requirement'
                                      value={
                                        assessorPlannerDataFromAssessment
                                          ? assessorPlannerDataFromAssessment
                                              .map(
                                                (item, index) =>
                                                  `Recommendation ${index + 1}:\n` +
                                                  `  Scheme: ${item?.scheme_name}\n` +
                                                  `    Scopes/Technical Areas:\n${item?.cluster_values
                                                    ?.map((value) => `     - ${value?.name}`)
                                                    .join('\n')}\n\n`
                                              )
                                              .join('\n')
                                          : '' // Set a default value when data is not available
                                      }
                                      style={{whiteSpace: 'pre-wrap', fontWeight: 'bold'}}
                                      readOnly // Ensure the textarea is read-only
                                    /> */}
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      onChange={(e) => setReviewReportK(e?.target?.value)}
                                      name='uaf_requirement'
                                      value={reviewReportK}
                                    />
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    L - Has the lead assessor made recommendations/decisions based
                                    on objective evidence?
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      onChange={(e) => setReviewReportL(e?.target?.value)}
                                      name='uaf_requirement'
                                      value={reviewReportL}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mb-5 d-flex justify-content-between'>
                  <div>
                    {/* <button className='btn btn-primary' onClick={() => setTabValue("2")}>
                      <i className='fa fa-angle-double-left' aria-hidden='true' /> Back
                    </button> */}
                  </div>
                  <div>
                    <button type='submit' className='btn btn-primary'>
                      save <i className='fa fa-angle-double-right' aria-hidden='true' />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel value='4'>
            <form onSubmit={handleWASubmit}>
              <div className='py-lg-10 px-lg-10'>
                <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                  <div className='card-body p-9'>
                    {/* <h3 className='mb-10'>Witness Assessment Details</h3> */}
                    <div className='row'>
                      <div className='col-md-3 mb-5'>
                        <h3>Assessment Type</h3>
                        <Select
                          options={witnessNumber?.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                          onChange={(
                            selectedOption: SingleValue<{label: string; value: string}>
                          ) => {
                            getAssessorDataByAssessment('Witness Assessment', selectedOption?.value)
                            setAssessmentWitnessNumber(selectedOption?.value)
                          }}
                        />
                      </div>
                      <div className='mb-5 col-lg-12'>
                        <h3>Witness Assessment Report date, if applicable </h3>

                        <table
                          className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                          id='kt_table_users'
                        >
                          <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                              <th className='w-10px pe-2 fs-7'>Date(s)</th>
                              <th className='w-10px pe-2 fs-7'>Schemes</th>
                              <th className='w-10px pe-2 fs-7'>Assessment Number</th>
                              <th className='w-10px pe-2 fs-7'>Assessment Duration</th>
                              <th className='w-10px pe-2 fs-7'>Assessor Name</th>
                              <th className='w-10px pe-2 fs-7'>WRR</th>
                            </tr>
                          </thead>

                          <tbody className='text-gray-600 fw-semibold'>
                            {assessorPlannerDataFromAssessment?.map((item) => (
                              <tr>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item && item.update_at ? formatDate(item.update_at) : '--'}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>{item?.scheme_name}</div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.assessment_number}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.assessment_duration}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-gray-800 mb-1'>
                                    {item?.assessor_name?.map((assessor, index) => (
                                      <span key={assessor[0].id}>
                                        {assessor[0].first_name} {assessor[0].last_name}
                                        {index < item.assessor_name.length - 1 ? ', ' : ''}
                                      </span>
                                    ))}
                                  </div>
                                </td>
                                <td>
                                  {item?.multi_docs ? (
                                    <button
                                      className='btn btn-primary btn-sm '
                                      type='button'
                                      onClick={() => handleshowMultipleWRReport(item?.multi_docs)}
                                    >
                                      Reports
                                    </button>
                                  ) : (
                                    <div className='badge badge-light fw-bolder'>
                                      <div className='badge badge-light-danger fw-bold'>
                                        Pending...
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='row mt-10'>
                      <div className='mb-5 col-lg-12'>
                        <h3>
                          Competence verification of Assessment Team for witness assessment, if
                          applicable{' '}
                        </h3>
                        <table
                          className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                          id='kt_table_users'
                        >
                          <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                              <th className='w-10px pe-2 fs-7'>Assessor Name / Info</th>
                              <th className='w-10px pe-2 fs-7'>Schemes</th>
                              {/* <th className='w-5px pe-2 fs-7 text-center'>Assessor Info</th> */}
                              <th className='w-10px pe-2 fs-7'>
                                Competence Verification By System{' '}
                              </th>
                              <th className='w-10px pe-2 fs-7'>Remarks</th>
                            </tr>
                          </thead>
                          {witnessReviewData?.length == 0 || witnessReviewData?.length < 0 ? (
                            <tbody className='text-gray-600 fw-semibold'>
                              {assessorPlannerDataFromAssessment?.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <ul style={{listStyle: 'none'}}>
                                        {item?.assessor_name?.map((assessor) => (
                                          <li className='mb-1' key={assessor[0].assessor_id}>
                                            {assessor[0].first_name} {assessor[0].last_name}
                                            <i
                                              className='p-2 fs-5 fas fa-info-circle'
                                              onClick={() =>
                                                handleOpenInfoModel(assessor[0].assessor_id)
                                              }
                                            ></i>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </td>

                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        //   placeholder='Enter Remarks'
                                        name='uaf_requirement'
                                        value={item?.scheme_name}
                                        readOnly
                                      />
                                    </div>
                                  </td>

                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        value='Verified'
                                        name='uaf_requirement'
                                        readOnly
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    {/* <div className="text-gray-800 mb-1">
                                    {!witnessReviewId ? (

                                      <textarea
                                        className="form-control form-control-solid"
                                        rows={5}
                                        value={wRRemarks[item.id] || ''}
                                        onChange={(e) => handleWRRemarksChange(item.id, e.target.value)}
                                        name="uaf_requirement"
                                      />


                                    ) : (
                                      <>
                                        {witnessReviewed?.map((reviewItem: any) => {
                                          if (reviewItem.review === item.id) {
                                            return (
                                              <textarea
                                                key={reviewItem.id}
                                                className="form-control form-control-solid"
                                                rows={5}
                                                value={reviewItem.remark || ''}
                                                onChange={(e) => handleWitnessReviewChange(reviewItem.id, e.target.value)}
                                                name="uaf_requirement-1"
                                              />
                                            );
                                          }
                                          return null;
                                        })}
                                      </>

                                    )}
                                  </div> */}
                                    <div className='text-gray-800 mb-1'>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        onChange={(e) => {
                                          const newValue = e.target.value
                                          const newData = [...assessorPlannerDataFromAssessment]
                                          newData[index] = {...item, witnessReviewRemarks: newValue}
                                          setAssessorPlannerDataFromAssessment(newData)
                                        }}
                                        name='witness_review_remarks'
                                        value={item?.witnessReviewRemarks || ''}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tbody className='text-gray-600 fw-semibold'>
                              {witnessReviewData?.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <ul style={{listStyle: 'none'}}>
                                        {item?.assessor_data[0].assessor_data?.map((assessor) => (
                                          <li className='mb-1' key={assessor.assessor_id}>
                                            {assessor.first_name} {assessor.last_name}
                                            <i
                                              className='p-2 fs-5 fas fa-info-circle'
                                              onClick={() =>
                                                handleOpenInfoModel(assessor.assessor_id)
                                              }
                                            ></i>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </td>

                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        //   placeholder='Enter Remarks'
                                        name='uaf_requirement'
                                        value={item.assessor_data[0]?.scheme_name}
                                        readOnly
                                      />
                                    </div>
                                  </td>

                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        value='Verified'
                                        name='uaf_requirement'
                                        readOnly
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        onChange={(e) => {
                                          const newValue = e.target.value
                                          const newData = [...witnessReviewData]
                                          newData[index] = {...item, remark: newValue}
                                          setWitnessReviewData(newData)
                                        }}
                                        name='witness_review_remarks'
                                        value={item?.remark || ''}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    {/* <div className="row mt-10">
                      <div className=''>
                        <div className='mb-5 col-lg-12'>
                          <h3>Tech Expert </h3>
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            placeholder=' '
                            value={witnessTechExpert}
                            onChange={(e) => setWitnessTechExpert(e?.target?.value)}
                          // onChange={(e) => setValue({...value, ncr_number: e.target.value})}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className='row mt-10'>
                      <div className='mb-5 col-lg-12'>
                        <h3>Review of Witness Assessment Reports: </h3>
                        <table
                          className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                          id='kt_table_users'
                        >
                          <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                              <th className='w-10px pe-2 fs-7'>Assessment Number</th>
                              <th className='w-100px pe-2 fs-7'>Schemes</th>
                              <th className='w-250px pe-2 fs-7'>Scopes / Technical Areas </th>
                              <th className='w-150px pe-2 fs-7'>
                                sufficient evidence that the issued NCs, if any, have been closed
                                out? Latest Date of closure{' '}
                              </th>
                              <th className='w-150px pe-2 fs-7'>
                                Does the Witness Assessment report contain clear recommendations?
                              </th>
                            </tr>
                          </thead>
                          {witnessReviewData?.length == 0 || witnessReviewData?.length < 0 ? (
                            <tbody className='text-gray-600 fw-semibold'>
                              {assessorPlannerDataFromAssessment?.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <span>{item?.assessment_number}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        name='scheme_name'
                                        value={item?.scheme_name}
                                        readOnly
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      {item?.cluster_values?.length > 0 ? (
                                        item?.cluster_values[0]?.name === 'Other' ? (
                                          <textarea
                                            className='form-control form-control-solid'
                                            rows={5}
                                            value='N/A'
                                            readOnly
                                          />
                                        ) : (
                                          <textarea
                                            className='form-control form-control-solid'
                                            rows={5}
                                            value={item?.cluster_values
                                              ?.map((value) => `- ${value?.name}`)
                                              .join('\n')}
                                            readOnly
                                          />
                                        )
                                      ) : (
                                        <textarea
                                          className='form-control form-control-solid'
                                          rows={5}
                                          value='N/A'
                                          readOnly
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        onChange={(e) => {
                                          const newValue = e.target.value
                                          const newData = [...assessorPlannerDataFromAssessment]
                                          newData[index] = {...item, witnessReviewReportA: newValue}
                                          setAssessorPlannerDataFromAssessment(newData)
                                        }}
                                        name='witness_review_report_a'
                                        value={item?.witnessReviewReportA || ''}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        onChange={(e) => {
                                          const newValue = e.target.value
                                          const newData = [...assessorPlannerDataFromAssessment]
                                          newData[index] = {...item, witnessReviewReportB: newValue}
                                          setAssessorPlannerDataFromAssessment(newData)
                                        }}
                                        name='witness_review_report_b'
                                        value={item?.witnessReviewReportB || ''}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tbody className='text-gray-600 fw-semibold'>
                              {witnessReviewData?.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <span>{item.assessor_data[0]?.assessment_number}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        name='scheme_name'
                                        value={item.assessor_data[0]?.scheme_name}
                                        readOnly
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <div className='text-gray-800 mb-1'>
                                        {item?.assessor_data[0]?.cluster_data?.length > 0 ? (
                                          item?.assessor_data[0]?.cluster_data[0]?.cluster_name ===
                                          'Other' ? (
                                            <textarea
                                              className='form-control form-control-solid'
                                              rows={5}
                                              value='N/A'
                                              readOnly
                                            />
                                          ) : (
                                            <textarea
                                              className='form-control form-control-solid'
                                              rows={5}
                                              value={item?.assessor_data[0]?.cluster_data
                                                ?.map((value) => `- ${value?.cluster_name}`)
                                                .join('\n')}
                                              readOnly
                                            />
                                          )
                                        ) : (
                                          <textarea
                                            className='form-control form-control-solid'
                                            rows={5}
                                            value='N/A'
                                            readOnly
                                          />
                                        )}
                                      </div>
                                      {/* <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        value={item?.assessor_data[0]?.cluster_data
                                          ?.map((value) => `- ${value?.cluster_name}`)
                                          .join('\n')}
                                        readOnly
                                      /> */}
                                    </div>
                                  </td>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        onChange={(e) => {
                                          const newValue = e?.target?.value
                                          const newData = [...witnessReviewData]
                                          newData[index] = {...item, review_report_qa: newValue}
                                          setWitnessReviewData(newData)
                                        }}
                                        name='witness_review_report_a'
                                        value={item?.review_report_qa || ''}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className='text-gray-800 mb-1'>
                                      <textarea
                                        className='form-control form-control-solid'
                                        rows={5}
                                        onChange={(e) => {
                                          const newValue = e?.target?.value
                                          //console.log('New Value:', newValue);
                                          const newData = [...witnessReviewData]
                                          newData[index] = {...item, review_report_qb: newValue}
                                          setWitnessReviewData(newData)
                                          //console.log('Updated Data:', newData);
                                        }}
                                        name='witness_review_report_b'
                                        value={item?.review_report_qb || ''}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mb-5 d-flex justify-content-between'>
                  <div>
                    {/* <button className='btn btn-primary' onClick={() => setTabValue("3")}>
                      <i className='fa fa-angle-double-left' aria-hidden='true' /> Back
                    </button> */}
                  </div>
                  <div>
                    <button type='submit' className='btn btn-primary'>
                      save <i className='fa fa-angle-double-right' aria-hidden='true' />
                    </button>
                    {/* <button className='btn btn-primary' onClick={() => setTabValue("5")}>
                      Next <i className='fa fa-angle-double-right' aria-hidden='true' />
                    </button> */}
                  </div>
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel value='5'>
            <CreateAnnexureReport
              allAssessorPlannerData={allAssessorPlannerData}
              singleCab={singleCab}
            />
          </TabPanel>
          <TabPanel value='6'>
            {/* <form onSubmit={handleArcSubmit}> */}
            <div className='py-lg-10 px-lg-10'>
              <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-body p-9'>
                  <h3 className='mb-10'>ARC Member Recommendation</h3>
                  <div className='mb-5 col-lg-12'>
                    {/* <label className='required form-label'>Date/s of Witness assessment/s </label> */}
                    <table
                      className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                      id='kt_table_users'
                    >
                      <thead>
                        <tr className='text-start  fw-bold  text-uppercase gs-0'>
                          <th className=' pe-2 fs-7'>Schemes(s)</th>
                          <th className=' pe-2 fs-7'>
                            Selected Codes are Recommended by Assessors
                          </th>
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-semibold'>
                        {aRCclusters?.map((item) => (
                          <tr key={item?.scheme_name}>
                            <td>
                              <div className='text-gray-800 mb-1'>{item?.scheme_name}</div>
                            </td>
                            <td>
                              <div className='text-gray-800 mb-1'>
                                {item?.clusters?.map((cluster) => (
                                  <div key={cluster?.id}>
                                    <input
                                      type='checkbox'
                                      value={cluster?.id}
                                      onChange={() => handleCheckboxChange(cluster?.id)}
                                      checked={selectedClusters.includes(cluster?.id)} // Default checked based on the `selectedClusters` array
                                    />
                                    <label style={{marginLeft: '5px'}}>{cluster?.name}</label>
                                  </div>
                                ))}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='row mt-10'>
                    <div className=''>
                      <div className='mb-5 col-lg-12'>
                        <h5>Countries where UAF Accreditation Certificates can be issued </h5>
                        <Select
                          isMulti
                          className='react-select-styled react-select-solid '
                          classNamePrefix='react-select'
                          options={countryList?.map((item) => ({
                            value: item?.id,
                            label: item?.name,
                          }))}
                          placeholder='Select Country'
                          onChange={handleCountryChange}
                          value={countryList
                            ?.filter((item) => selectedCountryIds?.includes(item.id))
                            ?.map((item) => ({label: item?.name, value: item?.id}))}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mt-10'>
                    <div className='mb-5 col-lg-12'>
                      <h5>Other Key Locations Recommended </h5>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder=' '
                        value={recommendedLoaction}
                        onChange={(e) => setRecommendedLoaction(e?.target?.value)}
                      />
                    </div>
                  </div>
                  <div className='mt-10'>
                    <div className='mb-5 col-lg-12'>
                      <h5>Other Locations Recommended (marketing etc Non-Key) </h5>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder=' '
                        value={otherLoaction}
                        onChange={(e) => setOtherLoaction(e?.target?.value)}
                      />
                    </div>
                  </div>
                  <div className='row mt-10'>
                    <div className='mb-5 col-lg-12'>
                      <h3>ARC Member Reports:</h3>
                      <table
                        className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                        id='kt_table_users'
                      >
                        <thead>
                          <tr className='text-start  fw-bold  text-uppercase gs-0'>
                            <th style={{width: '50%'}} className='pe-2 fs-7'>
                              <span style={{display: 'none'}}> Q</span>
                            </th>
                            <th style={{width: '50%'}} className='pe-2 fs-7'>
                              <span style={{display: 'none'}}>A</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-semibold'>
                          <tr>
                            <td>
                              <div className='text-gray-800 mb-1'>
                                <h5>Accreditation is to be:</h5>
                                <div className='text-gray-800 mb-1'>
                                  <div className=''>
                                    <input
                                      type='radio'
                                      id='granted'
                                      name='options'
                                      value='Granted'
                                      checked={permissionCheck === 'Granted'}
                                      onChange={handlePermissionChange}
                                    />
                                    <label style={{marginLeft: '5px'}}>Granted</label>
                                  </div>
                                  <div className=''>
                                    <input
                                      type='radio'
                                      id='maintained'
                                      name='options'
                                      value='Maintained'
                                      checked={permissionCheck === 'Maintained'}
                                      onChange={handlePermissionChange}
                                    />
                                    <label style={{marginLeft: '5px'}}>Maintained</label>
                                  </div>
                                  <div className=''>
                                    <input
                                      type='radio'
                                      id='reinstated'
                                      name='options'
                                      value='Reinstated'
                                      checked={permissionCheck === 'Reinstated'}
                                      onChange={handlePermissionChange}
                                    />
                                    <label style={{marginLeft: '5px'}}>Reinstated</label>
                                  </div>
                                  <div className=''>
                                    <input
                                      type='radio'
                                      id='expanded'
                                      name='options'
                                      value='Expanded'
                                      checked={permissionCheck === 'Expanded'}
                                      onChange={handlePermissionChange}
                                    />
                                    <label style={{marginLeft: '5px'}}>Expanded</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='text-gray-800 mb-1'>
                                <textarea
                                  className='form-control form-control-solid'
                                  rows={5}
                                  value={permissionText}
                                  name='uaf_requirement'
                                  onChange={(e) => setPermissionText(e?.target?.value)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='text-gray-800 mb-1'>
                                <h5> Subject to the conditions, if any:</h5>
                              </div>
                            </td>
                            <td>
                              <div className='text-gray-800 mb-1'>
                                <textarea
                                  className='form-control form-control-solid'
                                  rows={5}
                                  value={anyCondition}
                                  name='uaf_requirement'
                                  onChange={(e) => setAnyCondition(e?.target?.value)}
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='text-gray-800 mb-1'>
                                <h5>In case the Accreditation is not granted, the Reasons for:</h5>

                                <div className='text-gray-800 mb-1'>
                                  <div className=''>
                                    <input
                                      type='radio'
                                      id='not_granted'
                                      name='reportOptions'
                                      value='Not Granted'
                                      checked={selectedReportCheck === 'Not Granted'}
                                      onChange={handleReportChange}
                                    />
                                    <label style={{marginLeft: '5px'}}>Not Granted</label>
                                  </div>
                                  <div className=''>
                                    <input
                                      type='radio'
                                      id='withdrawn'
                                      name='reportOptions'
                                      value='Withdrawn'
                                      checked={selectedReportCheck === 'Withdrawn'}
                                      onChange={handleReportChange}
                                    />
                                    <label style={{marginLeft: '5px'}}>Withdrawn</label>
                                  </div>
                                  <div className=''>
                                    <input
                                      type='radio'
                                      id='suspended'
                                      name='reportOptions'
                                      value='Suspended'
                                      checked={selectedReportCheck === 'Suspended'}
                                      onChange={handleReportChange}
                                    />
                                    <label style={{marginLeft: '5px'}}>Suspended</label>
                                  </div>
                                  <div className=''>
                                    <input
                                      type='radio'
                                      id='reduced'
                                      name='reportOptions'
                                      value='Reduced'
                                      checked={selectedReportCheck === 'Reduced'}
                                      onChange={handleReportChange}
                                    />
                                    <label style={{marginLeft: '5px'}}>Reduced</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='text-gray-800 mb-1'>
                                <textarea
                                  className='form-control form-control-solid'
                                  rows={5}
                                  name='uaf_requirement'
                                  onChange={handleReportTextChange}
                                  value={reportTextValue}
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className='row mt-10'>
                    <div className='mb-5 col-lg-12'>
                      <h3>Details of ARC Member :</h3>
                      <table
                        className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                        id='kt_table_users'
                      >
                        <thead>
                          <tr className='text-start  fw-bold  text-uppercase gs-0'>
                            <th className='pe-2 fs-7'>Name</th>
                            <th className='pe-2 fs-7'>Date of Review</th>
                            <th className='pe-2 fs-7'>Signature</th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-semibold'>
                          <tr>
                            <td>
                              <div className='text-gray-800 mb-1'>
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  placeholder=' Enter ARC member name '
                                  value={`${arcMemberDetails?.first_name} ${arcMemberDetails?.last_name}`}
                                  onChange={(e) => setARCMemberName(e?.target?.value)}
                                  disabled
                                />
                              </div>
                            </td>
                            <td>
                              <div className='text-gray-800 mb-1'>
                                <ReactDatePicker
                                  className='react_date_picker form-control form-control-solid '
                                  selected={arcReportDate}
                                  placeholder='Enter Review Date '
                                  // selected={assessmentPlannerDetails?.major_nc_cab_start_date}
                                  onChange={(date) => setArcReportDate(date)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode='select'
                                />
                              </div>
                            </td>
                            <td>
                              <div className='text-gray-800 mb-1'>
                                <SignaturePadComponent
                                  setSignatureImageData={setSignatureImageData}
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-5 d-flex justify-content-between'>
                <div>
                  {/* <button className='btn btn-primary' onClick={() => setTabValue("4")}>
                    <i className='fa fa-angle-double-left' aria-hidden='true' /> Back
                  </button> */}
                </div>
                <div>
                  <button onClick={handleArcSubmit} className='btn btn-primary'>
                    Create Report
                    <i className='fa fa-angle-double-right' aria-hidden='true' />
                  </button>
                </div>
              </div>
            </div>
            {/* </form> */}
          </TabPanel>

          {/* <TabPanel value="3">Item Three</TabPanel> */}
        </TabContext>
      </Box>

      <Modal show={showInfoModal} onHide={handleCloseInfoModel} centered size='xl'>
        <div className='d-block text-center'>
          <div className='card-custom-header '>
            <div className='d-flex flex-column justify-content-center align-items-center py-5'>
              <div className=''>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/uaf_logo.png')}
                  className='h-60px h-lg-60px app-sidebar-logo-default theme-light-show'
                />
              </div>
              <div className='mt-5'>
                <h2 style={{fontSize: '18px'}}>UNITED ACCREDITATION FOUNDATION</h2>
              </div>
            </div>
          </div>
          <div className='px-20 py-3 text-center cf-border-bottom'>
            <h2 className='cf-text-gray' style={{fontSize: '14px'}}>
              Initial Competence Evaluation
            </h2>
          </div>
          <div className='px-20 py-3 cf-border-bottom'>
            <div className='row '>
              <div className='col-lg-6 col-xl-6 mt-1'>
                <h2 className='px-5' style={{fontSize: '16px'}}>
                  Assessor Name / Lead Assessor
                </h2>
              </div>
              <div className='col-lg-6 col-xl-6 text-center mt-1'>
                <h2 className='' style={{fontSize: '16px'}}>
                  {assessorDetails?.first_name} {assessorDetails?.last_name}
                  {/* {userData?.first_name} {userData?.last_name} */}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <Modal.Body>
          <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
              <tr
                style={{background: '#f7f7f7'}}
                className='text-start  fw-bold  text-uppercase gs-0'
              >
                <th className='px-5 min-w-100px'>Competence Parameters Requirement</th>
                <th className='px-5 min-w-125px'>Actual</th>
                <th className='px-5 min-w-150px'>Status</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              <tr>
                <td className='px-5'>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    Under Graduate (Bachelor)*
                  </div>
                </td>
                <td>
                  {assessorDocuments?.graduation_certification ? (
                    <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                  ) : (
                    <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                  )}
                </td>
                <td>
                  {assessorDocuments?.graduation_certification_approved ? (
                    <div className='badge badge-light-success fw-bold'>Approved</div>
                  ) : (
                    <div className='badge badge-light-danger fw-bold'>Pending...</div>
                  )}
                </td>
              </tr>
              {assessorDocuments?.work_experience === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Do you have work experience in accreditaion/certification process (Minimum 5
                      years)
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.work_experience_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.work_experience_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.internal_training === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Have you done UAF internal training for accreditation process? If yes, then
                      upload the relevant docs.
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.internal_training_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.internal_training_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_17011 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO/IEC 17011
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_17011_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_17011_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_17021_1 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO/IEC 17021-1
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_17021_1_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_17021_1_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_17021_2 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO/IEC 17021-2
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_17021_2_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_17021_2_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_17021_3 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO/IEC 17021-3
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_17021_3_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_17021_3_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_17021_10 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO/IEC 17021-10
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_17021_10_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_17021_10_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_17024 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO/IEC 17024
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_17024_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_17024_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_27000 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO/IEC 27000
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_27000_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_27000_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_27005 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO/IEC 27005
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_27005_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_27005_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_27006 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO/IEC 27006
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_27006_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_27006_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_27007 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO/IEC 27007
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_27007_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_27007_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_22000 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO 22000
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_22000_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_22000_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_22003 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO 22003
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_22003_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_22003_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_22003_1 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO/IEC 22003-1
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_22003_1_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_22003_1_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_50001 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO 50001
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_50001_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_50001_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_50003 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO 50003
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_50003_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_50003_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_45001 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO 45001
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_45001_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_45001_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_13485 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO 13485
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_13485_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_13485_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_9001 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO 9001
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_9001_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_9001_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
              {assessorDocuments?.training_14001 === true ? (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      Training on ISO 14001
                    </div>
                  </td>
                  <td>
                    {assessorDocuments?.training_14001_approved ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    ) : (
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    )}
                  </td>
                  <td>
                    {assessorDocuments?.training_14001_approved ? (
                      <div className='badge badge-light-success fw-bold'>Approved</div>
                    ) : (
                      <div className='badge badge-light-danger fw-bold'>Pending...</div>
                    )}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>

          <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
              <tr
                style={{background: '#f7f7f7'}}
                className='text-start  fw-bold  text-uppercase gs-0'
              >
                <th className='px-5 min-w-100px'>Competence Scheme</th>
                <th className='px-5 min-w-125px'>Cluster</th>
                <th className='px-5 min-w-150px'>Status</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {assessorDetails?.scheme_with_cluster?.map((item) => (
                <tr>
                  <td className='px-5'>
                    <div className='text-gray-800 text-hover-primary mb-1'>{item?.scheme_name}</div>
                  </td>
                  <td>
                    <ul>
                      {item?.clusters?.map((clusterItem) => (
                        <li key={clusterItem?.id} className='text-gray-800'>
                          {clusterItem.name}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td>
                    <div className='badge badge-light-success fw-bold'>Approved</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      <Modal show={showPdfModal} onHide={handleClosePdfModal} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
            title='DOCX Viewer'
            width='100%'
            height='500px'
          ></iframe>
          {/* Display the PDF in the modal */}
          {/* <iframe src={pdfUrl} title='PDF Viewer' width='100%' height='500px'></iframe> */}
          {/* <iframe src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`} title='DOCX Viewer' width='100%' height='500px'></iframe> */}
        </Modal.Body>
      </Modal>

      <Modal show={showmulti} onHide={handleClosemulti} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table
            style={{margin: '0 0 0 25px'}}
            className='table align-middle table-row-dashed fs-6 gy-5'
            id='kt_table_users'
          >
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th>Sr No</th>
                <th>Name</th>
                <th>Report</th>
                <th className='min-w-125px'>Remarks</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {multipleDocuments?.map((item, index) =>
                item?.report ? (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.report_name}</td>
                    <td>
                      <a target='_blank' href={item?.report}>
                        <img src='/media/doc.png' alt='documents' style={{cursor: 'pointer'}} />
                      </a>
                    </td>
                    <td>{item?.remark}</td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      <Modal show={showWRmulti} onHide={handleWRClosemulti} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table
            style={{margin: '0 0 0 25px'}}
            className='table align-middle table-row-dashed fs-6 gy-5'
            id='kt_table_users'
          >
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th>Sr No</th>
                <th>Name</th>
                <th>Report</th>
                <th className='min-w-125px'>Remarks</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {multipleWRDocuments?.map((item, index) =>
                item?.report ? (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.report_name}</td>
                    <td>
                      <a target='_blank' href={item?.report}>
                        <img src='/media/doc.png' alt='documents' style={{cursor: 'pointer'}} />
                      </a>
                    </td>
                    <td>{item?.remark}</td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CreateARCReport
