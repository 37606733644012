import React, { createContext, useState, useEffect, useContext, ReactNode } from "react";

// Define the context shape
interface TimezoneContextType {
  timezone: string;
  setTimezone: (timezone: string) => void;
}

// Create the context
const TimezoneContext = createContext<TimezoneContextType | undefined>(undefined);

// Define provider props
interface TimezoneProviderProps {
  children: ReactNode;
}

// TimezoneProvider component
export const TimezoneProvider: React.FC<TimezoneProviderProps> = ({ children }) => {
  // Initialize timezone from localStorage or default to "UTC"
  const [timezone, setTimezone] = useState<string>(() => {
    return localStorage.getItem("selectedTimezone") || "UTC";
  });

  // Save the timezone to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("selectedTimezone", timezone);
  }, [timezone]);

  return (
    <TimezoneContext.Provider value={{ timezone, setTimezone }}>
      {children}
    </TimezoneContext.Provider>
  );
};

// Custom hook to use the TimezoneContext
export const useTimezone = (): TimezoneContextType => {
  const context = useContext(TimezoneContext);
  if (!context) {
    throw new Error("useTimezone must be used within a TimezoneProvider");
  }
  return context;
};
