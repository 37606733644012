import axios from 'axios'
import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {toast} from 'react-toastify'
import moment from 'moment'
import {Modal, Button} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'

type TableRow = {
  [key: string]: string
}

const OtherInvoice = () => {
  const BASE_URL = process.env.REACT_APP_API_URL

  const [invoiceSubject, setInvoiceSubject] = useState<string>('')
  const [invoiceTo, setInvoiceTo] = useState<string>('')
  const [invoiceLocation, setInvoiceLocation] = useState<string>('')
  const [invoiceEmail, setInvoiceEmail] = useState<string>('')
  const [invoiceNumber, setInvoiceNumber] = useState<string>('')
  const [issuesBy, setIssuesBy] = useState<string>('')
  const [tableRows, setTableRows] = useState<TableRow[]>([])
  console.log(tableRows, 'tableRows')

  const [columns, setColumns] = useState<any[]>([])
  const [showRemarks, setShowRemarks] = useState(false)
  const [remarks, setRemarks] = useState('')
  const [showPreview, setShowPreview] = useState(false)
  const [editingColumn, setEditingColumn] = useState<number | null>(null)

  const addRow = () => {
    const newRow: TableRow = {}
    columns.forEach((column) => {
      newRow[column] = ''
    })
    setTableRows([...tableRows, newRow])
  }

  const addColumn = () => {
    const newColumn = prompt('Enter column name') || `column${columns.length + 1}`
    if (newColumn && !columns.includes(newColumn)) {
      setColumns([...columns, newColumn])
      setTableRows(tableRows.map((row) => ({...row, [newColumn]: ''})))
    }
  }

  const deleteRow = (rowIndex: number) => {
    const updatedRows = tableRows.filter((_, index) => index !== rowIndex)
    setTableRows(updatedRows)
  }

  const deleteColumn = (columnName: string) => {
    setColumns(columns.filter((column) => column !== columnName))
    setTableRows(
      tableRows.map((row) => {
        const newRow = {...row}
        delete newRow[columnName]
        return newRow
      })
    )
  }

  const handleInputChange = (rowIndex: number, column: string, value: string) => {
    const updatedRows = [...tableRows]
    updatedRows[rowIndex][column] = value
    setTableRows(updatedRows)
  }

  const handleColumnNameChange = (index: number, newName: string) => {
    const updatedColumns = [...columns]
    updatedColumns[index] = newName
    setColumns(updatedColumns)
  }

  const addRemarks = () => setShowRemarks(true)

  const handleSubmit = async () => {
    if (!invoiceSubject || !invoiceNumber || !issuesBy || !invoiceEmail || !invoiceTo) {
      toast.error('Please fill all required fields.')
      return
    }

    const payload = {
      invoice_email: invoiceEmail,
      invoice_location: invoiceLocation,
      invoice_to: invoiceTo,
      invoice_number: invoiceNumber,
      issued_date: moment().format('YYYY-MM-DD'),
      issues_by: issuesBy,
      invoice_subject: invoiceSubject,
      remarks: remarks,
      table_data: JSON.stringify(tableRows),
    }

    try {
      await axios.post(`${BASE_URL}/payment/other/anonymous/invoice/`, payload)
      toast.success('Invoice sent successfully!')
      setShowPreview(false)
    } catch (error) {
      console.error('Submission error:', error)
      toast.error('Failed to submit data.')
    }
  }

  const generatePDF = () => {
    const input = document.getElementById('pdfContent')
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'mm', 'a4')
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()

        const imgProps = pdf.getImageProperties(imgData)
        const imgWidth = pdfWidth - 20
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width

        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight)
        pdf.save(`${invoiceSubject}`)
      })
    } else {
      console.error("Element with id 'pdfContent' not found.")
    }
  }

  return (
    <div className='row'>
      <div>
        <div className='card mb-5 mb-xl-10 mt-10'>
          <div className='card-header cursor-pointer app_fee_card' style={{background: '#f2f2f2'}}>
            <div className='d-flex flex-column justify-content-center align-items-center w-100'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/uaf_logo.png')}
                className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
              />
              <span className='w-50 d-flex required justify-content-end'></span>
              <input
                type='text'
                className='form-control  form-control-solid w-50'
                placeholder='Enter Subject'
                onChange={(e) => setInvoiceSubject(e?.target?.value)}
                value={invoiceSubject}
              />
            </div>
          </div>
          <div
            className='card-body px-9 py-5 align-items-center'
            style={{paddingLeft: '100px', paddingRight: '100px'}}
          >
            <div className='mt-2 d-flex justify-content-between align-items-center w-100'>
              <div className='p-2'>
                <div className='p-4'>
                  <p className='m-0 d-flex mb-1'>
                    <strong
                      className='mt-3 required d-flex required justify-content-end'
                      style={{width: '180px'}}
                    >
                      Invoice To:
                    </strong>

                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Enter Name'
                      onChange={(e) => setInvoiceTo(e?.target?.value)}
                      value={invoiceTo}
                    />
                  </p>
                  <p className='m-0 d-flex mb-1'>
                    <strong
                      className='mt-3 d-flex  required justify-content-end'
                      style={{width: '180px'}}
                    >
                      Email:
                    </strong>
                    <input
                      type='email'
                      className='form-control form-control-solid'
                      placeholder='Enter Email'
                      onChange={(e) => setInvoiceEmail(e?.target?.value)}
                      value={invoiceEmail}
                    />
                  </p>
                  <p className='m-0 d-flex'>
                    <strong className='mt-3  d-flex  justify-content-end' style={{width: '180px'}}>
                      Location:&nbsp;&nbsp;&nbsp;
                    </strong>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Enter Location'
                      onChange={(e) => setInvoiceLocation(e?.target?.value)}
                      value={invoiceLocation}
                    />
                  </p>
                </div>
              </div>
              <div className='p-4'>
                <p className='m-0 d-flex mb-1'>
                  <strong
                    className='mt-3 required d-flex required justify-content-end'
                    style={{width: '180px'}}
                  >
                    Invoice Number:
                  </strong>

                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Enter Invoice Number'
                    onChange={(e) => setInvoiceNumber(e?.target?.value)}
                    value={invoiceNumber}
                  />
                </p>
                <p className='m-0 d-flex mb-1'>
                  <strong className='mt-3 d-flex justify-content-end' style={{width: '180px'}}>
                    Issue Date:&nbsp;&nbsp;&nbsp;
                  </strong>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    disabled
                    value={moment().format('DD/MMM/YYYY')}
                  />
                </p>
                <p className='m-0 d-flex'>
                  <strong
                    className='mt-3 required d-flex required justify-content-end'
                    style={{width: '180px'}}
                  >
                    Issued By:
                  </strong>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Enter Issued By'
                    onChange={(e) => setIssuesBy(e?.target?.value)}
                    value={issuesBy}
                  />
                </p>
              </div>
            </div>
            <div className='mt-5 d-flex justify-content-center'>
              <table className='table align-middle table-row-dashed fs-6 gy-5 app_fee_form'>
                <thead>
                  <tr className='text-start fw-bold text-uppercase gs-0'>
                    <th className='w-10px' style={{padding: '0 0 24px 0'}}>
                      S.No
                    </th>
                    {columns.map((column, index) => (
                      <th className='min-w-50px' key={index}>
                        {editingColumn === index ? (
                          <input
                            className='form-control form-control-solid'
                            type='text'
                            value={column}
                            onChange={(e) => handleColumnNameChange(index, e.target.value)}
                            onBlur={() => setEditingColumn(null)}
                            autoFocus
                          />
                        ) : (
                          <>
                            <div className='d-flex justify-content-start flex-shrink-0'>
                              <div className='me-5 mt-2'> {column}</div>

                              <a
                                onClick={() => setEditingColumn(index)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                              >
                                <KTIcon iconName='pencil' className='fs-3' />
                              </a>
                              <a
                                onClick={() => deleteColumn(column)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              >
                                <KTIcon iconName='trash' className='fs-3' />
                              </a>
                            </div>
                          </>
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold'>
                  {tableRows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{rowIndex + 1}</td>
                      {columns.map((column) => (
                        <td key={column}>
                          <input
                            className='form-control form-control-solid'
                            type='text'
                            value={row[column]}
                            onChange={(e) => handleInputChange(rowIndex, column, e.target.value)}
                          />
                        </td>
                      ))}
                      <td>
                        <button
                          className='btn btn-danger btn-sm'
                          onClick={() => deleteRow(rowIndex)}
                        >
                          Delete Row
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {showRemarks && (
              <div className='mt-3'>
                <textarea
                  className='form-control form-control-solid'
                  placeholder='Enter remarks'
                  rows={4}
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
        <button className='btn btn-primary mt-3' onClick={addRow}>
          Add Row
        </button>
        <button className='btn btn-primary mt-3 ms-3' onClick={addColumn}>
          Add Column
        </button>
        <button className='btn btn-primary mt-3 ms-3' onClick={addRemarks}>
          Add Remarks
        </button>
        <button className='btn btn-warning mt-3 ms-3' onClick={() => setShowPreview(true)}>
          Preview
        </button>

        <Modal show={showPreview} onHide={() => setShowPreview(false)} size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>Preview Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id='pdfContent'>
              <div
                className='card-header cursor-pointer app_fee_card'
                style={{background: '#f2f2f2'}}
              >
                <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/uaf_logo.png')}
                    className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
                  />
                  <span className='my-font-family' style={{fontSize: '22px'}}>
                    {invoiceSubject}
                  </span>
                </div>
              </div>

              <div
                className='card-body px-9 py-5 align-items-center'
                style={{paddingLeft: '100px', paddingRight: '100px'}}
              >
                <div className='mt-2 d-flex justify-content-between align-items-center w-100'>
                  <div className='p-2'>
                    <p className='m-0'>
                      <strong>Invoice To:</strong> {invoiceTo}
                    </p>
                    <p className='m-0'>
                      <strong>Email:</strong> {invoiceEmail}
                    </p>
                    {invoiceLocation ? (
                      <p className='m-0'>
                        <strong>Location:</strong> {invoiceLocation}
                      </p>
                    ) : null}
                  </div>
                  <div className='p-4'>
                    <p className='m-0'>
                      <strong>Invoice Number:</strong> {invoiceNumber}
                    </p>
                    <p className='m-0'>
                      <strong>Issue Date:</strong> {moment().format('DD/MMM/YYYY')}
                    </p>
                    <p className='m-0'>
                      <strong>Issued By:</strong> {issuesBy}
                    </p>
                  </div>
                </div>

                <div className='mt-5 d-flex justify-content-center'>
                  <table className='table align-middle table-row-dashed fs-6 gy-5 app_fee_form'>
                    <thead>
                      <tr className='text-start fw-bold text-uppercase gs-0'>
                        <th className='min-w-20px'>S.No</th>
                        {columns.map((col) => (
                          <th className='min-w-50px' key={col}>
                            {col}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-semibold'>
                      {tableRows.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          {columns.map((col) => (
                            <td key={col}>{row[col]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {remarks ? (
                  <div className='mt-5'>
                    <p style={{fontSize: '18px'}}>{remarks}</p>
                  </div>
                ) : null}
              </div>
              <div
                    className='card-title m-0 justify-content-between align-items-center w-100 px-4 py-5'
                    style={{border: '1px solid #333'}}
                  >
                    <h4 className='fw-bolder m-0 pb-2'>International Transfers can be made to the:</h4>
                    <p className='inline_master_head m-0'>Account Name: United Accreditation Foundation Inc. </p>
                    <p className='inline_master_head m-0'>Bank of America, N.A., New York, NY , United States, 901796 , </p>
                    <p className='inline_master_head m-0'>Account Number: 435036712144 </p>
                    <p className='inline_master_head m-0'>ACH ROUTING NO. 051000017,</p>
                    <p className='inline_master_head m-0'>WIRE ROUTING NO: 026009593,</p>
                    <p className='inline_master_head m-0'>A/c with Institution BIC: BOFAUS3N.</p>
                    <p className='inline_master_head m-0'>Being a computer generated document, signature is not required</p>
                  </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setShowPreview(false)}>
              Close
            </Button>
            <Button variant='primary' onClick={handleSubmit}>
              Submit
            </Button>
            <Button variant='info' onClick={generatePDF}>
              Download as PDF
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default OtherInvoice
