import axios from 'axios';
import React, { useEffect, useState } from 'react'

type Props = {
    userId: string | undefined;
    ARC_member: string | undefined;
    ARC_no: string | undefined;
}

const GenerateApprovalReport = ({ userId, ARC_member, ARC_no }: Props) => {


    const API_URL = process.env.REACT_APP_API_URL;
    const [approvalReport, setApprovalReport] = useState<any>();
    const formatMyDate = (date: Date | string | null) => {
        if (!date) return null;
      
        // If the date is a string, parse it to a Date object
        const parsedDate = typeof date === "string" ? new Date(date) : date;
      
        // Ensure the parsed date is valid
        if (isNaN(parsedDate.getTime())) return null;
      
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
        const day = String(parsedDate.getDate()).padStart(2, "0");
      
        return `${month}-${day}-${year}`;
      };


    const getApprovalReport = () => {
        axios
            .get(`${API_URL}/arc/management/details/get/?cab=${userId}&assessor=${ARC_member}&arc_no=${ARC_no}`)
            .then((response) => {
                const result = response.data
                setApprovalReport(result[0])
            })
            .catch((error) => {
                console.error('API Error:', error)
            })
    }
    useEffect(() => {
        getApprovalReport()
    }, [])
    return (<>
        {approvalReport ? (
            <div className=' mb-5 mb-xl-10' id='kt_profile_details_view'
            style={{width:"100%",
                background: "#fff",
                padding: "25px",
                borderRadius: "5px"
              }}
            >
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>
                        Comments/Review by the Any one of the Board of Director/Senior Manager/Person nominated by Senior Manager
                        </h3>
                    </div>
                </div>
                <div className='card-body p-9'>

                    <div className='mb-5 col-lg-12'>
                        {/* <label className='required form-label'>Date/s of Witness assessment/s </label> */}
                        <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                            <thead style={{ display: "none" }}>
                                <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                    <th className=' pe-2 fs-7'></th>
                                    <th className=' pe-2 fs-7'></th>

                                </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-semibold'>

                                <tr >
                                    <td style={{ width: "50%" }}>
                                        <div className='text-gray-800 mb-1'>
                                            Are there any disagreements between the ARC Members regarding the final
                                            recommendations?
                                        </div>
                                    </td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            {approvalReport?.agreement}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            Any other Unresolved Administrative Issues?
                                        </div>
                                    </td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            {approvalReport?.issue}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            Is all the information in the ARC Folder adequate to decide that the
                                            requirements for accreditation have been fulfilled?
                                        </div>
                                    </td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            {approvalReport?.info}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            Comments (if any)- To be communicated to Senior Executive/Manager/ARC
                                            Member/CAB
                                        </div>
                                    </td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            {approvalReport?.comments}
                                        </div>
                                    </td>
                                </tr>



                            </tbody>
                        </table>
                    </div>
                    <div className='mb-5'>
                        <label className='form-label'>
                            ARC Recommendation is:
                        </label>
                        <div className='form-check mb-3'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='adequateYes'
                                name='adequate'
                                value={approvalReport?.adequate_value}
                                checked={approvalReport?.adequate_value === true}
                            //   onChange={handleCheckboxChange}
                            />
                            <label className='form-check-label' htmlFor='adequateYes'>
                                Endorsed for Issuance of Accreditation Schedule and Certificates
                            </label>
                        </div>
                        <div className='form-check'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='adequateNo'
                                name='adequate'
                                value={approvalReport?.adequate_value}
                                checked={approvalReport?.adequate_value === false}
                            //   onChange={handleCheckboxChange}
                            />
                            <label className='form-check-label' htmlFor='adequateNo'>
                                Following more information is required:
                            </label>
                        </div>
                        {approvalReport?.issurace_remark}
                    </div>


                    <div className='row mt-10'>
                        <div className='mb-5 col-lg-12'>
                            <h3>Administration Approvel and Signature: :</h3>
                            <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                                <thead>
                                    <tr className='text-start  fw-bold  text-uppercase gs-0'>

                                        <th className='pe-2 fs-7'>Date of Accreditation Decision</th>
                                        <th className='pe-2 fs-7'>Date of Accreditation Expiry:</th>
                                        <th className='pe-2 fs-7'>Name</th>
                                        <th className='pe-2 fs-7'>Signature</th>
                                    </tr>
                                </thead>
                                <tbody className='text-gray-600 fw-semibold'>
                                    <tr>

                                        <td>
                                            <div className='text-gray-800 mb-1'>
                                                {approvalReport?.decision_date && approvalReport?.decision_date ? formatMyDate(approvalReport?.decision_date) : '--'}

                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-gray-800 mb-1'>
                                                {approvalReport?.expiry_date && approvalReport?.expiry_date ? formatMyDate(approvalReport?.expiry_date) : '--'}
                                            </div>
                                        </td>
                                        <td >
                                            <div className='text-gray-800 mb-1'>
                                                {approvalReport?.name}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-gray-800 mb-1'>
                                                <img src={approvalReport?.arc_member_sign} alt="ARC Member Signature" />
                                                {/* {signatureImageData} */}
                                            </div>
                                        </td>

                                    </tr>


                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        ) : null}
    </>
    )
}

export default GenerateApprovalReport