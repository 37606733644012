/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Card4} from '../../../../_metronic/partials/content/cards/Card4'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {Button, Modal} from 'react-bootstrap'
import {toast} from 'react-toastify'
import UAFLoader from '../../../loader/UafLoader'
const API_URL = process.env.REACT_APP_API_URL
interface UserData {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: number
  company: string
  company_web: string
  subject: string
  message: string
  last_login: string
  is_cab_client: boolean
  cab_profile: string
  address: string
  country: string
  language: string
  currency: string
  img: string
  country_name: string
  programme_names: {id: string; name: string}[]
  scheme_names: {id: string; name: string}[]
  user_schemes: string
}
interface CertificateType {
  id: string
  name: string
}
export function UAFDocumentWrapper({}) {
  const BASE_URL = process.env.REACT_APP_API_URL

  const [documentDetails, setDocumentDetails] = useState<any>({
    description: '',
    documentFile: null,
    revision_number: '',
  })

  const [searchQuery, setSearchQuery] = useState('')
  const [filteredValue, setFilteredValue] = useState<UserData[]>([])
  const [loader, setLoader] = useState<boolean>(false)

  const [addDocumentModal, setAddDocumentModal] = useState<boolean>(false)

  const [documentData, setDocumentData] = useState<any>([])
  const [certificateList, setCertificateList] = useState<CertificateType>()

  const [showDeleteAssessment, setShowDeleteAssessment] = useState<any>()

  const [addAssessorDocumentModal, setAddAssessorDocumentModal] = useState<boolean>(false)
  const [assessorModal, setShowAssessorModal] = useState<boolean>(false)
  const [documentId, setDocumentId] = useState<string>()
  const [activeTab, setActiveTab] = useState('Document')
  const [assessorDocument, setAssessorDocument] = useState<any[]>([])
  const [documents, setDocuments] = useState<File[]>([])
  const [documentAssessor, setDocumentAssessor] = useState<{
    description: string
    revision_number: string
  }>({
    description: '',
    revision_number: '',
  })

  const fetchDocumentData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/global/uaf/documents/get/`)
      setDocumentData(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchAssessorData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/team/assessor/get/multiple/documents/`)
      setAssessorDocument(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchDocumentData()
    fetchAssessorData()
  }, [])

  useEffect(() => {
    const fetchCertificateList = async () => {
      try {
        const response = await axios.get(`${API_URL}/certificate/list/certification/`)
        setCertificateList(response.data)
      } catch (error) {
        // Handle errors
        console.error('API Error:', error)
        // throw error;
      }
    }
    fetchCertificateList()
  }, [])

  const handleDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files)
      setDocumentDetails((prevDetails) => ({
        ...prevDetails,
        documentFiles: [...prevDetails.documentFiles, ...filesArray],
      }))
    }
  }

  const handleDeleteDocument = (index: number) => {
    setDocuments((prev) => prev.filter((_, i) => i !== index));
  };
  

  const handleSubmit = async () => {
    try {
      if (
        !documentDetails.description ||
        documentDetails.description.trim() === '' ||
        documents.length === 0 
      ) {
        toast.error('Please enter description and upload at least one document')
        return
      }

      const formData = new FormData()
      formData.append('description', documentDetails.description)
      formData.append('revision_number', documentDetails.revision_number)
      documents.forEach((file) => {
        formData.append('document', file); 
      });

      await axios.post(`${BASE_URL}/global/uaf/documents/`, formData)
      fetchDocumentData()
      toast.success('Documents uploaded.')
      setAddDocumentModal(false)
      setDocumentDetails({description: '', revision_number: '', documentFiles: []})
      setDocuments([]);
    } catch (error) {
      toast.error('Error uploading documents')
    }
  }

  const handleDeleteAssessment = async (item) => {
    try {
      setLoader(true)
      const response = await axios.delete(`${BASE_URL}/global/uaf/documents/${item?.id}/destroy/`)
      if (response.status === 204) {
        setLoader(false)
        toast.success('Document deleted successfully')
      }
      fetchDocumentData()
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  const handleDocumentsUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const uploadedFiles = Array.from(e.target.files) // Convert FileList to Array
      setDocuments((prev) => [...prev, ...uploadedFiles]) // Append new files
    }
  }
  const handleRemoveDocument = (index: number) => {
    setDocuments((prev) => prev.filter((_, i) => i !== index))
  }

  // Handle form submission
  const handleAssessorSubmit = async () => {
    if (documents.length === 0) {
      toast.error('Please select  and upload documents.')
      return
    }

    try {
      setLoader(true)
      const formData = new FormData()
      formData.append('description', documentAssessor.description)
      formData.append('revision_number', documentAssessor.revision_number)
      // formData.append('assessor_id', JSON.stringify(selectedIds))
      documents.forEach((doc, index) => {
        formData.append(`documents`, doc)
      })
      // selectedIds.forEach((assessorid) => {
      //   formData.append(`assessor_id`,assessorid)
      // })

      const response = await axios.post(
        `${BASE_URL}/team/assessor/upload/multiple/documents/`,
        formData,
        {
          headers: {'Content-Type': 'multipart/form-data'},
        }
      )

      if (response.status === 200 || 201) {
        setLoader(false)
        toast.success('Documents uploaded successfully!')
        setAddAssessorDocumentModal(false)

        setDocuments([])
        setDocumentAssessor({description: '', revision_number: ''})
        fetchAssessorData()
      }
    } catch (error) {
      console.error('Error uploading documents:', error)
      toast.error('Failed to upload documents.')
    }
  }

  const handleDeleteAssessorDocument = async () => {
    try {
      setLoader(true)
      const response = await axios.delete(
        `${BASE_URL}/team/assessor/destroy/multiple/documents/${documentId}/`
      )
      if (response.status === 204) {
        setLoader(false)
        toast.success('Document deleted successfully')
      } else {
        setLoader(false)
        toast.info('Request completed, but no content returned')
      }
      setShowAssessorModal(false)
      fetchAssessorData()
      setDocumentId('')
    } catch (error) {
      setLoader(false)
      toast.error('Failed to delete the document')
      console.error(error)
    }
  }
  const handleShowAssessorDocument = async (id: string) => {
    setDocumentId(id)
    setShowAssessorModal(true)
  }

  return (
    <>
      {loader ? <UAFLoader /> : null}
      <div className='col-xl-12'>
        <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
          <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 p-4'>
            <li className='nav-item'>
              <div
                className={`nav-link ${activeTab === 'Document' ? 'active' : ''}`}
                onClick={() => setActiveTab('Document')}
              >
                Documents
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={`nav-link ${activeTab === 'Assessor' ? 'active' : ''}`}
                onClick={() => setActiveTab('Assessor')}
              >
                Assessor
              </div>
            </li>
          </ul>

          {activeTab === 'Document' && (
            <>
              <div className='d-flex flex-wrap flex-stack mb-6'>
                <h3 className='fw-bolder m-2'>Documents</h3>

                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    className='btn btn-primary m-2'
                    onClick={() => setAddDocumentModal(true)}
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                    Document
                  </button>
                </div>
              </div>
              {documentData?.length > 0 ? (
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5 location_table_show'
                  id='kt_table_users'
                >
                  <thead>
                    <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                      <th className='min-w-80px text-center'>S No</th>
                      <th className='min-w-80px text-center'>Revision No</th>
                      <th>Description</th>
                      <th className='min-w-125px'>Document</th>
                      <th className='text-end min-w-100px'>Action</th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-semibold'>
                    {documentData?.map((item, index) => {
                      return (
                        <tr key={item?.id}>
                          <td className='min-w-80px text-center'>{index + 1}</td>
                          <td className='min-w-80px text-center'>{item?.revision_number}</td>
                          <td>{item?.description}</td>
                          <td>
                            <a href={item?.document} target='_blank'>
                              <img src='/media/doc.png' alt='doc' />
                            </a>
                          </td>
                          <td className='text-center'>
                            <div className='dropdown'>
                              <button
                                className='btn btn-light btn-active-light-primary btn-sm dropdown-toggle'
                                type='button'
                                id={`dropdownMenuButton${index}`}
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                              >
                                Actions
                              </button>

                              <ul
                                className='dropdown-menu'
                                aria-labelledby={`dropdownMenuButton${index}`}
                              >
                                <li>
                                  <a
                                    className='dropdown-item'
                                    href='#'
                                    onClick={() => setShowDeleteAssessment(item)}
                                  >
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : null}
            </>
          )}
          {activeTab === 'Assessor' && (
            <>
              <div className='d-flex flex-wrap flex-stack'>
                <h3 className='fw-bolder m-3'>Assessor</h3>
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    className='btn btn-primary m-2'
                    onClick={() => setAddAssessorDocumentModal(true)}
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                    Assessor Document
                  </button>
                </div>
              </div>
              {assessorDocument?.length > 0 ? (
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5 location_table_show'
                  id='kt_table_users'
                >
                  <thead>
                    <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                      <th className='min-w-80px text-center'>S No</th>
                      <th className='min-w-80px text-center'>Revision No</th>
                      <th>Description</th>
                      <th className='min-w-125px'>Document</th>
                      <th className='text-end min-w-100px'>Action</th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-semibold'>
                    {assessorDocument?.map((item, index) => {
                      return (
                        <tr key={item?.id}>
                          <td className='min-w-80px text-center'>{index + 1}</td>
                          <td className='min-w-80px text-center'>{item?.revision_number}</td>
                          <td>{item?.description}</td>
                          <td>
                            <a href={item?.document} target='_blank'>
                              <img src='/media/doc.png' alt='doc' />
                            </a>
                          </td>
                          <td className='text-center'>
                            <div className='dropdown'>
                              <button
                                className='btn btn-light btn-active-light-primary btn-sm dropdown-toggle'
                                type='button'
                                id={`dropdownMenuButton${index}`}
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                              >
                                Actions
                              </button>

                              <ul
                                className='dropdown-menu'
                                aria-labelledby={`dropdownMenuButton${index}`}
                              >
                                <li>
                                  <div
                                    className='dropdown-item'
                                    onClick={() => handleShowAssessorDocument(item?.id)}
                                  >
                                    Delete
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : null}
            </>
          )}

          <Modal show={addDocumentModal} onHide={() => setAddDocumentModal(false)} size='lg'>
            <Modal.Header closeButton>
              <Modal.Title>UAF Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row col-md-12'>
                <div className='col-md-6'>
                  <input
                    className='form-control form-control-lg border-1 mb-2'
                    onChange={(e) =>
                      setDocumentDetails({...documentDetails, description: e.target.value})
                    }
                    value={documentDetails.description}
                    placeholder='Add Document Description'
                  />
                </div>
                <div className='col-md-6'>
                  <input
                    className='form-control form-control-lg border-1 mb-2'
                    onChange={(e) =>
                      setDocumentDetails({...documentDetails, revision_number: e.target.value})
                    }
                    value={documentDetails.revision_number}
                    placeholder='Add Revision Number'
                  />
                </div>
                <div className='col-md-6 d-flex justify-content-center'>
                  <div className='contact-page__input-box text-center'>
                    <label htmlFor='certification_file' className='file-upload-upload'>
                      Upload Documents
                    </label>
                    <input
                      id='certification_file'
                      type='file'
                      multiple
                      onChange={(e) => {
                        if (e.target.files) {
                          const selectedFiles = Array.from(e.target.files)
                          setDocuments((prev) => [...prev, ...selectedFiles])
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='col-md-12 mt-3'>
                  <h5>Uploaded Documents:</h5>
                  {documents.length > 0 ? (
                    <ul className='list-group'>
                      {documents.map((file, index) => (
                        <li
                          key={index}
                          className='list-group-item d-flex justify-content-between align-items-center'
                        >
                          {file.name}
                          <button
                            className='btn btn-danger btn-sm'
                            onClick={() => handleDeleteDocument(index)}
                          >
                            Delete
                          </button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No documents uploaded yet.</p>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setAddDocumentModal(false)}>
                Close
              </Button>
              <Button variant='success' onClick={handleSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={addAssessorDocumentModal}
            onHide={() => setAddAssessorDocumentModal(false)}
            size='lg'
          >
            <Modal.Header closeButton>
              <Modal.Title>Assessor Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row col-lg-12'>
                <textarea
                  name='description'
                  className='form-control form-control-lg border-1 mb-2'
                  onChange={(e) =>
                    setDocumentAssessor((prev) => ({...prev, description: e.target.value}))
                  }
                  value={documentAssessor.description}
                  placeholder='Add Subject'
                  rows={5} // Corrected the attribute to "rows"
                ></textarea>
                <div className='col-lg-6'>
                  <input
                    name=''
                    className='form-control form-control-lg border-1 mb-2'
                    id=''
                    onChange={(e) =>
                      setDocumentAssessor((prev) => ({...prev, revision_number: e.target.value}))
                    }
                    value={documentAssessor?.revision_number}
                    placeholder='Revision Number'
                  />
                </div>
                <div className='col-lg-4'>
                  <div className='contact-page__input-box text-center'>
                    <label htmlFor='certification_file' className='file-upload-upload'>
                      {documents.length > 0
                        ? `${documents.length} Documents Uploaded`
                        : 'Upload Docs'}
                    </label>
                    <input
                      id='certification_file'
                      type='file'
                      multiple // Allows multiple files
                      onChange={(e) => handleDocumentsUpload(e)}
                    />
                  </div>
                </div>
              </div>

              {/* Uploaded Documents List */}
              <div className='row mt-4'>
                {documents.length > 0 && (
                  <>
                    <h5 className='mb-3'>Uploaded Documents:</h5>
                    <ul className='list-group'>
                      {documents.map((doc, index) => (
                        <li
                          key={index}
                          className='list-group-item d-flex justify-content-between align-items-center'
                        >
                          <span>{doc.name}</span>
                          <button
                            type='button'
                            className='btn btn-sm btn-danger'
                            onClick={() => handleRemoveDocument(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setAddAssessorDocumentModal(false)}>
                Close
              </Button>
              <Button variant='success' onClick={handleAssessorSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showDeleteAssessment?.id}
            onHide={() => setShowDeleteAssessment('')}
            backdrop='static'
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3>Do you want to delete this Document ?</h3>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setShowDeleteAssessment('')}>
                Close
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  handleDeleteAssessment(showDeleteAssessment)
                  setShowDeleteAssessment('')
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={assessorModal} onHide={() => setShowAssessorModal(false)} backdrop='static'>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3>Do you want to delete this Assessor Document ?</h3>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setShowAssessorModal(false)}>
                Close
              </Button>
              <Button
                variant='danger'
                onClick={() => {
                  handleDeleteAssessorDocument()
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  )
}
