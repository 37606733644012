import axios from 'axios'
import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {toast} from 'react-toastify'
import moment from 'moment'
import {Button, Modal} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
import {log} from 'console'
import UAFLoader from '../../loader/UafLoader'
import NumberToWord from './NumberTOWord'

const BASE_URL = process.env.REACT_APP_API_URL

const AssessorJobOrder = () => {
  const [loader, setLoader] = useState<boolean>(false)
  const [assessorUser, setAssessorUser] = useState<any>()
  const [assessorId, setAssessorID] = useState<any>()
  const [assementPlannerID, setAssementPlannerID] = useState<any>()
  console.log(assementPlannerID, 'assementPlannerID')
  const [pdfInvoice, setPdfInvoice] = useState<File | null>(null)
  const [newAssessmentData, setNewAssessmentData] = useState<any>([])
  const [userRole, setUserRole] = useState<any>([])
  const [assessmentList, setAssessmentList] = useState<any>([])
  const [assessorPlannerDataFromAssessment, setAssessorPlannerDataFromAssessment] = useState<any>(
    []
  )
  console.log(assessorPlannerDataFromAssessment, 'assessorPlannerDataFromAssessment')
  const [value, setValue] = useState<any[]>([])
  const [showPreview, setShowPreview] = useState(false)
  const [createJobDetail, setCreateJobDetail] = useState({
    cab: '',
    assessor_planner: '',
    // assessment: "",
    assessor: '',
    // assessor_role: "",
    // system: "",
    // on_off_site: "",
    total_manday: 0,
    manday_rate: 0,
    arrangements_assessor: 0,
    value_of_job_usd: 0,
    job_order_approved: '',
    job_order_issued: '',
    value_of_job_words: '',
    job_order_number: '',
    // planner_scheme: "",
  })
  useEffect(() => {
    axios
      .get(`${BASE_URL}/team/accessor/verified/list/pagination/`)
      .then((response) => {
        const data = response.data?.results?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        }))
        setValue(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  const getAssessorDataByAssessment = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/cab/filter/scheme/assessor/planner/pagination/?assessor=${assessorId}}`
        //  `${BASE_URL}/cab/assessor/planner/scheme/data/filter/?assessor=${urlId}`
      )
      const result = response.data.results
      setAssessorPlannerDataFromAssessment(result)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAssessorDataByAssessment()
  }, [assessorId])
  const getAssessmentList = () => {
    axios
      .get(`${BASE_URL}/cab/assessment/list/`)
      .then((response) => {
        const data = response.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }))
        setAssessmentList(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    getAssessmentList()
  }, [])
  const formatMyDate = (date: Date | string | null) => {
    if (!date) return null;
  
    // If the date is a string, parse it to a Date object
    const parsedDate = typeof date === "string" ? new Date(date) : date;
  
    // Ensure the parsed date is valid
    if (isNaN(parsedDate.getTime())) return null;
  
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
  
    return `${month}-${day}-${year}`;
  };
  const fetchRole = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/role/list/`)
      setUserRole(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchRole()
  }, [])
  useEffect(() => {
    if (assementPlannerID) {
      setCreateJobDetail({
        ...createJobDetail,
        assessor_planner: assementPlannerID?.assessor_planner,
        // assessor_role: userRole?.find(item => item.name === (assementPlannerID?.assessor_name.find((i) => i?.[0].assessor_id === assessorId)?.[0]?.role))?.id,
        cab: assementPlannerID?.cab,
        // assessment: assessmentList?.find(item => item.label === assementPlannerID?.assessment_type)?.value,
        assessor: assementPlannerID?.assessor_name?.find(
          (i) => i?.[0].assessor_id === assessorId
        )?.[0].assessor_id,
        // on_off_site: assementPlannerID?.assessor_data.find((i) => i.assessor?.assessor_id === assessorId)?.location,
        // system: assementPlannerID?.scheme
      })
    }
  }, [assementPlannerID])
  useEffect(() => {
    if (assessorId) {
      setCreateJobDetail({...createJobDetail, assessor: assessorId})
    }
  }, [assessorId])
  // useEffect(() => {
  //   if (newAssessmentData) {
  //     setCreateJobDetail({ ...createJobDetail, planner_scheme: newAssessmentData?.map((i) => i.id) })
  //   }
  // }, [newAssessmentData])

  const getAssessorsData = () => {
    axios
      .get(
        `${BASE_URL}/cab/assessor/planner/scheme/data/filter/?assessor=${assessorId}&assessor_planner=${assementPlannerID?.assessor_planner}`
      )
      .then((response) => {
        const data = response?.data
        setNewAssessmentData(data)
      })
      .catch((error) => {
        // Handle errors
        console.error('API Error:', error)
      })
  }
  useEffect(() => {
    if (assementPlannerID?.assessor_planner) {
      getAssessorsData()
    }
  }, [assementPlannerID?.assessor_planner])
  const validate = () => {
    if (!createJobDetail.job_order_number) {
      toast.error('Please Enter Job Order Number')
      return false
    }
    if (!createJobDetail.total_manday) {
      toast.error('Please Enter Total Mandays')
      return false
    }
    if (!createJobDetail.manday_rate) {
      toast.error('Please Enter Manday Rate')
      return false
    }
    if (!createJobDetail.arrangements_assessor) {
      toast.error('Please Enter Travel Arrangements')
      return false
    }
    if (!createJobDetail.value_of_job_usd) {
      toast.error('Please Enter Value of Job Order')
      return false
    }
    // if (createJobDetail.job_order_approved?.trim() === '' || !createJobDetail.job_order_approved) {
    //   toast.error('Please Enter Job Order Approved By')
    //   return false
    // }
    // if (createJobDetail.job_order_issued?.trim() === '' || !createJobDetail.job_order_issued) {
    //   toast.error('Please Enter Job Order Issued By')
    //   return false
    // }
    // if (createJobDetail.value_of_job_words?.trim() === '' || !createJobDetail.value_of_job_words) {
    //   toast.error('Please Enter Total Value')
    //   return false
    // }

    return true
  }
  // const createJobPost = async () => {
  //   if (!validate()) return

  //   try {
  //     const response = await axios.post(`${BASE_URL}/cab/job/order/post/`, createJobDetail);
  //     toast.success("Job Order Created Successfully")
  //     getAssessorDataByAssessment()
  //     setCreateJobDetail({
  //       ...createJobDetail,
  //       // assessment: "",
  //       assessor: "",
  //       // assessor_role: "",
  //       // system: "",
  //       // on_off_site: "",
  //       total_manday: "",
  //       manday_rate: "",
  //       arrangements_assessor: "",
  //       value_of_job_usd: "",
  //       job_order_approved: "",
  //       job_order_issued: "",
  //       value_of_job_words: "",
  //       job_order_number:"",
  //       // planner_scheme: "",
  //     })

  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  const createJobPost = async () => {
    if (!validate()) return

    try {
      setLoader(true)
      const formData = new FormData()
      Object.keys(createJobDetail).forEach((key) => {
        formData.append(key, createJobDetail[key])
      })

      if (pdfInvoice instanceof File) {
        formData.append('pdf_invoice', pdfInvoice)
      } else {
        console.error('pdfInvoice is not a valid File object')
        return
      }
      const response = await axios.post(`${BASE_URL}/cab/job/order/post/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Required for file uploads
        },
      })
      if (response.status === 200) {
        setLoader(false)
        toast.success('Job Order Created Successfully')

        // Fetch updated data and reset form details
        getAssessorDataByAssessment()
        setCreateJobDetail({
          ...createJobDetail,
          assessor: '',
          total_manday: 0,
          manday_rate: 0,
          arrangements_assessor: 0,
          value_of_job_usd: 0,
          job_order_approved: '',
          job_order_issued: '',
          value_of_job_words: '',
          job_order_number: '',
        })
      } else {
        toast.error('Failed to create job order. Please try again.')
        setLoader(false)
      }
    } catch (error) {
      console.error(error)
      setLoader(false)
      toast.error('Failed to create job order. Please try again.')
    }
  }

  const CreatePDF = async (): Promise<File> => {
    return new Promise((resolve, reject) => {
      const input = document.getElementById('pdfContent')
      if (input) {
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'mm', 'a4')
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = pdf.internal.pageSize.getHeight()

            // Define margins
            const margin = 10 // 10mm margin
            const contentWidth = pdfWidth - margin * 2
            const contentHeight = pdfHeight - margin * 2
            const imgProps = pdf.getImageProperties(imgData)
            const imgHeight = (imgProps.height * contentWidth) / imgProps.width

            let heightLeft = imgHeight
            let position = margin
            pdf.addImage(imgData, 'PNG', margin, position, contentWidth, imgHeight)
            heightLeft -= contentHeight

            // Add additional pages if needed
            while (heightLeft > 0) {
              position = heightLeft - (imgHeight + margin)
              pdf.addPage()
              pdf.addImage(imgData, 'PNG', margin, position, contentWidth, imgHeight)
              heightLeft -= contentHeight
            }
            const pdfBlob = pdf.output('blob')
            const file = new File(
              [pdfBlob],
              `job_order_${assementPlannerID?.assessment_number}.pdf`,
              {
                type: 'application/pdf',
              }
            )
            resolve(file) // Return the file
          })
          .catch((error) => {
            console.error('Error creating PDF:', error)
            reject(error)
          })
      } else {
        console.error("Element with id 'pdfContent' not found.")
        reject(new Error("Element with id 'pdfContent' not found."))
      }
    })
  }
  const generatePDF = () => {
    const input = document.getElementById('pdfContent')
    if (input) {
      setLoader(true)
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'mm', 'a4')
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()

        // Define margins
        const margin = 10 // 10mm margin
        const contentWidth = pdfWidth - margin * 2
        const contentHeight = pdfHeight - margin * 2

        const imgProps = pdf.getImageProperties(imgData)
        const imgHeight = (imgProps.height * contentWidth) / imgProps.width

        let heightLeft = imgHeight
        let position = margin

        // Add the first page
        pdf.addImage(imgData, 'PNG', margin, position, contentWidth, imgHeight)
        heightLeft -= contentHeight

        // Add additional pages if needed
        while (heightLeft > 0) {
          position = heightLeft - (imgHeight + margin)
          pdf.addPage()
          pdf.addImage(imgData, 'PNG', margin, position, contentWidth, imgHeight)
          heightLeft -= contentHeight
        }
        setLoader(false)
        pdf.save(`job_order_${assementPlannerID?.assessment_number}.pdf`)
      })
    } else {
      console.error("Element with id 'pdfContent' not found.")
      setLoader(false)
    }
  }
  const handleSaveAndSubmit = async () => {
    try {
      // Await the PDF generation to get the resolved File object
      const pdfFile = await CreatePDF()

      setPdfInvoice(pdfFile)
    } catch (error) {
      console.error('Error during Save & Submit:', error)
    }
  }
  useEffect(() => {
    if (pdfInvoice) {
      createJobPost()
    }
  }, [pdfInvoice])
  useEffect(() => {
    // Calculate the total value dynamically
    const totalMandays = parseFloat(createJobDetail.total_manday.toString()) || 0
    const mandayRate = parseFloat(createJobDetail.manday_rate.toString()) || 0
    const travelCost = parseFloat(createJobDetail.arrangements_assessor.toString()) || 0

    const totalValue = (totalMandays * mandayRate) + travelCost

    setCreateJobDetail((prevState) => ({
      ...prevState,
      value_of_job_usd: parseFloat(totalValue.toFixed(2)), // Convert to number
    }))
  }, [
    createJobDetail.total_manday,
    createJobDetail.manday_rate,
    createJobDetail.arrangements_assessor,
  ])
  return (
    <>
      {loader ? <UAFLoader /> : null}
      <div className='row'>
        <div className='mb-5 col-6'>
          <label className='required form-label'>Select Assessor</label>
          <Select
            id='select_user'
            name='User'
            options={value}
            className='react-select-styled'
            classNamePrefix='react-select'
            value={assessorUser}
            onChange={(e) => {
              setAssessorUser(e)
              setAssessorID(e?.value)
            }}
          />
        </div>
        <div className='mb-5 col-6 px-10 py-5 '>
          <h4 className='fw-bolder mb-2'>
            Assessor: <span className='inline_master_head'>{assessorUser?.label}</span>
          </h4>
        </div>

        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-120px'>Assessment Number</th>
                <th className='min-w-120px'>Assessment Type</th>
                <th className='min-w-120px'>Scheme</th>
                <th className='min-w-100px '>Assessment Date</th>
                <th className='min-w-100px '>Mandays</th>
                <th className='min-w-100px '>COI</th>
                <th className='min-w-100px '>Order Number</th>
                <th className='min-w-100px '>Action</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {assessorPlannerDataFromAssessment?.map((item) => (
                <>
                  {item?.assessor_data?.find((i) => i?.assessor?.assessor_id === assessorId) ? (
                    <tr key={item.id}>
                      <td className='min-w-125px ps-4 text-dark fw-bold text-hover-primary  fs-6'>
                        {item?.assessment_number}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary  fs-6'>
                        {item?.assessment_type}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary  fs-6'>
                        {item?.scheme_short_name}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>
                        {item && item.create_at ? formatMyDate(item.create_at) : ''}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>
                        {item?.assessment_duration}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary  fs-6'>
                        {item?.assessor_data?.find((i) => i?.assessor?.assessor_id === assessorId)
                          ?.coi_assessor === true &&
                        item?.assessor_data?.find((i) => i?.assessor?.assessor_id === assessorId)
                          ?.coi_cab === true ? (
                          <>
                            <div className='badge badge-light fw-bolder'>
                              <div className='badge badge-light-success fw-bold'>Approved...</div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='badge badge-light fw-bolder'>
                              <div className='badge badge-light-danger fw-bold'>Pending...</div>
                            </div>
                          </>
                        )}
                      </td>
                      <td>
                        {item?.assessor_data?.find((i) => i?.assessor?.assessor_id === assessorId)
                          ?.assessor_job_order?.length ? (
                          <>
                            <div className='badge badge-light fw-bolder'>
                              <div className='badge badge-light-success fw-bold'>
                                {
                                  item?.assessor_data?.find(
                                    (i) => i?.assessor?.assessor_id === assessorId
                                  )?.assessor_job_order?.[0]?.job_order_number
                                }
                              </div>
                            </div>
                          </>
                        ) : (
                          '--'
                        )}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary  fs-6'>
                        <button
                          className='btn btn-primary btn-active-light-primary btn-sm '
                          type='button'
                          onClick={() => setAssementPlannerID(item)}
                        >
                          create Job Order
                        </button>
                      </td>
                    </tr>
                  ) : null}
                </>
              ))}
            </tbody>
          </table>
        </div>

        {assementPlannerID ? (
          <>
            <div className='card mb-5 mb-xl-10'>
              <div className='card-body px-9 py-5'>
                <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                  <h4 className='fw-bolder m-0'>Job Order Number:</h4>
                  <input
                    type='text'
                    className='form-control form-control-solid w-50'
                    placeholder='--'
                    onChange={(e) =>
                      setCreateJobDetail({
                        ...createJobDetail,
                        job_order_number: e.target.value || '',
                      })
                    }
                    value={createJobDetail?.job_order_number}
                  />
                </div>
                <hr className='jo_hr' />
                <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                  <h4 className='fw-bolder m-0'>Job Order date:</h4>
                  <input
                    type='text'
                    className='form-control form-control-solid w-50'
                    placeholder='--'
                    value={moment(new Date()).format('DD/MMM/YYYY')}
                  />
                </div>

                <hr className='jo_hr' />
                <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                  <h4 className='fw-bolder m-0'>Total Mandays:</h4>
                  <input
                    type='number'
                    className='form-control form-control-solid w-50'
                    placeholder='--'
                    onChange={(e) => {
                      const rawValue = e.target.value // Get the raw value from the input
                      const sanitizedValue = rawValue.replace(/^0+(?=\d)/, '') // Remove leading zeros
                      const numericValue = sanitizedValue === '' ? 0 : Number(sanitizedValue) // Default to 0 if empty
                      setCreateJobDetail({
                        ...createJobDetail,
                        total_manday: numericValue, // Update state with sanitized number
                      })
                    }}
                    value={createJobDetail.total_manday.toString()} // Convert the value to a string for the input
                  />
                </div>
                <hr className='jo_hr' />
                <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                  <h4 className='fw-bolder m-0'>Manday Rate:</h4>
                  <input
                    type='number'
                    className='form-control form-control-solid w-50'
                    placeholder='--'
                    onChange={(e) => {
                      const rawValue = e.target.value // Get the raw value from the input
                      const sanitizedValue = rawValue.replace(/^0+(?=\d)/, '') // Remove leading zeros
                      const numericValue = sanitizedValue === '' ? 0 : Number(sanitizedValue) // Default to 0 if empty
                      setCreateJobDetail({
                        ...createJobDetail,
                        manday_rate: numericValue, // Update state with sanitized number
                      })
                    }}
                    value={createJobDetail.manday_rate.toString()} // Convert the value to a string for the input
                  />
                </div>
                <hr className='jo_hr' />
                <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                  <h4 className='fw-bolder m-0'>Travel Arrangements by Assessors if any:</h4>
                  <input
                    type='number'
                    className='form-control form-control-solid w-50'
                    placeholder='--'
                    onChange={(e) => {
                      const rawValue = e.target.value // Get the raw value from the input
                      const sanitizedValue = rawValue.replace(/^0+(?=\d)/, '') // Remove leading zeros
                      const numericValue = sanitizedValue === '' ? 0 : Number(sanitizedValue) // Default to 0 if empty
                      setCreateJobDetail({
                        ...createJobDetail,
                        arrangements_assessor: numericValue, // Update state with sanitized number
                      })
                    }}
                    value={createJobDetail.arrangements_assessor.toString()} // Convert the value to a string for the input
                  />
                </div>
                <hr className='jo_hr' />
                <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                  <h4 className='fw-bolder m-0'>Total Value of Job Order in USD:</h4>
                  <input
                    type='number'
                    className='form-control form-control-solid w-50'
                    placeholder='--'
                    value={createJobDetail.value_of_job_usd}
                    readOnly
                  />
                </div>
                {/* <hr className='jo_hr' />
                <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                  <h4 className='fw-bolder m-0'>Job Order Approved By:</h4>
                  <input
                    type='text'
                    className='form-control form-control-solid w-50'
                    placeholder='--'
                    onChange={(e) =>
                      setCreateJobDetail({
                        ...createJobDetail,
                        job_order_approved: e.target.value || '',
                      })
                    }
                    value={createJobDetail?.job_order_approved}
                  />
                </div> */}
                {/* <hr className='jo_hr' />
                <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                  <h4 className='fw-bolder m-0'>Job Order Issued By:</h4>
                  <input
                    type='text'
                    className='form-control form-control-solid w-50'
                    placeholder='--'
                    onChange={(e) =>
                      setCreateJobDetail({
                        ...createJobDetail,
                        job_order_issued: e.target.value || '',
                      })
                    }
                    value={createJobDetail?.job_order_issued}
                  />
                </div> */}
                <hr className='jo_hr' />
                <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                  <h4 className='fw-bolder m-0'>Net Payable on account of this job order:</h4>
                  <input
                    type='text'
                    className='form-control form-control-solid w-50'
                    placeholder='--'
                    readOnly
                    value={NumberToWord(Math.round(createJobDetail.value_of_job_usd))}
                  />
                </div>

                <hr className='jo_hr' />
                <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                  <h4 className='fw-bolder m-0'>
                    {' '}
                    Complete Name and Address of Certification Body:{' '}
                    <span className='inline_master_head'>{assementPlannerID?.company_name}</span>
                  </h4>
                </div>
                <hr className='jo_hr' />
                <div
                  className='card-title m-0 justify-content-between align-items-center w-100 px-4 py-5'
                  style={{border: '1px solid #333'}}
                >
                  <h4 className='fw-bolder m-0 pb-2'>Issued on behalf of Director</h4>
                  <p className='inline_master_head m-0'>
                    United Accreditation Foundation Inc (UAF){' '}
                  </p>
                  <img
                    alt='Logo'
                    src={'/media/logos/uaf_logo.png'}
                    className='h-30px h-lg-60px py-2 app-sidebar-logo-default theme-light-show'
                  />
                  <p className='inline_master_head m-0'>1060 Laskin Road, </p>
                  <p className='inline_master_head m-0'>Suite 12B/13B, </p>
                  <p className='inline_master_head m-0'>Virginia Beach VA 23451, </p>
                  <p className='inline_master_head m-0'>United States of America</p>
                  <p className='inline_master_head m-0'>+1-757-228-5581</p>
                  <p className='inline_master_head m-0'>www.uafaccreditation.org </p>
                </div>
              </div>
            </div>
            <div className='d-flex gap-5'>
              {/* <button className='thm-btn' onClick={createJobPost}>
            Send
          </button> */}
              <button
                id='create_assessment_inovice'
                className='btn btn-warning'
                onClick={() => setShowPreview(true)} /*  */
              >
                Preview
              </button>
            </div>
          </>
        ) : null}

        <Modal show={showPreview} onHide={() => setShowPreview(false)} size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>Preview Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id='pdfContent'>
              <div className=''>
                <div className='' style={{border: '1px solid #333'}}>
                  <div className='d-flex'>
                    <div className=''>
                      <img
                        alt='Logo'
                        src={'/media/logos/uaf_logo.png'}
                        className='p-1  app-sidebar-logo-default theme-light-show'
                        style={{
                          width: '250px',
                          height: '120px',
                          border: '1px solid #333',
                        }}
                      />
                    </div>
                    <div className='text-center' style={{border: '1px solid #333'}}>
                      <h2 style={{padding: '35px 120px 0px 121px'}}>
                        UNITED ACCREDITATION FOUNDATION INC,
                        <br /> 1060 LASKIN ROAD, SUITE 12B/13B, VIRGINIA BEACH, VA 23451
                      </h2>
                    </div>
                  </div>
                  <div className='row' style={{padding: '0px 10px 0px 10px', fontSize: '18px'}}>
                    <div className='col-3' style={{border: '1px solid #333'}}>
                      <div className='p-2'>Job Order Number </div>
                    </div>
                    <div className='col-3' style={{border: '1px solid #333'}}>
                      <div className='p-2'>{createJobDetail?.job_order_number}</div>
                    </div>
                    <div className='col-3' style={{border: '1px solid #333'}}>
                      <div className='p-2'>Dated</div>
                    </div>
                    <div className='col-3' style={{border: '1px solid #333'}}>
                      <div className='p-2'> {moment(new Date()).format('DD/MMM/YYYY')}</div>
                    </div>
                  </div>
                  <div className='row' style={{padding: '0px 10px 0px 10px', fontSize: '18px'}}>
                    <div className='col-6' style={{border: '1px solid #333'}}>
                      <div className='p-2'>To</div>
                    </div>
                    <div className='col-6' style={{border: '1px solid #333'}}>
                      {assessorUser?.label}
                    </div>
                  </div>
                  <div style={{padding: '0px 10px 0px 10px', border: '1px solid #333'}}>
                    <div className='row text-center' style={{fontSize: '18px'}}>
                      <div className='' style={{padding: '20px 0px 20px 0px'}}>
                        <h2>Assessment Job Order</h2>
                        <span>{assementPlannerID?.assessment_number}</span>
                      </div>
                    </div>
                  </div>
                  <div style={{padding: '0px 10px 0px 10px', border: '1px solid #333'}}>
                    <div className='row text-center' style={{fontSize: '25px'}}>
                      <div className='' style={{padding: '20px 0px 20px 0px'}}>
                        <h2 style={{fontSize: '25px'}}>Certification Body:</h2>
                        <span style={{fontSize: '18px'}}> {assementPlannerID?.company_name} </span>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    <table
                      className=' table align-middle table-row-dashed fs-6 gy-5 app_fee_form'
                      style={{border: '1px solid #333', marginBottom: 0}}
                    >
                      <thead>
                        <tr className='text-start  fw-bold  text-uppercase gs-0'>
                          <th className='min-w-20px ' style={{paddingLeft: '20px'}}>
                            S.No
                          </th>
                          <th className='min-w-50px'>Details</th>
                          <th className='min-w-50px'>Total Assessor days</th>
                          <th className='min-w-50px'>Assessor day Fee ($)</th>
                          <th className='min-w-50px'>Total Amount USD($)</th>
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-semibold'>
                        <tr>
                          <td style={{paddingLeft: '25px'}}>1</td>
                          <td>{assementPlannerID?.assessment_type}</td>

                          <td>{createJobDetail?.total_manday}</td>
                          <td>{createJobDetail?.manday_rate}</td>
                          <td>
                            {Number(createJobDetail?.total_manday) *
                              Number(createJobDetail?.manday_rate)}
                          </td>
                        </tr>

                        <tr>
                          <td style={{paddingLeft: '25px'}}>3</td>
                          <td>Travel Arrangements by Assessors if any</td>
                          <td></td>
                          <td></td>
                          <td>{createJobDetail?.arrangements_assessor}</td>
                        </tr>
                        <tr>
                          <td style={{paddingLeft: '25px'}}>4</td>
                          <td>Total Value of Job Order in USD: </td>
                          <td></td>
                          <td></td>
                          <td>{createJobDetail?.value_of_job_usd}</td>
                        </tr>
                        <tr>
                          <td style={{paddingLeft: '25px'}}>5</td>

                          <td>Net Payable on account of this job order </td>
                          <td></td>
                          <td></td>
                          <td>{NumberToWord(Math.round(createJobDetail.value_of_job_usd))}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      className='card-title m-0 justify-content-between align-items-center w-100 px-4 py-5'
                      style={{border: '1px solid #333'}}
                    >
                      <h4 className='fw-bolder m-0 pb-2'>Issued on behalf of Director</h4>
                      <p className='inline_master_head m-0'>
                        United Accreditation Foundation Inc (UAF){' '}
                      </p>
                      <img
                        alt='Logo'
                        src={'/media/logos/uaf_logo.png'}
                        className='h-30px h-lg-60px py-2 app-sidebar-logo-default theme-light-show'
                      />
                      <p className='inline_master_head m-0'>1060 Laskin Road, </p>
                      <p className='inline_master_head m-0'>Suite 12B/13B, </p>
                      <p className='inline_master_head m-0'>Virginia Beach VA 23451, </p>
                      <p className='inline_master_head m-0'>United States of America</p>
                      <p className='inline_master_head m-0'>+1-757-228-5581</p>
                      <p className='inline_master_head m-0'>www.uafaccreditation.org </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setShowPreview(false)}>
              Close
            </Button>

            <Button variant='primary' onClick={handleSaveAndSubmit}>
              Save & Submit
            </Button>

            <Button variant='info' onClick={generatePDF}>
              Download as PDF
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default AssessorJobOrder
