/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, ChangeEvent} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {getUserDetails, handledoc} from './AssessorFunction'
import {UserDataType} from '../../AssessorInterface'
import {ChangeStatus} from './AssessorFunction'
import {Button, Dropdown, DropdownButton, Modal} from 'react-bootstrap'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import axios from 'axios'
import {toast} from 'react-toastify'
import UAFLoader from '../../../../loader/UafLoader'
import {debounce, IconButton, Tooltip} from '@mui/material'
const API_URL = process.env.REACT_APP_API_URL
interface AssessorHeaderProps {
  userId: string
}
interface Document {
  name: string
  file: File
}
const AssessorHeader: React.FC<AssessorHeaderProps> = ({userId}) => {
  const is_staff = localStorage.getItem('is_staff')
  const is_superuser = localStorage.getItem('is_superuser')
  console.log(is_staff, 'is_staff')
  const location = useLocation()
  const navigate = useNavigate()
  const userIdObject = JSON.parse(userId)
  const uuid = userIdObject['*']
  const uuidRegex =
    /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  const uuids = uuid.match(uuidRegex)
  const urlId = uuids[0]
  console.log(urlId, 'urlId')
  const [sendShow, setSendShow] = useState(false)
  const [showDocumentModal, setShowDocumentModal] = useState(false)
  const [userData, setUserData] = useState<UserDataType>()
  const [documentData, setDocumentData] = useState<any>()
  const [editorData, setEditorData] = useState('')
  const [documentDetails, setDocumentDetails] = useState<Document[]>([])
  const [numFields, setNumFields] = useState(1)
  const [loader, setLoader] = useState<any>(false)
  const [uploadButtonLabels, setUploadButtonLabels] = useState<string[]>(['Upload'])
  const [documentNames, setDocumentNames] = useState<string[]>([''])
  const [disabledUploaders, setDisabledUploaders] = useState<boolean[]>([false])
  const [subject, setSubject] = useState<string>('') // State for subject
  const [remarks, setRemarks] = useState<string>('') // State for remarks
  const [isDisabled, setIsDisabled] = useState(false)
  const [rejectPop, setRejectPop] = useState<any>();
 const [rejectionReason, setRejectionReason] = useState<any>();
 const [showApproveModal,setShowApproveModal] = useState(false);
  const fetchData = async () => {
    try {
      const result = await getUserDetails(urlId)
      setDocumentData(result.documents)
      setUserData(result.details)
    } catch (error) {
      // Handle errors
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  const handleClick = () => {
    setShowApproveModal(true);
    // ChangeStatus(urlId, navigate)
  }
  const handleApproveClose = () => {
    setShowApproveModal(false);
  }

  const handleApproveAssessor = () => {
    ChangeStatus(urlId, navigate)
  }


  const handleRejectPop =  () => {
    setRejectPop(true);
   }
   const handleClosePop =  () => {
     setRejectPop(false);
    setRejectionReason("");
    }
    const rejectValid = () => {
      if (!rejectionReason) {
        toast.error('Enter Reason'); // Notify the user if no reason is provided
        return false; // Return false to indicate invalid input
      }
      return true; // Return true if validation passes
    };
  const handleReject = async () => {
    if (!rejectValid()) {
      return; // Exit if validation fails
    }
    const rejectionPayload = { rejection_reason: rejectionReason };
    try {
      const response = await axios.post(`${API_URL}/team/reject/accessor/potential/${urlId}/`,rejectionPayload)
      if (response.status === 200) {
        toast.success('Assessor is rejected');
        fetchData(); 
        handleClosePop();
      }
    } catch (error) {
      console.error('Error rejecting assessor:', error);
    toast.error('Failed to reject assessor');
    }
  }
  const handleSendClose = () => setSendShow(false)
  const handleSendShow = () => setSendShow(true)
  const handleInputChange = (event) => {
    const {name, value} = event.target
    // setFormData({
    //   ...formData,
    //   [name]: value
    // });
  }
  const handledocument = async () => {
    // const { name, value } = event.target;
    // setFormData({
    //   ...formData,
    //   [name]: value
    // });

    await handledoc(urlId) // Wait for handledoc to complete
    fetchData()
    // Then call fetchData
  }
  const handleNewDocument = async () => {
    setShowDocumentModal(true)
  }

  const handleClose = async () => {
    setShowDocumentModal(false)
    setRemarks('')
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData()
    // setFormData({
    //   ...formData,
    //   EditorData: data
    // });
  }
  const handleDocumentUpload = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.files && e.target.files.length > 0) {
      // Check if files is not null and has items
      const file = e.target.files[0] // Store the file for easy access
      const newDocument: Document = {
        name: file.name,
        file: file,
      }

      setDocumentDetails((prevState) => {
        const updatedDetails = [...prevState]
        updatedDetails[index] = newDocument
        return updatedDetails
      })

      setDocumentNames((prevNames) => {
        const updatedNames = [...prevNames]
        updatedNames[index] = file.name // Use file here directly
        return updatedNames
      })

      setUploadButtonLabels((prevLabels) => {
        const updatedLabels = [...prevLabels]
        updatedLabels[index] = 'Document Uploaded'
        return updatedLabels
      })

      setDisabledUploaders((prevDisabled) => {
        const updatedDisabled = [...prevDisabled]
        updatedDisabled[index] = true
        return updatedDisabled
      })
    }
  }

  const deleteDocument = (index: number) => {
    setDocumentDetails((prevDetails) => prevDetails.filter((_, i) => i !== index))
    setDocumentNames((prevNames) => prevNames.filter((_, i) => i !== index))
    setUploadButtonLabels((prevLabels) => prevLabels.filter((_, i) => i !== index))
    setDisabledUploaders((prevDisabled) => prevDisabled.filter((_, i) => i !== index))
    setNumFields((prevNumFields) => prevNumFields - 1)
  }

  const addNewField = () => {
    setNumFields((prevNumFields) => prevNumFields + 1)
    setUploadButtonLabels((prevLabels) => [...prevLabels, 'Upload'])
    setDocumentNames((prevNames) => [...prevNames, ''])
    setDisabledUploaders((prevDisabled) => [...prevDisabled, false])
  }

  const handleDocumentSubmit = async () => {
    try {
      setLoader(true)
      const formData = new FormData()
      documentDetails.forEach((item) => formData.append('documents', item.file))
      formData.append('assessor', userData?.id || '')
      formData.append('remark', remarks || '')

      const response = await axios.post(`${API_URL}/team/ask/assessor/documents/`, formData, {
        headers: {'Content-Type': 'multipart/form-data'},
      })
      setLoader(false)
      toast.success('Document Uploaded')
      handleClose()
      resetDocumentFields()
    } catch (error) {
      setLoader(false)
      console.error('Error occurred while uploading documents:', error)
    }
  }

  const resetDocumentFields = () => {
    setDocumentDetails([])
    setDocumentNames([])
    setDisabledUploaders([])
    setRemarks('')
  }
  return (
    <>
      {loader ? <UAFLoader /> : null}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {userData?.first_name} {userData?.last_name}
                    </a>
                    <a href='#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {userData?.country_name}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {userData?.email}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2'
                    >
                      <KTIcon iconName='phone' className='fs-4 me-1' />
                      {userData?.phone}
                    </a>
                    {/* {userData?.resume ?(<a

                      onClick={() => handlePdfIconClick(userData?.resume)}
                      className=' me-3 p-4'
                    >
                      <img src='/media/no-doc.png' alt='' />
                    </a>):null} */}
                  </div>
                </div>

                {/* <div className='d-flex my-2'>
                  <div className='badge badge-light-primary fw-bold fs-6'>

                    {userData?.is_potential_assessor ? 'Potential Assessor' : userData?.is_document_review_pending ? 'Document Review Pending' : userData?.pending_management_review
                      ? 'Pending Management Review' : 'Pending Review'}
                  </div>
                </div> */}

                <div className='d-flex my-4'>
                  <button
                    className='btn btn-sm btn-primary me-2 p-4'
                    onClick={() => {
                      if (!isDisabled) {
                        if (documentData?.is_document_submit) {
                          handleNewDocument()
                        } else {
                          handledocument()

                          // Disable button for 1 minute
                          setIsDisabled(true)
                          setTimeout(() => setIsDisabled(false), 60000) // 1 minute
                        }
                      }
                    }}
                    disabled={isDisabled}
                  >
                    <KTIcon iconName='check' className={`fs-3 ${isDisabled ? '' : 'd-none'}`} />
                    <span className='indicator-label'>
                      {isDisabled ? 'Please wait...' : 'Ask To Upload Doc'}
                    </span>
                    {isDisabled && (
                      <span className='indicator-progress'>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  {/* <a
                    onClick={() => handleSendShow()}
                    // href='#'
                    className='btn btn-sm btn-primary me-3 p-4'
                  >
                    Send Mail
                  </a> */}
                  {is_staff === 'true' && is_superuser === 'true' && (
                    <div className='me-0'>
                      <DropdownButton className='' id='dropdown-basic-button' title='Action'>
                        <Dropdown.Item onClick={handleClick}>Approve</Dropdown.Item>
                        <Dropdown.Item onClick={handleRejectPop}>Reject</Dropdown.Item>
                        {/* <Dropdown.Item href='#/action-3'>InProcess</Dropdown.Item> */}
                      </DropdownButton>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex my-2 fw-bold fs-6'>
                Status:{' '}
                <div className='badge badge-light-primary fw-bold fs-6'>
                  {userData?.is_potential_assessor
                    ? 'Potential Assessor'
                    : userData?.is_document_review_pending
                    ? 'Document Review Pending'
                    : userData?.pending_management_review
                    ? 'Pending Level2 Review'
                    : userData?.is_document_pending
                    ? 'Pending Document'
                    : userData?.is_potential_assessor_reject
                    ? 'Rejected Assessor'
                    : 'Pending Review'}
                </div>
                {userData?.is_potential_assessor_reject ===true ?
                <span>
                <Tooltip
                                  title={
                                    <span style={{ fontSize: '1.2rem' }}>{userData?.potential_assessor_reject_reason}</span> 
                                  } >
                                  <IconButton>
                                    <i className='fas fa-info-circle'></i>
                                  </IconButton>
                                </Tooltip>
                </span>:null}

              </div>
              {/*
            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Profile Completion</span>
                  <span className='fw-bolder fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div>
            </div> */}
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/assessor/${urlId}/overview` && 'active')
                  }
                  to={`/assessor/${urlId}/overview`}
                >
                  Overview
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/assessor/${urlId}/documents` && 'active')
                  }
                  to={`/assessor/${urlId}/documents`}
                >
                  Documents
                </Link>
              </li>
              {/* <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/assessor/${urlId}/settings` && 'active')
                  }
                  to={`/assessor/${urlId}/settings`}
                >
                  Settings
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <Modal show={sendShow} onHide={handleSendClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Send Mail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type='text'
            className='form-control form-control-lg border-1 mb-2'
            id='Subject'
            name='Subject'
            placeholder='Subject'
            onChange={handleInputChange}
            required
          />
          <CKEditor editor={ClassicEditor} data={editorData} onChange={handleEditorChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleSendClose}>
            Close
          </Button>
          <Button
            variant='success'
            onClick={() => {
              handleSendClose()
              // OnEmailSend();
              // Here you can use editorData for further processing
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDocumentModal} onHide={handleClose} size='lg' backdrop='static'>
        <div className='modal-header'>
          <h2>Upload Documents</h2>
          <button className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTIcon className='fs-1' iconName='cross' />
          </button>
        </div>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='mb-5 col-12'>
            <label className='form-label'>Remarks</label>
            <textarea
              rows={3}
              className='form-control form-control-solid'
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            ></textarea>
          </div>

          {[...Array(numFields)].map((_, index) => (
            <div className='row mb-4' key={index}>
              <div className='col-lg-6 fv-row'>
                <input
                  type='text'
                  placeholder='Document Name'
                  className='form-control form-control-lg form-control-solid mb-3'
                  value={documentNames[index] || ''}
                  disabled
                />
              </div>
              <div className='col-lg-4 fv-row'>
                <label
                  htmlFor={`documentUpload-${index}`}
                  className='file-upload-upload_upload'
                  style={{cursor: disabledUploaders[index] ? 'not-allowed' : 'pointer'}}
                >
                  {uploadButtonLabels[index]}
                </label>
                <input
                  type='file'
                  id={`documentUpload-${index}`}
                  style={{display: 'none'}}
                  onChange={(e) => handleDocumentUpload(e, index)}
                  disabled={disabledUploaders[index]}
                />
              </div>
              <div className='col-lg-2'>
                <button onClick={() => deleteDocument(index)} className='delete_butt_div'>
                  Delete
                </button>
              </div>
            </div>
          ))}

          <button onClick={addNewField} className='add_butt_div'>
            Add Other Document
          </button>
          <hr />
          <button onClick={handleDocumentSubmit} className='submit_butt_div'>
            Submit
          </button>
        </div>
      </Modal>
      <Modal show={rejectPop} onHide={handleClosePop} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reason For Rejection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <textarea
              rows={10}
              className='form-control form-control-solid'
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            ></textarea>
        
          <button className='btn btn-primary btn-sm  mt-3' type='button' onClick={handleReject}>Submit</button>
          
        </Modal.Body>
      </Modal>
       <Modal show={showApproveModal} onHide={handleApproveClose}>
              <Modal.Header closeButton>
                <Modal.Title>Assessor</Modal.Title>
              </Modal.Header>
              <Modal.Body>Has User Submitted All The Required Documents?</Modal.Body>
              <Modal.Footer>
              <Button className='btn btn-primary' onClick={handleApproveAssessor}>
                  Approve
                </Button>
                <Button className='btn btn-danger' onClick={handleApproveClose}>
                  Close
                </Button>
                
                
              </Modal.Footer>
            </Modal>
    </>
  )
}
export default AssessorHeader
